import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../../actions/userLogoutAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// Imports images from Image folder
import profileLogo from "../../../assets/images/porfile-img.png";
import logo from "../../../assets/images/logo.png";
import { useState } from "react";
const AdminHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitFormLoader, setSubmitFormLoader] = useState("");

  const logout = async (e) => {
    try {
      e.preventDefault();
      let userData = localStorage.getItem("id");
      setSubmitFormLoader(true);
      await dispatch(logoutAction(userData));
      setSubmitFormLoader(false);
      const { from } = { from1: { pathname: "/login" } };
      history.push(from);
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // let width=window.width;

  const menuBtn = () => {
    document.getElementsByTagName("body")[0].classList.toggle("removeBoby");
  };


  return (
    <div id="admin-dashboard">
      {submitFormLoader && <div className="loader"></div>}
      <div className="topBar">
        <button
          onClick={menuBtn}
          id="menuBtn"
          type="button"
          className="fa fa-bars"
        >
          {" "}
        </button>
        <div className="puma">
          <Link to="#">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="top-right-panel d-flex">
          <div className="top-search ">
            <form className="example">
              {/* <input type="text" placeholder="Search.." name="search" />
            <button type="submit"><i className="fa fa-search" /></button> */}
            </form>
          </div>
          <ul>
            <li>
              <Link to="#">
                <span>
                  <i className="fa fa-bell" />
                </span>
              </Link>
            </li>
            <li>
              <Link to="#">
                <span>
                  <i className="fa fa-envelope" />
                </span>
              </Link>
            </li>
            <li className="profile">
              <div className="dropdown">
                <Link
                  className="dropdown-toggle"
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img src={profileLogo} />
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link className="dropdown-item" to="#">
                    Admin Profile
                  </Link>
                  <Link to="/admin/change-password" className="dropdown-item" >
                   Change Password
                  </Link>
                  <Link className="dropdown-item" to="#" onClick={logout}>
                    Logout
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
