import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import { useEffect, useState } from "react";
import { GroupSearch } from "./groupSearch";
import { Link, useHistory } from "react-router-dom";
// import banner from '../../../assets/images/banner-flyer.jpg'
import services from "../../../services/services";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import { GroupDetail } from "../group-associate/groupdetailpopup";
import Swal from "sweetalert2";
import moment from "moment";
import DataTable from "react-data-table-component";
import * as universalFunction from "../../../utils/universalFunctions";
import ShortDesc from './shortDesc'
import Geocode from "react-geocode";
import parse from 'html-react-parser';
import { toast } from "react-toastify";
toast.configure();


const UserGroup = () => {
  const [showGroups, setGroups] = useState([]);
  const [showDesc, setShowDesc] = useState(false);
  const [getId, setId] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [page, setPage] = useState(0);
  const [showP, setShowP] = useState(false);
  const [sendData, setSendData] = useState({
    h:'',
    p:''
  });
  const [groupData, setGroupData] = useState({});
  const [locationN, setLocation] = useState({});

  let history = useHistory();
  const gDetail = (id) => {
    setId(id);
  };

  useEffect(() => {
   
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }
 
  }, [page]);

  useEffect(() => {
    if (getId) {
      services.getGroup(getId).then((response) => {
        setGroupData(response.data.data);
      });
    }
  }, [getId]);


  async function successCallback(position) {
    setSubmitFormLoader(true);
    localStorage.setItem("lat", position.coords.latitude);
    localStorage.setItem("lon", position.coords.longitude);
    fetchData();
    setSubmitFormLoader(false);
  }

  async function fetchData(search) {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY); //Insert your Google Maps API here
    try{
      setSubmitFormLoader(true)

      if (localStorage.getItem("lat")) {
        let response = await Geocode.fromLatLng(
          localStorage.getItem("lat"),
          localStorage.getItem("lon")
        );
        // console.log(response.results[0], "kkkk");
        const { address_components, place_id } = response.results[0];
        
        let area =
          address_components.length > 0 &&
          address_components.filter(
            (data) => data.types[0] === "administrative_area_level_1"
          );
        let country =
          address_components.length > 0 &&
          address_components.filter((data) => data.types[0] === "country");

          let data = await services.groupsU(page, 10, country[0]?.long_name,area[0]?.long_name,  localStorage.getItem("lat"),localStorage.getItem("lon") );
          setGroups(data?.data.data);
          setSubmitFormLoader(false)

          
          setLocation({
          groupLocationToFind: area[0]?.long_name,
          country: country[0]?.long_name,
        })
      }


  
    }catch(e){
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false)
    }

  }

  const getSearch = async (data) => {

    let nData = {...data, ...locationN,lat:localStorage.getItem("lat"),lng:localStorage.getItem("lon")};
    try {
      setSubmitFormLoader(true);
      let res = await services.searchGroup(nData);
      setGroups(res.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      console.log(e);
      setSubmitFormLoader(false);
    }
  };

  const navigateData = (id) => {
    // If geolocation is available, try to get the visitor's position
    if (!(localStorage.getItem("role") === "2")) {
      Swal.fire({
        icon: "warning",
        // title: "Oops...",
        text: "Please login first in order to enter event",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/login");
        }
      });
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }

    // Define callback function for successful attempt
    async function successCallback(position) {
      // alert("Latitude: " + position.coords.latitude + ", " +  position.coords.longitude);
      setSubmitFormLoader(true);
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
      history.push({
        pathname: `/user/associated-events-with-group/${id}`,
      });
      setSubmitFormLoader(false);
    }
  };

  // showGroups.length = 0
  const columns = [

    
    {
      name: "Enter Game",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <button onClick={() => navigateData(row.id)} className="btn btn-game">
            Enter Now
          </button>
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    
    {
      name: "Group Id",
      selector: (row) => row.groupId,
      sortable: true,
      filterable: true,
      center:true
    },
    {
      name: "Group Details ",
      selector: (row) => row.id,
      center:true,
      cell: (row) => (
        <>
          <button  onClick={() => gDetail(row.id)} className="btn btn-view">
            Group Detail
          </button>
        </>
      ),
      width: "10rem",

      button: true,
    },
    {
      name: "Group Name",
      center:true,
      selector: (row) => row.groupName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Group Type",
      selector: (row) => row.groupType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Group Location",
      selector: (row) => row.groupLocation,
      sortable: true,
      filterable: true,
    },



  ];


  const columns2 = [

    
    {
      name: "Event Type",
      selector: (row) => row.id,
      cell: (row) => `${universalFunction.findLeague(row.eventType)} (${(row.eventType)})`,
      width: "15rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    
    {
      name: "League",
      selector: (row) => universalFunction.findLeague(row.eventType, row.league),
      sortable: true,
      width: "15rem",
      center:true
    },

    {
      name: "Factors",
      selector: (row) => row?.numberOfFactor,
      center:true,
      width: "10rem",

      button: true,
    },

    {
      name: "Percentages",
      center:true,
      selector: (row) =>row?.cardParameterData[0]?.factors?.map(({factorCode,percentageOfOccurence})=><span className="p-1"><b>{factorCode}{percentageOfOccurence}</b></span>) || "",
      width: "30rem",
    },

    {
      name: "Date Start",
      selector: (row) => moment(row?.cardParameterData[0]?.winParameterStartDate).format("MM/DD/YYYY"),
      center:true,

    }


  ];


  const myFunction = (id) => {
    let dots = document.getElementById(`dots${id}`);
    let moreText = document.getElementById(`more${id}`);
    let btnText = document.getElementById(`myBtn${id}`);

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "More Details";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  };


  return (
    <>
      <UserHeader />
      {submitFormLoader && <div className="loader"></div>}
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>{ !getId ? "Groups" : "groups details"}</h1>
              { !getId &&  <><Link to="/">Home</Link>{" "}
              <span>
                <i className="fa fa-angle-right" />{" "}
              </span>
              <Link to="#" className="active">
                Groups
              </Link>
              </>
              }
            </div>
          </div>
        </div>
        {/* <GroupDetail id={getId} show1={show1} handleclose={glose} /> */}
      </section>
      {  !getId &&
      <section className="myaccount-sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <GroupSearch onclick={getSearch} />
        
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="upcoming-right">
                <DataTable
                  columns={columns}
                  data={showGroups}
              
                  pagination
                  persistTableHead
                />

                
                
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      <ShortDesc handleclose={()=>setShowP(false)} data={sendData} show={showP} />
      {  getId &&     
      <section className="grp-rules mt-5">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
          <button
           onClick={()=>setId('')}
           className="btn btn-game mb-4"
         >
           Back
         </button>
          </div>
            <div className="col-lg-12">

              <div className="heading-sec mb-5">

                <h2>Group Rules</h2>
                <p>
                  Carrejoue games are played in Groups. General rules and the
                  method of play using the sporting Event as the basis for
                  delivering results remain the same across the groups. GROUP
                  DETAILS result with the variations in laws, the number of
                  participants expected and sometimes group choice.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="rules-sequence">
                <div className="seq-numb">01</div>
                <h4>FACTORS</h4>
                <p>
                Details provide the populating percentage used for each Factor to create the 
                Game Cards for the Group.

                </p>

               {showDesc && <DataTable
                className="mt-3"
                    columns={columns2}
                    data={groupData?.allEvents}
                    pagination
                    persistTableHead
                  />}

              <Link
                  onClick={() => setShowDesc(!showDesc)}
                  to="#"
                  className="mb-4"
                >
                  {showDesc ? "Hide" : "More"} Details{" "}
                </Link>
           
                 

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">02</div>
                <h4>LOCATION</h4>
                 <p>
                {groupData?.descriptions}
                </p> 

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">03</div>
                <h4>PATTERNS PLAYED</h4>
                <p className="mb-1">
                Details provide the specific variances on Winning Patterns used
                by the Group.
                </p>
                  {/* <p>
                  { groupData?.patterns}
                  </p> */}
                                
                <Link
                  onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'PATTERNS PLAYED',p: parse(groupData?.patterns || "")})}}
                  to="#"
                  className="more-link"
                >
                More Details{" "}
                </Link>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">04</div>
                <h4>PRIZE/PAYOUT</h4>
                <p>
                  {/* Groups may not play for money by choice or because of legal
                  reasons. We want to make the game widely available for people
                  to enjoy and help the teams, their sponsors and interests.
                  Sometimes <span id={`dots${3}`}>...</span>{" "}
                  <span style={{ display: "none" }} id={`more${3}`}>
                    {" "}
                    it is available as Link Marketing/Promotional offering.
                    These game cards are free and Link nice way to learn about
                    the game and maybe get some nice swag.{" "}
                  </span> */}
                  Details provide for each pattern played the prizes won by a winner in the Group.
                </p>
                <div>

                <Link
                 onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'PRIZE/PAYOUT',p: parse(groupData?.prizePayout || "")})}}
                  to="#"
                  className="more-link"
                >
                  More Details{" "}
                </Link>


                </div>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">05</div>
                <h4>ROUNDING</h4>
                <p> {groupData?.rounding}</p>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">06</div>
                <h4>ROLLOVER/PUSHDOWN</h4>
                <p> {groupData?.rolloverPushdown}</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">07</div>
                <h4>Card Costs</h4>
                <p> {groupData?.cardCosts}</p>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">08</div>
                <h4>Organized Group Rules</h4>
                <p style={{overflow:"hidden", textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}> 
                {parse(groupData?.organizedGroupRules || "")}</p>
                <Link
                 onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'Organized Group Rules',p: parse(groupData?.organizedGroupRules || "")})}}
                  to="#"
                  className="more-link"
                >
                     More Details{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
}
      <PreviewAdvertisement />

      <UserFooter />
    </>
  );
};

export default UserGroup;
