import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import services from "../../../services/services";
import './advertisement.css'

function ModalImage({bannerImageData, gameCardImageData, sponsorRibbonImageData, handleclose, show1,   sendImage, imageData, showb }) {

  const [show, setshow] = useState({
    img: true,
    vid: false,
  });

  const [adCard, setAdCard] = useState([]);
  const [adImage, setAdImage] = useState("");

  const [gameCard, setGameCard] = useState([]);
  const [cardImage, setCardImage] = useState("");
  

  const [sponsorCard, setsponsorCard] = useState([]);
  const [sponsorRibbonImage, setsponsorImage] = useState("");

  const [pageData, setPageData] = useState({});
  const [errors, setErrors] = useState({});

  const [showImageField, setImageField] = useState(true);
  const [showVideoField, setVideoField] = useState(false);


  // console.log(gameCardImageData,'kkk');
  useEffect(()=>{
 setAdCard(bannerImageData);
 setGameCard(gameCardImageData)
 setsponsorCard(sponsorRibbonImageData)
  },[bannerImageData, gameCardImageData, sponsorRibbonImageData])


  const openImageField = (e) => {
    setAdImage("")
    setImageField(true);
  };

  const closeImageField = (e) => {
    setImageField(false);
  };

  const openVideoField = (e) => {
    setAdImage("")
    setVideoField(true);
  };

  const closeVideoField = (e) => {
    setVideoField(false);
  };

  function openImg(e) {
    const { id } = e.target;
    setshow({ [id]: true });

    if (id === "img") {
      openImageField();
      closeVideoField();
    } else {
      closeImageField();
      openVideoField();
    }
  }

  const adVideoValid= (e) => {
    let file = e.target.files[0];
    if (file?.size > 50e6) {
      toast.error("Please upload a file smaller than 10 MB", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      return false;
    }
    setAdImage(file);

  }

  const adImageValid = (e) => {
    
    if (e.target.files[0]) {
      let img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[0]);
      img.onload = () => {
        if (img.width === 1920 && img.height === 400) {
          setAdImage(e.target.files[0]);
          // setSelectedFile([event.target.files[0]]);
          return true;
        }
        toast.error(
          `this width : ${img.width}px and height : ${img.height}px not valid for Ad Banner`,
          {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          }
        );

        return true;
      };
    }
  };
  


 const handleInputChange = (e) => {
  const {name, value} = e.target;
  setPageData((p)=>({...p, [name]: value}));
 }

 const handleInputChangeSelect = (e) => {
  const {value} = e.target;

  let adImage = document.getElementById('adImgshow');
  let gameC = document.getElementById('gamecardshow');
  let sponsor = document.getElementById('sponsorShow');
if(value==="0"){
  adImage.classList.add("d-none");
  gameC.classList.add("d-none");
  sponsor.classList.add("d-none");
}
if(value==="1"){
  adImage.classList.remove("d-none");
  gameC.classList.add("d-none");
  sponsor.classList.add("d-none");

}
if(value==="2"){
  gameC.classList.remove("d-none");
  adImage.classList.add("d-none");
  sponsor.classList.add("d-none");

}
if(value==="3"){
  sponsor.classList.remove("d-none");
  adImage.classList.add("d-none");
  gameC.classList.add("d-none");
}
 }

 const sponsorImageValid = (e) => {
  try{
  if (e.target.files[0]) {
    let img = new Image();
    img.src = window.URL.createObjectURL(
      e.target.files[0]
    );
    img.onload = async () => {
      if (img.width === 160 && img.height === 60) {

        setsponsorImage(e.target.files[0])
        return true;
      }
      toast.error(`this width : ${img.width}px and height : ${img.height}px not valid for Sponsor Ribbon`, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
     
      return true;
    };
  }
}
catch(e){
  console.log(e.response);
}
}




const gameCardImageValid = (e) => {
  try{
  let img = new Image();
  img.src = window.URL.createObjectURL(
    e.target.files[0]
  );
  img.onload = () => {
    if (img.width === 300 && img.height === 1020) {
      setCardImage(e.target.files[0]);
      return true;
    }
    toast.error(`this width : ${img.width}px and height : ${img.height}px not valid for game card`, {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
   
    return true;
  };
}
catch(e){
  console.log(e.response);
}

}
 

const addSponsor = async () => {
  try{
  let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let regex = new RegExp(expression);
  let valid  = true;
  let {sponsorRibbonImageAdId, sponsorRibbonImageDescription, sponsorRibbonImageAdLink} = pageData;
  
  if(!sponsorRibbonImage){valid = false; setErrors((p)=>({...p, sponsorRibbonImage:'Please upload image'}));}
  if(!sponsorRibbonImageAdId || sponsorRibbonImageAdId==="000"){valid = false; setErrors((p)=>({...p, sponsorRibbonImageAdId:'Please Enter Valid Ad id'}));}
  if(!sponsorRibbonImageDescription) {  valid = false;  setErrors((p)=>({...p, sponsorRibbonImageDescription:'Please Enter Ad Description'}));}
  if (!sponsorRibbonImageAdLink?.match(regex)){  valid = false;  setErrors((p)=>({...p, sponsorRibbonImageAdLink:'Please Enter Valid Ad Link'}));}
// console.log(sponsorRibbonImage);
setTimeout(()=>{
  setErrors((p)=>({...p,sponsorRibbonImage:'', sponsorRibbonImageAdId :"",sponsorRibbonImageDescription :"",sponsorRibbonImageAdLink:""}));
},5000)
if(valid){
  let formData = new FormData();
  formData.append("files", sponsorRibbonImage)
  let res = await services.uploadImage(formData);
  // console.log(res.data.data[0]?.path);
  let data = {sponsorRibbonImage: res.data.data[0]?.path, sponsorRibbonImageAdId, sponsorRibbonImageDescription, sponsorRibbonImageAdLink};
// console.log(data);
const cdata = [...sponsorCard]
let filterAd = cdata.length && cdata.find((data)=>data.sponsorRibbonImageAdId == sponsorRibbonImageAdId);

if(filterAd){
  toast.warn(`Ad Id should be unique`, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "1",
  });
  return
}
  setsponsorCard((p)=>([...p, data]));
  document.getElementById("sponsorRibbonImage").value="";
  setsponsorImage('')
  setPageData((p)=>({...p, sponsorRibbonImageAdId : "",sponsorRibbonImageDescription :"",sponsorRibbonImageAdLink:""}));
}
}
  catch(e){
    console.log(e.response);
  }


}



const addGameCard = async () => {

  try{
  let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let regex = new RegExp(expression);
  let valid  = true;
  let {gameCardImageAdId, gameCardImageDescription, gameCardImageAdLink} = pageData;
  if(!cardImage) {  valid = false; setErrors((p)=>({...p, gameCardImage:'Please upload image'}));}
  if(!gameCardImageAdId || gameCardImageAdId==="000") {  valid = false; setErrors((p)=>({...p, gameCardImageAdId:'Please Enter Valid Ad id'}));}
  if(!gameCardImageDescription) { valid = false;  setErrors((p)=>({...p, gameCardImageDescription:'Please Enter Ad Description'}));}
  if(!gameCardImageAdLink?.match(regex))  {valid = false;  setErrors((p)=>({...p, gameCardImageAdLink:'Please Enter Valid Ad Link'}));}
 
// console.log(sponsorRibbonImage);
setTimeout(()=>{
  setErrors((p)=>({...p,gameCardImage:'', gameCardImageAdId :"",gameCardImageDescription :"",gameCardImageAdLink:""}));
},5000)

if(valid){
  let formData = new FormData();
  formData.append("files", cardImage)
  let res = await services.uploadImage(formData);
  let data = {gameCardImage: res.data.data[0]?.path, gameCardImageAdId, gameCardImageDescription, gameCardImageAdLink};
const cdata = [...gameCard]

let filterAd = cdata.length && cdata.find((data)=>data.gameCardImageAdId === gameCardImageAdId);
if(filterAd){
  toast.warn(`Ad Id should be unique`, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "1",
  });
  return
}
  setGameCard((p)=>([...p, data]));
  document.getElementById("gameCardImage").value="";
  setCardImage('')
  setPageData((p)=>({...p, gameCardImageAdId :"",gameCardImageDescription :"",gameCardImageAdLink:""}));
}
}
  catch(e){
    console.log(e.response);
  }


}   


const addAd = async () => {
  try{
  let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let regex = new RegExp(expression);
  let valid  = true;
  let {imageAdId, imageDescription, imageAdLink} = pageData;
  if(!adImage){valid = false; setErrors((p)=>({...p, image:'Please upload image'}));}
  if(!imageAdId || imageAdId==="000"){valid = false; setErrors((p)=>({...p, imageAdId:'Please Enter Valid Ad id'}));}
  if(!imageDescription) {  valid = false;  setErrors((p)=>({...p, imageDescription:'Please Enter Ad Description'}));}
  if(!imageAdLink?.match(regex)) {  valid = false;  setErrors((p)=>({...p, imageAdLink:'Please Enter Valid Ad Link'}));}
  
// console.log(sponsorRibbonImage);
setTimeout(()=>{
  setErrors((p)=>({...p,image:'', imageAdId :"",imageDescription :"",imageAdLink:""}));
},5000)
if(valid){
  let formData = new FormData();
  formData.append("files", adImage)
  let res = await services.uploadImage(formData);
  // console.log(res.data.data[0]?.path);
  let data = {image: res.data.data[0]?.path, imageAdId, imageDescription, imageAdLink};
// console.log(data);
const cdata = [...adCard]
let filterAd = cdata.length && cdata.find((data)=>data.imageAdId === imageAdId);

if(filterAd){
  toast.warn(`Ad Id should be unique`, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "1",
  });
  return
}

  setAdCard((p)=>([...p, data]));
  document.getElementById("adImage").value="";
  setAdImage('');
  setPageData((p)=>({...p, imageAdId : "",imageDescription :"",imageAdLink:""}));
}
}
  catch(e){
    console.log(e.response);
  }


}

const filterData = (arr, id, set) => {
 let Arr = [...arr]
 let data = Arr.filter((_,i)=>i!==id);
 set(data);
}

// show1=true


  return (
    <>
      {/* <Button variant="primary" onClick={handleshow}>
          Launch static backdrop modal
        </Button>
   */}
      <Modal
      className="Total-Revenue"
        size="xl"
        centered
        show={show1}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{showb ? "View" : "Upload"} Image / Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="col-lg-3 ">
                    <div className="dropdown">
                      <label>Advertiser Location </label>
                      <select
                        id=""
                        className="drop-button form-control"
                        name="priority"
                        onChange={handleInputChangeSelect}
                      >
                        <option value="0">Select Location</option>
                        <option value="1">Ad Banner</option>
                        <option value="2">Game Card</option>
                        <option value="3">Sponsor Ribbon</option>
  
                      </select>
                   
                    </div>
                  </div>

                  <div className="row d-none" id="adImgshow">
                  <div className="row">
                    <div className="col-md-12 ml-4"> 
                  <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subscription11"
                        checked={show.img}
                        id="img"
                        onChange={openImg}
                      />
                      <label className="form-check-label" for="subscription11">
                        Select Image
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subscription11"
                        checked={show.vid}
                        id="vid"
                        onChange={openImg}
                      />
                      <label className="form-check-label" for="subscription11">
                        Select Video
                      </label>
                    </div>
                    </div>
                  </div>
                  <div style={{display:'flex', flexWrap:'wrap', alignItems:'center'}}> 
                
                  <div className="col-lg-3">
                 
                    {showImageField && (
                      <div className="dropdown ">
                        <label>Ad Image ( 1920 * 400)</label>
                        <div className="input-group">
                          <div className="custom">
                            <input type="file" name="image" id="adImage" accept="image/x-png,image/gif,image/jpeg" onChange={adImageValid}
                              className="custom-file-input form-control"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputGroupFile04"
                            >
                              {/* Choose file */}
                              <span style={{fontSize:'12px'}} className="text">{adImage?.name || "Choose file"} </span>
                            </label>
                         
                          </div>
                      
                          <div className="errorMsg">{errors.image}</div>
                        </div>
                      </div>
                    ) }
                    { showVideoField && (
                      <div className="dropdown ">
                        <label>Ad Video</label>
                        <div className="input-group">
                          <div className="custom">
                            <input
                              type="file"
                              name="image"
                              id="adImage"
                              accept="video/mp4,video/x-m4v,video/*"
                              onChange={adVideoValid}
                              className="custom-file-input form-control"
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="inputGroupFile04"
                            >
                           <span style={{fontSize:'12px'}} className="text">{adImage?.name || "Choose file"} </span>
                            </label>
                          </div>
                          <div className="errorMsg">{errors.image}</div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-2">
                    <div className="dropdown ">
                      <label>Ad Id</label>
                      <input
                        type="text"
                        name="imageAdId"
                        placeholder="Eg:101"
                        className="form-control"
                        value={pageData?.imageAdId}
                        maxLength={3}
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.imageAdId}</div>
                    </div>
                  </div>

                  
                  <div className="col-lg-2">
                    <div className="dropdown ">
                      <label>Ad Link</label>
                      <input
                        type="text"
                        name="imageAdLink"
                        className="form-control"
                        value={pageData?.imageAdLink}
                        data-language="en"
                        placeholder="Ad Link"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.imageAdLink}</div>
                    </div>
                  </div>

                  
                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Ad Description</label>
                      <input
                        type="text"
                        name="imageDescription"
                        className="form-control"
                        value={pageData?.imageDescription}
                        data-language="en"
                        placeholder="Ad Description"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.imageDescription}</div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                  { !showb && <Button style={{padding:'9px 35px'}} onClick={addAd} variant="primary">
            Add <i className="fa fa-plus" aria-hidden="true"></i>
          </Button>}
                  </div>
                  </div>
                  <div className="col-md-12 mt-4">
                <div className="table-responsive">
                <table className="table">
                  <thead>
                  <th>Ad Image</th>
                  <th>Ad Id</th>
                  <th>Ad Link</th>
                  <th>Ad Description</th>
                  <th>Action</th>
                  </thead>
                  <tbody>
                  { adCard ? adCard.map(({image,imageAdId, imageDescription,imageAdLink},id)=>
                   <tr key={id}>
                   <td className="maxP"><a  rel="noreferrer" href={image} target="_blank">{image}</a></td>
                   <td>{imageAdId}</td> 
                   <td>{imageAdLink}</td>
                   <td>{imageDescription}</td>
                   <td>
                   <button onClick={()=>filterData(adCard,id,setAdCard)} style={{padding: '4px 10px'}} type="button" variant="danger" className="button btn-danger">
                       <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                   </td>
                   </tr>
               
                  ) : 
                  <tr>
                  <td className="text-center" colSpan="5">Not Found</td>
                  </tr>
                  }
                  
                  </tbody>
                  
                  </table>
                </div>
                </div>
</div>

                  <div className="row d-none" id="gamecardshow">
                  <div style={{display:'flex', flexWrap:'wrap', alignItems:'center'}}> 
                  <div className="col-lg-3">
                    <label>Ad Image ( 300 * 1020) </label>
                    <div className="input-group">
                      <div className="custom">
                        <input
                         id="gameCardImage"
                          type="file"
                          name="gameCardImage"
                          accept="image/x-png,image/gif,image/jpeg"
                          className="custom-file-input form-control"
                          onChange={gameCardImageValid}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile04"
                        >
                         <span style={{fontSize:'12px'}} className="text">{cardImage?.name || "Choose file"} </span>
                    
                        </label>
                      </div>
                      <div className="errorMsg">{errors.gameCardImage}</div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="dropdown ">
                      <label>Ad Id</label>
                      <input
                        type="text"
                        name="gameCardImageAdId"
                        className="form-control"
                        value={pageData?.gameCardImageAdId}
                        maxLength={3}
                        placeholder="Eg:101"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.gameCardImageAdId}</div>
                    </div>
                  </div>

                  
                  <div className="col-lg-2">
                    <div className="dropdown ">
                      <label>Ad Link</label>
                      <input
                        type="text"
                        name="gameCardImageAdLink"
                        className="form-control"
                        value={pageData?.gameCardImageAdLink}
                        data-language="en"
                        placeholder="Ad Link"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.gameCardImageAdLink}</div>
                    </div>
                  </div>

                  
                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Ad Description</label>
                      <input
                        type="text"
                        name="gameCardImageDescription"
                        className="form-control"
                        value={pageData?.gameCardImageDescription}
                        data-language="en"
                        placeholder="Ad Description"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.gameCardImageDescription}</div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                  { !showb && <Button style={{padding:'9px 35px'}} onClick={addGameCard} variant="primary">
            Add <i className="fa fa-plus" aria-hidden="true"></i>
          </Button>}
                  </div>

                  </div>
                  <div className="col-md-12 mt-4">
                <div className="table-responsive">
                <table className="table">
                  <thead>
                  <th>Ad Image</th>
                  <th>Ad Id</th>
                  <th>Ad Link</th>
                  <th>Ad Description</th>
                  <th>Action</th>
                  </thead>
                  <tbody>
                  { gameCard ? gameCard.map(({gameCardImage,gameCardImageAdId, gameCardImageDescription,gameCardImageAdLink},id)=>
                   <tr key={id}>
                   <td className="maxP"><a  rel="noreferrer" href={gameCardImage} target="_blank">{gameCardImage}</a></td>
                   <td>{gameCardImageAdId}</td>

                   <td>{gameCardImageAdLink}</td>
                   <td>{gameCardImageDescription}</td>
                   <td>
                   <button onClick={()=>filterData(gameCard,id,setGameCard)} style={{padding: '4px 10px'}} type="button" variant="danger" className="button btn-danger">
                       <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                   </td>
                   </tr>
               
                  ) : 
                  <tr>
                  <td className="text-center" colSpan="5">Not Found</td>
                  </tr>
                  }
                  
                  </tbody>
                  
                  </table>
                </div>
                </div>
</div>

                  <div className="row d-none" id="sponsorShow">
                    <div style={{display:'flex', flexWrap:'wrap', alignItems:'center'}}> 
                 <div className="col-lg-3">


                    <label>Ad Image ( 160 * 60)</label>
                    <div className="input-group">
                      <div className="custom">
                        <input
                         id="sponsorRibbonImage"
                          type="file"
                          name="sponsorRibbonImage"
                          accept="image/x-png,image/gif,image/jpeg"
                          className="custom-file-input form-control"
                          onChange={sponsorImageValid}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile04"
                        >
                        <span style={{fontSize:'12px'}} className="text">{sponsorRibbonImage?.name || "Choose file"} </span>
                        </label>
                      </div>
                      <div className="errorMsg">{errors.sponsorRibbonImage}</div>
                    </div>


                  </div>
                  <div className="col-lg-2">
                    <div className="dropdown ">
                      <label>Ad Id</label>
                      <input
                        type="text"
                        name="sponsorRibbonImageAdId"
                        className="form-control"
                        value={pageData.sponsorRibbonImageAdId}
                        data-language="en"
                        maxLength={3}
                        placeholder="Eg:101"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.sponsorRibbonImageAdId}</div>
                    </div>
                  </div>

                  
                  <div className="col-lg-2">
                    <div className="dropdown ">
                      <label>Ad Link</label>
                      <input
                        type="text"
                        name="sponsorRibbonImageAdLink"
                        className="form-control"
                        value={pageData.sponsorRibbonImageAdLink}
                        data-language="en"
                        placeholder="Ad Link"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.sponsorRibbonImageAdLink}</div>
                    </div>
                  </div>

                  
                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Ad Description</label>
                      <input
                        type="text"
                        name="sponsorRibbonImageDescription"
                        className="form-control"
                        value={pageData.sponsorRibbonImageDescription}
                        data-language="en"
                        placeholder="Ad Description"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.sponsorRibbonImageDescription}</div>
                    </div>
                  </div>

                  <div className="col-lg-2">
                 {!showb && <Button style={{padding:'9px 35px'}} onClick={addSponsor} variant="primary">
            Add <i className="fa fa-plus" aria-hidden="true"></i>
          </Button>}
                  </div>

                  </div>
                  <div className="col-md-12 mt-4">
                <div className="table-responsive">
                <table className="table">
                  <thead>
                  <th>Ad Image</th>
                  <th>Ad Id</th>
                  <th>Ad Link</th>
                  <th>Ad Description</th>
                  <th>Action</th>
                  </thead>
                  <tbody>
                  { sponsorCard ? sponsorCard.map(({sponsorRibbonImage,sponsorRibbonImageAdId, sponsorRibbonImageDescription,sponsorRibbonImageAdLink},id)=>
                   <tr key={id}>
                   <td className="maxP"><a  rel="noreferrer" href={sponsorRibbonImage} target="_blank">{sponsorRibbonImage}</a></td>
                   <td>{sponsorRibbonImageAdId}</td>

                   <td>{sponsorRibbonImageAdLink}</td>
                   <td>{sponsorRibbonImageDescription}</td>
                   <td>
                     <button onClick={()=>filterData(sponsorCard,id,setsponsorCard)} style={{padding: '4px 10px'}} type="button" variant="danger" className="button btn-danger">
                       <i className="fa fa-trash-o" aria-hidden="true"></i>
                       </button>
                       {/* <button  style={{padding: '4px 10px'}} type="button"  className="button btn-warning">
                       <i className="fa fa-pencil" aria-hidden="true"></i>
                       </button> */}
                       </td>
                   </tr>
               
                  ) : 
                  <tr>
                  <td className="text-center" colSpan="5">Not Found</td>
                  </tr>
                  }
                  
                  </tbody>
                  
                  </table>
                </div>
                </div>
                  </div>
        </Modal.Body>
        <Modal.Footer>
          <Button  variant="secondary" onClick={handleclose}>
            Close
          </Button>
         { !showb && <Button style={{padding:'9px 35px'}} onClick={()=>sendImage({bannerImageData:adCard, sponsorRibbonImageData:sponsorCard, gameCardImageData:gameCard})} variant="primary">
            Save
          </Button>}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { ModalImage };
