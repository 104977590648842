/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";

import { CommonTable } from "./dataT";

// Function for create advertisement
const WinParameter = () => {
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [winPData, setwinPData] = useState([]);
  const [page, setPage] = useState(0);
  useEffect(() => {
    getWinPInfo();
  }, [page]);

  const getWinPInfo = async (search) => {
    try {
      setSubmitFormLoader(true);
      let res = await services.winParameter(page, 10, search);
      setwinPData(res.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        <div className="rightBody">
          {submitFormLoader && <div className="loader"></div>}
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Win Parameters</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right">
                    {" "}
                    <Link to="/admin/create-win-parameter">
                      {" "}
                      <button type="button" className="btn btn-primary">
                        Add
                      </button>
                    </Link>
                  </div>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4">
                 
                </div>

                <div className="table-responsive">
                  <CommonTable
                    getlist={getWinPInfo}
                    setpage={setPage}
                    data={winPData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-foot"></div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default WinParameter;
