/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { useForm } from "react-hook-form";

toast.configure();

// Function for create advertisement
const BasketBallCallerBoard = () => {
 

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper Total-Revenue-1">
        {/* {submitFormLoader && <div className="loader"></div>} */}
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
        <div className="customTableWrapper">
          <div className="row">
            <div className="col-sm-12">
              <div className="left">
                <h4>Basketball Caller Board</h4>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-2 col-md-6">
              <div className="form-group">
                <label> Event Id</label>
                <input type="text" placeholder="C02682206" className="form-control" />
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="form-group">
                <label>Date</label>
                <input type="date" placeholder className="form-control" />
              </div>
            </div>
            <div className="grid-col">
              <div className="form-group">
                <label>Quarter</label>
                <div className="input-group" style={{width: '160px'}}>
                  <input type="button" defaultValue="-" className="button-minus icon-shape icon-sm btn btn-danger" data-field="quantity" />
                  <input type="number" step={1} max={5} defaultValue={1} name="quantity" className="quantity-field text-center w-25 form-control" />
                  <input type="button" defaultValue="+" className="button-plus icon-shape icon-sm-2 lh-0 btn btn-success" data-field="quantity" />
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3">
              <div className="form-group">
                <label> Event League</label>
                <input type="text" placeholder="NBA" className="form-control" />
              </div>
            </div>
            <div className="col-lg-3 col-md-5">
              <div className="form-group">
                <label> Arena Name</label>
                <input type="text" placeholder="Footprint Center" className="form-control" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <label> Away Name</label>
                <input type="text" placeholder="Phoenix Suns" className="form-control" />
              </div>
            </div>
            <div className="col-lg-1 col-md-4">
              <div className="form-group">
                <label> Score</label>
                <input type="text" placeholder={122} className="form-control" />
              </div>
            </div>
            <div className="col-lg-1">
              <div className="devide-sep"> </div>
            </div>
            <div className="col-lg-1 col-md-4">
              <div className="form-group">
                <label> Score</label>
                <input type="text" placeholder={115} className="form-control" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <label> Home Name	</label>
                <input type="text" placeholder="Los Angeles Lakers" className="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12">
            <div className="Total-Revenue-1">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn active">Two Point (T)</button>
                      <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn"> In the Key (K)</button> <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">And One (O)</button> <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Second Chance (S)</button> <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Free Throw (F)	</button> <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Technical FT (X)</button> <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Arc Three (A) </button> <button className="btn btn-value">0</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Corner Three (C)</button> <button className="btn btn-value">4</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Dunk (D)</button> <button className="btn btn-value">1</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Alley-Oop (P) </button> <button className="btn btn-value">15</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Add Point (U) </button> <button className="btn btn-value">0</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <button className="btn event-trigger-btn">Subtract Point (V)</button> <button className="btn btn-value">0</button>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-2">
                      <div className="form-group">
                        <label>Intiated Call </label>
                        <input type="text" placeholder="First - Passing (F)" className="form-control" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                      <button className="btn add-btn">Accept Call</button>
                      {/* <button class="btn cancel-btn ml-2">Erase Call</button> */}
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Total Actions </label>
                        <input type="text" placeholder className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-11">
                  <div className="card-design-outer">
                    <div className="card-inner-design">
                      <div className="card-wrap">
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern"> </div>
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern"> </div>
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern"> </div>
                          <div className="pattern-main" />
                        </div>
                      </div>
                      <div className="card-wrap">
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                      </div>
                      <div className="card-wrap">
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                      </div>
                      <div className="card-wrap">
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                      </div>
                      <div className="card-wrap">
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                        <div className="card-box">
                          <div className="card-pattern" />
                          <div className="pattern-main" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-baseline">
                    <div className="col-lg-4 col-md-4 text-center">  <button className="btn start-call-btn btn-block">Start Call</button></div>
                    <div className="col-lg-4 col-md-4 text-center">  <button className="btn stop-call-btn btn-block">Stop Call</button></div>
                    <div className="col-lg-4 col-md-4 text-center">  <button className="btn cancel-btn btn-block">Cancel Game</button></div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-12">
                  <div className="left mb-3">
                    <h4>Summary of Results</h4>
                  </div>
                </div>   
                <div className="col-lg-12 col-md-12">
                  <div className="table-responsive">
                    <div className="table-fixed" id="style-1">
                      <table className="table">
                        <thead className="thead-dark">
                          <tr>
                            <th>Square Number</th>
                            <th>Quarter </th>
                            <th>Away Points</th>
                            <th>Home Points</th>
                            <th>Result Description </th>
                            <th>Results </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>1</td>
                            <td>0</td>
                            <td>2</td>
                            <td>Dunk</td>
                            <td>D</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td> </td>
                            <td>1</td>
                            <td>2</td>
                            <td>Free Throw </td>
                            <td>F</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>1</td>
                            <td>0</td>
                            <td>2</td>
                            <td>Dunk</td>
                            <td>D</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td> </td>
                            <td>1</td>
                            <td>2</td>
                            <td>Free Throw </td>
                            <td>F</td>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>1</td>
                            <td>0</td>
                            <td>2</td>
                            <td>Dunk</td>
                            <td>D</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td> </td>
                            <td>1</td>
                            <td>2</td>
                            <td>Free Throw </td>
                            <td>F</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="spacer-foot" />
                  </div>
                </div>      
              </div>
            </div>
            <div className="spacing-bar" />
          </div>
        </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default BasketBallCallerBoard;
