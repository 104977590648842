/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { UserFooter } from "../../core/footer/userFooter";
import UserHeader from "../../core/header/userHeader";
import services from "../../../services/services";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import Geocode from "react-geocode";
import { Example } from "../modal";
import { GroupDetail } from "./groupdetailpopup";
import * as universalFunction from "../../../utils/universalFunctions";
import moment from "moment";
import DataTable from "react-data-table-component";
import ShortDesc from "../group/shortDesc";
import parse from 'html-react-parser';

function GroupAssociateEvent(prop) {
  const [state, setState] = useState([]);
  const [show, setShow] = useState(false);
  const [getId, setId] = useState("");
  const [pass, setPass] = useState("");
  const [saveData, setSaveData] = useState("");
  const [saveLocation, setLocation] = useState({});
  const [showDesc, setShowDesc] = useState(false);
  const [err, setErr] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  let { history, location } = prop;
  const [showP, setShowP] = useState(false);
  const [sendData, setSendData] = useState({
    h:'',
    p:''
  });
  const [groupData, setGroupData] = useState({});

  useEffect(() => {
    if (getId) {
      try {
        services.getGroup(getId).then((response) => {
          setGroupData(response.data.data);
        });
      } catch (e) {}
    }
  }, [getId]);



  useEffect(() => {
    window.scrollTo(0, 0);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }

    const fetchData = async () => {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY); //Insert your Google Maps API here
      // Geocode.enableDebug();
      try {
        if (localStorage.getItem("lat")) {
          let response = await Geocode.fromLatLng(
            localStorage.getItem("lat"),
            localStorage.getItem("lon")
          );
          // console.log(response.results[0], "kkkk");
          const { address_components, place_id } = response.results[0];
          
          let area =
            address_components.length > 0 &&
            address_components.filter(
              (data) => data.types[0] === "administrative_area_level_1"
            );
          let country =
            address_components.length > 0 &&
            address_components.filter((data) => data.types[0] === "country");

          let data = {
            groupLocation: area[0]?.long_name,
            placeId: "",
            country: country[0]?.long_name,
            eventId: prop?.match?.params.id,
            latitude: localStorage.getItem("lat"),
            longitude :localStorage.getItem("lon")
          };
          setLocation({
            groupLocation: area[0]?.long_name,
            placeId: place_id,
            country: country[0]?.long_name,
          })
          const result = await services.associatedGroups(data);
          setState(result.data.data);
        }
      } catch (e) {
        // console.log(e);
      }
    };

    fetchData();
  }, []);

  async function successCallback(position) {
    setSubmitFormLoader(true);
    localStorage.setItem("lat", position.coords.latitude);
    localStorage.setItem("lon", position.coords.longitude);
    setSubmitFormLoader(false);
  }


  const handleNavigate = (data) => {
    const {
      id,
      groupLocation,
      groupType,
      country,
      latitude,
      longitude,
      placeId,
      password,
    } = data;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback1, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }

    async function successCallback1(position) {
      setSubmitFormLoader(true);
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);

      const newData = {
        userId: Number(localStorage.getItem("id")),
        groupId: Number(id),
        eventId: Number(prop.match?.params?.id),
        isOrganized: groupType === "Organized" ? true : false,
        password: password,
  
        latitide: position.coords.latitude,
        longitude: position.coords.longitude,
        groupLocation: saveLocation?.groupLocation,
        placeId: saveLocation?.placeId,
        // latitide: latitude,
        // longitude: longitude,
        // groupLocation: groupLocation,
        // placeId: placeId,
        country: country,
      };
  
      // console.log(newData);
      if (newData.isOrganized) {
        setShow(true);
        setSaveData(newData);
        setSubmitFormLoader(false);
        return false;
      }
  
      history.push({ pathname: `/user/game-card`, state: { newData } });
      setSubmitFormLoader(false);
    }
  


  };

  const handleClose = () => setShow(false);

  const enterGame = () => {
    if (!pass) {
      return setErr("Please Enter Game Password");
    }
    if (pass !== saveData.password) {
      return setErr("Password is not matched");
    }

    history.push({ pathname: `/user/game-card`, state: { newData: saveData } });
  };

  const gDetail = (id) => {
    setId(id);
  };

  const columns = [
    {
      name: "Enter Game",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <button
            onClick={() =>
              handleNavigate({
                id: row.id,
                groupLocation: row.groupLocation,
                groupType: row.groupType,
                country: row.country,
                latitude: row.latitude,
                longitude: row.longitude,
                placeId: row.placeId,
                password: row.password,
              })
            }
            className="btn btn-game"
          >
            Enter Now
          </button>
        </>
      ),
      width: "8rem",
      center: true,
      button: true,
    },

    {
      name: "Group Id",
      selector: (row, i) => row.groupId,
      sortable: true,
      filterable: true,
      center: true,
    },
    {
      name: "Group Details",
      cell: (row) => (
        <>
          <button onClick={() => gDetail(row.id)} className="btn btn-view">
            Group Details
          </button>
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
 
    {
      name: "Group Name",
      selector: (row) => row.groupName,
      sortable: true,
      center: true,
      filterable: true,
    },
    {
      name: "Group Location",
      selector: (row) => row.groupLocation,
      sortable: true,
      width: "16rem",
      center: true,
      filterable: true,
    },
    {
      name: "Group Type",
      selector: (row) => row.groupType,
      sortable: true,
      center: true,
      filterable: true,
    },
  


  ];

  const columns2 = [

    
    {
      name: "Event Type",
      selector: (row) => row.id,
      cell: (row) => `${universalFunction.findLeague(row.eventType)} (${(row.eventType)})`,
      width: "15rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    
    {
      name: "League",
      selector: (row) => universalFunction.findLeague(row.eventType, row.league),
      sortable: true,
      width: "15rem",
      center:true
    },

    {
      name: "Factors",
      selector: (row) => row?.numberOfFactor,
      center:true,
      width: "10rem",

      button: true,
    },

    {
      name: "Percentages",
      center:true,
      selector: (row) =>row?.cardParameterData[0]?.factors?.map(({factorCode,percentageOfOccurence})=><span className="p-1"><b>{factorCode}{percentageOfOccurence}</b></span>) || "",
      width: "30rem",
    },

    {
      name: "Date Start",
      selector: (row) => moment(row?.cardParameterData[0]?.winParameterStartDate).format("MM/DD/YYYY"),
      center:true,

    }

  ];


  return (
    <>
      <UserHeader />
      <Example
        show1={show}
        handleclose={handleClose}
        entergame={enterGame}
        onchange={(e) => setPass(e.target.value)}
        msg={err}
      />
     {submitFormLoader && <div className="loader"></div>}
      {/* <GroupDetail id={getId} show1={show1} handleclose={glose} /> */}
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>{!getId ? "Associated groups details" : "groups details"}</h1>
              {!getId && (
                <>
                  {" "}
                  <Link to="/">Home</Link>
                  <span>
                    <i className="fa fa-angle-right" />
                  </span>
                  <Link to="#" className="active">
                    Associated Groups Details
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {!getId && (
        <section className="groupdetails-sec">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="group-right-2">
                  <h3>
                    Associated groups with the{" "}
                    {universalFunction.findLeague(state?.eventType)}
                  </h3>

                  <DataTable
                    columns={columns}
                    data={state?.groupData}
                    pagination
                    paginationPerPage={10}
                    persistTableHead
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <ShortDesc handleclose={()=>setShowP(false)} data={sendData} show={showP} />
      {  getId &&     
      <section className="grp-rules mt-5">
        <div className="container">
          <div className="row">
          <div className="col-lg-12">
          <button
           onClick={()=>setId('')}
           className="btn btn-game mb-4"
         >
           Back
         </button>
          </div>
            <div className="col-lg-12">

              <div className="heading-sec mb-5">

                <h2>Group Rules</h2>
                <p>
                  Carrejoue games are played in Groups. General rules and the
                  method of play using the sporting Event as the basis for
                  delivering results remain the same across the groups. GROUP
                  DETAILS result with the variations in laws, the number of
                  participants expected and sometimes group choice.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="rules-sequence">
                <div className="seq-numb">01</div>
                <h4>FACTORS</h4>
                <p>
                Details provide the populating percentage used for each Factor to create the 
                Game Cards for the Group.

                </p>

               {showDesc && <DataTable
                className="mt-3"
                    columns={columns2}
                    data={groupData?.allEvents}
                    pagination
                    persistTableHead
                  />}

              <Link
                  onClick={() => setShowDesc(!showDesc)}
                  to="#"
                  className="mb-4"
                >
                  {showDesc ? "Hide" : "More"} Details{" "}
                </Link>
           
                 

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">02</div>
                <h4>LOCATION</h4>
                 <p>
                {groupData?.descriptions}
                </p> 

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">03</div>
                <h4>PATTERNS PLAYED</h4>
                <p className="mb-1">
                Details provide the specific variances on Winning Patterns used
                by the Group.
                </p>
                  {/* <p>
                  { groupData?.patterns}
                  </p> */}
                                
                <Link
                  onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'PATTERNS PLAYED',p: parse(groupData?.patterns || "")})}}
                  to="#"
                  className="more-link"
                >
                More Details{" "}
                </Link>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">04</div>
                <h4>PRIZE/PAYOUT</h4>
                <p>
                  {/* Groups may not play for money by choice or because of legal
                  reasons. We want to make the game widely available for people
                  to enjoy and help the teams, their sponsors and interests.
                  Sometimes <span id={`dots${3}`}>...</span>{" "}
                  <span style={{ display: "none" }} id={`more${3}`}>
                    {" "}
                    it is available as Link Marketing/Promotional offering.
                    These game cards are free and Link nice way to learn about
                    the game and maybe get some nice swag.{" "}
                  </span> */}
                  Details provide for each pattern played the prizes won by a winner in the Group.
                </p>
                <div>

                <Link
                 onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'PRIZE/PAYOUT',p: parse(groupData?.prizePayout|| "")})}}
                  to="#"
                  className="more-link"
                >
                  More Details{" "}
                </Link>


                </div>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">05</div>
                <h4>ROUNDING</h4>
                <p> {groupData?.rounding}</p>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">06</div>
                <h4>ROLLOVER/PUSHDOWN</h4>
                <p> {groupData?.rolloverPushdown}</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">07</div>
                <h4>Card Costs</h4>
                <p> {groupData?.cardCosts}</p>

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6">
              <div className="rules-sequence">
                <div className="seq-numb">08</div>
                <h4>Organized Group Rules</h4>
                <p style={{overflow:"hidden", textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}> 
                {parse(groupData?.organizedGroupRules|| "")}</p>
                <Link
                 onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'Organized Group Rules',p: parse(groupData?.organizedGroupRules|| "")})}}
                  to="#"
                  className="more-link"
                >
                  More Details{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
}
      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}

export default withRouter(GroupAssociateEvent);
