import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
} from "../actions/type";

// const user = JSON.parse(localStorage.getItem("user"));

// const initialState = user? { isSignUp: '', user }: { isSignUp: false, user: null };
const initialState = {}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isSignUp: "success",
        user: payload.user,
      };
    case SIGNUP_FAIL:
      return {
        ...state,
        isSignUp: "fail",
        user: null,
      };
    default:
      return state;
  }
}