import blog1 from '../../../assets/images/blog1.jpg';
import blog2 from '../../../assets/images/blog2.jpg';
import blog3 from '../../../assets/images/blog3.jpg';
import nextIcon from '../../../assets/images/next-icon.png';
import calendar from '../../../assets/images/calendar.png';
import Slider from "react-slick";


export function LatestNewsAndBlogs() {

        const settings = {
        dots: true,
        arrows:false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
    return (
        <section className="blog-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-sec text-center">
                <h2>LATEST NEWS &amp; BLOG</h2>
                <h4>This can be several items.  See <b>company comments</b>, thoughts on Upcoming Events and recent occurrences of the Sports, as well as, <b>answers</b> to common user questions.</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="blog-logos logo-list-2">



          <Slider {...settings}>   
            <div className="slide-in-right slide">
              <div className="slater_block">
                <a href="#" target="_blank">
                  <figure>
                    <img alt="" src={blog2} />
                  </figure>
                  <div className="bottom_content">
                    <h5><span className="date-icon"><img src={calendar} alt=""/></span> OCT 20, 2021</h5>
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry
                    </h4>
                    <p className="ourwork">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                      
                      <img src={nextIcon} className="rigthpo" alt="swiper-slider-icon" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="slide-in-right slide">
              <div className="slater_block">
                <a href="#" target="_blank">
                  <figure>
                    <img src={blog1} alt="" />
                  </figure>
                  <div className="bottom_content">
                    <h5><span className="date-icon"><img src={calendar} alt="" /></span> OCT 20, 2021</h5>
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry
                    </h4>
                    <p className="ourwork">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                      <img src={nextIcon} className="rigthpo" alt="swiper-slider-icon" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="slide-in-right slide">
              <div className="slater_block">
                <a href="#" target="_blank">
                  <figure>
                    <img alt="" src={blog3}/>
                  </figure>
                  <div className="bottom_content">
                    <h5><span className="date-icon"><img src={calendar} alt="" /></span> OCT 20, 2021</h5>
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry
                    </h4>
                    <p className="ourwork">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                      <img src={nextIcon} className="rigthpo" alt="swiper-slider-icon" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="slide-in-right slide">
              <div className="slater_block">
                <a href="#" target="_blank">
                  <figure>
                    <img src={blog2} alt="" />
                  </figure>
                  <div className="bottom_content">
                    <h5><span className="date-icon"><img src={calendar} alt=""/></span> OCT 20, 2021</h5>
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry
                    </h4>
                    <p className="ourwork">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                      <img src={nextIcon} className="rigthpo" alt="swiper-slider-icon" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="slide-in-right slide">
              <div className="slater_block">
                <a href="#" target="_blank">
                  <figure>
                    <img alt="" src={blog1} />
                  </figure>
                  <div className="bottom_content">
                    <h5><span className="date-icon"><img src={calendar} alt="" /></span> OCT 20, 2021</h5>
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry
                    </h4>
                    <p className="ourwork">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                      <img src={nextIcon}  className="rigthpo" alt="swiper-slider-icon" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="slide-in-right slide">
              <div className="slater_block">
                <a href="#" target="_blank">
                  <figure>
                    <img src={blog3} alt="" />
                  </figure>
                  <div className="bottom_content">
                    <h5><span className="date-icon"><img src="images/calendar.png" /></span> OCT 20, 2021</h5>
                    <h4>
                      Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry
                    </h4>
                    <p className="ourwork">
                      Lorem Ipsum is simply dummy text of the printing and typesetting
                      industry.
                      <img src={nextIcon} className="rigthpo" alt="swiper-slider-icon" />
                    </p>
                  </div>
                </a>
              </div>
            </div>
            </Slider>
          </div></div></section>
    )
}