//Firebase account deatils
const firebaseConfig = {
    apiKey: "AIzaSyA1C4omjVsDDl9b017Sqm_m0-XLeu-p81I",
  authDomain: "kibugaming-3e8df.firebaseapp.com",
  databaseURL: "https://kibugaming-3e8df-default-rtdb.firebaseio.com",
  projectId: "kibugaming-3e8df",
  storageBucket: "kibugaming-3e8df.appspot.com",
  messagingSenderId: "172053151250",
  appId: "1:172053151250:web:e246a2066c620ec69b22cb",
  measurementId: "G-MFVHBS0T8Y"
};

export default firebaseConfig;





