/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "./roles.css";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const Roles = () => {
  const history = useHistory();
  const [saveData, setSaveData] = useState({});
  const [roles, setRole] = useState([]);
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitFormLoader, setSubmitFormLoader] = useState("");

  useEffect(() => {
    if (typeof history?.location?.state !== "undefined") {
      setValue("roleName", history?.location?.state?.roleName);
    }
    if (typeof history?.location?.state?.edit !== "undefined") {
      setValue("roleName", history?.location?.state?.edit?.roleName);
    }
    fetchData();
  }, []);

  let subscribe = watch();

  // console.log(history, "---0--");

  async function fetchData(search) {
    try {
      setSubmitFormLoader(true);
      let data = await services.getRole();
      let res = await services.listPermissions();
      let listP = { permissions: {} };
      res?.data?.data?.permissionlist && Object?.keys(res?.data?.data?.permissionlist)?.forEach(
        (d) =>
          (listP.permissions[d.toLowerCase()] = {
            add: false,
            delete: false,
            edit: false,
            view: false,
          })
      );


      setRole(data?.data.data);
      setSaveData(listP);

      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }

  const createRoleData = async (d) => {
    let data =
      typeof history?.location?.state !== "undefined"
        ? history?.location?.state
        : { ...d, ...saveData };

    try {
      setSubmitFormLoader(true);
      let response = history?.location?.state?.roleId
        ? await services.updateRole(data)
        : await services.createRole(data);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setValue("roleName", "");
      history.push({
        pathname: "/admin/roles",
        state: undefined,
      });
      fetchData();
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  const deleteRole = async (id) => {
    Swal.fire({
      title: "Do you want to delete?",
      // showDenyButton: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setSubmitFormLoader(true);
          let res = await services.deleteRole(id);
          Swal.fire(res.data.message, "", "success");
          setSubmitFormLoader(false);
          fetchData();
        } catch (e) {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          setSubmitFormLoader(false);
        }
      }
    });
  };

  const updateStatus = async (e) => {
    try {
      let data = {
        isEnabled:e.target.checked
      }
      setSubmitFormLoader(true);
      let response = await services.statusRole(e.target.value, data);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => roles?.currentPage * 10 + i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Role Name",
      selector: (row, i) => row.roleName,
      sortable: true,
      filterable: true,
    },

    {
      name: "Disable/Enable	",
      cell: (row) => (
        <label className="switch">
          <input value={row.id} onChange={updateStatus} defaultChecked={row.isEnabled ? true : false} type="checkbox" />
          <span className="slider round" />
        </label>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      //  button: true,
    },
    {
      name: "Status",
      selector: (row, i) => row.roleStatus,
      sortable: true,
      filterable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <i
            onClick={() =>
              history.push({
                pathname: "/admin/view-permissions",
                state: { view: row },
              })
            }
            className="fa fa-eye fa-lg"
            aria-hidden="true"
          />
          <i
            onClick={() =>
              history.push({
                pathname: "/admin/edit-permissions",
                state: { edit: row },
              })
            }
            className="fa fa-pencil-square-o fa-lg icon-edit"
            aria-hidden="true"
          />
          <i
            onClick={() => deleteRole(row.id)}
            className="fa fa-trash fa-lg icon-delete"
            aria-hidden="true"
          />
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      //  button: true,
    },
  ];


  // console.log(typeof history?.location?.state !== "undefined");

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>Create Role </h4>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label> Role Name</label>
                  <input
                    type="text"
                    placeholder="Role Name"
                    className="form-control"
                    {...register("roleName", { required: true })}
                  />
                  {errors.roleName && (
                    <p className="text-danger">Please enter the role name.</p>
                  )}
                </div>
              </div>
              <div className="col-lg-9 col-md-6 order-2 order-lg-1">
                {!(typeof history?.location?.state !== "undefined") ? (
                  <button
                    className="btn permission-btn"
                    onClick={handleSubmit(() =>
                      history.push({
                        pathname: "/admin/set-permissions",
                        state: { name: subscribe["roleName"] },
                      })
                    )}
                  >
                    Set Permissions
                  </button>
                ) : (
                  <button
                    className="btn permission-btn"
                    onClick={() => {
                      setValue("roleName", "");
                      history.push({
                        pathname: "/admin/roles",
                        state: undefined,
                      });
                    }}
                  >
                    Reset
                  </button>
                )}
              </div>
              <div className="col-lg-4 col-md-6 order-1 order-lg-2">
                <button
                  onClick={handleSubmit(createRoleData)}
                  className="btn admin-primary-btn"
                >
                  Add Role
                </button>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 mb-3">
                <div className="left">
                  <h4> Roles </h4>
                </div>
              </div>
              <div className="clol-lg-12 col-md-12">
                <DataTable
                  columns={columns}
                  data={roles.data}
                  pagination
                  paginationServer
                  paginationTotalRows={roles.totalItems}
                  // paginationPerPage={10}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  // onChangePage={(page) => setPage(page)}
                  persistTableHead
                />
              </div>
              <div className="spacer-foot" />
            </div>
            {/*------ Footer bar------ */}
            <AdminFooter />
          </div>
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default Roles;
