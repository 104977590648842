/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import { useForm } from "react-hook-form";

toast.configure();

// Function for create advertisement
const FootBallCallerBoard = () => {
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [eventdata, setData] = useState([]);
  const [event, setEvent] = useState(undefined);
  const [callerBoardData, setcallerBoardData] = useState([]);
  const [callName, setinitiateValue] = useState("");
  const [firebaseId, setFirebaseId] = useState('');
  const [firebaseData, setfirebaseData] = useState({});
  const [gameIndex, setGameIndex] = useState("");
  const [miniCallData, setMiniCallData] = useState([]);
  const [possessionName, sePossessionName] = useState("");
  const [quarterName] = useState({
    5:"OT1",
    6:"OT2",
    7:"OT3",
    8:"OT4",
    9:"OT5",
    10:"OT6",
    11:"OT7",
    12:"OT8",
    13:"OT9",
    14:"OT10",
  })

  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const subscribe = watch();

  const callEvent = (e, data) => {
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      setinitiateValue("");
    } else {
      let a1 = document.getElementsByClassName("active")[0];
      if (a1) {
        a1.classList.remove("active");
      }

      e.currentTarget.classList.add("active");
      setinitiateValue(data);
    }
  };

  console.log(possessionName,"possessionName");

  useEffect(()=>{
    fetch()
    setValue("homeDrive",0)
    setValue("awayDrive",0)
    setValue("quarter",1);
    setValue("ownOpp","Own")
  },[])

  useEffect(()=>{
    if (firebaseId) {
      setSubmitFormLoader(true);
      services
        .firebase(firebaseId)
        .then(async (res) => {
          setfirebaseData(res.data.data);
          console.log(res.data.data.awayDrive,'---=');
          setValue("awayDrive",res.data.data.awayDrive)
          setValue("homeDrive",res.data.data.homeDrive)
          setValue("quarter",res.data.data.quarter);
          sePossessionName({id: res.data.data?.possession, partnerName: res.data.data?.teamName})
          let filterData = res.data?.data?.calls;
          setcallerBoardData(res.data.data);
          setMiniCallData(chunckArr(filterData));
          setSubmitFormLoader(false);
        })
        .catch((e) => {
          setSubmitFormLoader(false);
        });
    }
  },[firebaseId])

  useEffect(() => {
    if(subscribe?.eventId){
    let data = [...eventdata];
    let findData = data.filter((dat) => dat?.eventId === subscribe?.eventId);
    setEvent(findData[0]);

    // let atbat = partner?.filter(({ id }) => id === findData[0]?.awayName);
    // setAtbatInp(atbat);
    setFirebaseId('');
    if (findData[0]?.fireBaseCallerBoardId) {
      setFirebaseId(findData[0]?.fireBaseCallerBoardId)
    }
  }
  }, [subscribe?.eventId]);

  const fetch = async () => {
    try {
      setSubmitFormLoader(true);
      let res = await services.callerBoardEvents(0,100,"A");
      setData(res.data?.data?.data && res.data.data.data.map((data) => data));
      setSubmitFormLoader(false);
      // let response = await services.partners();
      // setPartner(response?.data?.data?.data);
      // console.log(res.data.data.data);
    } catch (e) {
      setSubmitFormLoader(false);
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  };


  const acceptCall = async (data) => {

    if (!possessionName) {
      toast.warn("Please add the either home drive and away drive", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      return;
    }

    if (!callName) {
      toast.warn("Please select the call event", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      return;
    }



    let callname = callName?.match(/\(([^)]+)\)/)[1];


    try {
      setSubmitFormLoader(true);
      let res = await services.footBallCallerBoard({
        ...data,
        "possession":possessionName?.id,
        callName: callname,
      });
      setFirebaseId('');
      setFirebaseId(res?.data?.data?.fireBaseCallerBoardId);
      // reset({
      //   batter: "",
      // });
      eraseCall();
      setSubmitFormLoader(false);
    } catch (error) {
      setSubmitFormLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  };

  const eventStart = async (id) => {
    try {
      setSubmitFormLoader(true);
      let response = await services.startGame(id);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      let res = await services.events(0,100);
      setData(res.data?.data?.data && res.data.data.data.map((data) => data));
      setValue('eventId', "")
      setValue('eventId', response?.data?.data?.eventId)
      setSubmitFormLoader(false);
      
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
    // }
  }
  const stopCall = async () => {
    Swal.fire({
      title: "Do you want to stop the call?",
      // showDenyButton: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setSubmitFormLoader(true);
          let res = await services.endCall({ eventId: subscribe?.eventId,date:moment().format("MM-DD-YYYY HH:mm")});
          Swal.fire(res.data.message, "", "success");
          setEvent({...event,...res.data.data})
          setSubmitFormLoader(false);
        } catch (e) {
          setSubmitFormLoader(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }
      }
    });
  };
  
  const cancelCall = async () => {
    Swal.fire({
      title: "Do you want to cancel the call?",
      // showDenyButton: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setSubmitFormLoader(true);
          let res = await services.cancelGame(event?.id);
          Swal.fire(res.data.message, "", "success");
          setEvent({...event,...res.data.data})
          setSubmitFormLoader(false);
        } catch (e) {
          setSubmitFormLoader(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }
      }
    });
  };
  
  const eraseCall = () => {
    let a1 = document.getElementsByClassName("active")[0];
    setinitiateValue("");
    setValue("yardLine","")
    if (a1) {
      a1.classList.remove("active");
    }
  };

  const findCallName = (d) => {
    if(callerBoardData?.counts){
      let find = callerBoardData?.counts.find(({callName})=> callName===d);
      return find?.name
    }
   }

   function chunckArr(arr) {
    let x = 0;
    let y = 0;

    let arr2 = [];
    arr.map((data, i) => {
      if (x <= 24) {
        if (y <= 24) {
          arr2.push([data]);
          setGameIndex(x + 1);
        } else {
          setGameIndex(x + 1);

          arr2[x].push(data);
        }

        x++;
        y++;
      } else {
        arr2[0].push(data);
        x = 1;
        setGameIndex(x);
      }
    });

    return arr2;
  }
useEffect(()=>{
  setValue("homeDrive",0)
  setValue("awayDrive",0)
  sePossessionName("");
},[subscribe?.quarter])
   let arrayBox = new Array(25).fill(1);

  const findDrive = (event,p) =>{
    if(event?.awayNameData?.id===p){
    return "a"
    }
    if(event?.homeNameData?.id===p){
    return "h"
    }

    return false
  }

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper Total-Revenue-1">
        {submitFormLoader && <div className="loader"></div>}
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>Football Caller Board</h4>
                </div>
              </div>
            </div>
            <form className="row mt-3" autoComplete="off">
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Game / Event Id</label>
                  <input
                    list="encodings"
                    type="text"
                    className="form-control"
                    {...register("eventId", { required: true })}
                  />
                  {errors.eventId && (
                    <p className="text-danger">Please select the event id.</p>
                  )}
                  <datalist id="encodings">
                    {eventdata &&
                      eventdata?.map((data) => (
                        <option key={data?.eventId}>{data?.eventId}</option>
                      ))}
                  </datalist>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Date</label>
                  <input
                    disabled
                    type="text"
                    defaultValue={
                      event?.eventDate
                        ? moment(event?.eventDate).format("MM/DD/YYYY")
                        : ""
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="grid-col">
                <div className="form-group">
                  <label>Away Drive #</label>
                  <div className="input-group" style={{ width: "160px" }}>
                    <input
                      type="button"
                      disabled={subscribe?.awayDrive==0}
                      onClick={()=>{setValue("awayDrive",Number(subscribe?.awayDrive)-1); sePossessionName(event?.homeNameData)}}
                      defaultValue="-"
                      className="button-minus icon-shape icon-sm btn btn-danger"
                    />
                    <input
                      {...register("awayDrive")}
                      type="number"
                      disabled
                      defaultValue={subscribe?.awayDrive}
                      className="quantity-field text-center w-25 form-control"
                    />
                    <input
                      type="button"
                      defaultValue="+"
                      disabled={event == undefined}
                      onClick={()=>{setValue("awayDrive",Number(subscribe?.awayDrive)+1); sePossessionName(event?.awayNameData)}}
                      className="button-plus icon-shape icon-sm-2 lh-0 btn btn-success"
                    />
                  </div>
                </div>
              </div>
              <div className="drive-pd">
                <div className="form-group">
                  <label>Home Drive #</label>
                  <div className="input-group" style={{ width: "160px" }}>
                    <input
                      type="button"
                      disabled={subscribe?.homeDrive==0}
                      onClick={()=>{setValue("homeDrive",Number(subscribe?.homeDrive)-1); sePossessionName(event?.awayNameData)}}
                      defaultValue="-"
                      className="button-minus icon-shape icon-sm btn btn-danger"
                    />
                    <input
                     {...register("homeDrive")}
                      type="number"
                      disabled
                      defaultValue={subscribe?.homeDrive}
                      className="quantity-field text-center w-25 form-control"
                    />
                    <input
                      type="button"
                      defaultValue="+"
                      disabled={event == undefined}
                      onClick={()=>{setValue("homeDrive",Number(subscribe?.homeDrive)+1); sePossessionName(event?.homeNameData)}}
                      className="button-plus icon-shape icon-sm-2 lh-0 btn btn-success"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5">
                <div className="form-group">
                  <label>Possession</label>
                  <input
                    type="text"
                    defaultValue={possessionName?.partnerName}
                    disabled
                    // placeholder="Buccaneers"
                    className="form-control"
                  />
                </div>
              </div>
            </form>
            <div className="row">
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label> Own/Opponent</label>
                  <div className="activebtn">
                    <button onClick={()=>setValue("ownOpp","Own")} className={`own-btn  ${subscribe?.ownOpp==="Own" && "activeopt"}`}>Own</button>
                    <button onClick={()=>setValue("ownOpp","Opp")} className={`own-btn ${subscribe?.ownOpp==="Opp" && "activeopt"}`}>OPP</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Yard Line</label>
                  <input maxLength={2} {...register("yardLine", { required: true, validate: {
                    positive: v => parseInt(v) < 56 || "Please enter the valid yard line",
                    lessThanTen: v => parseInt(v) > 0 || "Please enter the valid yard line" ,
                    }})} className="form-control" list="browsers" />
                  <datalist id="browsers">
                    {[...Array(55).keys()]?.map(d=>
                      <option>{d+1}</option>)}
                  </datalist>
                  {errors.yardLine && (
                    <p className="text-danger">{errors.yardLine.message || "Please select the yard line."}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label>Stadium Name</label>
                  <input
                    type="text"
                    disabled
                    defaultValue={event && event?.eventLocation}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="grid-col">
                <div className="form-group">
                  <label>Quarter</label>
                  <div className="input-group" style={{ width: "160px" }}>
                    <input
                      type="button"
                      defaultValue="-"
                      disabled={event == undefined ? true : subscribe?.quarter==1}
                      onClick={()=>setValue("quarter",Number(subscribe?.quarter)-1)}
                      className="button-minus icon-shape icon-sm btn btn-danger"
                    />
                    <input
                      type="text"
                      disabled
                      {...register("quarter")}
                      value={subscribe?.quarter>=4 ? quarterName[Number(subscribe?.quarter)] : subscribe?.quarter}
                      name="quantity"
                      className="quantity-field text-center w-25 form-control"
                    />
                    <input
                      type="button"
                      defaultValue="+"
                      disabled={event == undefined ? true : subscribe?.quarter>=14}
                      onClick={()=>setValue("quarter",Number(subscribe?.quarter)+1)}
                      className="button-plus icon-shape icon-sm-2 lh-0 btn btn-success"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue-1">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "First - Passing (F)")}
                          className="btn event-trigger-btn"
                        >
                          First - Passing (F)
                        </button>
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[0]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Convert 4th - Pass (W)")}
                          className="btn event-trigger-btn"
                        >
                          {" "}
                          Convert 4th - Pass (W)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[1]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "First - Rushing (R)")}  className="btn event-trigger-btn">
                          First - Rushing (R)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[2]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Convert 4th - Rush (Y)")} className="btn event-trigger-btn">
                          Convert 4th - Rush (Y)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[3]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "First - Penalty (X)")} className="btn event-trigger-btn">
                          First - Penalty (X)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[4]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Convert 4th - Penalty (Z)")} className="btn event-trigger-btn">
                          Convert 4th - Penalty (Z)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[5]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, " Field Goal (G)")} className="btn event-trigger-btn">
                          Field Goal (G){" "}
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[6]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Touchdown (S)")} className="btn event-trigger-btn">
                          Touchdown (S)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[7]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Punt (P)")} className="btn event-trigger-btn">
                          Punt (P){" "}
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[8]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Change Possession (C)")} className="btn event-trigger-btn">
                          Change Possession (C)
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[9]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Turnover (T)")} className="btn event-trigger-btn">
                          Turnover (T){" "}
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[10]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button  onClick={(e) => callEvent(e, "Defensive TD (D)")} className="btn event-trigger-btn">
                          Defensive TD (D){" "}
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[11]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-2">
                        <div className="form-group">
                          <label>Intiated Call </label>
                          <input
                            type="text"
                            disabled
                            defaultValue={callName}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <button  
                        disabled={event == undefined ? true : (event) == undefined ? true : (event.isEventStarted && !event.isEventFinished) ? false : true }
                        onClick={handleSubmit(acceptCall)} className="btn add-btn">Accept Call</button>
                        {/* <button class="btn cancel-btn ml-2">Erase Call</button> */}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Total Actions </label>
                          <input
                            type="text"
                            disabled
                            defaultValue={callerBoardData?.calls?.length}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-11">
                    <div className="card-design-outer">
                      <div className="card-inner-design">
                    
                      {arrayBox.map((d,i)=>{
                     return  (i%5==-0) && <div className="card-wrap">
                          <div id={i%5+i+1}
                            className={`card-box ${
                              gameIndex === i%5+i+1 && "current"
                            }`}
                          >
                            <div className="card-pattern">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i]?.length > 0 &&
                                miniCallData[i%5+i].map((d, ind) => (
                                  <span style={{color:d?.color && 'red' }} key={ind}>{d?.callName}</span>
                                ))}
                            </div>
                            <div className="pattern-main">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i] &&
                                miniCallData[i%5+i][miniCallData[0].length - 1]
                                  ?.callName}
                            </div>
                          </div>
                          <div
                          id={i%5+i+2}
                             className={`card-box ${
                              gameIndex === i%5+i+2 && "current"
                            }`}
                          >
                            <div className="card-pattern">
                              {" "}
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+1]?.length > 0 &&
                                miniCallData[i%5+i+1].map((d, i) => (
                                  <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                                ))}
                            </div>
                            <div className="pattern-main">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+1] &&
                                miniCallData[i%5+i+1][miniCallData[0].length - 1]
                                  ?.callName}
                            </div>
                          </div>
                          <div id={i%5+i+3}
                            className={`card-box ${
                              gameIndex === i%5+i+3 && "current"
                            }`}
                          >
                            <div className="card-pattern">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+2]?.length > 0 &&
                                miniCallData[i%5+i+2].map((d, i) => (
                                  <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                                ))}{" "}
                            </div>
                            <div className="pattern-main">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+2] &&
                                miniCallData[i%5+i+2][miniCallData[0].length - 1]
                                  ?.callName}
                            </div>
                          </div>
                          <div id={i%5+i+4}
                            className={`card-box ${
                              gameIndex === i%5+i+4 && "current"
                            }`}
                          >
                            <div className="card-pattern">
                              {" "}
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+3]?.length > 0 &&
                                miniCallData[i%5+i+3].map((d, i) => (
                                  <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                                ))}
                            </div>
                            <div className="pattern-main">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+3] &&
                                miniCallData[i%5+i+3][miniCallData[0].length - 1]
                                  ?.callName}
                            </div>
                          </div>
                          <div id={i%5+i+5}
                            className={`card-box ${
                              gameIndex === i%5+i+5 && "current"
                            }`}
                          >
                            <div className="card-pattern">
                              {" "}
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+4]?.length > 0 &&
                                miniCallData[i%5+i+4].map((d, i) => (
                                  <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                                ))}
                            </div>
                            <div className="pattern-main">
                              {miniCallData.length > 0 &&
                                miniCallData[i%5+i+4] &&
                                miniCallData[i%5+i+4][miniCallData[0].length - 1]
                                  ?.callName}
                            </div>
                          </div>
                        </div>
                        })}
                        
                      </div>
                    </div>
                    <div className="row align-items-baseline">
                      <div className="col-lg-4 col-md-4 text- enter">
                        {" "}
                        <button onClick={()=>eventStart(event?.id)} disabled={event == undefined ? true : (!event.isEventStarted && !event.isEventFinished) ? false : true } className="btn start-call-btn btn-block">
                          Start Call
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-4 text-center">
                        <button onClick={stopCall} disabled={event == undefined ? true : !event.isEventStarted ? true : (event.isEventStarted && event.isEventFinished || event?.isEventCancelled ) ? true : false} className="btn stop-call-btn btn-block" > Stop Call </button></div>
                    <div className="col-lg-4 col-md-4 text-center"> 
                     <button onClick={cancelCall} disabled={event == undefined ? true : !event.isEventStarted ? true : (event.isEventStarted && event.isEventFinished || event?.isEventCancelled ) ? true : false } className="btn cancel-btn btn-block">Cancel Game</button></div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <div className="left mb-3">
                    <h4 className="d-flex flex-wrap" style={{justifyContent:'space-between',alignItems:'baseline'}}><span>Summary of Results </span>    <span style={{fontSize:'18px'}}>{event?.isEventFinished && `Call Finished :  ${ moment.utc(event?.eventFinishedAt).local().format('YYYY-MM-DD HH:mm')  }` || event?.isEventCancelled && `Event Cancelled :  ${ moment.utc(event?.eventCancelledAt).local().format('YYYY-MM-DD HH:mm')  }`}
                    </span></h4>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="table-responsive">
                      <div className="table-fixed" id="style-1">
                        <table className="table">
                          <thead className="thead-dark">
                            <tr>
                              <th>Square Number</th>
                              <th>Quarter </th>
                              <th>Team Name</th>
                              <th>Drive </th>
                              <th>Down Marker</th>
                              <th>Results </th>
                            </tr>
                          </thead>
                          <tbody>
                            {callerBoardData?.calls ? (
                              callerBoardData?.calls.map(
                                (
                                  {
                                    atBat,
                                    ownOpp,
                                    quarter,
                                    teamName,
                                    awayDrive,
                                    homeDrive,
                                    yardLine,
                                    callName,
                                    possession
                                
                                  },
                                  id
                                ) => {
                                  return (
                                    <tr key={id}>
                                      <td>{id+1}</td>
                                      <td>{quarter>4 ? quarterName[Number(quarter)] : quarter}</td>
                                      <td>{teamName} </td>
                                      <td>{findDrive(event,possession)==="a" ?  awayDrive : findDrive(event,possession)==="h" ? homeDrive : ""}</td>
                                      <td> {`${ownOpp}${yardLine}`}</td>
                                      <td>{findCallName(callName)}</td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={7}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="spacer-foot" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacing-bar" />
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default FootBallCallerBoard;
