import { LOGIN_SUCCESS, LOGIN_FAIL, SET_MESSAGE } from "./type";

import AuthService from "../services/services";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

export const loginAction = (userData) => (dispatch) => {

  return AuthService.login(userData).then(
    (data) => {
      localStorage.setItem("id", data.data.data.id);
      localStorage.setItem("token", data.data.data.token);
      localStorage.setItem("role", data.data.data.role);

      dispatch({
        type: LOGIN_SUCCESS,
        payload:data.data.data,
      });
      
    },

    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message, {
         position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      // return Promise.reject();
    }
  );
};
