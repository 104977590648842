import mailImage from '../../../assets/images/mail-new.png';
import locationImage from '../../../assets/images/location-new.png';
import callImage from '../../../assets/images/call.png';
import {Link} from 'react-router-dom';

export function UserFooter() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 footer-links">
                        <h4>useful links</h4>
                        <span className="decor" />
                        <ul>
                            <li><i className="fa fa-angle-right" /> <Link to="/user/about-us">About Us</Link></li>
                            <li><i className="fa fa-angle-right" /> <Link to="/user/contact-us">Contact Us</Link></li>
                            <li><i className="fa fa-angle-right" /> <Link to="/">Upcoming Events</Link></li>
                            <li><i className="fa fa-angle-right" /> <Link to="/">Blog &amp; News</Link></li>
                            <li><i className="fa fa-angle-right" /> <Link to="/user/responsible-gaming">Responsible Gaming </Link></li>
                            <li><i className="fa fa-angle-right" /> <a target="blank" href="https://kibugaming.com/">kiBuGaming.com </a></li>
                            <li><i className="fa fa-angle-right" /> <a href="https://carrejoue.com/" target="blank">carrejoue.com</a></li>
                            <li><i className="fa fa-angle-right" /> <Link to="/user/terms-condition">Terms &amp; Conditions</Link></li>
                            <li> <i className="fa fa-angle-right" /> <Link to="/user/privacy-policy">Privacy Policy</Link></li>
                        </ul>
                    </div>
                    {/* <div className="col-lg-3 col-md-6 footer-links">
                        <h4>Results</h4>
                        <span className="decor" />
                        <ul>
                            <li> <i className="fa fa-angle-right" /> <Link to="#">By Event Type</Link></li>
                            <li> <i className="fa fa-angle-right" /> <Link to="#">By Group</Link></li>
                            <li> <i className="fa fa-angle-right" /> <Link to="#">By Date</Link></li>
                            <li> <i className="fa fa-angle-right" /> <Link to="#">By Location</Link></li>
                        </ul>
                    </div> */}
                    <div className="col-lg-3 col-md-6 footer-contact">
                        <h4>Contact details</h4>
                        <span className="decor" />
                        <div className="row">
                            <div className="col-lg-1 col-md-1 col-1 cont"><img src={locationImage} alt="" /></div>
                            <div className="col-lg-10 col-md-10 col-10 cont">Neque porro quisquam est qui dolorem ipsum
                                quia dolor sit amet, consectetur.</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1 col-md-1 col-1 cont"><img src={callImage} alt="" /></div>
                            <div className="col-lg-10 col-md-10 col-10 cont">+91 123456789</div>
                        </div>
                        {/* <div className="social-links mt-3 mb-3">
                            <Link to="#" className="facebook"><i className="fa fa-facebook" /></Link>
                            <Link to="#" className="twitter"><i className="fa fa-twitter" /></Link>
                            <Link to="#" className="instagram"><i className="fa fa-instagram" /></Link>
                            <Link to="#" className="whatsapp"><i className="fa fa-whatsapp" /></Link>
                        </div> */}
                    </div>

                    <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Social Links</h4>
                    <span className="decor" />
                    <div className="social-links mt-3 mb-3">
                            <Link to="#" className="facebook"><i className="fa fa-facebook" /></Link>
                            <Link to="#" className="twitter"><i className="fa fa-twitter" /></Link>
                            <Link to="#" className="instagram"><i className="fa fa-instagram" /></Link>
                            <Link to="#" className="whatsapp"><i className="fa fa-whatsapp" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}