import {
  useLocation,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";

export const PrivateRoute = (props) => {
  return localStorage.getItem("role") === "2" ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        //   state: { from: location }
      }}
    />
  );
};

export const AdminRoute = (props) => {
  return localStorage.getItem("role") === "1" ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        //   state: { from: location }
      }}
    />
  );
};

export const PublicRoute = (props) => {
  if (localStorage.getItem("role") === "1" && props.restricted) {
    return <Redirect to="/admin-dashboard" />;
  }

  if (localStorage.getItem("role") === "2" && props.restricted) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};
