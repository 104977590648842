/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "./roles.css";
import Swal from "sweetalert2";
// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const PermissionModule = () => {
  const history = useHistory();
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [id, setId] = useState("");
  const [permission, setPermission] = useState([]);
  const [modalShow, setmodalShow] = useState(false);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setSubmitFormLoader(true);
      let data = await services.listPermissions();
      // console.log(data?.data.data);
      // debugger
      data?.data?.data?.permissionlist && setPermission(Object.keys(data?.data?.data?.permissionlist));
      setId(data?.data?.data?.id)
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }
  const handleClose = () => setmodalShow(false);

  const createModule = async (d) => {
    try {
      setSubmitFormLoader(true);
      let res = await services.createPermission(d);
      Swal.fire(res.data.message, "", "success");
      setSubmitFormLoader(false);
      setmodalShow(false)
      fetchData();
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }


const deletePermission = async (data) => {
  Swal.fire({
    title: "Do you want to delete?",
    // showDenyButton: true,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes",
    denyButtonText: `Don't save`,
  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      try {
        setSubmitFormLoader(true);
        let res = await services.deletePermission(id, {permissionName:data});
        Swal.fire(res.data.message, "", "success");
        setSubmitFormLoader(false);
        setmodalShow(false)
        fetchData();
      } catch (e) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
      }
    }
  });

}
  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Permissions</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-md-right">
                <button
                  className="btn permission-btn mt-3"
                onClick={() => setmodalShow(true)}
                >
                  Add Module
                </button>
              </div>
            </div>
            <Modal
        centered
        show={modalShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:'24px',color: '#0070C0', fontWeight:700}}>Create Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="dropdown ">
              <label>Module Name</label>
              <input
                type="text"
                className="form-control"
               {...register("permissionName", { required: true })}
                placeholder="Events"
              />
             {errors.permissionName && (
                        <p className="text-danger">
                          Please enter the module name.
                        </p>
                      )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{justifyContent: 'center'}}>
          <Button onClick={handleSubmit(createModule)} style={{background:'#0070C0',padding:'10px 30px'}}  variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
            <div className="row mt-4">
              <div className="clol-lg-12 col-md-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th>S. No</th>
                        <th>Module Name</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {permission.length ? permission.map((data,id)=><tr>
                        <td>{id+1}</td>
                        <td>{data}</td>
                        <td className="text-center">
                        
                         <button onClick={()=>deletePermission(data)}> <span className="fa fa-trash fa-2x icon-act"></span></button>
       
                        </td>
                      </tr>) : <tr>
                        <td className="text-center" colSpan={3}>Not Found</td>
                        
                      </tr>  }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="spacer-foot" />
            </div>
            {/*------ Footer bar------ */}
            <AdminFooter />
          </div>
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default PermissionModule;
