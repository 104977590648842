export const General = () => (
  <div
    className="tab-pane fade"
    id="nav-gen"
    role="tabpanel"
    aria-labelledby="nav-generalrules-tab"
  >
    <h3 className="pt-4 pb-3">General Rules</h3>
    <ol>
      <li>
        All Game Cards must be obtained prior to the start of the Event. See
        Group Rules for exceptions.
      </li>
      <li>
        Each Game Card can play in up to six different Payout or Prize Pools,
        each defined by a separate Winning Pattern.
      </li>
      <li>All Game Cards for an Event are on the same Subject Square.</li>
      <li>
        At the start of the Event, the Subject Square is the upper leftmost
        square of the Game Cards.
      </li>
      <li>
        The pre-populated Defined Result of the Subject Square is the only
        possible Match to the next Defined Result delivered by the Key Element
        of an Event.
      </li>
      <li>
        The Key Element of the Event delivers Defined Results for creating a
        Match on the Game Cards.
      </li>
      <li>
        Once the Key Element of the Event delivers a Defined Result as a
        possible Match to the Game Cards, the Subject Square advances, making
        the next square of the Game Cards available for a Match.
      </li>
      <li>
        The Subject Square advances from left to right and top to bottom, except
        after the 25th square on a 5x5 game card.
      </li>
      <li>
        On a 5x5 game card, once a Defined Result is delivered to the lower
        rightmost square, the 25th square, the next Subject Square again becomes
        the upper leftmost square.
      </li>
      <li>
        The squares of Game Cards often receive multiple Defined Results from
        the Key Element during an Event. No significance is made between
        matching the first Defined Result delivered or the last Defined Result
        delivered.
      </li>
      <li>
        Once a Match is created on a Game Card it will remain a Match throughout
        the Event.
      </li>
      <li>
        The Game continues receiving random Defined Results from the Key Element
        of the Event until the Event is over.
      </li>
      <li>
        Any Game Card exhibiting a Winning Pattern for the Group, Event and
        Pattern Type entered by that Game Card wins a share of the Payout or
        Prize Pool of that Winning Pattern for the Group.
      </li>
      <li>
        If a Special Result makes the last Match to create a Winning Pattern on
        a Game Card played in a Payout Pool, the Game Card may win an additional
        bonus in the Payout Pool. A special result is designated by the RED
        color of the normal result.
      </li>
      <li>
        Play is prohibited to the owners, employees and the immediate families,
        thereof, in KiBu Gaming Corporation and its affiliates, except Groups
        created by KiBu Gaming Corporation and its affiliates, specifically for
        the employees or families thereof. For a complete list of all restricted
        affiliates
      </li>
      <li>
        {" "}
        All the decisions of the company's management are final. All Defined
        Results by our professionals are considered final regarding Carrejoue.
        Performed on a uniform, objective basis, our Event Type tables, form the
        basis of the Defined Results.
        <ol className="pl-3 pt-2">
          <li>
            Our scorers watch the Event and the Defined Results are strongly
            connected to the rules of play of the Event.
          </li>
          <li>
            The referee, judge and umpire rulings offer explanation of the rules
            of play of the Event and the on-field action, but are not
            authoritative. The official explanations and decisions may not be
            timely enough for a Game of Carrejoue, in particular instant replay
            decisions.
          </li>
          <li>
            Our game callers will also use the official scoring in making their
            decisions, but official scorers are given wide latitude to change
            their scoring beyond the timeliness required in a Game of Carrejoue.
            League changes to an official scorer's position do occur
            occasionally even after an Event has concluded.
          </li>
        </ol>
      </li>
      <li>
        {" "}
        The nature of the Events can lead to decisions we would have made
        differently if we were presented more time or better views of the
        on-field action.
        <ol className="pl-3 pt-2">
          <li>
            Challenged plays, initiating an instant replay ruling in the Event,
            must be immediate. Basketball often overturns scoring sometimes
            several minutes later.
          </li>
          <li>
            Defined Results will be entered to reflect the Event decision of an
            immediately challenged play.
          </li>
          <li>
            Official scoring will be considered, but not determinative. Official
            scorers often change their results several plays later.
          </li>
          <li>
            Defined Results entered will be final after a Defined Result has
            been entered in the following square.
          </li>
        </ol>
      </li>
      <li>
        The fundamental design of the game is as follows:
        <ul className="pl-3 pt-2">
          <li>A Sporting Event (contains) </li>
          <li>a Key Element (returns)</li>
          <li>a random Defined Result (compares to) </li>
          <li>a Game Card pre-populated Defined Result. </li>
          <li>A comparative Match creates</li>
          <li>a Mark and</li>
          <li>a pre-defined order of Marks create</li>
          <li>a Winning Pattern.</li>
        </ul>
      </li>
    </ol>
  </div>
);
