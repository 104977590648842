import AdminHeader from '../../core/header/adminHeader';
import AdminSidebar from '../../core/sidebar/admin-sidebar';
import { useHistory, withRouter, Link } from "react-router-dom";
import { useEffect } from "react";
import circle from '../../../assets/images/circle.png';
import cardP from '../../../assets/images/card-parameter.svg';
import eventImg from '../../../assets/images/events.svg';
import accountImg from '../../../assets/images/accounts.svg';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Sector } from 'recharts';

import './dashboard.css'

const AdminDashboard = () => {

  let history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("role") == "2")
      return history.push("/");
    if (localStorage.getItem("role") == "1") {
      history.push("/admin-dashboard");
    }
  }, [history]);

  const data = [
    {
      name: 'JAN',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'FEB',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'MAR',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'APR',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'MAY',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'JUN',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'JUL',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const data1 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  

  return (
      <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
        <div className="customTableWrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="left page-title">
              <h4 className="home"><i className="fa fa-home bg-gradient-primary page-title-icon text-white" />
                Dashboard</h4>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-4 stretch-card grid-margin">
            <div className="card bg-gradient-danger card-img-holder text-white">
              <div className="card-body">
                <img src={circle} className="card-img-absolute" alt="circle-image" />
                <h4 className="mb-3"> Card Generated <i><img src={cardP} alt="" /></i>
                </h4>
                <h2> 50,0000</h2>
                {/* <h6 class="card-text">Increased by 60%</h6> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 stretch-card grid-margin">
            <div className="card bg-gradient-info card-img-holder text-white">
              <div className="card-body">
                <img src={circle} className="card-img-absolute" alt="circle-image" />
                <h4 className="mb-3">Events Created <i><img src={eventImg} /> </i>
                </h4>
                <h2>45,6334</h2>
                {/* <h6 class="card-text">Decreased by 10%</h6> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 stretch-card grid-margin">
          <div style={{cursor:'pointer'}} onClick={()=>history.push('/admin/user-registered')} className="card bg-gradient-success card-img-holder text-white">
              <div className="card-body">
                <img src={circle} className="card-img-absolute" alt="circle-image" />
                <h4 className="mb-3">Registered Users  <i><img src={accountImg} /> </i>
                </h4>
                <h2>95,000</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-7 grid-margin d-flex stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <div className="clearfix">
                  <h4 className="card-title float-left">Group Details</h4>
                  <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-right">
                    <ul>
                      <li><span className="legend-dots" style={{background: 'linear-gradient(to right, rgba(218, 140, 255, 1), rgba(154, 85, 255, 1))'}} />Events </li>
                      <li><span className="legend-dots" style={{background: 'linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1))'}} />Groups
                      </li>
                      <li><span className="legend-dots" style={{background: 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))'}} />Cards
                      </li>
                    </ul>
                  </div>
                </div>
                <BarChart
      width={700}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv" fill="#fe7096" />
      <Bar dataKey="uv" fill="#36d7e8" />
      <Bar dataKey="uv" fill="#9a55ff" />
    </BarChart>
              </div>
            </div>
          </div>
          <div className="col-md-5 grid-margin d-flex stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <h4 className="card-title">Advertise Click Sources</h4>

                <PieChart width={490} height={200} >
        <Pie
          data={data1}
          cx={220}
          cy={100}
          innerRadius={30}
          outerRadius={70}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      
      </PieChart>                <div id="traffic-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4">
                  <ul>
                    <li><span className="legend-dots" style={{background: 'linear-gradient(to right, rgba(54, 215, 232, 1), rgba(177, 148, 250, 1))'}} />Add Banner<span className="float-right">30%</span></li>
                    <li><span className="legend-dots" style={{background: 'linear-gradient(to right, rgba(6, 185, 157, 1), rgba(132, 217, 210, 1))'}} />Direct
                      Click<span className="float-right">30%</span></li>
                    <li><span className="legend-dots" style={{background: 'linear-gradient(to right, rgba(255, 191, 150, 1), rgba(254, 112, 150, 1))'}} />Bookmarks
                      Click<span className="float-right">40%</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
}

export default withRouter(AdminDashboard);
