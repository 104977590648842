import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
// import './eventtype.css'
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";


export default function FootBallRule() {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <UserHeader />
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>Football Event Rules</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" onClick={()=>history.goBack()}>Event Type Rules</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" className="active">
                Football Event Rules
              </Link>
              
            </div>
          </div>
        </div>
      </section>
      <section className="gamerules-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading-sec mb-5">
                <h2>FOOTBALL Event Specifics Rules</h2>
                <h4 className="pb-3">
                Reading the <b>GAME RULES</b> first will provide definitions helpful to more fully understanding the <b> Football Specific </b> rules.  Please contact us with any clarifications needed and your questions or comments.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <nav>
                <div
                  className="nav nav-tabs nav-fill"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-item nav-link active"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-keyelem-foot"
                    role="tab"
                    aria-controls="nav-key"
                    aria-selected="true"
                  >
                    Key Element
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-specresult-foot-tab"
                    data-toggle="tab"
                    href="#nav-spec-result-foot"
                    role="tab"
                    aria-controls="nav-specresult"
                    aria-selected="false"
                  >
                    {" "}
                    Special Results{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-8-factors-foot-tab"
                    data-toggle="tab"
                    href="#nav-8-factors-foot"
                    role="tab"
                    aria-controls="nav-8-factors"
                    aria-selected="false"
                  >
                    8-Factor Chart{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-6-factors-foot-tab"
                    data-toggle="tab"
                    href="#nav-6-factors-foot"
                    role="tab"
                    aria-controls="nav-6-factors"
                    aria-selected="false"
                  >
                    6-Factor Chart{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-4-factors-foot-tab"
                    data-toggle="tab"
                    href="#nav-4-factors-foot"
                    role="tab"
                    aria-controls="nav-4-factors"
                    aria-selected="false"
                  >
                    4-Factor Chart{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-desc-foot-tab"
                    data-toggle="tab"
                    href="#nav-desc-foot"
                    role="tab"
                    aria-controls="nav-desc"
                    aria-selected="false"
                  >
                    {" "}
                    Descriptions{" "}
                  </a>
                </div>
              </nav>
              <div
                className="tab-content py-4 px-3 px-sm-0"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="nav-keyelem-foot"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <h3 className="pt-4 pb-2">Key Element </h3>
                  <p>
                    {" "}
                    The Key Element of American-style Football is the series of
                    downs. In most leagues, including the National Football
                    League (NFL) and the American colleges, a team gets four
                    downs in a series of downs. In the Canadian Football League
                    (CFL), a team gets three downs in a series of downs. In
                    Carrejoue, no distinction is made in the play of the game
                    for which team has the series of downs. Both teams series of
                    downs will deliver the Defined Results to your game card. It
                    does not take long to see how this works.
                  </p>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-spec-result-foot"
                  role="tabpanel"
                  aria-labelledby="nav-specresult-foot-tab"
                >
                  <h3 className="pt-4 pb-2">Special Results </h3>
                  <p>
                  Special results of a defined result that pay an additional share of the prize pool under the game rules are:
                  </p>
                  <p> Turnover <strong>(<span className="text-danger">T</span>)</strong> –– Offense loses the ball by fumble or interception. </p>
                  <p>

                  4th Down Stop(4) – Defense gets the ball by Turnover on Downs, Missed FG, Blocked FG or Failed Punt.
                 This could also be the result of a fake punt or fake field goal on a 4th down play.

                  </p>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-8-factors-foot"
                  role="tabpanel"
                  aria-labelledby="nav-8-factors-foot-tab"
                >
                  <h3 className="pt-4 pb-1 text-center">
                    DEFINED RESULTS: 8-FACTOR CHART
                  </h3>
                  <h5 className="text-center pb-3">F, R, X, S, G, T, C, P </h5>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            {" "}
                            FIRST-PASS (F,<span className="danger-txt">F</span>)
                          </th>
                          <th>
                            {" "}
                            FIRST-RUSH (R,<span className="danger-txt">R</span>)
                          </th>
                          <th>
                            {" "}
                            FIRST-PENALTY (X,
                            <span className="danger-txt">X</span>)
                          </th>
                          <th>TOUCHDOWN (S)</th>
                          <th>FIELD GOAL (G) </th>
                          <th>
                            {" "}
                            TURNOVER (T,<span className="danger-txt">T</span>)
                          </th>
                          <th>CHANGE (C) </th>
                          <th>PUNT (P) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pass</td>
                          <td>Run</td>
                          <td>Penalty</td>
                          <td> </td>
                          <td> </td>
                          <td> Safety </td>
                          <td>End of Half or Game </td>
                          <td> Blocked Punt </td>
                        </tr>
                        <tr>
                          <td>Flea Flicker</td>
                          <td>Reverse</td>
                          <td />
                          <td> </td>
                          <td> </td>
                          <td>
                            {" "}
                            Interception (T,
                            <span className="danger-txt">T</span>){" "}
                          </td>
                          <td>
                            Missed 4<sup>th</sup> Down Conversion{" "}
                          </td>
                          <td>Muffed Return </td>
                        </tr>
                        <tr>
                          <td>Screens</td>
                          <td>Direct Snap </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td>
                            {" "}
                            Fumble Lost (T,<span className="danger-txt">T</span>
                            ){" "}
                          </td>
                          <td> Missed Field Goal </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>
                            Fake Punt**(<span className="danger-txt">F</span>)
                          </td>
                          <td>
                            Fake Punt (<span className="danger-txt">R</span>)
                          </td>
                          <td>
                            Fake Punt (<span className="danger-txt">X</span>)
                          </td>
                          <td />
                          <td> </td>
                          <td>
                            Blocked FG, in play (T,
                            <span className="danger-txt">T</span>){" "}
                          </td>
                          <td>Fake Punt not converted </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>
                            Fake FG**(<span className="danger-txt">F</span>)
                          </td>
                          <td>
                            Fake FG (<span className="danger-txt">R</span>)
                          </td>
                          <td>
                            {" "}
                            Fake FG (<span className="danger-txt">X</span>)
                          </td>
                          <td />
                          <td> </td>
                          <td>
                            Short FG Attempt, in play (T,
                            <span className="danger-txt">T</span>){" "}
                          </td>
                          <td>Fake FG not converted </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td> </td>
                          <td />
                          <td />
                          <td> </td>
                          <td> </td>
                          <td>Bad Snap </td>
                          <td> </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-6-factors-foot"
                  role="tabpanel"
                  aria-labelledby="nav-6-factors-foot-tab"
                >
                  <h3 className="pt-4 pb-1 text-center">
                    DEFINED RESULTS: 6-FACTOR CHART{" "}
                  </h3>
                  <h5 className="text-center pb-3">F, R, S, G, C, P </h5>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            {" "}
                            FIRST-PASSING (F,
                            <span className="danger-txt">F</span>)
                          </th>
                          <th>
                            {" "}
                            FIRST-RUN (R,
                            <span className="danger-txt">R</span>)
                          </th>
                          <th>
                            {" "}
                            TOUCHDOWN (S)
                          </th>
                          <th>FIELD GOAL (G) </th>
                          <th>
                            {" "}
                            CHANGE (C,<span className="danger-txt">C</span>)
                          </th>
                          <th>PUNT (P) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pass</td>
                          <td>Run</td>
                          <td></td>
                          <td></td>
                          <td>End of Half or Game</td>
                          <td>Blocked Punt</td>
                        </tr>
                        <tr>
                          <td>Flea Flicker</td>
                          <td>Reverse</td>
                          <td />
                          <td></td>
                          <td>
                            Missed 4<sup>th</sup> Down Conversion
                          </td>
                          <td>Muffed Return</td>
                        </tr>
                        <tr>
                          <td>Screen</td>
                          <td>Direct Snap</td>
                          <td />
                          <td />
                          <td>Missed Field Goal</td>
                          <td />
                        </tr>
                        <tr>
                          <td> Penalty  </td>
                          <td></td>
                          <td> </td>
                          <td />
                          <td>Fake Punt not converted</td>
                          <td />
                        </tr>
                        <tr>
                          <td>  </td>
                          <td> </td>
                          <td> </td>
                          <td />
                          <td>Fake FG not converted</td>
                          <td />
                        </tr>
                        <tr>
                        <td>Fake Punt  </td>
                        <td>Fake Punt  </td>
                          <td />
                          <td />
                          <td>Bad Snap</td>
                          <td />
                        </tr>
                        <tr>
                        <td>Fake FG	 </td>
                        <td>Fake FG	 </td>
                          <td />
                          <td />
                          <td>Safety</td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            Blocked FG, in play (C,
                            <span className="danger-txt">C</span>)
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            Short FG Attempt, in play (C,
                            <span className="danger-txt">C</span>)
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            Fumble Lost (C,<span className="danger-txt">C</span>
                            )
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            Interception (C,
                            <span className="danger-txt">C</span>)
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-4-factors-foot"
                  role="tabpanel"
                  aria-labelledby="nav-4-factors-foot-tab"
                >
                  <h3 className="pt-4 pb-1 text-center">
                    DEFINED RESULTS: 4-FACTOR CHART{" "}
                  </h3>
                  <h5 className="text-center pb-3">F, S, C, P </h5>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>
                            {" "}
                            FIRST DOWN (F,<span className="danger-txt">
                              F
                            </span>){" "}
                          </th>
                          <th>SCORE (S) </th>
                          <th>
                            CHANGE (C, <span className="danger-txt">C</span>){" "}
                          </th>
                          <th>PUNT (P) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pass (F,<span className="danger-txt"> F</span>)</td>
                          <td>Touchdown</td>
                          <td>End of Half or Game</td>
                          <td>Blocked Punt</td>
                        </tr>
                        <tr>
                          <td>Run  (F, <span className="danger-txt">F</span>)</td>
                          <td>Field Goal</td>
                          <td>
                            Missed 4<sup>th</sup> Down Conversion
                          </td>
                          <td>Muffed Return</td>
                        </tr>
                        <tr>
                          <td>
                          Penalty (F, <span className="danger-txt">F</span>)
                          </td>
                          <td />
                          <td>Missed Field Goal</td>
                          <td />
                        </tr>
                        <tr>
                        <td>
                            Fake Punt (<span className="danger-txt">F</span>)
                          </td>
                          <td />
                          <td>Fake Punt not converted</td>
                          <td />
                        </tr>

                        <tr>
                        <td>
                            Fake FG (<span className="danger-txt">F</span>)
                          </td>
                         
                          <td />
                          <td>Fake FG not converted</td>
                          <td />
                        </tr>
                        <tr>
                        <td />
                          <td />
                          <td>Bad Snap</td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>Safety</td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            Blocked FG, in play (C,
                            <span className="danger-txt">C</span>)
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            Short FG Attempt, in play (C,
                            <span className="danger-txt">C</span>)
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            Fumble Lost (C,<span className="danger-txt">C</span>
                            )
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td>
                            Interception (C,<span className="danger-txt">C</span>
                            )
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-desc-foot"
                  role="tabpanel"
                  aria-labelledby="nav-desc-foot-tab"
                >
                  <h3 className="pt-4 pb-3"> Descriptions </h3>

                  <p className="cont-pdb">
                    <strong>“A” Defined Result</strong>
                  </p>
                  <p>
                  Flea Flicker – A player pitches the ball, hands it off or passes the ball backwards to another player who then pitches it back, hands it off or passes it backwards to a player eventually throws a legal forward pass would all result in an “A” defined result in CARREJOUE 6-FACTOR
                  </p>
                  <p>Fake Punt or Field Goal – A player makes a legal forward pass for a first down on a fake field goal or fake punt is an “A” or an “4” in CARREJOUE 6-FACTOR.  This can occur accidentally on a bad snap, bobbled snap, bad set or missed kick.
                  </p>

                  <p className="cont-pdb">
                    <strong>“G” Defined Result </strong>
                  </p>
                  <p>Backwards pass – A backward pass behind the line of scrimmage then run by the receiver is an “G” defined result in CARREJOUE 6-FACTOR.</p>
                  <p>Fake Punt or Field Goal – This conversion may occur accidentally on a bad snap, bobbled snap, bad set or missed kick.  Accidental or not a run on a fake punt or fake field goal for a first down is a “G” or “4” in CARREJOUE 6-FACTOR.</p>
                
                  <p className="cont-pdb">
                    <strong>	Muffed Snap</strong>
                  </p>
                  <p>A bad snap or “muffed snap” can result in either a turnover or failed fourth down conversion.  If it is a fourth down play and the offensive team retrieves the bad snap and fails to make a first down, it is a failed fourth down conversion.  </p>
                  <p>If the defensive team retrieves the bad snap on any play, it is a turnover, a fumble.</p>
               

                  <p className="cont-pdb">
                    <strong>Blocked Field Goal</strong>
                  </p>
                  <p>A Blocked Field Goal can result in either a turnover or failed fourth down conversion.  If it is blocked and leaves the field of play without scoring a field goal, it is a failed fourth down conversion.   If the ball is blocked into the field of play, retrieved by the offensive team and fails to make a first down, it is a failed fourth down conversion.  can be the result on any down, even on 1st down, if the offensive team’s kicker kicks the ball.  </p>
                  <p>If the ball is blocked into the field of play and retrieved by the defensive team, it is a turnover, a fumble.</p>
               
                
                
                
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PreviewAdvertisement />

      <UserFooter />
    </>
  );
}
