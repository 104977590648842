import { useState, useEffect } from "react";
import services from "../../../services/services";
import "./preview-advertisement.css";

const PreviewAdvertisement = () => {
  const [imagesAndVideos, setimagesAndVideos] = useState([]);
  const [index, setIndex] = useState(0);
  const [getlink, setlink] = useState([]);

  useEffect(() => {
    const tick = () => setIndex((i) => i + 1);
    const id = setInterval(tick, 10000);
    async function fetch() {
      try {
        let result = await services.adBannerImage();
        result.data?.data?.map(
          ({ image, imageAdLink }) => {
            setimagesAndVideos((pre) => [...pre, image]);
            setlink((pre) => [...pre, imageAdLink]);
          }
        );
      } catch (e) {
        console.log(e.response);
      }
    }

    fetch();

    return () => clearInterval(id);
  }, []);


  return (
    <div className="banner-ad">
      <div className="container">
        <div className="col-xl-12 text-center col-lg-12">
          {imagesAndVideos.length > 0 ? imagesAndVideos[index % imagesAndVideos.length]?.split(".").pop() ===
          "mp4" ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={getlink[index % getlink.length]}
            >
              <video
                style={{ width: "400px", height: "auto" }}
                className="photo"
                loop={true}
                autoPlay={true}
                muted={true}
              >
                <source
                  type="video/mp4"
                  alt="Avatar"
                  src={imagesAndVideos[index % imagesAndVideos.length]}
                />
              </video>
            </a>
          ) : (
            <a
              rel="noreferrer"
              target="_blank"
              href={getlink[index % getlink.length]}
            >
              <img
                id="image_size"
                src={imagesAndVideos[index % imagesAndVideos.length]}
                alt="Avatar"
                className="img-fluid"
              />
            </a>
          ): ''}
          <h5 className="pt-4">An affiliate relationship exists with some of our fine advertisers.  We may be
                compensated for purchases you make from them.  Purchases do not increase
                your odds of winning.  No purchase necessary to play.   
                 </h5>
        </div>
      </div>
    </div>
  );
};

export default PreviewAdvertisement;
