import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import { useEffect, useState } from "react";

import { withRouter, useHistory } from "react-router-dom";
import phone from "../../../assets/images/phone-call.svg";
import webicon from "../../../assets/images/web-icon.svg";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import "./responsiblegaming.css";
import services from "../../../services/services";
import { toast } from "react-toastify";
import ModalForm from "./popup";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import moment from "moment";

toast.configure();

const ResponsibleGaming = () => {
  const [formdata, setFormData] = useState({});
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let history = useHistory();
  const [show, setShow] = useState(false);
  const [saveEmail, setemail] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [val, setVal] = useState("");
  const [err, setErr] = useState("OTP has been sent in your mail id");
  const [inputDisabled, setInputDisabled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("token")) {
      async function fetch() {
        try{
          setSubmitFormLoader(true);
          let response = await services.userInfo();
          // console.log('yo',response.data.data);
          setInputDisabled(true);
          setFormData(response.data.data);
          setSubmitFormLoader(false);
        }catch(e){
          setSubmitFormLoader(false);
        }
       
      }

      fetch();
    }
  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(formdata);
  }, [formdata]);

  async function fetch(dat) {
    let data = {
      firstName: dat.firstName,
      lastName: dat.lastName,
      email: dat.email,
      mobileNumber: `${dat.mobileNumber}`,
    };
    setemail(dat.email);
    try {
      setSubmitFormLoader(true);
      let res = await services.exclusion(data);

      if (res.data.message === "Sucess") {
        setShow(true);
      }
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }

  const HandleClose = () => setShow(false);

  const verifyOtp = async () => {
    let data = {
      otp: Number(val),
      email: saveEmail,
    };

    try {
      setSubmitFormLoader(true);
      let res = await services.exclusionOtpVerify(data);
        setErr("");
        setShow(false);
        setFormData(res.data.data);
        setInputDisabled(true);
        setSubmitFormLoader(false);
        Swal.fire(res.data.message, "", "success");
 
    } catch (e) {
      // console.log(e.response);
      toast.error(e.response.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);

    }
    // console.log(res.data);
  };

  const submitData = async (d) => {
    let data = {
      timePeriod: d?.timeperiod,
      email: saveEmail,
    };

    Swal.fire({
      title: "Do you want to Submit the Form?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "OK",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire("Saved!", "", "success");
        try {
          setSubmitFormLoader(true);
          let res = localStorage.getItem("token")
            ? await services.exclusionTimePeriod({ timePeriod: d?.timeperiod })
            : await services.exclusionWithoutLogin(data);
          Swal.fire(res.data.message, "", "success");

          localStorage.clear();
          setSubmitFormLoader(false);
          history.push("/login");
        } catch (e) {
          console.log(e.response);
          setSubmitFormLoader(false);
        }
      }
    });
  };

  return (
    <>
      <UserHeader />
      <ModalForm
        msg={err}
        verifyotp={verifyOtp}
        onchange={(e) => setVal(e.target.value)}
        show1={show}
        handleclose={HandleClose}
      />
      {submitFormLoader && <div className="loader"></div>}
      <section className="hero-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <h2>Self-Exclusion Form</h2>
              <p>
                If you fill this form out and provide it to us, we will take you
                off all marketing efforts, including newsletters, announcements
                and other promotional efforts. Your account will be inactivated
                and any moneys in the account will be returned to you in
                accordance with our policies.
              </p>
              <p>
                Please do not feel defeated; a new opportunity is now possible.
                Contact the helpline numbers in your area and find the new
                possibilities and friendships near you.
              </p>
              <p>
                Submitting this form with us is confidential and will only
                affect your relationship with us. Your account will be
                inactivated for the period you select and all marketing
                materials, advertising, promotions and announcements will stop
                until your account is activated and you positively select to
                restart such items.
              </p>
              <p>
                Any funds in your account will be returned to you in accordance
                with our policies.
              </p>
              <p>
                Many states offer a more comprehensive form that may reach us.
                If we are informed by a state of a self-exclusion form, the same
                actions will be taken as if you filled out this form. This will
                give you time to seek out other activities, friendships,
                self-help groups and information without distraction.
              </p>
              <p>
                By making this election, you will not be able to play games on
                any of our websites for money or prizes. You will still be able
                to access general information from our website as a guest. This
                self-exclusion will only apply to our websites.
              </p>
              <p>
                {" "}
                To reinstate your account, please wait the required time period
                than contact Customer Service (link) or go through our
                reinstatement process online. Online you will be asked to verify
                your information like now, then you will make a positive
                affirmation you want to reinstate your account.
              </p>
              {/* <p>Please consider this option and seek additional information and help.</p> */}
              <p className="mt-5">
                {" "}
                <a href="#contact-detials" className="btn-hero">
                  Contact Customer Service
                </a>{" "}
              </p>
            </div>
            <div className="col-lg-4 col-md-8 offset-lg-1">
              <div className="respons-form-area">
                <form>
                  <div className="form-group">
                    <label> First Name:</label>
                    <input
                      disabled={inputDisabled}
                      {...register("firstName", { required: true })}
                      className="form-control"
                    />
                    {errors.firstName && (
                      <p className="text-danger">
                        Please enter the first name.
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label> Last Name:</label>
                    <input
                      disabled={inputDisabled}
                      {...register("lastName", { required: true })}
                      className="form-control"
                    />
                    {errors.lastName && (
                      <p className="text-danger">Please enter the last name.</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label> Mobile Number:</label>
                    <input
                      disabled={inputDisabled}
                      {...register("mobileNumber", { required: true })}
                      className="form-control"
                    />
                    {errors.mobileNumber && (
                      <p className="text-danger">
                        Please enter the mobile number.
                      </p>
                    )}
                  </div>
                  <div className="form-group">
                    <label> Email Id:</label>
                    <input
                      disabled={inputDisabled}
                      {...register("email", { required: true })}
                      className="form-control"
                    />
                    {errors.email && (
                      <p className="text-danger">Please enter the email.</p>
                    )}
                  </div>

                  {!inputDisabled && (
                    <div className="row">
                      <div className="col-lg-12 col-md-12 text-center">
                        <button
                          type="button"
                          onClick={handleSubmit(fetch)}
                          className="btn-otp mt-2 mb-2"
                        >
                          Sent OTP
                        </button>
                      </div>
                    </div>
                  )}
                  {inputDisabled && (
                    <>
                      <div className="form-group">
                        <label> Date Of Birth:</label>
                        <input
                          defaultValue={
                            formdata?.dob
                              ? moment(formdata?.dob).format("MM/DD/YYYY")
                              : ""
                          }
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label> Mailing Address: </label>
                        <input
                          defaultValue={
                            formdata?.address &&
                            `${formdata?.address}, ${formdata?.city}`
                          }
                          disabled
                          type="text"
                          autoFocus="off"
                          autoComplete="off"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          {" "}
                          Period to suspend (please choose) activity:
                        </label>
                        <select
                          {...register("timeperiod", { required: true })}
                          className="form-control"
                        >
                          <option value="">
                            .................. Select Time Period
                            ..................{" "}
                          </option>
                          <option value={"semiannual"}>6 Months </option>
                          <option value={"annual"}>1 Year </option>
                          <option value={"2yearly"}>2 Years </option>
                          <option value={"3yearly"}>3 Years</option>
                          <option value={"5yearly"}>5 Years</option>
                          <option value={"8yearly"}>8 Years</option>
                        </select>
                        {errors.timeperiod && (
                          <p className="text-danger">
                            Please select time period.
                          </p>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 offset-lg-3">
                          <button
                            type="button"
                            onClick={handleSubmit(submitData)}
                            className="btn btn-primary btn-block mt-2 mb-2"
                          >
                            Submit
                          </button>
                        </div>
                        {/* <div className="col-lg-6 col-md-6">
                      <button className="btn btn-secondary btn-block mt-2 mb-2">
                        Cancel{" "}
                      </button>
                    </div> */}
                      </div>
                    </>
                  )}
                </form>
                {/* <hr>

                            <div className="text-center">

                                <a href="#">Contact Customer Service </a>

                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="responsible-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="heading-sec mb-5">
                <h2>
                  Responsible Gaming - <span>1-800-522-4700</span>
                </h2>
                <h4 className="pb-3">Call now, get the help available.</h4>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <p>
                KiBu Gaming Corporation, Inc. asks you to game responsibly. We
                hope you enjoy the games, but with discipline, within limits and
                proper conduct so as not to harm yourself or your loved ones.{" "}
              </p>
              <p>
                The page links below offer direct steps to find help. Help is
                available and life fulfillment, laughter, entertainment, joy and
                fun, without gaming is possible. Please be responsible and do
                not play with money necessary for living expenses such as rent,
                mortgage, utilities, food, taxes and automotive.
              </p>
              <p>
                Gaming is merely one avenue for entertainment. Many people enjoy
                pets, movies, dancing, casual sports, hiking, canoeing,
                bicycles, road trips, concerts, board games, video games,
                gardening, zoo trips, museums, car shows, golfing, fishing, etc.{" "}
              </p>
              <h4>
                State Helplines &amp; Websites{" "}
                <a href="#contact-detials" style={{ paddingLeft: "10px" }}>
                  Contact Lists
                </a>
              </h4>
              <p>
                Calling the numbers provided for help opens a whole new world of
                adventure, connecting you with people who are finding new and
                exciting or long forgotten ways of entertaining themselves.{" "}
              </p>
              <p>
                Counselors devote their lives to helping folks find a new
                meaning, a new direction in life to overcome the boredom and
                mental anguish felt when abandoning something you feel
                passionate about.
              </p>
              <p>
                Self-help groups include individuals willing to discuss their
                situation then and how they perceive things now, the activities
                they enjoy living differently.
              </p>
              <h4> Other Responsible Gaming Materials</h4>
              <p>
                Links to several resources and pamphlets to help you maintain
                your limits, not play in excess, discuss the feelings associated
                with problem gaming, etc.
              </p>
              <p>
                If these resources are difficult to use, read, adhere to or
                follow, please contact your state helpline.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="responsible-contact" id="contact-detials">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="heading-sec mb-5">
                <h2>
                  Responsible Gaming - <span>Contact Infromation</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h4 className="mb-4">NATIONAL CONTACTS</h4>
            </div>
            <div className="col-lg-5 col-md-12">
              <div className="step">
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.ncpgambling.org/">
                        https://ncpgambling.org{" "}
                      </a>{" "}
                    </p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.ncpgambling.org/help-treatment/help-by-state/">
                        https://www.ncpgambling.org/help-treatment/help-by-state/
                      </a>{" "}
                    </p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.gamblersanonymous.org/ga/">
                        www.gamblersanonymous.org{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <h4 className="mb-4">STATE CONTACTS</h4>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Arizona</h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-NEXT-STEP or text ‘NEXTSTEP’ to 53342</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://problemgambling.az.gov/">
                        https://problemgambling.az.gov/{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>California</h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-GAMBLER (426-2537) or text ‘SUPPORT’ to 53342</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.cdph.ca.gov/Programs/OPG/Pages/opg-landing.aspx">
                        https://www.cdph.ca.gov/Programs/OPG/Pages/opg-landing.aspx
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Connecticut </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-888-789-7777</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://portal.ct.gov/DMHAS/Programs-and-Services/Problem-Gambling/PGS---Home-Page">
                        https://portal.ct.gov/DMHAS/Programs-and-Services/Problem-Gambling/PGS---Home-Page
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Delaware </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-888-850-8888</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.deproblemgambling.org/">
                        www.deproblemgambling.org
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Florida </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-ADMIT-IT</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="http://www.gamblinghelp.org/">
                        http://www.gamblinghelp.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Georgia </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.georgiagamblinghelp.org/">
                        https://www.georgiagamblinghelp.org/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Idaho </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-926-2588</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Illinois </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-GAMBLER (426-2537)</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://illinoisgamblinghelp.org/">
                        https://illinoisgamblinghelp.org/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5> Indiana </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-994-8448</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.in.gov/fssa/dmha/addiction-services/problem-gambling/">
                        https://www.in.gov/fssa/dmha/addiction-services/problem-gambling/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Iowa </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-BETS-OFF or text 855-895-8398</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://yourlifeiowa.org/gambling">
                        https://yourlifeiowa.org/gambling
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Kansas </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href=" https://www.ksproblemgambling.org/">
                        https://www.ksproblemgambling.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Kentucky </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.kygamblinghelp.org/">
                        {" "}
                        https://www.kygamblinghelp.org/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Louisiana </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-877-770-STOP (7867)</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://ldh.la.gov/page/1545">
                        {" "}
                        https://ldh.la.gov/page/1545
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Maine </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>
                      211 or 1-866-811-5695 or text YOUR ZIP CODE to 898-211
                    </p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://maineproblemgambling.org/">
                        {" "}
                        https://maineproblemgambling.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Maryland </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-GAMBLER (426-2537)</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.mdproblemgambling.com/">
                        {" "}
                        https://www.mdproblemgambling.com/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Massachusetts </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-327-5050 or 1-800-426-1234</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.mass.gov/orgs/office-of-problem-gambling-services">
                        https://www.mass.gov/orgs/office-of-problem-gambling-services
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Michigan </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-270-7117</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="http://www.michapg.com/">
                        {" "}
                        http://www.michapg.com/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Minnesota </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-333-HOPE (4673) or text ‘HOPE’ to 53342</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://getgamblinghelp.com/">
                        {" "}
                        https://getgamblinghelp.com/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Mississippi </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-888-777-9696</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="http://www.msgambler.org/">
                        {" "}
                        http://www.msgambler.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Missouri </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-888-BETS-OFF</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Montana </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-888-900-9979</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="http://www.mtproblemgambling.org/">
                        http://www.mtproblemgambling.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Nebraska </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-833-BET-OVER (238-6837)</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://problemgambling.nebraska.gov/">
                        https://problemgambling.nebraska.gov/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Nevada </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.nevadacouncil.org/">
                        {" "}
                        https://www.nevadacouncil.org/{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>New Hampshire </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-603-724-1605</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://nhproblemgambling.org/Home.aspx">
                        https://nhproblemgambling.org/Home.aspx{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>New Jersey </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-GAMBLER (426-2537)</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://800gambler.org/">
                        {" "}
                        https://800gambler.org/{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>New Mexico </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-572-1142 or 1-888-696-2440</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://rganm.org/"> https://rganm.org/ </a>{" "}
                    </p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.nmcpg.org/">
                        {" "}
                        https://www.nmcpg.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>New York </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-877-846-7369</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href=" https://nyproblemgambling.org/">
                        {" "}
                        https://nyproblemgambling.org/
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>North Carolina </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="http://www.nccouncilpg.org/">
                        {" "}
                        http://www.nccouncilpg.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Ohio </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-589-9966</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://pgnohio.org/"> https://pgnohio.org/</a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Oklahoma </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.oapgg.org/">
                        {" "}
                        https://www.oapgg.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Oregon </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-877-MY-LIMIT (695-4648)</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.opgr.org/"> https://www.opgr.org/</a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Pennsylvania</h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-848-1880</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.pacouncil.com/">
                        {" "}
                        https://www.pacouncil.com/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Rhode Island </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-877-942-6253</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href=" https://www.pgsri.org/">
                        {" "}
                        https://www.pgsri.org/
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Virginia </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-888-532-3500</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="http://www.vacpg.org/"> http://www.vacpg.org/</a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Washington </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-547-6133</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="   https://www.hca.wa.gov/health-care-services-supports/behavioral-health-recovery/problem-gambling">
                        https://www.hca.wa.gov/health-care-services-supports/behavioral-health-recovery/problem-gambling
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Wisconsin </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-426-2535</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href=" https://wi-problemgamblers.org/">
                        {" "}
                        https://wi-problemgamblers.org/{" "}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>Wyoming </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.wyomingcpg.org/">
                        {" "}
                        https://www.wyomingcpg.org/{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="step">
                <h5>
                  *If your state is not listed or you have trouble connecting,
                  use these national resources.{" "}
                </h5>
                <div className="info">
                  <div className="address">
                    <i>
                      {" "}
                      <img src={phone} alt="" />{" "}
                    </i>
                    <p>1-800-522-4700</p>
                  </div>
                  <div className="email">
                    <i>
                      {" "}
                      <img src={webicon} alt="" />{" "}
                    </i>
                    <p>
                      <a href="https://www.ncpgambling.org/help-treatment/help-by-state/">
                        https://www.ncpgambling.org/help-treatment/help-by-state/{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PreviewAdvertisement />

      <UserFooter />
    </>
  );
};

export default withRouter(ResponsibleGaming);
