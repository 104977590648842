import { Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";

function GameModal({ handleclose, show, data }) {
    let arrayBox = new Array(25).fill(1);
    const [showCard, setShowCard] = useState([]);
    const [gameIndex, setGameIndex] = useState("");

    useEffect(()=>{
        setShowCard(data?.cardData || [])
    },[data])


  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Game Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="col-lg-12 col-md-12">
        <div className="text-center card-number"> CARD : #{data?.cardNumber}</div>
                    <div className="card-design-outer">
                
                    <div className="row">
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">{data?.patternPlayedMultipleWinVersion}</div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">{data?.eventTypeLeagueFactorCardVersion}</div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center">
                      {" "}
                      <div className="btntop-txt">{data?.cardCreatedTime}</div>
                    </div>
                  </div>
                    <div className="card-inner-design">
                    
                    {arrayBox?.map((d,i)=>{
                     return  (i%5==-0) && <div className="card-wrap">
                        <div id={i%5+i+1}
                          className={`card-box ${
                            gameIndex === i%5+i+1 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {/* {miniCallData.length > 0 &&
                              miniCallData[i%5+i]?.length > 0 &&
                              miniCallData[i%5+i].map((d, ind) => (
                                <span style={{color:d?.color && 'red' }} key={ind}>{d?.callName}</span>
                              ))} */}
                          </div>
                          <div className="pattern-main">
                          {showCard.length && showCard[i%5+i]}
                          </div>
                        </div>
                        <div
                        id={i%5+i+2}
                           className={`card-box ${
                            gameIndex === i%5+i+2 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {" "}
                            {/* {miniCallData.length > 0 &&
                              miniCallData[i%5+i+1]?.length > 0 &&
                              miniCallData[i%5+i+1].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))} */}
                          </div>
                          <div className="pattern-main">
                          {showCard.length && showCard[i%5+i+1]}
                          </div>
                        </div>
                        <div id={i%5+i+3}
                          className={`card-box ${
                            gameIndex === i%5+i+3 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {/* {miniCallData.length > 0 &&
                              miniCallData[i%5+i+2]?.length > 0 &&
                              miniCallData[i%5+i+2].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))}{" "} */}
                          </div>
                          <div className="pattern-main">
                          {showCard.length && showCard[i%5+i+2]}
                          </div>
                        </div>
                        <div id={i%5+i+4}
                          className={`card-box ${
                            gameIndex === i%5+i+4 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {/* {" "}
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+3]?.length > 0 &&
                              miniCallData[i%5+i+3].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))} */}
                          </div>
                          <div className="pattern-main">
                          {showCard.length && showCard[i%5+i+3]}
                          </div>
                        </div>
                        <div id={i%5+i+5}
                          className={`card-box ${
                            gameIndex === i%5+i+5 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {/* {" "}
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+4]?.length > 0 &&
                              miniCallData[i%5+i+4].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))} */}
                          </div>
                          <div className="pattern-main">
                          {showCard.length && showCard[i%5+i+4]}
                          </div>
                        </div>
                      </div>
                      })}
                      
                    </div>
                    <div className="row">
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">{data?.groupIdCardDateCreated}</div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">
                      {/* {data?.eventDateNumber} */}
                      {data?.eventData?.eventId?.slice(2)}
                      </div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center">
                      {" "}
                      <div className="btntop-txt">{data?.partnerIdSaleSpot || "C309k"}</div>
                    </div>
                  </div>
                    </div>
                  </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleclose}>
            Close
          </Button>
          {/* <Button style={{background:'#0070C0',padding:'10px 30px'}} onClick={entergame} variant="primary">
            Enter
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GameModal;
