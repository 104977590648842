import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { useState } from "react";


export default function BarCodeRetailer({ show,submit}) {
const [val, setVal] = useState("")
  return (
    <>
      {/* <Button variant="primary" onClick={handleshow}>
          Launch static backdrop modal
        </Button>
   */}
      <Modal id="myModal"
      //  size="lg"
        centered
        show={show}

        backdrop="static"
        keyboard={false}
      >

        <Modal.Body>
          <div className="col-lg-12">
          {/* <form role="form"> */}
        <div className="row text-center">
          <div className="col-lg-8 offset-lg-2 ">
            <div className="form-group">
              <label className="control-label"><h3>Enter Your Pin</h3></label>
              <input onChange={(e)=>setVal(e.target.value)} maxLength={4} className="form-control" type="text"  />
            </div>
          </div>
 </div>
 <div>

 </div>
        <div className="col-md-4 offset-md-4">
        <button type="button" onClick={()=>submit(val)}  className="btn mt-1 btn-submit btn-block"> Submit </button>
        </div>
      {/* </form> */}
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}


