import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// import banner from '../../../assets/images/banner-flyer.jpg'
import services from "../../../services/services";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import barcode from "../../../assets/images/cuopon-qr.png";
import logo from "../../../assets/images/kibu-logo.png";
import BarCodeRetailer from "./modal";
import moment from "moment";
import "./retailer.css";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import parse from 'html-react-parser';



toast.configure();
const RetailerCoupon = () => {
  const [show, setShow] = useState(true);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [data, setshowData] = useState([]);
  const { eId, gId, name, uId } = useParams();

  const fetchData = async (d) => {
    let data = {
      userId: uId,
      eventId: eId,
      groupId: gId,
      pattern: name,
      pin: Number(d),
    };
    try {
      setSubmitFormLoader(true);
      let res = await services.prizeDetailRetailer(data);
      Swal.fire(res.data.message, "", "success");
      setShow(false);

      setshowData(res.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
        autoClose:false,
        fontSize:"22px"
      });
      setSubmitFormLoader(false);
      // console.log(e.response.data.message);
    }
  };
  return (
    <>
      {/* <UserHeader /> */}
      <BarCodeRetailer submit={fetchData} show={show} />
      {submitFormLoader && <div style={{zIndex:1200, left:'46%'}} className="loader"></div>}
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <div className="logo-watermark-2">
                <img src={logo} alt="" />{" "}
              </div>
              <h1>Retailer Coupon</h1>
              {/* <Link to="/">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "} */}
              {/* <Link to="#" className="active">
                Retailer Coupon
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      <section className="result-sec">
        <div className="team-table">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-8 offset-lg-2">
                <div className="retailer-coupon-area">
                  <form className="xc" role="retailer-form ">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 text-center">
                        <h3>Retailer COUPON/ENTRY FORM </h3>
                      </div>

                    </div>
                    <div className="row mt-4 text-center">
                    <div className="col-lg-6 col-md-6 offset-lg-3">
                        <div className="form-group">
                          <label className="control-label">Expire/Enter Date</label>
                          <h6>{
                              data?.eventDate
                                ? moment(data?.eventDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""
                            }</h6>
                          {/* <input
                            defaultValue={
                              data?.eventDate
                                ? moment(data?.eventDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""
                            }
                            disabled
                            className="form-control text-center"
                            type="text"
                          /> */}
                        </div>
                      </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                            <label className="control-label">Event Date</label>
                            <h6>{data?.eventDate
                                ? moment(data?.eventDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}</h6>
                          {/* <input
                            defaultValue={
                              data?.eventDate
                                ? moment(data?.eventDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""
                            }
                            disabled
                            className="form-control text-center"
                            type="text"
                          /> */}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="control-label">Event ID</label>
                              <h6>{data?.eventId}</h6>
                              {/* <input
                                value={data?.eventId}
                                className="form-control text-center"
                                type="text"
                                disabled
                              /> */}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="control-label">Group ID</label>
                              <h6>{data?.groupId}</h6>
                              {/* <input
                                value={data?.groupId}
                                className="form-control text-center"
                                type="text"
                                disabled
                              /> */}
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label className="control-label">
                                Pattern
                              </label>
                              <h6>{data?.pattern} </h6>
                              {/* <input value={data?.pattern} disabled className="form-control text-center" type="text" /> */}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 offset-lg-3">
                            <div className="form-group">
                              <label className="control-label">
                               Card #
                              </label>
                              <h6>{data?.cardNumber}</h6>
                              {/* <input disabled value={data?.cardNumber} className="form-control text-center" type="text" /> */}
                            </div>
                          </div>
                        </div>
  
                    <div className="row mt-4">
                      <div className="col-lg-12 col-md-12 coupon-content">

                        <h5>PRIZE DESCRIPTION : <span style={{color:' #7e7e7e', fontWeight: 500}}>{data?.shortPrize}</span></h5>
                        <div>
                          {parse(data?.longPrize || "")}
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
   
      </section>

      <PreviewAdvertisement />

      {/* <UserFooter /> */}
    </>
  );
};

export default RetailerCoupon;
