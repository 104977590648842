import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import baseball from '../../../assets/images/baseball-img.png';
import football from '../../../assets/images/american-football.png';
import basketball from '../../../assets/images/basketball-img.png';

toast.configure();

// Function for create advertisement
const CallerBoardHome = () => {


  return (
    <div id="admin-dashboard">
      <div className="mainWrapper Total-Revenue-1">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
        <div className="customTableWrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="left">
              <h4> Caller Boards</h4>
            </div>
          </div>
        </div>
        <div className="caller-board-sec" />
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="event-box-green text-center">
              <Link to="/admin/baseball-caller-board">
                <img src={baseball} />
              </Link><div className="overlay">
                <Link to="/admin/baseball-caller-board" className="game-name-2">
                  <div className="button-list">
                    <span className="line1" />
                    Baseball Caller Board
                    <span className="line2" />
                  </div>
                </Link>
              </div>
              <Link to="/admin/baseball-caller-board" className="game-name">Baseball Caller Board</Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="event-box-pink text-center">
            <Link to="/admin/football-caller-board" >
                <img src={football} />
              </Link><div className="overlay">
              <Link to="/admin/football-caller-board" className="game-name-2">
                  <div className="button-list">
                    <span className="line1" />
                    Football Caller Board
                    <span className="line2" />
                  </div></Link>
              </div>
              <Link to="/admin/football-caller-board" className="game-name">Football Caller Board</Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="event-box-grey text-center">
              <img src={basketball} />
              <div className="overlay">
              <Link to="/admin/basketball-caller-board" className="game-name-2">
                  <div className="button-list">
                    <span className="line1" />
                    Basketball Caller Board
                    <span className="line2" />
                  </div>
                </Link>
              </div>
              <Link  to="/admin/basketball-caller-board" className="game-name">Basketball Caller Board</Link>
            </div>
          </div>
        </div>
      </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CallerBoardHome;
