// Development port
const httpReq = {
  // baseURL: "http://localhost:5000/api",
  // baseURL: "https://kibugamingcorporationqa-api.chetu.com/api",
  baseURL: "https://api.kibugaming.com/api",
  headers: {
    "Content-type": "application/json",
  },
};

export default httpReq;
