/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import DataTable from "react-data-table-component";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "./roles.css";
// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const SetPermission = () => {
  const history = useHistory();

  console.log(history);
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const [permission, setPermission] = useState([]);
  const [saveData, setSaveData] = useState({});

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitFormLoader, setSubmitFormLoader] = useState("");

  const { id } = useParams();

  useEffect(() => {

    if(history?.location?.state?.view){
      setDisInput(true);
      setFormData(history?.location?.state?.view?.permissions)
    }
    if(history?.location?.state?.edit){
      setFormData(history?.location?.state?.edit?.permissions)
    }
    fetchData();
  }, []);

  
  // useEffect(()=>{
  //   if(history?.location?.state?.edit){
  //     setSaveData(formData)
  //   }

  // },[])

console.log("formData",formData);


  async function fetchData(search) {
    try {
      setSubmitFormLoader(true);
      let data = await services.listPermissions();
      setPermission(Object.keys(data?.data?.data?.permissionlist || []));
      let listP = {roleName: history?.location?.state?.name, permissions:{}};
      let objName = history?.location?.state?.edit?.permissions;
      data?.data?.data?.permissionlist && Object.keys(data?.data?.data?.permissionlist).forEach((d) =>
      listP.permissions[d.toLowerCase()] = {
            add: false,
            delete: false,
            edit: false,
            view: false,
      })
      history?.location?.state?.edit ? setSaveData(
        {roleName: history?.location?.state?.edit?.roleName,
          roleId:history?.location?.state?.edit?.id, 
          permissions:{...listP.permissions,...objName}})  :setSaveData(listP)
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }

  const getPermissionData = (e) => {
    const { value, name, checked } = e.target;
    setSaveData({
      ...saveData,
      permissions:{...saveData.permissions, [name.toLowerCase()]: {...saveData.permissions[name.toLowerCase()],[value]: checked }},
    });
  };

  console.log("saveData", saveData);

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Module Name",
      selector: (row, i) => row,
      sortable: true,
      filterable: true,
    },

    {
      name: "Add",
      cell: (row) => (
        <input
         disabled={disInput}
          name={row}
          defaultChecked={formData[row.toLowerCase()]?.add ? true : false}
          value="add"
          onChange={getPermissionData}
          type="checkbox"
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      //  button: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <input
          disabled={disInput}
          defaultChecked={formData[row.toLowerCase()]?.edit ? true : false}
          name={row}
          value="edit"
          onChange={getPermissionData}
          type="checkbox"
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      //  button: true,
    },
    {
      name: "View",
      cell: (row) => (
        <input
          name={row}
          disabled={disInput}
          defaultChecked={formData[row.toLowerCase()]?.view ? true : false}
          value="view"
          onChange={getPermissionData}
          type="checkbox"
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      //  button: true,
    },

    {
      name: "Delete",
      cell: (row) => (
        <input
          name={row}
          value="delete"
          disabled={disInput}
          defaultChecked={formData[row.toLowerCase()]?.delete ? true : false}
          onChange={getPermissionData}
          type="checkbox"
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      // button: true,
    },
  ];

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Permissions for {history?.location?.state?.name || history?.location?.state?.view?.roleName || history?.location?.state?.edit?.roleName}</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right">
                {/* <a class="btn permission-btn mt-3" href="#">Set Permissions</a> */}
              </div>
            </div>
            <div className="row mt-4">
              <div className="clol-lg-12 col-md-12">
                <DataTable
                  columns={columns}
                  data={permission}
                  pagination
                  // paginationServer
                  // paginationTotalRows={permission.totalItems}
                  // paginationPerPage={10}
                  // paginationComponentOptions={{
                  //   noRowsPerPage: true,
                  // }}
                  // onChangePage={(page) => setPage(page)}
                  persistTableHead
                />

                <div className="row pb-4">
                  <div className="col-lg-12">
                    <button
                      onClick={() => history.goBack()}
                      className="btn admin-back-btn "
                    >
                      {"Back"}
                    </button>
               { !history?.location?.state?.view &&    <button
                      type="button"
                      onClick={() => {
                        let sData = { roleName: history?.location?.state?.name };
                        history.push({
                          pathname: "/admin/roles",
                          state: { ...sData, ...saveData },
                        });
                      }}
                      className="btn admin-primary-btn ml-3"
                    >
                      {history?.location?.state?.edit ? "Update" : "Submit"}
                    </button>}
                  </div>
                </div>
              </div>
              <div className="spacer-foot" />
            </div>
            {/*------ Footer bar------ */}
            <AdminFooter />
          </div>
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default SetPermission;
