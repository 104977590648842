/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import "./group.css";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const Group = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
    const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [groupId, setGroupId] = useState();
  const [showGroups, setGroups] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);
  const [showArchive, setShowArchive] = useState(false);
  const [reason, setReason] = useState('');
  const [getDelete, setDelete] = useState([]);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(()=>{
    fetchDeleted()
  },[])

  async function fetchData(search) {
    try{
      setSubmitFormLoader(true)
      let data = await services.groups(page, 10, search);
      setGroups(data?.data.data);
      setSubmitFormLoader(false)
    }catch(e){
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false)
    }

  }

  async function fetchDeleted() {
    try {
      setSubmitFormLoader(true);
      let res = await services.getDeletedGroups();
      setDelete(res?.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }

  const deleteAdvertisementByClick = () => {
    if (showSweetAlert) {
      services
        .deleteGroup(groupId,reason)
        .then((response) => {
          setSweetAlert(false);

          // Render to load advertisement page
          fetchData();
          fetchDeleted();
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        });
    }
  };

  const sweetAlertForDelete = (group) => {
    setSweetAlert(true);
    setGroupId(group);
    setReason("")
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const renderOnEditPage = (group) => {
    history.push(`/admin/edit-group/${group}`);
  };

  const renderOnAdvertisementDetailPage = (group) => {
    history.push({
      pathname: `/admin/group-details/${group}`,
      state: "group",
    });
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => showGroups?.currentPage * 10 +i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Group Id",
      selector: (row, i) => row.groupId,
      sortable: true,
      filterable: true,
    },

    {
      name: "Group Name",
      selector: (row) => row.groupName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Group Type",
      selector: (row) => row.groupType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Minimum Age",
      selector: (row) => row.age,
      sortable: true,
      filterable: true,
    },


    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => sweetAlertForDelete(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => renderOnEditPage(row.id)}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const columns2 = [
    {
      name: "S. No.",
      selector: (row, i) => showGroups?.currentPage * 10 +i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Group Id",
      selector: (row, i) => row.groupId,
      sortable: true,
      filterable: true,
    },

    {
      name: "Group Name",
      selector: (row) => row.groupName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Group Type",
      selector: (row) => row.groupType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Minimum Age",
      selector: (row) => row.age,
      sortable: true,
      filterable: true,
    },

  {
      name: "Reason",
      selector: (row) => row.deleteReason,
      sortable: true,
      filterable: true,
    },


    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    padding:0px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    fetchData(filterText);
    // setFilterText('')
  };

  console.log(reason);
  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>{showArchive && "Deleted"} Groups</h4>
                </div>
              </div>
              {submitFormLoader && <div className="loader"></div>}
              <div className="col-lg-6 col-md-6 text-lg-right">
                    {" "}
                 {!showArchive &&   <Link to="/admin/create-group">
                      {" "}
                      <button type="button" className="btn btn-primary">
                        Add
                      </button>
                    </Link>}
                  </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4">
            
                  
                </div>

                <div className="table-responsive">
                <div className={`d-flex mb-2 align-items-baseline justify-content-between`}>
                <button onClick={()=>{setShowArchive(!showArchive);setFilterText('')}} type="button" class="btn btn-primary">{showArchive ? "Back" : "Deleted Groups"}</button>
                 
               
                 {!showArchive && <div className="d-flex">
                  <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter Groups"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  
                  </div>}
                  </div>



                  {showArchive ? <DataTable
                    columns={columns2}
                    data={getDelete?.data}
                    pagination
                    paginationServer
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    // onChangePage={(page) => setPage(page)}
                    persistTableHead
                  /> :   
  
                  <DataTable
                    columns={columns}
                    data={showGroups.data}
                    pagination
                    paginationServer
                    paginationTotalRows={showGroups.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage(page)}
                    persistTableHead
                  />}
                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      title="Are you sure?"
                      onConfirm={deleteAdvertisementByClick}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                      disabled={!reason}
                    >
               
                      <textarea
                        type="text"
                        className="form-control mt-3"
                        placeholder="Enter valid reason why you deleting?"
                        onChange={(e)=>setReason(e.target.value)}
                      />
 
                    </SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="space-foot"></div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default Group;
