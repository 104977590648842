/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import * as universalFunction from "../../../utils/universalFunctions";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const CardParameter = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
  const [cardId, setId] = useState("");
  const [showCardParameter, setCardParameter] = useState([]);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchData();
  }, [page]);

  async function fetchData(search) {
    try{
      setSubmitFormLoader(true)
      let data = await services.cardParameters(page, 10, search);
      setCardParameter(data?.data.data);
      setSubmitFormLoader(false)

    }catch(e){
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false)

    }
   
  }

  const deletePartnerByClick = () => {
    if (showSweetAlert) {
      setSubmitFormLoader(true)
      services
        .deleteCardParameter(cardId)
        .then((response) => {
          setSweetAlert(false);

          // Render to load advertisement page

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitFormLoader(false)
          fetchData();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitFormLoader(false)
        });
    }
  };

  const sweetAlertForDelete = (id) => {
    setSweetAlert(true);
    setId(id);
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const renderOnEditPage = (id) => {
    history.push(`/admin/view-card-parameter/${id}`);
  };

  const viewCardParameter = (id) => {
    history.push({
      pathname: `/admin/view-card-parameter/${id}`,
      state: "card-parameter",
    });
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => showCardParameter.currentPage * 10 + i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Version No",
      selector: (row) => row.versionNumber,
      sortable: true,
      filterable: true,
    },
    {
      name: "	Event Type",
      selector: (row) => universalFunction.findLeague(row.eventType),
      sortable: true,
      filterable: true,
    },
    {
      name: "Event League",
      selector: (row) => universalFunction.findLeague(row.eventType,row.league),
      sortable: true,
      filterable: true,
    },
    {
      name: "Factors Code	",
      selector: (row) => row.factors?.map(d=>d.factorCode),
      sortable: true,
      filterable: true,
    },

    {
      name: "Code Description",
      selector: (row) => row.codeDescription,
      sortable: true,
      filterable: true,
    },

    // {
    //   name: "Special Factor",
    //   selector: (row) => row.specialFactorCode,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Actions",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <span
            onClick={() => viewCardParameter(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => sweetAlertForDelete(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => renderOnEditPage(row.id)}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    padding:0px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    fetchData(filterText);
    // setFilterText('')
  };


  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Card Parameters</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right">
                    {" "}
                    <Link to="/admin/create-card-parameter">
                      {" "}
                      <button type="button" className="btn btn-primary">
                        Add
                      </button>
                    </Link>
                  </div>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4">
                
                </div>

                <div className="table-responsive">
                  <div className="d-flex justify-content-end">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter by Version Number"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>
                  <DataTable
                    columns={columns}
                    data={showCardParameter.data}
                    pagination
                    paginationServer
                    paginationTotalRows={showCardParameter.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage(page)}
                    persistTableHead
                  />

                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      title="Are you sure?"
                      onConfirm={deletePartnerByClick}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                    ></SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-foot"></div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default CardParameter;
