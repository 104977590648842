/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const Partner = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const [showPartner, setPartner] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);
  const [submitFormLoader, setSubmitFormLoader] = useState("");

  useEffect(() => {
    fetchData(page,10);
  }, [page]);

  async function fetchData(page,num, search) {
    try {
      setSubmitFormLoader(true);
      let data = await services.partners(page, num, search);
      setPartner(data?.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }

  const deletePartnerByClick =  () => {
    if (showSweetAlert) {
      services
        .deletePartner(partnerId)
        .then((response) => {
          setSweetAlert(false);

          // Render to load advertisement page

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          fetchData(page, 10);

           
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const sweetAlertForDelete = (id) => {
    setSweetAlert(true);
    setPartnerId(id);
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const renderOnEditPage = (id) => {
    history.push(`/admin/edit-partner/${id}`);
  };

  const renderOnAdvertisementDetailPage = (id) => {
    history.push({
      pathname: `/admin/edit-partner/${id}`,
      state: "partner",
    });
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => showPartner.currentPage * 10 + i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Partner Id",
      selector: (row) => row.partnerId,
      sortable: true,
      filterable: true,
    },
    {
      name: "Partner Name",
      selector: (row) => row.partnerName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Partner Location Description",
      selector: (row) => row.partnerLocationDescription,
      sortable: true,
      filterable: true,
    },
    {
      name: "Partner Location Address",
      selector: (row) => row.partnerLocationAddress,
      sortable: true,
      filterable: true,
    },

    {
      name: "Partner Mailing Address",
      selector: (row) => row.partnerMailingAddress,
      sortable: true,
      filterable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => sweetAlertForDelete(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => renderOnEditPage(row.id)}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    padding: 0px 25px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    fetchData(page, 10,filterText);
    // setFilterText('')
  };

  const handlePageChange  = async (page) => {
 
    fetchData(page, 10,filterText);
    
	
	};
  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Partners</h4>
                </div>
              </div>
              {submitFormLoader && <div className="loader"></div>}
              <div className="col-lg-6 col-md-6 text-lg-right">
                {" "}
                <Link to="/admin/create-partner">
                  {" "}
                  <button type="button" className="btn btn-primary">
                    Add
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4"></div>

                <div className="table-responsive">
                  <div className="d-flex justify-content-end">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter Partners"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>
                  <DataTable
                    columns={columns}
                    data={showPartner.data}
                    pagination
                    paginationServer
                    paginationTotalRows={showPartner.totalItems}
                    // paginationPerPage={10}
                    paginationResetDefaultPage={false}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={handlePageChange}
                    persistTableHead
                  />

                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      title="Are you sure?"
                      onConfirm={deletePartnerByClick}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                    ></SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-foot"></div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default Partner;
