/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
// import './event.css';
import styled from "styled-components";
import Button from "react-bootstrap/Button";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const Employee = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [getRole, setGetRole] = useState([]);

  useEffect(() => {
    fetchData();
  }, [page]);

  async function fetchData(search) {
    try {
      setSubmitFormLoader(true);
      let data = await services.getEmployee(page, 10, search);
      let res = await services.getRole(0,100);
      setGetRole(res.data.data.data);
      setEmployee(data?.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }

  const deleteEmployee = () => {
    if (showSweetAlert) {
      services
        .deleteEmployee(employeeId)
        .then((response) => {
          setSweetAlert(false);

          // Render to load advertisement page

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });

          fetchData();
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const sweetAlertForDelete = (id) => {
    setSweetAlert(true);
    setEmployeeId(id);
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const renderOnEditPage = (id) => {
    history.push(`/admin/employee-details/${id}`);
  };

  const renderViewPage = (id) => {
    history.push({
      pathname: `/admin/employee-details/${id}`,
      state: "employee",
    });
  };



  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => employee?.currentPage * 10 + i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Last Name",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{row.lastName}</span>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Role Name",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{getRoleName(row.role)}</span>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Supervisor Name",
      selector: (row) => row.supervisor,
      sortable: true,
      filterable: true,
    },
    {
      name: "Home Address",
      selector: (row) => row.homeAddress,
      sortable: true,
      filterable: true,
    },


    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => renderViewPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => sweetAlertForDelete(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => renderOnEditPage(row.id)}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    padding: 0px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    fetchData(filterText);
    // setFilterText('')
  };

  const getRoleName = (id) => {
  let data =  getRole.length && getRole.find(data=>data.id===id);
  return data?.roleName
    }

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Employees</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right">
                {" "}
                <Link to="/admin/create-employee">
                  {" "}
                  <button type="button" className="btn btn-primary">
                    Add
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4"></div>

                <div className="table-responsive">
                  <div className="d-flex justify-content-end">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter Employees"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>
                  <DataTable
                    columns={columns}
                    data={employee.data}
                    pagination
                    paginationServer
                    paginationTotalRows={employee.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage(page)}
                    persistTableHead
                  />
                  {/* <BasicTable

                  data={showEvents}

                /> */}
                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      // dangerBtnBsStyle="primary"
                      title="Are you sure?"
                      onConfirm={deleteEmployee}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                    ></SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="space-foot"></div>

          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default Employee;
