/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import moment from "moment";
import Swal from "sweetalert2";
import * as Constants from "../../../helper/constants";
import { useForm, Controller } from "react-hook-form";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreateEmployee = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const [showEventLeague, setShowEventLeague] = useState([]);
  const [getRole, setGetRole] = useState([]);
  const {
    register,
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const { id } = useParams();
  let subscribe = watch();
  useEffect(() => {
    // reset form with user data
    reset(formData);
  }, [formData]);




  useEffect(() => {
    fetchData();
  }, [id]);

  async function fetchData() {
    setSubmitFormLoader(true);
    let res = await services.getRole(0,100);
    setGetRole(res.data.data.data);
    if (id !== undefined) {
      let response = await services.getSingleEmployee(id);
      setFormData({...response?.data?.data,
         retire: response?.data?.data.retire.toString(),
         health: response?.data?.data.health.toString(),
         life: response?.data?.data.life.toString()
        });
      if (history?.location?.state) {
        setDisInput(true);
      }
    }
    setSubmitFormLoader(false);
  }


  const submitData = async (d) => {

    let data = [d]?.map(({cellPhone,isDeleted, updatedAt,resetOtp,resetOtpExpireAt,id,email,password,createdAt,disableFrom,disableTill,isDisabled,isOtpVerified, ...rest }) => rest);

    let convertBool = { 
      retire : eval(d.retire),
      health : eval(d.health),
      life : eval(d.life)
    }

    let newData = {...data[0], ...convertBool}

    try {
      setSubmitFormLoader(true);
      let res = id ? await services.updateEmployee(id,newData) : await services.createEmployee(d);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });

      history.push("/admin/employees");
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
    }


  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
          <div className="row">
          <div className="col-sm-12">
            <div className="left">
              <h4>{disInput
                      ? "View"
                      : id
                      ? "Edit"
                      : "Create"} Employee </h4>
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-4">
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Role</label>
              <select disabled={disInput} {...register("role", { required: true })} className="form-control">
                <option value=''>Select One</option>
               {getRole.length && getRole.map(({id,roleName})=><option key={id} value={id}>{roleName?.toUpperCase()}</option>)}
              </select>  
              {errors.role && (
                    <p className="text-danger">Please select the role.</p>
                  )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> First Name</label>
              <input disabled={disInput} maxLength={30} {...register("firstName", { required: true })} type="text" className="form-control" />
              {errors.firstName && (
                    <p className="text-danger">Please enter the first name.</p>
                  )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Last Name</label>
              <input disabled={disInput} maxLength={30} {...register("lastName", { required: true })} type="text" className="form-control" />
              {errors.lastName && (
                    <p className="text-danger">Please enter the last name.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Personal Email</label>
              <input type="email" maxLength={50} disabled={disInput} 
               {...register("personalEmail", { required: true,
                pattern: {
               value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
               message: "Please enter valid email address."
               }})}
                className="form-control" />
              {errors.personalEmail && (
                    <p className="text-danger">{errors.personalEmail.message || "Please enter the personal email."}</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Login Email</label>
              <input type="email" maxLength={50} disabled={id && true}
              {...register("email", { required: true, 
               pattern: {
               value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
               message: "Please enter valid email address."
               } })} 
              className="form-control" />
              {errors.email && (
                    <p className="text-danger">{errors.email.message || "Please enter the login email."}</p>
               )}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Hire Date</label>
              <Controller
                        control={control}
                        {...register("hireDate", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subDays(new Date(), 0)}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />
              {/* <input type="date" maxLength={50} disabled={disInput} {...register("hireDate", { required: true })} className="form-control" /> */}
              {errors.hireDate && (
                    <p className="text-danger">Please select the hire data.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Supervisor Name</label>
              <input type="text" maxLength={50} disabled={disInput} {...register("supervisor", { required: true })} className="form-control" />
              {errors.supervisor && (
                    <p className="text-danger">Please enter the supervisor name.</p>
               )}  
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Emergency Contact </label>
              <input type="tel" maxLength={50} disabled={disInput} {...register("emergencyContact", { required: true })} className="form-control" />
              {errors.emergencyContact && (
                    <p className="text-danger">Please enter the emergency contact.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Emergency Phone</label>
              <input type="tel" maxLength={50} disabled={disInput} {...register("emergencyPhone", { required: true })} className="form-control" />
              {errors.emergencyPhone && (
                    <p className="text-danger">Please enter the emergency phone.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Second Contact </label>
              <input type="tel" maxLength={50} disabled={disInput} {...register("emergencySecondContact", { required: true })} className="form-control" />
              {errors.emergencySecondContact && (
                    <p className="text-danger">Please enter the second contact.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Second Phone</label>
              <input type="tel" maxLength={50} disabled={disInput} {...register("emergencySecondPhone", { required: true })} className="form-control" />
              {errors.emergencySecondPhone && (
                    <p className="text-danger">Please enter the second phone.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Home Address</label>
              <input type="text" maxLength={255} disabled={disInput} {...register("homeAddress", { required: true })} className="form-control" />
              {errors.homeAddress && (
                    <p className="text-danger">Please enter the home Address.</p>
               )}  
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Mailing Address</label>
              <input type="text" maxLength={255} disabled={disInput} {...register("mailingAddress", { required: true })} className="form-control" />
              {errors.mailingAddress && (
                    <p className="text-danger">Please enter the mailing Address.</p>
               )} 
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-4">
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Work Location</label>
              <input type="text" maxLength={255} disabled={disInput} {...register("workLocation", { required: true })} className="form-control" />
              {errors.workLocation && (
                    <p className="text-danger">Please enter the work location.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Date of Birth </label>
              <Controller
                        control={control}
                        {...register("dateOfBirth", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            showYearDropdown
                            maxDate={moment().subtract(18, 'years')._d}
                            dateFormatCalendar="MMMM"
                            dropdownMode="select"
                            scrollableYearDropdown
                          />
                        )}
                      />
              {errors.dateOfBirth && (
                    <p className="text-danger">Please enter the date of birth.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Other Phone</label>
              <input type="tel" maxLength={50} disabled={disInput} {...register("otherPhone", { required: true })} className="form-control" />
              {errors.otherPhone && (
                    <p className="text-danger">Please enter the other phone.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label> Bus. Phone </label>
              <input type="tel" maxLength={50} disabled={disInput} {...register("businessPhone", { required: true })} className="form-control" />
              {errors.businessPhone && (
                    <p className="text-danger">Please enter the business phone.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6 health">
            <div className="form-group">
              <div> <label>Health</label></div>
              <div className="form-check-inline">
                <label  className="form-check-label d-flex align-items-center">
                  <input type="radio" className="form-check-input" value={true} disabled={disInput} {...register("health", { required: true })} />Yes
                </label>
              </div>
              <div className="form-check-inline">
                <label  className="form-check-label d-flex align-items-center">
                  <input type="radio" className="form-check-input" value={false} disabled={disInput} {...register("health", { required: true })} />No
                </label>
              </div>
              {errors.health && (
                    <p className="text-danger">Please select the health.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>EE Cost </label>
              <input type="number" disabled={disInput} {...register("healthEECost", { required: true })} className="form-control" />
              {errors.healthEECost && (
                    <p className="text-danger">Please enter the EE cost.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Eff. Date </label>
              <Controller
                        control={control}
                        {...register("healthEffectiveDate", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subDays(new Date(), 0)}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />
              {errors.healthEffectiveDate && (
                    <p className="text-danger">Please select the Eff date.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6" />
          <div className="col-lg-3 col-md-6 health">
            <div className="form-group">
              <div> <label>Life</label></div>
              <div className="form-check-inline">
                <label className="form-check-label d-flex align-items-center">
                  <input type="radio" value={true}  className="form-check-input" disabled={disInput} {...register("life", { required: true })} />Yes
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label d-flex align-items-center">
                  <input type="radio" value={false} className="form-check-input" disabled={disInput} {...register("life", { required: true })} />No
                </label>
              </div>
              {errors.life && (
                    <p className="text-danger">Please select the life.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>EE Cost </label>
              <input type="number" disabled={disInput} {...register("lifeEECost", { required: true })} className="form-control" />
              {errors.lifeEECost && (
                    <p className="text-danger">Please enter the EE cost.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Eff. Date </label>
              <Controller
                        control={control}
                        {...register("lifeEffectiveDate", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subDays(new Date(), 0)}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />
              {errors.lifeEffectiveDate && (
                <p className="text-danger">Please select the Eff date.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6" />
          <div className="col-lg-3 col-md-6 health">
            <div className="form-group">
              <div> <label>Retire</label></div>
              <div className="form-check-inline">
                <label className="form-check-label d-flex align-items-center">
                  <input type="radio" className="form-check-input" value={true} disabled={disInput} {...register("retire", { required: true })} />Yes
                </label>
              </div>
              <div className="form-check-inline">
                <label className="form-check-label d-flex align-items-center">
                  <input type="radio" className="form-check-input" value={false} disabled={disInput} {...register("retire", { required: true })} />No
                </label>
              </div>
              {errors.retire && (
                    <p className="text-danger">Please select the retire.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>EE Cost </label>
              <input type="number" disabled={disInput} {...register("retireEECost", { required: true })} className="form-control" />
              {errors.retireEECost && (
                    <p className="text-danger">Please enter the EE cost.</p>
               )}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="form-group">
              <label>Eff. Date </label>
              <Controller
                        control={control}
                        {...register("retireEffectiveDate", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subDays(new Date(), 0)}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />
              {errors.retireEffectiveDate && (
                <p className="text-danger">Please select the Eff date.</p>
               )}
            </div>
          </div>
          <div className="col-lg-12 col-md-12">
            <div className="form-group">
              <label>Notes</label>
              <textarea className="form-control" placeholder="Comments.." disabled={disInput} {...register("notes", { required: true })}/>
              {errors.notes && (
                <p className="text-danger">Please enter the notes.</p>
               )}
            </div>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-lg-12">
            <button className="btn admin-back-btn" onClick={()=>history.goBack()}>Back</button>
            {!disInput && (
              <button className="btn admin-primary-btn ml-2" onClick={handleSubmit(submitData)}>
                          {id ? "Update" : "Submit"}
                        </button>
                      )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="spacer-foot" />
        </div>
        </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreateEmployee;
