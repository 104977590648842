
const AdminFooter = () => {

    const d = new Date();
    let year = d.getFullYear();
    return (
        <div id="admin-dashboard " style={{width:'100%'}} >
            <div className="footer">
                Copyright © 2019-{year} kibu gaming corporation. All rights reserved.
            </div>
        </div>
    )
}

export default AdminFooter;