/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../actions/userLogoutAction";
import { UserFooter } from "../../core/footer/userFooter";
import UserHeader from "../../core/header/userHeader";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import pic from "../../../assets/images/pic-prof.png";
import upload from "../../../assets/images/upload-prof.png";
import mail from "../../../assets/images/icon-mail.svg";
import phone from "../../../assets/images/icon-phone.svg";
import services from "../../../services/services";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import moment from "moment";
import "./editprofile.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
toast.configure();


function EditProfile(prop) {
  const [state, setState] = useState([]);
  const [states, setSates] = useState({ states: [] });
  const [countries, setCountries] = useState({ countries: [] });
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const history = useHistory();


  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      services.listOfCountry().then((result) => {
        setCountries({
          isLoadedCountry: true,
          countries: result.data.data,
        });
      });
    } catch (error) {
      console.log(error);
    }
    fetch();
  }, []);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(state);
  }, [state]);

  async function fetch() {
    try {
      setSubmitFormLoader(true);
      let response = await services.userInfo();
      setState(response.data.data);
      handleInputChangeForCountryId(response.data.data.country);
      setSubmitFormLoader(false);
    } catch (error) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER
    })
      setSubmitFormLoader(false);
    }
  }

  const handleInputChangeForCountryId = (Id) => {
    services.listOfStates(Id).then((result) => {
      setSates({
        isLoadedStates: true,
        states: result.data.data,
      });
    });
  };


  const updateInfo = async (d) => {
    let data = {
      firstName: d.firstName,
      lastName: d.lastName,
      mobileNumber: d.mobileNumber,
      dob: d.dob,
      address: d.address,
      country: d.country,
      state: d.state,
      city: d.city,
      zipCode: d.zipCode,
    };

    Swal.fire({
      title: "Are you sure, you want update the Profile?",
      // showDenyButton: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setSubmitFormLoader(true);
          let res = await services.userUpdate(data);
          Swal.fire(res.data.message, "", "success");
          setSubmitFormLoader(false);
          fetch();
        } catch (e) {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          setSubmitFormLoader(false);
        }
      }
    });
  };


  const logout = async (e) => {
        
    try {
        e.preventDefault();
        let userData = localStorage.getItem("id");
        setSubmitFormLoader(true)
        await dispatch(logoutAction(userData));
        setSubmitFormLoader(false)
        const { from } =  { from1: { pathname: "/login" } };
        history.push(from)

    } catch (error) {
        toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER
        })
    }
}

const profileImg = async (e) => {
  try{
    setSubmitFormLoader(true)
    let formData = new FormData();
    formData.append("files", e.target.files[0])
    let res = await services.uploadImage(formData);
    let response = await services.updateProfile({profilePicture: res.data.data[0]?.path});
    toast.success(response.data.message, {
      position: toast.POSITION.TOP_CENTER
  })
  setSubmitFormLoader(false)
  fetch();
  }
  catch(e){
    toast.error(e, {
      position: toast.POSITION.TOP_CENTER
  })
  }
}

  return (
    <>
      <UserHeader />
      <section className="bredcum">
        <div className="container">
          <div className="row">
          {submitFormLoader && <div className="loader"></div>}
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>My Profile</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" className="active">
                My Profile
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="myaccount-sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="upcoming-left">
                <div className="profile-area">
                  <div className="profile-pic">
                    <img src={state?.profilePicture || pic} alt="" />
                    <div className="image-upload">
                      <label htmlFor="file-input" id="AddPhotosLable">
                        <img src={ upload} alt="" id="AddPhotos" />
                      </label>
                      <input
                        id="file-input"
                        name="userfile"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        onChange={profileImg}
                      />
                    </div>
                  </div>
                  <div className="profile-content">
                    <h5 style={{ textTransform: "capitalize" }}>
                      {state?.firstName}
                    </h5>
                    {/* <Link to="/user/edit-profile" className="btn btn-edit"><i className="fa fa-edit" /> Edit Profile </Link> */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-1 cont-5">
                    <img src={mail} alt="" />
                  </div>
                  <div className="col-lg-10 col-md-10 col-10 cont-5">
                    {state?.email}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-1 col-md-1 col-1 cont-5">
                    <img src={phone} alt="" />
                  </div>
                  <div className="col-lg-10 col-md-10 col-10 cont-5">
                    {state?.mobileNumber}
                  </div>
                </div>
                <div className="devider">
                  <span className="line" />
                </div>
              
                <ul className="botlist">
                  <li>
                    {" "}
                    <Link to="#">
                      {" "}
                      <i className="fa fa-file" /> Tax Document
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="/user/change-password">
                      {" "}
                      <i className="fa fa-lock" /> Change Password
                    </Link>
                  </li>
                  <li>
                    {" "}
                    <Link to="#" onClick={logout}>
                      {" "}
                      <i className="fa fa-power-off" /> Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="editprofile-right">
                <h3>Edit Information</h3>
                <form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="control-label">First Name:</label>
                        <input
                          maxLength={15}
                          className="form-control text-capitalize"
                          type="text"
                          placeholder="First Name"
                          {...register("firstName", { required: true })}
                        />
                        {errors.firstName && (
                          <p className="text-danger">
                            Please enter the first name.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="control-label">Last Name:</label>
                        <input
                          className="form-control text-capitalize"
                          type="text"
                          maxLength={15}
                          placeholder="Last Name"
                          {...register("lastName", { required: true })}
                        />
                        {errors.lastName && (
                          <p className="text-danger">
                            Please enter the last name.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="control-label">Mobile Number:</label>
                        <Controller
                          control={control}
                          {...register("mobileNumber", { required: true })}
                          render={({ field: { value, onChange } }) => (
                            <PhoneInput
                              style={{ border: "1px solid #ced4da" }}
                              defaultCountry={"US"}
                              addInternationalOption={false}
                              countries={["US"]}
                              maxLength={10}
                              placeholder="Enter phone number"
                              value={value}
                              className="form-control mb-0"
                              onChange={onChange}
                            />
                          )}
                        />
                        {errors.mobileNumber && (
                          <p className="text-danger">
                            Please enter the mobile number.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="control-label">Date of Birth:</label>
                       
                  <Controller
                          control={control}
                          name="customerCellPhone"
                          {...register("dob", { required: true })}
                          render={({ field: { value, onChange } }) => (
                             <DatePicker
                    className="form-control mb-0"
                    placeholderText="DOB"
                    selected={value ? moment(value).toDate() : value}
                    onChange={onChange}
                    showYearDropdown
                    maxDate={moment().subtract(18, 'years')._d}
                    dateFormatCalendar="MMMM"
                    scrollableYearDropdown
                  />
                   )}
                        />
                        {errors.dob && (
                          <p className="text-danger">Age must be 18+.</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="control-label">Address:</label>
                        <input
                          {...register("address", { required: true })}
                          type="text"
                          maxLength={100}
                          placeholder="Address"
                          className="form-control"
                        />
                        {errors.address && (
                          <p className="text-danger">
                            Please enter the address.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-2">
                        <label>Country:</label>
                        <select
                          className="form-control"
                          id="sel1"
                          onClick={(e) =>
                            handleInputChangeForCountryId(e.target.value)
                          }
                          {...register("country", { required: true })}
                        >
                          <option value="">Select Country</option>
                          {countries.countries.map((countries, id) => (
                            <option
                              key={id}
                              value={countries.id}
                              selected={state?.country == countries.id}
                            >
                              {countries.country}
                            </option>
                          ))}
                        </select>
                        {errors.country && (
                          <p className="text-danger">
                            Please select the country.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-2">
                        <label>State:</label>
                        <select
                          className="form-control"
                          {...register("state", { required: true })}
                        >
                          <option value="">Select State</option>
                          {states.states.map((states, id) => (
                            <>
                              <option
                                key={id}
                                value={[states.id]}
                                selected={state.state == states.id}
                              >
                                {states.stateName}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.state && (
                          <p className="text-danger">
                            Please select the state.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group mb-2">
                        <label>City:</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("city", { required: true })}
                        />
                        {errors.city && (
                          <p className="text-danger">Please enter the city.</p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label className="control-label">Zip Code:</label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Pin Code"
                          className="form-control"
                          {...register("zipCode", { required: true })}
                        />
                        {errors.zipCode && (
                          <p className="text-danger">
                            Please enter the zipcode.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="form-group">
                        <button
                          onClick={handleSubmit(updateInfo)}
                          type="submit"
                          className="btn btn-saveprofile btn-block"
                        >
                          {" "}
                          Update Profile{" "}
                        </button>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-4">
                      <div className="form-group">
                        <button className="btn btn-cancel btn-block">
                          {" "}
                          Cancel{" "}
                        </button>
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}

export default EditProfile;
