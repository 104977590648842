/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import "./winparameter.css";
import { useForm, Controller } from "react-hook-form";

import services from "../../../services/services";
import Swal from "sweetalert2";
import { subDays } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

// Function for create advertisement
const CreateWinParameter = () => {
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [dis, setDis] = useState(false);
  let { id } = useParams();
  let history = useHistory();
  const {
    register,
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const subscription = watch();

  useEffect(() => {
    if (subscription.allhorizontalLine) {
      setValue("horizontalLineOne", true);
      setValue("horizontalLineTwo", true);
      setValue("horizontalLineThree", true);
      setValue("horizontalLineFour", true);
      setValue("horizontalLineFive", true);
    }
    if (!subscription.allhorizontalLine) {
      setValue("horizontalLineOne", false);
      setValue("horizontalLineTwo", false);
      setValue("horizontalLineThree", false);
      setValue("horizontalLineFour", false);
      setValue("horizontalLineFive", false);
    }
    if (subscription.allVerticalLine) {
      setValue("verticalLineOne", true);
      setValue("verticalLineTwo", true);
      setValue("verticalLineThree", true);
      setValue("verticalLineFour", true);
      setValue("verticalLineFive", true);
    }
    if (!subscription.allVerticalLine) {
      setValue("verticalLineOne", false);
      setValue("verticalLineTwo", false);
      setValue("verticalLineThree", false);
      setValue("verticalLineFour", false);
      setValue("verticalLineFive", false);
    }
  }, [subscription.allhorizontalLine, subscription.allVerticalLine]);

  useEffect(() => {

    console.log(history);
    if (id) {
      if (!history?.location?.state) {
        setDis(true);
      } 
      getWinPInfo();
    }
  }, []);

  const getWinPInfo = async () => {
    try {
      setSubmitFormLoader(true);
      let res = await services.getWinParameter(id);
      let organiseData = {
        versionNumber: res.data.data?.versionNumber,
        startDate: res.data.data?.startDate,
        endDate: res.data.data?.endDate,
        ...res.data.data?.horizontalLines.map((d) => d)[0],
        ...res.data.data?.verticalLines.map((d) => d)[0],
        ...res.data.data?.diagonals.map((d) => d)[0],
        ...res.data.data?.corners.map((d) => d)[0],
        ...res.data.data?.halfBlackout.map((d) => d)[0],
        windows: res.data.data?.windows,
        substituteForBlackout: res.data.data?.substituteForBlackout,
        blackout: res.data.data?.blackout,
        rollOverBlackoutPoolOnly: res.data.data?.rollOverBlackoutPoolOnly,
        pushDownAll: res.data.data?.pushDownAll,
      };
      reset(organiseData);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };
  const submitData = async (d) => {
    let payload = {
      versionNumber: d.versionNumber + "",
      startDate: d.startDate,
      endDate: d.endDate,
      horizontalLines: [
        {
          horizontalLineOne: d.horizontalLineOne,
          horizontalLineTwo: d.horizontalLineTwo,
          horizontalLineThree: d.horizontalLineThree,
          horizontalLineFour: d.horizontalLineFour,
          horizontalLineFive: d.horizontalLineFive,
          anyTwoHorizontalLine: d.anyTwoHorizontalLine,
          allhorizontalLine: d.allhorizontalLine,
        },
      ],
      verticalLines: [
        {
          verticalLineOne: d.verticalLineOne,
          verticalLineTwo: d.verticalLineTwo,
          verticalLineThree: d.verticalLineThree,
          verticalLineFour: d.verticalLineFour,
          verticalLineFive: d.verticalLineFive,
          anyTwoVerticalLine: d.anyTwoVerticalLine,
          allVerticalLine: d.allVerticalLine,
        },
      ],
      diagonals: [
        {
          upperLeftLowerRight: d.upperLeftLowerRight,
          lowerLeftUpperRight: d.lowerLeftUpperRight,
        },
      ],
      corners: [
        {
          // upperLeftPostage: d.upperLeftPostage,
          // upperRightPostage: d.upperRightPostage,
          // lowerLeftPostage: d.lowerLeftPostage,
          // lowerRightPostage: d.lowerRightPostage,
          // corners: d.corners,
          // allPatterns: d.allPatterns,
          upperLeftPostageCorners: d.upperLeftPostageCorners,
          lowerRightCornersPostage: d.lowerRightCornersPostage,
          upperLeftLowerRightPostage: d.upperLeftLowerRightPostage,
        },
      ],
      halfBlackout: [
        {
          top: d.top,
          bottom: d.bottom,
          left: d.left,
          right: d.right,
          upperLeft: d.upperLeft,
          lowerLeft: d.lowerLeft,
          upperRight: d.upperRight,
          lowerRight: d.lowerRight,
        },
      ],
      windows: d.windows || false,
      substituteForBlackout: d.substituteForBlackout || false,
      blackout: d.blackout || false,
      x: true,
      smallBox: true,
      rollOverBlackoutPoolOnly: d.rollOverBlackoutPoolOnly || false,
      pushDownAll: d.pushDownAll || false,
    };

    try {
      setSubmitFormLoader(true);
      let res = await services.createWinParameter(payload);
      Swal.fire({
        title: res.data.message,
        // showDenyButton: true,
        confirmButtonText: "OK",
      });
      reset({ versionNumber: "" });
      history.push("/admin/win-parameters");
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          {submitFormLoader && <div className="loader"></div>}
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>{id ? "View" : "Create"} Win Parameter </h4>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label> Version Number</label>
                  <input
                    disabled={dis}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="XXXXX"
                    pattern="\d*"
                    className="form-control"
                    {...register("versionNumber", {
                      required: true,
                      minLength: 4,
                      maxLength: 5,
                    })}
                  />
                  {errors.versionNumber && (
                    <p className="text-danger">
                      Please enter the version number in correct format.
                    </p>
                  )}
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label> Start Date</label>
                  <Controller
                    control={control}
                    {...register("startDate", { required: true })}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        disabled={dis}
                        className="form-control"
                        placeholderText="please select date"
                        selected={value ? moment(value).toDate() : value}
                        onChange={onChange}
                        minDate={subDays(new Date(), 0)}
                        maxDate={subscription.endDate}
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                      />
                    )}
                  />
                  {errors.startDate && (
                    <p className="text-danger">Please enter the start Date.</p>
                  )}
                </div>
              </div> */}
              {/* <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label>End Date</label>
                  <Controller
                    control={control}
                    {...register("endDate", { required: true })}
                    render={({ field: { value, onChange } }) => (
                      <DatePicker
                        disabled={dis}
                        className="form-control"
                        placeholderText="please select date"
                        selected={value ? moment(value).toDate() : value}
                        onChange={onChange}
                        minDate={subscription.startDate}
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        scrollableYearDropdown
                      />
                    )}
                  />
                  {errors.endDate && (
                    <p className="text-danger">Please enter the endDate.</p>
                  )}
                </div>
              </div> */}
            </div>
            <div className="row mt-4">
              <div className="col-lg-4 col-md-6">
                <div className="win-pattern-head text-center"> Lines </div>
                <div className="win-pattern-inner">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example3"
                          {...register("horizontalLineOne")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example3"
                        >
                          Horizontal 1
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example4"
                          {...register("horizontalLineTwo")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example4"
                        >
                          Horizontal 2
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example5"
                          {...register("horizontalLineThree")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example5"
                        >
                          Horizontal 3
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example655"
                          {...register("horizontalLineFour")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example655"
                        >
                          Horizontal 4
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example6"
                          {...register("horizontalLineFive")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example6"
                        >
                          Horizontal 5
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example32"
                          {...register("anyTwoHorizontalLine")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example32"
                        >
                          Any 2 Horizontal
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example7"
                          {...register("allhorizontalLine")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example7"
                        >
                          Horizontal All
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example8"
                          {...register("verticalLineOne")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example8"
                        >
                          Vertical 1
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example9"
                          {...register("verticalLineTwo")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example9"
                        >
                          Vertical 2
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example10"
                          {...register("verticalLineThree")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example10"
                        >
                          Vertical 3
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example11"
                          {...register("verticalLineFour")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example11"
                        >
                          Vertical 4
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example12"
                          {...register("verticalLineFive")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example12"
                        >
                          Vertical 5
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example33"
                          {...register("anyTwoVerticalLine")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example33"
                        >
                          Any 2 Vertical
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example13"
                          {...register("allVerticalLine")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example13"
                        >
                          Vertical All
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="win-pattern-head text-center"> Diagonals </div>
                <div className="win-pattern-inner">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example14"
                          {...register("upperLeftLowerRight")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example14"
                        >
                          UL - LR
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example15"
                          {...register("lowerLeftUpperRight")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example15"
                        >
                          LL - UR
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="win-pattern-head text-center"> Corners </div>
                <div className="win-pattern-inner">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example16"
                          {...register("upperLeftPostageCorners")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example16"
                        >
                          UL Postage + Corners
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example19"
                          {...register("upperLeftLowerRightPostage")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example19"
                        >
                          UL + LR Postage
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example20"
                          {...register("lowerRightCornersPostage")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example20"
                        >
                          LR Postage + Corners
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <div className="win-pattern-head text-center">
                  {" "}
                  Half Blackout{" "}
                </div>
                <div className="win-pattern-inner">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example22"
                          {...register("top")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example22"
                        >
                          Top
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example23"
                          {...register("left")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example23"
                        >
                          Left
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example24"
                          {...register("upperLeft")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example24"
                        >
                          UL
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example25"
                          {...register("lowerLeft")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example25"
                        >
                          LL
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example26"
                          {...register("bottom")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example26"
                        >
                          Bottom
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example27"
                          {...register("right")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example27"
                        >
                          Right
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example28"
                          {...register("upperRight")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example28"
                        >
                          UR
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example29"
                          {...register("lowerRight")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example29"
                        >
                          LR
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <div className="win-pattern-head text-center"> Window</div>
                <div className="win-pattern-inner">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example30"
                          {...register("windows")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example30"
                        >
                          Window
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example60"
                          {...register("substituteForBlackout")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example60"
                        >
                          Substitute for Blackout
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-3">
                <div className="win-pattern-head text-center"> Blackout</div>
                <div className="win-pattern-inner">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-check mb-3">
                        <input
                          disabled={dis}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example31"
                          {...register("blackout")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example31"
                        >
                          Blackout
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={true}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example61"
                          {...register("rollOverBlackoutPoolOnly")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example61"
                        >
                          Rollover Blackout Pool Only
                        </label>
                      </div>
                      <div className="form-check mb-3">
                        <input
                          disabled={true}
                          className="form-check-input me-2"
                          type="checkbox"
                          id="form2Example62"
                          {...register("pushDownAll")}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="form2Example62"
                        >
                          Push Down All
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingBottom: "5rem" }}>
             
                <div className="col-lg-2 col-md-3">
                  <Link to="/admin/win-parameters">
                    <button
                      type="submit"
                      className="btn btn-back btn-block mt-4"
                    >
                      Back
                    </button>
                  </Link>
                </div>
                {!id && (
                <div className="col-lg-2 col-md-3">
                  <button
                    onClick={handleSubmit(submitData)}
                    className="btn admin-primary-btn btn-block mt-4"
                  >
                  {id ? "Update" : "Submit"}
                  </button>
                </div>
              )}
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreateWinParameter;
