import { useState } from "react";
import { useForm } from "react-hook-form";

export const GroupSearch = ({onclick}) => {

  const { register, reset, handleSubmit } = useForm();

  return (
    <div className="upcoming-left">
      <h4>Group Details Search </h4>
      <form>
        <div className="form-group">
          <input
            type="text"
            placeholder="Group Id"
            {...register("groupId")}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Group Name"
            {...register("groupName")}
            className="form-control"
          />
        </div>
        {/* <div className="form-group">
          <input
            type="text"
            placeholder="Location"
            {...register("groupLocation")}
            className="form-control"
          />
        </div> */}

        <div className="form-group">
          <div className="form-check form-check-inline">
            <input
              // ckecked
              className="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios3"
              defaultValue="free"
              defaultChecked
            />
            <label className="form-check-label" htmlFor="exampleRadios3">
              Free
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" defaultValue="paid" />
              <label className="form-check-label" htmlFor="exampleRadios4">
                Paid
              </label>
            </div> */}
        </div>
        <button type="button" onClick={handleSubmit(onclick)} className="btn btn-submit btn-block"> Submit </button>
      </form>
    </div>
  );
};
