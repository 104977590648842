export function Subscribe(){
    return(
        <section className="newsletter">
        <div className="container">
          <div className="row">
            <div className=" col-xl-7 col-lg-7 col-md-12">
              <h3>Subscribe to the our newsletter for stay <br />
                up to date with your favorite events!</h3>
            </div>
            <div className=" col-xl-5 col-lg-5 col-md-12">
              <div className="newsletter-deg">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Your email address" />
                  <button className="btn border-rad" type="button">Subscribe</button>
                </div>
                <h5>Sign up using your e-mail to receive our newsletter. </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}