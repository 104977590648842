export const Location = () => (
  <div
    className="tab-pane fade"
    id="nav-loc"
    role="tabpanel"
    aria-labelledby="nav-location-tab"
  >
    <h3 className="pt-4 pb-2">Locations</h3>
    <p>
      Locations are subject to geographic restrictions. Groups are categorized
      into four different location types – National, Regional, Local and
      Organized. Providers may have multiple Groups able to participate in the
      same Event each creating a different prize and/or payout pool. So the
      National location is in a different Line (L) pool than a Local location.
      An organized location is playing for a different Blackout (B) pool than a
      National location. Locations are separateed by Federal and State
      legalities, physical location, provider requirements and other factors
      classifying players into separate games based on the same Event.
    </p>
    <p className="cont-pdb">
      <strong>NATIONAL – </strong>
    </p>
    <p>
      The National locations are the largest Groups and may have players entered
      from several states or most parts of a country. The game may be subject to
      International, National, Regional and Local government betting
      regulations. Generally, they have the most players, game cards and amounts
      wagered unlike constraints sometimes placed on regional, local or
      organized Groups.
    </p>
    <p className="cont-pdb">
      <strong>REGIONAL – </strong>
    </p>
    <p>
      A Regional Group is geo-fenced, for example, by a state within the United
      States, a Native American establishment, a Country within the European
      Union or possibly it is several casinos, Native American tribes or other
      Providers banding together. A Regional Group is often created because of
      jurisdictional laws or provider requirements.
    </p>
    <p>
      Several states in the United States and countries in the European Union
      restrict their visitors and citizens to Groups within the state or
      countries geographical boundaries similar to a state lottery. For example,
      an Arizona Sports Group may only be entered by citizens and visitors who
      were physically in the United States state of Arizona when they obtained
      their Game Card.
    </p>
    <p>
      These Regional Groups may share part of their fees with the regulating
      authority of the state or country.
    </p>
    <p className="cont-pdb">
      <strong>LOCAL – </strong>
    </p>
    <p>
      A Local Group is geo-fenced or controlled by actual attendance at a
      stadium, field or arena, or sometimes by participation into a team’s
      website. A participant can only play if they are in attendance, purchased
      an Event ticket to attend and/or entered through a particular team’s
      website.
    </p>
    <p>
      The Group is generally very limited in particpation size. Group Rules vary
      considerably and obtaining a Game Card varies significantly Group to
      Group. Some Game Cards are free, others are a flat fee and one common
      variation is often the Line (L) pool is a free entry with a purchase into
      any of the other payout pools.
    </p>
    <p>
      These Groups represent most of the Free Play, non-cash prize Games. The
      Game may also be a mix of non-cash and cash prizes. A Local Group is quite
      often overseen by and for the benefit of a team’s charitable organization.
      Part of the payout pool is kept by the team for their charitable purposes.
      Often you are helping your favorite team in its community outreach.
    </p>
    <p className="cont-pdb">
      <strong>ORGANIZED – </strong>
    </p>
    <p>
      An Organized Group covers all the other Groups. Participation may be by
      invitation only, awarded with a manufacturer's purchase, Game Card
      purchases at certain locations, or taking a particular survey.
    </p>
    <p>
      Each participant must be a registered user of our website, however it does
      not mean each participant must have money in an account with us. Game
      Cards can be obtained through a variety of payment options.
    </p>
    <p>
      Organized Groups are created by friends, companies, groups of casinos,
      sports bars, etc. They may be restricted based on various international,
      national, regional and local laws. They likely have maximum card purchases
      and wagering limits among other constraints set by the Organizer. Group
      Rules vary widely.
    </p>
    <p>
      An Organized Group may be created by anyone with a fee dependent on the
      number of participants and within the laws of the jurisdiction organized.
      Additional information must be provided by the creator for each group and
      the parameters for entering the group must be established.
    </p>
    <p>
      Group creators should plan ahead and realize sometimes it takes a while to
      create group parameters allowing the maximum desired participation, while
      complying with all International, National and Regional laws.
    </p>
  </div>
);
