export const appConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE'
  };

export const PatternKey = ['line','corner','diagonal','halfBlackout','window','blackout']

export const EVENT_TYPES_AND_LEAGUES = [
    {code:"A", name:"American Football",eventLeague: [{name:"NFL",code:"0"},{name:"NCAA",code:"3"},{name:"CFL ",code:"6"}] },
    {code:"B", name:"Baseball",eventLeague: [{name:"MLB",code:"0"}] },
    {code:"C", name:"Basketball", eventLeague: [{name:"NBA",code:"0"},{name:"XFL",code:"1"}] },
    {code:"F", name:"Futbol",eventLeague: [{name:"USFL",code:"2"}]  },
    {code:"G", name:"Cricket",eventLeague: [{name:"NCAA 1",code:"3"}]  },
    {code:"H", name:"Hockey", eventLeague: [{name:"NCAA 2",code:"4"},{name:"NCAA W",code:"5"},{name:"CFL",code:"6"},{name:"International",code:"I"}]},
    // {code:"L", name:"Lacrosse", eventLeague: [{name:"International",code:"I"}] },
    // {code:"Z", name:"Tournament 1",eventLeague: [{name:"Olympics",code:"O"}] }
];

export const BASEBALL_EIGHT_FACTOR = ['F','O','K','X','W','N','P','G'];
export const BASEBALL_SIX_FACTOR = ['F','O','K','X','W','N'];
export const BASEBALL_FOUR_FACTOR = ['F','O','K','X'];

export const PATTERNS = [
  { 
    key:"Horizontal Line One",
    value:"horizontalLineOne"
  },
  { 
    key:"Horizontal Line Two",
    value:"horizontalLineTwo"
  },
  { 
    key:"Horizontal Line Three",
    value:"horizontalLineThree"
  },
  { 
    key:"Horizontal Line Four",
    value:"horizontalLineFour"
  },
  { 
    key:"Horizontal Line Five",
    value:"horizontalLineFive"
  },
  { 
    key:"Any Two Horizontal Line",
    value:"anyTwoHorizontalLine"
  },

  { 
    key:"Upper Left Lower Right Diagonal",
    value:"upperLeftLowerRight"
  },

  { 
    key:"Lower Left Upper Right Diagonal",
    value:"lowerLeftUpperRight"
  },

  { 
    key:"Upper Left Postage Corners",
    value:"upperLeftPostage"
  },

  { 
    key:"Upper Right Postage Corners",
    value:"upperRightPostage"
  },

  { 
    key:"Lower Left Postage Corners",
    value:"lowerLeftPostage"
  },

  { 
    key:"Lower Right Postage Corners",
    value:"lowerRightPostage"
  },

  { 
    key:"Corners",
    value:"corners"
  },

  { 
    key:"Top Half Blackout",
    value:"top"
  },

  { 
    key:"Bottom Half Blackout",
    value:"bottom"
  },

  { 
    key:"Left Half Blackout",
    value:"left"
  },

  { 
    key:"Right Half Blackout",
    value:"right"
  },

  { 
    key:"UpperLeft Half Blackout",
    value:"upperLeft"
  },

  { 
    key:"LowerLeft Half Blackout",
    value:"lowerLeft"
  },

  { 
    key:"UpperRight Half Blackout",
    value:"upperRight"
  },

  { 
    key:"LowerRight Half Blackout",
    value:"lowerRight"
  },

  // { 
  //   key:"windows",
  //   value:"windows"
  // },

  // { 
  //   key:"substituteForBlackout",
  //   value:"substituteForBlackout"
  // },

  // { 
  //   key:"blackout",
  //   value:"blackout"
  // },

  // { 
  //   key:"rollOverBlackoutPoolOnly",
  //   value:"rollOverBlackoutPoolOnly"
  // },

  // { 
  //   key:"pushDownAll",
  //   value:"pushDownAll"
  // }
]

