/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { TextEditor } from "../editor/editor";

// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreatePrizes = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitFormLoader, setSubmitFormLoader] = useState("");

  const { id } = useParams();

  useEffect(() => {
    // reset form with user data
    reset(formData);
  }, [formData]);

  useEffect(() => {
    async function fetchData() {
      if (id !== undefined) {
        setSubmitFormLoader(true);
        let response = await services.getSinglePrize(id);
        setFormData(response.data.data);
        if (history?.location?.state) {
          setDisInput(true);
        }
        setSubmitFormLoader(false);
      }
    }
    fetchData();
  }, [id]);

  const createPrize = async (d) => {
   let data = {
      "prizeId":d.prizeId,
      "shortPrize": d.shortPrize,
      "retailDrawContest": d.retailDrawContest,
      "claimDescribe":d.claimDescribe,
      "legalDescribe": d.legalDescribe
     
  }

    try {
      if (id) {
        setSubmitFormLoader(true);
        let response = await services.updatePrize(id, data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/prizes");
      } else {
        setSubmitFormLoader(true);
        let response = await services.createPrize(data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/prizes");
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>
                    {" "}
                    {disInput
                      ? "View Prize"
                      : id
                      ? "Edit Prize"
                      : "Create Prize"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="Total-Revenue" style={{width:'100%'}}>
              <form
               onSubmit={handleSubmit(createPrize)}
               >
                <div className="row">
                  {submitFormLoader && <div className="loader"></div>}
                  <div className="col-lg-4">
                    <div className="dropdown ">
                      <label>Prize Id</label>
                      <input
                        maxLength={3}
                        // placeholder="Eg:A0AF"
                        disabled={disInput}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        {...register("prizeId", {
                          required: true,
                          maxLength: 3,
                          minLength: 3
                        })}
                      />
                      {errors.prizeId && (
                        <p className="text-danger">
                          Please enter the Prize Id in correct format.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="dropdown ">
                      <label>Short Prize</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("shortPrize", { required: true })}
                      />
                      {errors.shortPrize && (
                        <p className="text-danger">
                          Please enter the short prize.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="dropdown ">
                      <label>Retail/Draw/Contest</label>
                      <select
                        disabled={disInput}
                        className="drop-button form-control"
                        {...register("retailDrawContest", { required: true })}
                      >
                        <option value="">Please Select</option>
                        <option value="R">R</option>
                        <option value="D">D</option>
                        <option value="D2">D2</option>
                        <option value="C">C</option>
                        <option value="C2">C2</option>
                      </select>
                      {errors.retailDrawContest && (
                        <p className="text-danger">
                          Please select the given value.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="dropdown borderBox">
                      <label>Claim Describe</label>
                      {/* <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("claimDescribe", { required: true })}
                      /> */}
                      <TextEditor  read={disInput} key={formData.claimDescribe}  initial={formData.claimDescribe} {...register("claimDescribe", { required: true })} cb={(d) =>setValue("claimDescribe", d, { shouldValidate: true })} />

                    </div>
                      {errors.claimDescribe && (
                        <p className="text-danger mt-2">
                          Please enter the claim describe.
                        </p>
                      )}
                  </div>
                  <div className="col-lg-6">
                    <div className="dropdown borderBox">
                      <label>Legal Describe</label>
                      {/* <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("legalDescribe", { required: true })}
                      /> */}
                      <TextEditor  read={disInput} key={formData.legalDescribe}  initial={formData.legalDescribe} {...register("legalDescribe", { required: true })} cb={(d) =>setValue("legalDescribe", d, { shouldValidate: true })} />
                    </div>

                      {errors.legalDescribe && (
                        <p className="text-danger mt-2">
                          Please enter the legal describe.
                        </p>
                      )}
                  </div>

                </div>
                <div className="row mb-5">
                  <div className="col-lg-12">
                    <Link to="/admin/prizes">
                      <button
                        type="button"
                        className="btn admin-back-btn mr-2 "
                      >
                        Back
                      </button>
                    </Link>
                    {!disInput && (
                      <button type="submit" className="btn admin-primary-btn">
                      {id ? "Update" : "Submit"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreatePrizes;
