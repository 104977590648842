import React, { useEffect } from 'react';
import {Editor, EditorState,convertFromHTML ,ContentState, convertFromRaw, RichUtils} from 'draft-js';
import "./editor.css";
import { stateToHTML } from "draft-js-export-html";


export function TextEditor({cb,check, initial, read=false}) {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
 
  const editor = React.useRef(null);
  const firstRender = React.useRef(false);
  const resForm = React.useRef(false);
 
  function focusEditor() {
    editor.current.focus();
  }

  useEffect(()=>{
    if(resForm.current){
    setEditorState(EditorState.createEmpty());
    }
    resForm.current = true;
  },[check])


  let className = "RichEditor-editor";
 
  useEffect (()=>{
    if(initial){
      const blocksFromHTML = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(initial)
        )
      );
      setEditorState(blocksFromHTML)
    }
  },[])

  React.useEffect(() => {

    let contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    // focusEditor()
  }, []);

  const onChange = (editorState) => setEditorState(editorState);

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  }

  const toggleInlineStyle = (inlineStyle) => {
    onChange(
      RichUtils.toggleInlineStyle(editorState, inlineStyle)
    );
  }


  const content = stateToHTML(editorState.getCurrentContent());
 useEffect(()=>{
     if(firstRender.current){
        if(editorState.getCurrentContent().hasText()){
            cb(content,editorState.getCurrentContent().hasText());
         }else{
            cb("",editorState.getCurrentContent().hasText())
         }
     }
     firstRender.current = true;
    
    },[content]);


  return (
    <div letName="RichEditor-root">
    {/* <button onClick={this.logState}>Show me output</button> */}
    <BlockStyleControls
      editorState={editorState}
      onToggle={toggleBlockType}
    />
    <InlineStyleControls
      editorState={editorState}
      onToggle={toggleInlineStyle}
    />
    <div className={className} onClick={focusEditor}>
      <Editor
        readOnly={read}
        blockStyleFn={getBlockStyle}
        ref={editor}
        editorState={editorState}
        onChange={editorState => setEditorState(editorState)}
      />
    </div>
    </div>
  );
}

function getBlockStyle(block) {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      default:
        return null;
    }
  }
  
  class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
  
    render() {
      let className = "RichEditor-styleButton";
      if (this.props.active) {
        className += " RichEditor-activeButton";
      }
  
      return (
        <span className={className} onMouseDown={this.onToggle}>
          {this.props.label}
        </span>
      );
    }
  }


const BLOCK_TYPES = [
    { label: "H1", style: "header-one" },
    { label: "H2", style: "header-two" },
    { label: "H3", style: "header-three" },
    { label: "H4", style: "header-four" },
    { label: "H5", style: "header-five" },
    { label: "H6", style: "header-six" },
    { label: "Blockquote", style: "blockquote" },
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Code Block", style: "code-block" }
  ];
  
  const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
  
    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) => (
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };
  
  let INLINE_STYLES = [
    { label: "Bold", style: "BOLD" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
    { label: "Monospace", style: "CODE" }
  ];
  
  const InlineStyleControls = (props) => {
    let currentStyle = props.editorState.getCurrentInlineStyle();
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) => (
          <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
        );
    };