import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import services from "../../../services/services";
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import * as Constants from "../../../helper/constants";
import moment from "moment";
import Select from "react-select";
import * as universalFunction from "../../../utils/universalFunctions";


function AdsControl({ id, handleclose,adver, disInput, show,adsDelete, advertiser,cb, listAds, setListAd}) {

  const [adId, setAdId] = useState([]);
  const [adId2, setAdId2] = useState([]);
  const [getId, setId] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const {
    register:register2,
    reset:reset2,
    watch:watch2,
    setValue:setValue2,
    handleSubmit:handleSubmit2,
    control:control2,
    formState: { errors:errors2 },
  } = useForm();


  let subscribe = watch();
  let subscribe2 = watch2();

  useEffect(()=>{
  setValue("adId","")
  let data = [...adver];
  let find = data.find(d=>d?.advertiserId==subscribe?.advertiserId?.value);
  setAdId(
  find?.gameCardImageData.map(({ gameCardImageAdId }) => ({
    value: Number(gameCardImageAdId),
    label: gameCardImageAdId,
  }))
  );
  },[subscribe.advertiserId])

  useEffect(()=>{
   
    // setValue2("adId","")
    let data = [...adver];
    let find = data.find(d=>d?.advertiserId==subscribe2?.advertiserId?.value);
    setAdId2(
    find?.gameCardImageData.map(({ gameCardImageAdId }) => ({
      value: Number(gameCardImageAdId),
      label: gameCardImageAdId,
    }))
    );
  
    },[subscribe2.advertiserId])
  

  const submitData = async () => {
    try {
      setSubmitFormLoader(true);
   
        let res = await services.updateAdsControll(id,{adControls:listAds} || []);
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        handleclose(reset)
        setSubmitFormLoader(false);
      
    } catch (e) {
      setSubmitFormLoader(false);
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  }

const findAdId = (id) => {
  let data = [...adver];
  let filterData =  data.find(d=>d?.id==id) || "";
  return filterData?.advertiserId
}

const getUpdate = (d) => {
  let data =  
  {
 advertiserId: d.advertiserId?.id,
 adId: d?.adId?.value,
 runTime: d?.runTime,
 delayTime: d?.delayTime,
 hasOccurence: d?.hasOccurence ==="true" ? true : false,
 timeDuration: Number(d?.timeDuration) || null,
 occurence: Number(d?.occurence) || null
}

  let Addata = [...listAds];

  let findData = Addata.find((d,i)=>{
if(i===getId){
  return false
}

if(d.advertiserId == data.advertiserId && d?.adId == data?.adId){
  return true
}

  } );

  if(findData){
   return toast.error("This Advertiser Id and Ad Id already exist on the below lists.", {
     position: toast.POSITION.TOP_CENTER,
     toastId: "1",
   });
  }
 Addata[getId] = data;
 setListAd(Addata);
 setId("")
 reset2({
     advertiserId: '',
     adId: '',
     runTime: '',
     delayTime: '',
     hasOccurence:'false',
     timeDuration:'',
     occurence:''
 });
}


const editAd = (id,d) => {
  let dataAd = [...adver];
  let filterData =  dataAd.find(dat=>dat?.id==d?.advertiserId) || "";
  let uId = d?.adId;
  let findGame = filterData?.gameCardImageData.find(d=>d?.gameCardImageAdId==uId) || "";
  let data =  
  {
 advertiserId: {id: filterData?.id,label: `${filterData?.advertiserId},${filterData?.advertiserName}`,value:filterData?.advertiserId},
 adId: {label:findGame?.gameCardImageAdId,value: findGame?.gameCardImageAdId},
 runTime: d?.runTime,
 delayTime: d?.delayTime,
 hasOccurence: d?.hasOccurence ? "true" : "false",
 timeDuration: Number(d?.timeDuration) || null,
 occurence: Number(d?.occurence) || null
}
// console.log(data);
// return
setId(id);
reset2(data)
}

// console.log(subscribe2,"+++++++");
  return (
    <>
      <Modal 
        className="Total-Revenue ad_sec"
        size="xl"
        centered
        show={show}
        onHide={()=>handleclose(reset)}
        backdrop="static"
        keyboard={false}
      >
      {submitFormLoader && <div className="loader"></div>}

        <Modal.Header className="align-items-center" closeButton>
          {/* <Modal.Title> Prizes  </Modal.Title> */}
          <h5 className="mr-3" style={{ fontSize: "17px" }}>
         <b> Game Card Ads Control</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
           <div className="gscroll  col-md-12">
              <div className="gstyle">           
                <div className="col-lg-12 d-lg-flex p-0">
                  <div className="col-lg-2 col-12">
                    <div className="dropdown">
                      <label>Advertiser ID</label>
                      <Controller
                        control={control}
                        className="form-control mb-1"
                        {...register("advertiserId", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <Select
                          isDisabled={disInput}
                            value={value}
                            onChange={onChange}
                            options={advertiser}
                          />
                        )}
                      />
                      {errors.advertiserId && (
                        <p className="text-danger">
                          Please select the Advertiser Id.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>AdID</label>
                      <Controller
                        control={control}
                        className="form-control mb-1"
                        {...register("adId", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <Select
                          isDisabled={disInput}
                            value={value}
                            onChange={onChange}
                            options={adId}
                          />
                        )}
                      />
                      {errors.adId && (
                        <p className="text-danger">
                          Please select the Ad Id.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>RunTime (s)</label>
                      <input
                        type="number"
                        disabled={disInput}
                        className="form-control mb-1"
                        {...register("runTime", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.runTime && (
                      <p className="text-danger ">
                        Please enter the run time.
                      </p>
                    )}
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>Delay (s)</label>
                      <input
                      disabled={disInput}
                        type="text"
                        className="form-control mb-1"
                        {...register("delayTime", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.delayTime && (
                      <p className="text-danger ">
                        Please enter the delay time.
                      </p>
                    )}
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>Has Occurence</label>
                      <select
                       className="form-control"
                        disabled={disInput}
                        {...register("hasOccurence")}
                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label className="text-nowrap">No. of Occurence</label>
                      <input
                        disabled={subscribe?.hasOccurence === "false" || disInput}
                        type="number"
                        className="form-control mb-1"
                        {...register("occurence",{required:subscribe?.hasOccurence === "true" ? true : false})}
                      />
                      {errors.occurence && (
                      <p className="text-danger ">
                        Please enter the number of occurence.
                      </p>
                    )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>Duration (min)</label>
                      <input
                        disabled={subscribe?.hasOccurence === "false"  || disInput}
                        type="number"
                        className="form-control mb-1"
                        {...register("timeDuration",{required:subscribe?.hasOccurence === "false" ? false : true})}
                      />
                       {errors.noOfOccurence && (
                      <p className="text-danger ">
                        Please enter the time duration.
                      </p>
                    )}
                    </div>
                  </div>

                  <div className="col-lg-1 col-12">
                    <div className="dropdown text-center">
                      <label>Actions</label>
                         <button
                         onClick={handleSubmit((d)=>cb(d,reset))}
                        type="button"
                        disabled={disInput}
                        style={{ fontSize: "14px" }}
                        className="btn m-1 btn-primaryadd btn-block"
                      >
                     <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
   
                </div>
                {listAds?.length > 0 &&
                  listAds?.map(
                    (
                      {
                        advertiserId,
                        adId,
                        runTime,
                        delayTime,
                        hasOccurence,
                        timeDuration,
                        occurence
                     
                      },
                      id
                    ) => {
                      return (

                       getId===id ? 
                       <>
                       <div className="col-lg-12 d-lg-flex p-0">
                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label>AdvertiserID</label> */}
                      <Controller
                        control={control2}
                        className="form-control mb-1"
                        {...register2("advertiserId", { required: true })}
                          render={({ field: { value, onChange } }) => (
                          <Select
                          isDisabled={disInput}
                            value={value}
                            onChange={onChange}
                            options={advertiser}
                          />
                        )}
                      />
                      {errors2.advertiserId && (
                        <p className="text-danger">
                          Please select the Advertiser Id.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label>AdID</label> */}
                      <Controller
                        control={control2}
                        className="form-control mb-1"
                        {...register2("adId", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <Select
                           isDisabled={disInput}
                            value={value}
                            onChange={onChange}
                            options={adId2}
                          />
                        )}
                      />
                      {errors2.adId && (
                        <p className="text-danger">
                          Please select the Ad Id.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label>RunTime (s)</label> */}
                      <input
                        type="number"
                        disabled={disInput}
                        className="form-control mb-1"
                        {...register2("runTime", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors2.runTime && (
                      <p className="text-danger ">
                        Please enter the run time.
                      </p>
                    )}
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label>Delay (s)</label> */}
                      <input
                       disabled={disInput}
                        type="text"
                        className="form-control mb-1"
                        {...register2("delayTime", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors2.delayTime && (
                      <p className="text-danger ">
                        Please enter the delay time.
                      </p>
                    )}
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label>Has Occurence</label> */}
                      <select
                       className="form-control"
                        disabled={disInput}
                        {...register2("hasOccurence")}
                      >
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label className="text-nowrap">No. of Occurence</label> */}
                      <input
                        disabled={subscribe2?.hasOccurence === "false"}
                        type="number"
                        className="form-control mb-1"
                        {...register2("occurence",{required:subscribe2?.hasOccurence === "true" ? true : false})}
                      />
                      {errors2.occurence && (
                      <p className="text-danger ">
                        Please enter the number of occurence.
                      </p>
                    )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      {/* <label>Duration (min)</label> */}
                      <input
                        disabled={subscribe2?.hasOccurence === "false"  || disInput}
                        type="number"
                        className="form-control mb-1"
                        {...register2("timeDuration",{required:subscribe2?.hasOccurence === "false" ? false : true})}
                      />
                       {errors2.noOfOccurence && (
                      <p className="text-danger ">
                        Please enter the time duration.
                      </p>
                    )}
                    </div>
                  </div>

                  <div className="col-lg-1 col-12">
                    <div className="dropdown text-center">
                      {/* <label>Actions</label> */}
                         <button
                         onClick={handleSubmit2((d)=>getUpdate(d,reset))}
                        type="button"
                        disabled={disInput}
                        style={{fontSize: "14px" }}
                        className="btn m-1 btn-primaryadd btn-block"
                      >
                     <i className="fa fa-refresh"></i>
                      </button>
                    </div>
                  </div>
   
                </div>
                       </>
                        :
                       <>
                        <div className="col-lg-12 d-lg-flex p-0" key={id}>
                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={findAdId(advertiserId)}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                     
                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={adId}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={runTime}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={delayTime}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={hasOccurence ? "Yes" : "No"}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                     
                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={occurence}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                     
                          <div className="col-lg-2 col-12">
                            <div className="dropdown ">
                              <input
                                type="text"
                                value={timeDuration}
                                className="form-control"
                                disabled
                              />
                            </div>
                          </div>
                     
                     
                      
                          <div className="col-lg-1 col-12 pr-2 ">
                         <div className="dropdown d-flex">
                                    <button
                                    style={{width:'50%',padding:'2px 4px'}}
                                      onClick={()=>editAd(id,{
                        advertiserId,
                        adId,
                        runTime,
                        delayTime,
                        hasOccurence,
                        timeDuration,
                        occurence
                     
                      })}
                                      type="button"
                                      disabled={disInput}
                                      className="btn m-1 btn-primaryadd btn-block"
                                    >
                                  <i className="fa fa-edit"></i>
                                      
                                    </button>
                                    <button
                                      style={{width:'50%'}}
                                      onClick={() => adsDelete(id)}
                                      type="button"
                                      disabled={disInput}
                                      className="btn m-1 btn-danger btn-block p-1"
                                    >
                                  <i className="fa fa-trash"></i>
                                      
                                    </button>
                    </div>
                  </div>

                        </div>
                      </>
                      );
                    }
                  )}
              </div>
            </div>
           
          </div>
        </Modal.Body>
        <Modal.Footer>{id && 
         <Button variant="primary" 
          disabled={disInput}
          onClick={submitData}
          >
            Submit
          </Button> 
         }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdsControl;




