export const UserSearch = ({onClick,onChange}) => {


  const label={
    fontWeight: 700,
    color: '#000',
    marginLeft: '18px',
    fontSize: '15px'
  }

    return (

        <section className="search">
          <div className="container">
            <div className="search-filter">
              <div className="row">
                {/* <div className="col-xl-3 col-lg-3">
                  <div className="form-group">
                  <label style={label}> Criteria</label>
                    <select onChange={onChange} className="form-control" id="sel1">
                      <option>Search Criteria</option>
                      <option>Option 1</option>
                      <option>Option 2</option>
                      <option>Option 3</option>
                    </select>
                  </div>
                </div> */}
                <div className="col-xl-3 col-lg-3">
                  <div className="form-group">
                <label style={label}>Start Date</label>
                    <input name="startDate" onChange={onChange}  type="date" className="form-control" required data-language="en" placeholder="MM/DD/YYYY" />
                    
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3">
                  <div className="form-group">
                  <label style={label}>End Date</label>
                    <input name="endDate" onChange={onChange}  type="date" className="form-control" data-language="en" placeholder="MM/DD/YYYY" />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                <label style={label}>Venue</label>
                  <div className="form-group">
                    <input name="search" onChange={onChange}  id="username" type="text" placeholder="Search by Venue" autoFocus="off" autoComplete="off" className="form-control" />
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2">
                  <div className="form-group">
                  <label style={label}></label>
                    <button onClick={onClick} className="btn btn-search btn-block"> <i className="fa fa-search" /> Search </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
}

