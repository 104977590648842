import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
// import './eventtype.css'
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";

export default function BaseballRule() {
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <UserHeader />
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>BASEBALL Event Rules</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" onClick={()=>history.goBack()}>Event Type Rules</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" className="active">
                Baseball Event Rules
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="gamerules-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading-sec mb-5">
                <h2>BASEBALL Event Specifics Rules</h2>
                <h4 className="pb-3">
                Reading the <b> GAME RULES </b> first will provide definitions helpful to more fully understanding the <b>Baseball Specific rules</b>.  Please contact us with any clarifications needed and your questions or comments.
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <nav>
                <div
                  className="nav nav-tabs nav-fill"
                  id="nav-tab"
                  role="tablist"
                >
                  <a
                    className="nav-item nav-link active"
                    id="nav-home-tab"
                    data-toggle="tab"
                    href="#nav-keyelem"
                    role="tab"
                    aria-controls="nav-key"
                    aria-selected="true"
                  >
                    Key Element
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-specresult-tab"
                    data-toggle="tab"
                    href="#nav-spec-result"
                    role="tab"
                    aria-controls="nav-specresult"
                    aria-selected="false"
                  >
                    Special Results{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-8-factors-tab"
                    data-toggle="tab"
                    href="#nav-8-factors"
                    role="tab"
                    aria-controls="nav-8-factors"
                    aria-selected="false"
                  >
                    8-Factor Chart{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-6-factors-tab"
                    data-toggle="tab"
                    href="#nav-6-factors"
                    role="tab"
                    aria-controls="nav-6-factors"
                    aria-selected="false"
                  >
                    6-Factor Chart{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-4-factors-tab"
                    data-toggle="tab"
                    href="#nav-4-factors"
                    role="tab"
                    aria-controls="nav-4-factors"
                    aria-selected="false"
                  >
                    4-Factor Chart{" "}
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-desc-tab"
                    data-toggle="tab"
                    href="#nav-desc"
                    role="tab"
                    aria-controls="nav-desc"
                    aria-selected="false"
                  >
                    {" "}
                    Descriptions{" "}
                  </a>
                </div>
              </nav>
              <div
                className="tab-content py-4 px-3 px-sm-0"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="nav-keyelem"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <h3 className="pt-4 pb-2">Key Element </h3>
                  <p>
                    {" "}
                    The Key Element of Baseball is the batter. Regardless of the
                    league baseball always requires a batter. In Carrejoue, no
                    distinction is made in the play of the game for which team
                    the batter is on. The batters of both teams will deliver
                    Defined Results to your game card. It does not take long to
                    see how this works.
                  </p>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-spec-result"
                  role="tabpanel"
                  aria-labelledby="nav-specresult-tab"
                >
                  <h3 className="pt-4 pb-2">Special Results </h3>
                  <p>
                    Special Results are designated by the RED color being
                    applied to the normal defined result symbol. In baseball,
                    the following Special Results may pay a bonus prize or an
                    additional share of the payout pool:
                  </p>
                  <p>
                    Called Strikeout <strong>(<span className="text-danger">K</span>)</strong> – Batter’s third
                    strike is called by the umpire without the batter swinging.
                    <strong>
                      {" "}
                      <em>(25% bonus)</em>{" "}
                    </strong>{" "}
                  </p>
                  <p>
                    Homerun <strong>(<span className="text-danger">X</span>)</strong> – Batter hits the ball out of
                    the park or an inside-the-park homerun both count.
                    <br /> However, there can be no errors by the defense on the
                    inside-the-park homerun.{" "}
                    <strong>
                      {" "}
                      <em>(50% bonus)</em>{" "}
                    </strong>{" "}
                  </p>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-8-factors"
                  role="tabpanel"
                  aria-labelledby="nav-6-factors-tab"
                >
                  <h3 className="pt-4 pb-1 text-center">
                    DEFINED RESULTS: 8-FACTOR CHART{" "}
                  </h3>
                  <h5 className="text-center pb-3">N, G, O, K, X, F, W, P </h5>
                  <div className="table-responsive base-tab">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>INFIELD FLY (N)</th>
                          <th>GROUND OUT (G) </th>
                          <th>OUTFIELD OUT (O)</th>
                          <th>
                            STRIKEOUT (K,<span className="danger-txt">K</span>){" "}
                          </th>
                          <th>
                            EXTRA BASES (X,{" "}
                            <span className="danger-txt">X</span>){" "}
                          </th>
                          <th>FIRST (F) </th>
                          <th>WALK (W) </th>
                          <th>Pitch Error (P)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pop Up</td>
                          <td>Ground Out</td>
                          <td>Fly Out</td>
                          <td> K, Put Out </td>
                          <td> Double </td>
                          <td> Single </td>
                          <td> </td>
                          <td>Hit by Pitch</td>
                        </tr>
                        <tr>
                          <td>Line Out</td>
                          <td>Sacrifice Bunt</td>
                          <td>Line Out</td>
                          <td>
                            {" "}
                            Called** (<span className="danger-txt">
                              K
                            </span>){" "}
                          </td>
                          <td> Triple </td>
                          <td> Error - All Safe </td>
                          <td> </td>
                          <td> K, No Put Out</td>
                       
                        </tr>
                        <tr>
                          <td>Infield Fly Rule</td>
                          <td>Fielder's Choice</td>
                          <td>Sacrifice Fly</td>
                          <td> Swinging </td>
                          <td>
                            {" "}
                            Home Run** (<span className="danger-txt">
                              X
                            </span>){" "}
                          </td>
                          <td> Fielder's Choice </td>
                          <td> </td>
                          <td>Catcher’s Interference </td>
                        </tr>
                        <tr>
                          <td>Line Double Play</td>
                          <td>Ground Double Play</td>
                          <td>Infield Fly Rule</td>
                          <td> Foul Tip Caught</td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Pop Up Double Play</td>
                          <td>Ground Triple Play</td>
                          <td>Ground Out</td>
                          <td> Bunt Foul</td>
                          <td> </td>
                          <td>  </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Pop Up Triple Play </td>
                          <td />
                          <td>Double Play Batted Ball </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td />
                          <td>Triple Play Batted Ball</td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-6-factors"
                  role="tabpanel"
                  aria-labelledby="nav-location-tab"
                >
                  <h3 className="pt-4 pb-1 text-center">
                    DEFINED RESULTS: 6-FACTOR CHART{" "}
                  </h3>
                  <h5 className="text-center pb-3">N, O, K, X, F, W </h5>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th>INFIELD OUT (N) </th>
                          <th>OUTFIELD OUT (O) </th>
                          <th>
                            STRIKEOUT (K,<span className="danger-txt">K</span>){" "}
                          </th>
                          <th>
                            EXTRA BASES (X,{" "}
                            <span className="danger-txt">X</span>){" "}
                          </th>
                          <th>FIRST (F) </th>
                          <th>WALK (W) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pop Up</td>
                          <td>Fly Out</td>
                          <td>K, Put Out</td>
                          <td> Double </td>
                          <td> Single</td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Ground Out </td>
                          <td>Ground Out</td>
                          <td>
                            {" "}
                            Called** (<span className="danger-txt">
                              K
                            </span>){" "}
                          </td>
                          <td> Triple </td>
                          <td> Hit By Pitch</td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Line Out </td>
                          <td>Line Out</td>
                          <td> Swinging </td>
                          <td>
                            {" "}
                            Home Run** (<span className="danger-txt">
                              X
                            </span>){" "}
                          </td>
                          <td> All Safe On Error </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Sacrifice Bunt</td>
                          <td>Sacrifice Fly</td>
                          <td> Foul Tip Caught </td>
                          <td> </td>
                          <td> K, No Put Out </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Fielder’s Choice </td>
                          <td>Fielder's Choice</td>
                          <td> Bunt Foul </td>
                          <td> </td>
                          <td> Catcher’s Interference </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Infield Fly Rule</td>
                          <td>Infield Fly Rule</td>
                          <td> Bunt Foul </td>
                          <td> </td>
                          <td> Fielder's Choice </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Double Play Batted Ball </td>
                          <td>Double Play Batted Ball</td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Triple Play Batted Ball </td>
                          <td>Triple Play Batted Ball </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-4-factors"
                  role="tabpanel"
                  aria-labelledby="nav-4-factors-tab"
                >
                  <h3 className="pt-4 pb-1 text-center">
                    DEFINED RESULTS: 4-FACTOR CHART{" "}
                  </h3>
                  <h5 className="text-center pb-3">O, K, X, F </h5>
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-light">
                        <tr>
                          <th> OUT RECORDED (O) </th>
                          <th>
                            STRIKEOUT (K,<span className="danger-txt">K</span>){" "}
                          </th>
                          <th>
                            EXTRA BASES (X,{" "}
                            <span className="danger-txt">X</span>){" "}
                          </th>
                          <th>FIRST (F) </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pop Up</td>
                          <td> K, Put Out</td>
                          <td>Double</td>
                          <td> Single </td>
                        </tr>
                        <tr>
                          <td>Ground Out</td>
                          <td>
                            {" "}
                            Called** (<span className="danger-txt">
                              K
                            </span>){" "}
                          </td>
                          <td> Triple</td>
                          <td> Hit By Pitch </td>
                        </tr>
                        <tr>
                          <td>Line Out</td>
                          <td> Swinging</td>
                          <td>
                            {" "}
                            Home Run** (<span className="danger-txt">
                              X
                            </span>){" "}
                          </td>
                          <td> All Safe on Error </td>
                        </tr>
                        <tr>
                          <td>Sacrifice Bunt </td>
                          <td> Foul Tip Caught</td>
                          <td> </td>
                          <td> K, No Put Out </td>
                        </tr>
                        <tr>
                          <td>Fielder’s Choice </td>
                          <td>Bunt Foul</td>
                          <td> </td>
                          <td> Catcher’s Interference </td>
                        </tr>
                        <tr>
                          <td>Infield Fly Rule </td>
                          <td> </td>
                          <td> </td>
                          <td> Fielder's Choice </td>
                        </tr>
                        <tr>
                          <td>Fly Out </td>
                          <td> </td>
                          <td> </td>
                          <td> Walk</td>
                        </tr>
                        <tr>
                          <td>Sacrifice Fly </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Double Play Batted Ball </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                        <tr>
                          <td>Triple Play Batted Ball </td>
                          <td> </td>
                          <td> </td>
                          <td> </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-desc"
                  role="tabpanel"
                  aria-labelledby="nav-desc-tab"
                >
                  <h3 className="pt-4 pb-3"> Descriptions </h3>
                  <p className="cont-pdb">
                    <strong>“F” Defined Results </strong>
                  </p>
                  <p>
                    Single – the batter reaches first without an error by the
                    defense. This may include the batter making it to first
                    safely and then trying to take extra bases wherein the
                    batter is thrown out. The batter gets credit for a single
                    and this would correspond with an “F” defined result in
                    CARREJOUE for both 4-FACTOR and 6-FACTOR games. The “F”
                    result would also apply if the batter and base runner made
                    it safe to their next base, but was subsequently thrown out
                    trying to get extra bases.
                  </p>
                  <p>
                    Strikeout (K), no put out – the batter reaches first in a
                    very unusual play and it is recorded as an “F” defined
                    result in CARREJOUE, both 4-FACTOR and 6-FACTOR. In this
                    unusual play, the pitcher gets credit for a strikeout, but
                    not a put out. It happens when the batter strikes out, but
                    the catcher cannot field the ball cleanly either by dropping
                    the ball, not catching the ball or the ball is pitched into
                    the dirt. If this happens, the batter may attempt to reach
                    first safely before being tagged out or thrown out.
                  </p>
                  <p>
                    Catcher’s Interference – the batter is awarded first base,
                    if their bat hits the catcher’s mitt while the batter is in
                    the batter’s box. This is an error on the catcher and an “F”
                    defined result in CARREJOUE both 4-FACTOR and 6-FACTOR.
                  </p>
                  <p className="cont-pdb">
                    <strong>“X” Defined Results </strong>
                  </p>
                  <p>
                    Double – the batter reaches second without an error by the
                    defense. This may include the batter reaching second safely
                    and then getting thrown out at third. The batter gets credit
                    for a double and this would correspond with an “X” defined
                    result in CARREJOUE both 4-FACTOR and 6-FACTOR.
                  </p>
                  <p>
                    Triple – the batter reaches third without an error by the
                    defense. This may include the batter reaching third safely
                    and then getting thrown out at home. The batter gets credit
                    for a triple and this would correspond with an “X” defined
                    result in CARREJOUE both 4-FACTOR and 6-FACTOR.
                  </p>
                  <p className="cont-pdb">
                    <strong>“N” and “O” Defined Results </strong>
                  </p>
                  <p>
                    Force out or Fielder’s Choice Out Recorded – the batter ends
                    up on first, but the defense got a base runner out. The
                    batter does not get credit for a hit. This would correspond
                    with an “O” in CARREJOUE 4-factor and an “N” or "O" in
                    CARREJOUE 6-factor.
                  </p>
                  <p>
                    Fielder's Choice No Out Recorded - the batter ends up on
                    first and all the baserunners made it safe to their next
                    base. The batter does not get credit for a hit, nor is the
                    defense charged with an Error. This would create a defined
                    result of "F" in CARREJOUE, both 4-factor and 6-Factor.
                  </p>
                  <p className="cont-pdb">
                    <strong>Double Play Batted Ball –</strong>
                  </p>
                  <p>
                    The batter would not get credited for a hit even if they
                    made it to first. In an unusual play, sometimes two
                    baserunners are thrown out in a Double Play and there would
                    be no result for CARREJOUE, since the batter is still at
                    bat.
                  </p>
                  <p className="cont-pdb">
                    <strong>Sacrifice Bunt –</strong>
                  </p>
                  <p>
                    A sacrifice bunt result means an out occurred, otherwise the
                    defined result would be a hit (F) or an error (F), if
                    everyone is safe. Since someone got out, the defined result
                    would be an (O) in 4-Factor or an (N) in 6-factor.
                  </p>
                  <p className="cont-pdb">
                    <strong>Pop Up, Line Out, or Fly Out –</strong>
                  </p>
                  <p>
                    The defined result will be an “O” CARREJOUE 4-factor.
                    However, in CARREJOUE 6-factor the defined result will be an
                    “N” if an infielder makes the play and an “O” if an
                    outfielder makes the catch.
                  </p>
                  <p className="cont-pdb">
                    <strong>“K” Defined Result </strong>
                  </p>
                  <p>
                    Strikeout (K), Put Out – the pitcher is recorded a
                    strikeout, but the catcher or first baseman is usually
                    recorded the put out. This occurs when, on the third strike,
                    the catcher drops the ball, cannot catch the ball or misses
                    the ball completely. The batter may try to run to first
                    before being tagged or thrown out. It is a “K” defined
                    result in CARREJOUE both 4-factor and 6-factor.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PreviewAdvertisement />

      <UserFooter />
    </>
  );
}
