/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Geocode from "react-geocode";

let autoComplete;
toast.configure();

function LocalMap({ cb, getD, dis, forL }) {
  const [show, setShow] = useState("hide");
  const [mapV, setMapV] = useState("");
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [data, setData] = useState([]);
  const autoCompleteRef = useRef(null);

  function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );

    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect());
  }

  async function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    setMapV(query);
  }

  const getDataLocation = async (d,l) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY); //Insert your Google Maps API here
    Geocode.enableDebug();
    try {
      let response = await Geocode.fromAddress(d);
      const {
        geometry: { location },
      } = response.results[0];
      initMap({ lat: location?.lat, lng: location?.lng },l || 15);
    } catch (e) {
      // setSubmitFormLoader(false);
    }
  };

  useEffect(() => {
    if (mapV) {
      getDataLocation(mapV);
    }
  }, [mapV]);

  useEffect(() => {
    handleScriptLoad(autoCompleteRef);
    initMap();
    getDataLocation("USA",5);
    setData(getD || []);
  }, []);

  async function initMap(d, z) {
    let map = await new google.maps.Map(document.getElementById("map"), {
      center: d || { lat: 37.09024, lng: -95.712891},
      zoom: z || 5,
    });

    const drawingManager = await new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      markerOptions: {
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      circleOptions: {
        fillColor: "#ffff00",
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });

    google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      async function (polygon) {
        const coords = polygon
          .getPath()
          .getArray()
          .map((coord) => {
            return {
              lat: coord.lat(),
              lng: coord.lng(),
            };
          });

        let getLocat = await getLatLon(coords[0]);
        let obj = {
          locationDescription: getLocat,
          polygonCoordinates: [...coords, coords[0]],
        };
        setPolygonCoordinates((p) => [...p, obj]);
        // SAVE COORDINATES HERE
      }
    );

    drawingManager.setMap(map);
  }

  // console.log(polygonCoordinates, "polygonCoordinates");

  const submitData = () => {
    if (polygonCoordinates?.length === 0) {
      return toast.error("Please draw the polygon", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }

    setData((p) => [...p, ...polygonCoordinates]);
    cb([...data, ...polygonCoordinates]);
    setPolygonCoordinates([]);
    setMapV("");
    initMap();
  };

  const coordDelete = (id) => {
    let getData = [...data];
    let filterData = getData.filter((d, ind) => ind !== id);
    setData(filterData);
    cb(filterData);
  };

  async function getLatLon(d) {
    try {
      if (d) {
        let response = await Geocode.fromLatLng(d?.lat, d?.lng);
        const { formatted_address, address_components } = response.results[0];
        let area =
        address_components.length > 0 &&
        address_components.filter(
          (data) => data.types[0] === "administrative_area_level_1"
        );
      let country =
        address_components.length > 0 &&
        address_components.filter((data) => data.types[0] === "country");
        let data = { groupLocation: area[0]?.long_name,
          country: country[0]?.long_name}
          forL(data)
        return formatted_address;
      }
      
      return "";
    } catch (e) {
      initMap();
      return toast.error("Please draw a valid location", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  }

  return (
    <>
      <div>
        <button
          type="button"
          className="btn mt-0 mb-2 admin-primary-btn"
          onClick={() => setShow("show")}
        >
          Choose Location
        </button>
      </div>

      <div
        className={`modal ${show === "show" && "d-block"} fade ${show}`}
        id="basicExampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="col-6 search-location-input p-0">
                <input
                  className="form-control mb-0"
                  ref={autoCompleteRef}
                  disabled={dis}
                  placeholder="Enter a Location"
                  onChange={(e) => setMapV(e.target.value)}
                  value={mapV}
                  // {...register("locationDescription", { required: true })}
                  // disabled={dis}
                  // onKeyDown={handleKeyDown}
                />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => setShow("hide")}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ height: "600px" }}>
              <div id="map" style={{ height: "300px" }}></div>
              <div className="d-flex justify-content-center my-2">
                <button
                  onClick={submitData}
                  disabled={dis}
                  type="button"
                  style={{ width: "100%" }}
                  className="btn justify-content-center d-flex admin-back-btn m-0 w-auto"
                >
                  <span style={{ fontSize: "small", fontWeight: 700 }}>
                    Add Location
                  </span>
                </button>
              </div>
              <div className="col-md-12 p-0" style={{ height: "100px" }}>
                <div className="table-responsive">
                  <div className="table-fixed" style={{ maxHeight: "200px" }}>
                    <table className="my-0 table table-striped">
                      <thead className="thead-dark" style={{ top: "-1px" }}>
                        <tr>
                          <th scope="col">Location description</th>
                          <th scope="col">Number of Points Polygon</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.length > 0 ? (
                          data?.map(
                            (
                              { locationDescription, polygonCoordinates },
                              id
                            ) => (
                              <tr>
                                <td>{locationDescription}</td>
                                <td className="text-center">
                                  {polygonCoordinates?.length - 1}
                                </td>
                                <td className="text-center" colSpan={12}>
                                  <button
                                    style={{ width: "50%" }}
                                    onClick={() => coordDelete(id)}
                                    type="button"
                                    disabled={dis}
                                    className="btn m-1 btn-danger btn-block p-1"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={3}>
                              Not Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocalMap;
