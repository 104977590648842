/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import moment from "moment";
import Swal from "sweetalert2";
import * as Constants from "../../../helper/constants";
import { useForm, Controller } from "react-hook-form";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreateEvent = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const [dis, setDis] = useState(false);
  const [showEventLeague, setShowEventLeague] = useState([]);
  const [awayTeam, setAwayTeam] = useState([]);
  const {
    register,
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const { id } = useParams();
  let subscribe = watch();
  useEffect(() => {
    // reset form with user data
    reset(formData);
  }, [formData]);

  useEffect(() => {
    let filter =
      awayTeam && awayTeam.find(({ id }) => id == subscribe.homeName);
    // console.log(filter);
    setValue("eventLocation", filter?.partnerLocationDescription);
  }, [subscribe.homeName]);

  useEffect(() => {
    let data = Constants.EVENT_TYPES_AND_LEAGUES.find(
      ({ code }) => code === subscribe.eventType
    );
    setShowEventLeague(data?.eventLeague);
  }, [subscribe.eventType]);

  useEffect(() => {
    async function fetchData() {
      setSubmitFormLoader(true);
      let res = await services.partners(0,100);
      setAwayTeam(res.data.data.data);
      // console.log();
      // setValue("",)
      if (id !== undefined) {
        let response = await services.getEvent(id);
        setFormData(response.data.data);
        setDis(true);
        if (history?.location?.state) {
          setDisInput(true);
        }
      }
      setSubmitFormLoader(false);
    }
    fetchData();
  }, [id]);

  const createEvent = async (data) => {
    let sdata = {
      latitude: "28.989433680415658",
      longitude: " 76.39993668390608",
      uploadedBy: "admin",
      image: "null",
    };
    const {
      eventNumber,
      eventType,
      league,
      awayName,
      homeName,
      eventDate,
      eventTime,
      eventLocation,
    } = data;

    let title = awayTeam && awayTeam.find(({ id }) => id == awayName);
    let title2 = awayTeam && awayTeam.find(({ id }) => id == homeName);

    let newData = {
      eventNumber,
      eventType,
      league,
      awayName,
      homeName,
      eventDate : moment(eventDate).format(),
      eventTime,
      eventLocation,
      ...sdata,
    };

// console.log(newData);
//     return

    if(awayName == homeName){
      toast.error("away team and home team can't be same", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      return
    }
    try {
      if (id) {
        Swal.fire({
          title: `Are you sure about the following fields correctness ?`,
          // showDenyButton: true,
          showCancelButton: true,
          html: `<span style="font-size:15px">  Away Name : <b>${title?.partnerName}</b>
          </span>  <br><span style="font-size:15px">  Home Name : <b>${title2?.partnerName}</b>,  <b>${title2?.partnerLocationDescription}</b>
          </span>  <br>
           <span style="font-size:15px"> Event Location : <b> ${eventLocation} </b></span>`,
          confirmButtonText: "OK",
          denyButtonText: `Don't save`,
          confirmButtonColor: "#0070c0",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Swal.fire("Saved!", "", "success");
            try {
              setSubmitFormLoader(true);
              let res = await services.updateEvent(id, {
                awayName,
                homeName,
                eventLocation,
                ...sdata,
              });
              Swal.fire(res.data.message, "", "success");
              setSubmitFormLoader(false);
              history.push("/admin/event");
            } catch (e) {
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "1",
              });
              setSubmitFormLoader(false);
              // console.log(e.response.data.message);
            }
          }
        });
      } else {
        Swal.fire({
          title: "Are you sure about the following fields correctness ?",
          // showDenyButton: true,
          showCancelButton: true,
          html: `<span style="font-size:15px">  Away Name : <b>${title?.partnerName}</b>
          </span>  <br><span style="font-size:15px">  Home Name : <b>${title2?.partnerName}</b>,  <b>${title2?.partnerLocationDescription}</b>
          </span>  <br>
           <span style="font-size:15px"> Event Location : <b> ${eventLocation} </b></span>`,
          confirmButtonText: "OK",
          denyButtonText: `Don't save`,
          confirmButtonColor: "#0070c0",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // Swal.fire("Saved!", "", "success");
            try {
              setSubmitFormLoader(true);
              let response = await services.event(newData);
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "1",
              });
              setSubmitFormLoader(false);
              history.push("/admin/event");
            } catch (e) {
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "1",
              });
              setSubmitFormLoader(false);
              // console.log(e.response.data.message);
            }
          }
        });
      }
    } catch (e) {
      toast.error(e.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>
                    {" "}
                    {disInput
                      ? "View Event"
                      : id
                      ? "Edit Event"
                      : "Create Event"}
                  </h4>
                  {formData?.eventId && <h6>Event Id : {formData?.eventId}</h6>}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="Total-Revenue">
              <form>
                <div className="row management">
                  {submitFormLoader && <div className="loader"></div>}

                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown">
                      <label>Event Type</label>
                      <select
                        defaultValue={formData.eventType}
                        disabled={disInput}
                        disabled={dis}
                        className="drop-button form-control"
                        {...register("eventType", { required: true })}
                      >
                        <option value="">Select Event Type</option>
                        {Constants.EVENT_TYPES_AND_LEAGUES &&
                          Constants.EVENT_TYPES_AND_LEAGUES.map(
                            ({ code, name }, id) => {
                              return (
                                <option key={id} value={code}>
                                  {name}
                                </option>
                              );
                            }
                          )}
                      </select>
                      {errors.eventType && (
                        <p className="text-danger">
                          Please select the event type.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Event League</label>
                      <select
                        value={formData.league}
                        disabled={disInput}
                        disabled={dis}
                        className="drop-button form-control"
                        {...register("league", { required: true })}
                      >
                        <option value="">Select Event League</option>
                        {showEventLeague &&
                          showEventLeague.map(({ code, name }, id) => {
                            return (
                              <option key={id} value={code}>
                                {name}
                              </option>
                            );
                          })}
                      </select>
                      {errors.league && (
                        <p className="text-danger">
                          Please select the event league.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Away Team (Partner Id)</label>

                      <select
                        disabled={disInput}
                        className="drop-button form-control"
                        defaultValue={formData.awayName}
                        {...register("awayName", { required: true })}
                      >
                        <option value="">Please Select</option>
                        {awayTeam.length > 0 &&
                          awayTeam.map(({ id, partnerId }) => {
                            return (
                              <option key={id} value={id}>
                                {partnerId}
                              </option>
                            );
                          })}
                      </select>
                      {errors.awayName && (
                        <p className="text-danger">
                          Please select the away team.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Home Team (Partner Id)</label>
                      <select
                        disabled={disInput}
                        className="drop-button form-control"
                        {...register("homeName", { required: true })}
                      >
                        <option value="">Please Select</option>
                        {awayTeam.length > 0 &&
                          awayTeam.map(({ id, partnerId }) => {
                            return (
                              <option key={id} value={id}>
                                {partnerId}
                              </option>
                            );
                          })}
                      </select>
                      {errors.homeName && (
                        <p className="text-danger">
                          Please select the home team.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Event Date</label>

                      <Controller
                        control={control}
                        {...register("eventDate", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            disabled={dis}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subDays(new Date(), 0)}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />

                      {errors.eventDate && (
                        <p className="text-danger">
                          Please enter the event date.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Event Time</label>
                      <input
                        type="time"
                        disabled={disInput}
                        disabled={dis}
                        name="eventTime"
                        className="form-control"
                        {...register("eventTime", { required: true })}
                      />
                      {errors.eventTime && (
                        <p className="text-danger">
                          Please enter the event time.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Event Location</label>
                      <input
                        type="text"
                        name="eventLocation"
                        disabled={true}
                        className="form-control"
                        {...register("eventLocation")}
                      />
                      {errors.eventLocation && (
                        <p className="text-danger">
                          Please enter the event location.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Assign Event Number</label>
                      <input
                        disabled={disInput}
                        disabled={dis}
                        type="text"
                        name="eventNumber"
                        maxLength={3}
                        className="form-control"
                        {...register("eventNumber", { required: true })}
                      />
                      {errors.eventNumber && (
                        <p className="text-danger">
                          Please enter the assign event number.
                        </p>
                      )}
                    </div>
                  </div>

               {  formData?.deleteReason && <div className="col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Reason</label>
                      <input
                        className="form-control"
                        disabled={disInput}
                        disabled={dis}
                        value={formData?.deleteReason}
                      />
                      
                    </div>
                  </div>}
       
                </div>
              </form>
              <div className="row">
                    <div className="col-lg-12">
                      <Link to="/admin/event">
                        <button
                          type="button"
                          className="btn admin-back-btn mr-2"
                        >
                          Back
                        </button>
                      </Link>

                      {!disInput && (
                        <button onClick={handleSubmit(createEvent)} className="btn admin-primary-btn">
                          {id ? "Update" : "Submit"}
                        </button>
                      )}
                    </div>
                  </div>
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreateEvent;
