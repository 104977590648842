import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import signUpReducers from "./signUpReducers";
import message from "./message";
import logoutReducer from "./logoutReducer";

export default combineReducers({
    loginReducer,
    signUpReducers,
    message,
    logoutReducer
});