import { LOGOUT_SUCCESS, LOGOUT_FAIL, SET_MESSAGE } from "./type";
import AuthService from "../services/services";
import {  useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

export const logoutAction = (userId) => (dispatch) => {

  return AuthService.logoutUser(userId).then(
    (data) => {
       
      if (data) {
        toast.success(data.data.message, {
           position: toast.POSITION.TOP_CENTER,
        toastId: "1",
        });
      }
      
      localStorage.clear();
  

      dispatch({
        type: LOGOUT_SUCCESS,
        payload: { user: undefined },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message, {
         position: toast.POSITION.TOP_CENTER,
        toastId: "1",
     
      });
   

      dispatch({
        type: LOGOUT_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
    }
  );
};
