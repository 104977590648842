import { useEffect, useState } from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import BarCodeModal from "./modal";
import services from "../../../services/services";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import "./result.css";
import * as universalFunction from "../../../utils/universalFunctions";

toast.configure();

export default function GameResult() {
  let history = useHistory()
  const [show, setShow] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [result, setResult] = useState([]);
  const [event, setEvent] = useState({});
  const [modalData, setModalData] = useState([]);
  const [cardP, setCardP] = useState([]);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  let { state } = useLocation();

  let {id} = useParams();
// console.log(state);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  async function fetchData() {


    let data = {
      userId: state?.data?.userId,
      eventId: state?.data?.eventId,
      groupId: state?.data?.groupId,
      winParameterId: state?.winParameterId,
      numberOfFactor:Number(state?.numberOfFactor)
    };



    try {
      setSubmitFormLoader(true);
      let res = await services.gameCardStatus(data);
      let response =  await services.firebase(state.id);
      let eventRes = await services.singleEventResult(id);
      let cardplayed = await services.userCardPlayed(data.eventId,data.groupId,state?.winParameterId);
      setResultData(res.data.data)
      setResult(response.data.data)
      setEvent(eventRes.data.data)
      setCardP(cardplayed?.data?.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
      // console.log(e.response.data.message);
    }
  }


  const handleClose = () => setShow(false);

  const showModal = (data) => {
    setShow(true);
    setModalData(data)
  }

  const findCallName = (d) => {
    if(result?.counts){
      let find = result?.counts.find(({callName})=> callName===d);
      return find?.name
    }
   }

  const findDrive = (event,p) =>{
    // console.log(event,p);
  if(event?.awayNameData?.id===p){
  return "a"
  }
  if(event?.homeNameData?.id===p){
    console.log(2);
  return "h"
  }

  return false
}

const [quarterName] = useState({
  5:"OT1",
  6:"OT2",
  7:"OT3",
  8:"OT4",
  9:"OT5",
  10:"OT6",
  11:"OT7",
  12:"OT8",
  13:"OT9",
  14:"OT10",
})

  return (
    <>
      <UserHeader />
      <BarCodeModal data={modalData} handleclose={handleClose} show={show} />
      {submitFormLoader && <div className="loader"></div>}
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>Result</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link onClick={()=>history.goBack()} to="#">Game Cards</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" className="active">
                Result
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="result-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="result-top mb-5">
                <h2>{universalFunction.findLeague(event?.eventType)} - {universalFunction.findLeague(event?.eventType,event?.league)}</h2>
                {/* <h4>11 December 2021 / 15:20 GMT+0000</h4> */}
                <h4>{moment.utc(event?.eventDate).local().format('YYYY-MM-DD')}   / {event?.eventTime} ET</h4>
                
                <h3 style={{margin:'0 auto',width:'fit-content'}} className={`mycard-btn ${(event.isEventStarted && event.isEventCancelled) ? "bg-danger" : (event.isEventStarted && !event.isEventFinished) ? 'bg-primary' : (event.isEventStarted && event.isEventFinished) ? "bg-success" : 'bg-warning'}`}>
                  {(event.isEventStarted && event.isEventCancelled) ? "Cancelled" : (event.isEventStarted && !event.isEventFinished) ? 'Live' : (event.isEventStarted && event.isEventFinished) ? "Completed"  : 'UPCOMING'}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="team-table">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 offset-lg-3 text-center">
                <div className="row">
                  <div className="col-md-12">
                    <span className="team-name">{event?.awayName}</span>
                  </div>
                  <div className="col-md-12 text-center">
                    <div className="vs">VS</div>
                  </div>
                  <div className="col-md-12">
                    <span className="team-name">{event?.homeName}</span>
                  </div>
                </div>
                <div className="first-table-area">
                  <div className="row">
                    <div className="col-md-5">
                      <span className="grp-name-number">
                        Group Name/Group Id{" "}
                      </span>
                    </div>
                    <div className="col-md-1 text-center">
                      <strong>:</strong>
                    </div>
                    <div className="col-md-6">
                      <span className="game-base-name">
                      {cardP?.groupName}/<Link to="#">{cardP.groupId}</Link>{" "}
                      </span>
                    </div>
                  </div>
                  <div className="first-table-details">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>My Cards </th>
                            <th>Pattern Win</th>
                            <th>Prize Description </th>
                            <th>Claim Prize</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          resultData.length ? resultData.map((data)=>
                            <tr key={data.pin}>
                            <td>#256978</td>
                            <td>{data.pattern.toUpperCase()=="HALFBLACKOUT" && "HALF BLACKOUT" || data.pattern.toUpperCase()}</td>
                            <td>Coupon</td>
                            <td>
                              <button
                                onClick={()=>showModal(data)}
                                className="btn btn-game"
                              >
                                Claim
                              </button>
                            </td>
                          </tr>
                          ) :   <tr>
                            <td colSpan={5}>No prize found</td>
                          </tr>
                        }
                     
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="second-table-details">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 offset-lg-2">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th>Cards Played</th>
                      <th>Patterns</th>
                      <th>Win Count</th>
                      <th>Percent Wins (%) </th>
                    </tr>
                  </thead>
                  <tbody>
                  {cardP?.prizePercentage?.length > 0 ? cardP?.prizePercentage?.map(({cardPlayed,pattern,winCount, percentageWin},id)=>
                    <tr key={id}>
                      <td>{cardPlayed}</td>
                      <td>{pattern.toUpperCase()=="HALFBLACKOUT" && "HALF BLACKOUT" || pattern.toUpperCase()}</td>
                      <td>{winCount}</td>
                      <td>{Number(percentageWin).toFixed(2)}</td>
                    </tr>
                  ):   <tr>
                      <td className="text-center" colSpan={4}>Not Found</td>
                    </tr>}
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="summary-result">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-sec text-center mb-4">
                <h2  className="d-flex flex-wrap" style={{justifyContent:'center',alignItems:'baseline'}}>Summary of Results  <span style={{fontSize:'18px', color:"#222222",marginLeft: 'auto'}}>{event?.isEventFinished && `Call Finished :  ${ moment.utc(event?.eventFinishedAt).local().format('YYYY-MM-DD HH:mm')  }` || event?.isEventCancelled && `Event Cancelled :  ${ moment.utc(event?.eventCancelledAt).local().format('YYYY-MM-DD HH:mm')  }`}
                      </span></h2>
                

              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="table-responsive">
              <div className="table-fixed">
               {event?.eventType && event?.eventType?.split("")[0]=="A"? 
                   <table className="my-0 table table-striped">
                   <thead className="thead-dark">
                     <tr>
                       <th>Square Number</th>
                       <th>Quarter </th>
                       <th>Team Name</th>
                       <th>Drive </th>
                       <th>Down Marker</th>
                       <th>Results </th>
                     </tr>
                   </thead>
                   <tbody>
                     {result?.calls ? (
                       result?.calls.map(
                         (
                           {
                            atBat,
                            ownOpp,
                            quarter,
                            teamName,
                            awayDrive,
                            homeDrive,
                            yardLine,
                            callName,
                            possession
                         
                           },
                           id
                         ) => {
                             return (
                             <tr key={id}>
                               <td>{id+1}</td>
                               <td>{quarter>4 ? quarterName[Number(quarter)] : quarter}</td>
                               <td>{teamName} </td>
                               <td>{findDrive(event,possession)==="a" ?  awayDrive : findDrive(event,possession)==="h" ? homeDrive : ""}</td>
                               <td> {`${ownOpp}${yardLine}`}</td>
                               <td>{findCallName(callName)}</td>
                             </tr>
                           );
                         }
                       )
                     ) : (
                       <tr>
                         <td className="text-center" colSpan={7}>
                           No Data Found
                         </td>
                       </tr>
                     )}
                   </tbody>
                 </table>

                :
                 <table className="my-0 table table-striped">
                  <thead className="thead-dark">
                    <tr>
                      <th>Batter #</th>
                      <th>Square #</th>
                      <th>Batter Name </th>
                      <th>(Top,Bottom) T/B</th>
                      <th>Inning #</th>
                      <th>Result </th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                            {result?.calls ? (
                              result?.calls.map(
                                (
                                  {
                                    atBat,
                                    batter,
                                    batterNumber,
                                    callName,
                                    currentPosition,
                                    inning,
                                    topBottom,
                                  },
                                  id
                                ) => {
                                  return (
                                    <tr key={id}>
                                      <td>{callName ==="R"? "" : batterNumber}</td>
                                      <td>{(currentPosition % 25) === 0 ? 25 : (currentPosition % 25) }</td>
                                      <td>{callName ==="R"? "Non Batter Out" : batter} </td>
                                      <td> {topBottom}</td>
                                      <td>{inning}</td>
                                      <td>{callName ==="R"? "" : callName}</td>
                                      <td>{findCallName(callName)}</td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={7}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                </table>}
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}
