/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import moment from "moment";
import Swal from "sweetalert2";
import DataTable from "react-data-table-component";
import * as universalFunction from "../../../utils/universalFunctions";


export const UserEvent = ({ data, fetchData, loader }) => {
  let history = useHistory();
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  // Define callback function for failed attempt
  const [page, setPage] = useState(0);

  useEffect(() => {
    setSubmitFormLoader(true);
    fetchData(page);
    setSubmitFormLoader(false);
  }, [page]);


  const navigateData = (id) => {
    // If geolocation is available, try to get the visitor's position
    if (!(localStorage.getItem("role") === "2")) {
      Swal.fire({
        icon: "warning",
        // title: "Oops...",
        text: "Please login first in order to enter event",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/login");
        }
      });
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }

    // Define callback function for successful attempt
    async function successCallback(position) {
      // alert("Latitude: " + position.coords.latitude + ", " +  position.coords.longitude);
      setSubmitFormLoader(true);
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
      history.push({
        pathname: `/user/associated-groups-with-events/${id}`,
      });
      setSubmitFormLoader(false);
    }
  };


  const columns = [

    {
      name: "Enter Game",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <button onClick={() => navigateData(row.id)} className="btn btn-game">
            Enter Now
          </button>
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Away Team",
      selector: (row) => row.awayNameData?.partnerName,
      sortable: true,
      center: true,
      width: "15rem",
      filterable: true,
    },
    {
      name: "Home Team",
      selector: (row) => row.homeNameData?.partnerName,
      sortable: true,
      center: true,
      width: "15rem",
      filterable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.eventDate).format("MM/DD/YYYY"),
      sortable: true,
      filterable: true,
      center: true,
    },
    {
      name: "Time (ET)",
      selector: (row) => row.eventTime,
      sortable: true,
      filterable: true,
      center: true,
    },
    // {
    //   name: "Event Name",
    //   selector: (row) => universalFunction.findLeague(row.eventType),
    //   sortable: true,
    //   filterable: true,
    // },
   
    {
      name: "League Name",
      selector: (row) => universalFunction.findLeague(row.eventType, row.league),
      sortable: true,
      filterable: true,
      center: true,
    },

    {
      name: "Venue Details",
      selector: (row) => row.eventLocation,
      sortable: true,
      center: true,
      width: "15rem",
      filterable: true,
    },


  ];

  // const cbNavigate = (id) => navigateData(id);

  return (
    <section className="upcoming">
      {submitFormLoader && <div className="loader"></div>}
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading-sec text-center">
              <h2>UPCOMING EVENTS</h2>
              <h4>
              Enjoy these Events now. Turn your <b>Location Tracking</b> on and click ‘Enter Now’.  Groups you are qualified to play will appear, if they are using this Event.  Use the <b>Groups</b> tab to enter Groups you are eligible.
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" col-xl-12 col-lg-12 col-md-12">
            <DataTable
              columns={columns}
              data={data.data}
              pagination
              paginationServer
              progressPending={loader}
              paginationTotalRows={data.totalItems}
              // paginationPerPage={10}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              onChangePage={(page) => setPage(page)}
              persistTableHead
            />
          </div>
        </div>
      </div>

      <PreviewAdvertisement />
    </section>
  );
};
