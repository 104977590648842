import * as universalFunction from "../../../utils/universalFunctions";
import moment from "moment";
import { useState } from "react";
import ShortDesc from "../../../user/component/group/shortDesc";
import parse from 'html-react-parser';


export const TypeTable = ({type,win,card}) =>  (
        <div style={{padding:'0 30px'}} className="col-lg-12 col-md-12">
        <h6 className="pb-2">List of Old Types :</h6>
                    <div className="table-responsive">
                      <div className="table-fixed">
                        <table className="my-0 table table-striped">
                          <thead className="thead-dark">
                            <tr>
                              <th>Event Type</th>
                              <th>League</th>
                              <th>Number Of Factor</th>
                              <th>Card Version</th>
                              <th>Start Date</th>
                              <th>End Date </th>
                              <th>Win Version</th>
                              <th>Start Date</th>
                              <th>End Date </th>
                            </tr>
                          </thead>
                          <tbody>
                            {type?.length ? (
                                type.map(
                                (
                                  {
                                    eventType,
                                    league,
                                    numberOfFactor,
                                    cardParameterVersionId,
                                    cardParameterStartDate,
                                    cardParameterEndDate,
                                    winParameterVersionId,
                                    winParameterStartDate,
                                    winParameterEndDate,
                                  },
                                  id
                                ) => {
                                  return (
                                    <tr key={id}>
                                      <td>{universalFunction.findLeague(eventType)}</td>
                                      <td>{ universalFunction.findLeague(eventType,league)}</td>
                                      <td>{numberOfFactor} </td>
                                      <td> {card(cardParameterVersionId)}</td>
                                      <td>{moment(cardParameterStartDate).format("MM/DD/YYYY")}</td>
                                      <td>{cardParameterEndDate ? moment(cardParameterEndDate).format("MM/DD/YYYY") : ""}</td>
                                      <td>{win(winParameterVersionId)}</td>
                                      <td>{moment(winParameterStartDate).format("MM/DD/YYYY")}</td>
                                      <td>{winParameterEndDate ? moment(winParameterEndDate).format("MM/DD/YYYY") : ""}</td>
                                      
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={9}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
    )

export const PrizeTable = ({prize,cb}) =>  {
  const [showP, setShowP] = useState(false);
const [sendData, setSendData] = useState({
  h:'',
  p:''
});

  
  
return  (
        <div style={{padding:'0 30px'}} className="col-lg-12 col-md-12">
                <ShortDesc handleclose={()=>setShowP(false)} data={sendData} show={showP}/>
             <h6 className="pb-2">List of Old Prizes :</h6>
                    <div className="table-responsive">
                      <div className="table-fixed">
                        <table className="my-0 table table-striped">
                          <thead className="thead-dark">
                            <tr>
                               <th scope="col">Pattern</th>
                                <th scope="col">Type</th>
                                <th className="text-nowrap" scope="col">Prize Id</th>
                                <th className="text-nowrap" scope="col">Expire/Enter Date</th>
                                <th className="text-nowrap" scope="col">Select Date</th>
                                <th className="text-nowrap" scope="col">Notice Date</th>
                                <th className="text-nowrap" scope="col">Long Prize</th>
                                <th className="text-nowrap" scope="col">Advertiser Id</th>
                                <th className="text-nowrap" scope="col">Estimated Value ($)</th>
                                <th className="text-nowrap" scope="col">Start Date</th>
                                <th className="text-nowrap text-center" scope="col">End Date</th>
                                <th className="text-nowrap" scope="col">Ad Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {prize?.length ? (
                                prize.map(
                                (
                                  {
                                    pattern,
                      offerCode,
                      advertizerId,
                      estimatedRetailValue,
                      startDate,
                      endDate,
                      adDescription,
                      type,
                      prizeId,
                      hasNumberOfDays,
                      expireNumberOfDays,
                      expireEnterDate,
                      selectDate,
                      noticeDate,
                      longPrize,
                                  },
                                  id
                                ) => {
                                  return (
                                    <tr key={id}>
                                <td scope="row"><b>{pattern.toUpperCase()=="HALFBLACKOUT" && "HALF BLACKOUT" ||pattern.toUpperCase()}</b></td>
                                <td>{type}</td>
                                <td>{cb(prizeId)}</td>
                                <td>{hasNumberOfDays ? expireNumberOfDays :  expireEnterDate
                                ? moment(expireEnterDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"}</td>
                                <td>
                                  { selectDate
                                ? moment(selectDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"}
                                </td>
                                <td> 
                                { noticeDate
                                ? moment(noticeDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"}
                                </td>
                                <td style={{cursor:'pointer'}} className="text-center" onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'Long Prize',p: parse(longPrize)})}}> 
         
                                <span className="fa fa-eye fa-4x icon-act" ></span>
           
                                </td>
                                <td>{ advertizerId?.length &&
                                    advertizerId.map((data,index) =>(index ? ', ' : '') + `${data}`)}</td>
                                <td>{estimatedRetailValue}</td>
                                <td>
                                { startDate
                                ? moment(startDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"}
                                </td>
                                <td> {
                              endDate
                                ? moment(endDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"
                            
                           }</td>
                                <td style={{cursor:'pointer'}} className="text-center" onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'Ad Description',p: parse(adDescription)})}}>
                                <span className="fa fa-eye fa-4x icon-act" ></span>
                               </td>
                          
                              </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={12}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
    )


                            }