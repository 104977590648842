/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { UserFooter } from "../../core/footer/userFooter";
import UserHeader from "../../core/header/userHeader";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import logo from "../../../assets/images/kibu-logo.png";
import "./contactus.css";
import services from "../../../services/services";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import ModalForm from "../responsible-gaming/popup";
import { toast } from "react-toastify";
// Import toastify css file

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

function ContactUs(prop) {
  const [showForm, setShowForm] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [chooseop, setChooseOp] = useState("");
  const [email, setEmail] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [val, setVal] = useState("");
  const [err, setErr] = useState("OTP has been sent in your mail id");
  // const [formdata, setFormData] = useState({});

  const {
    register,
    reset,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const subscription = watch();

  useEffect(() => {
    if (chooseop === "no") {
      setShowForm(false);
      reset({ customerEmailId: "", customerZipCode: "" });
    } else {
      setShowForm(true);
      reset({ firstName: "", lastName: "", customerEmailId2: "" });
    }
  }, [chooseop]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const HandleClose = () => setShowModal(false);

  const verifyOtp = async () => {
    let data = {
      otp: Number(val),
      email: email,
    };

    try {
      setSubmitFormLoader(true);
      let res = await services.contactUsOtp(data);
      if (res.status === 200) {
        setErr("");
        setSubmitFormLoader(false);
        Swal.fire({
          title: res.data.message,
          // showDenyButton: true,
          confirmButtonText: "OK",
        });
        setShowModal(false);
        reset();
        // setFormData(res.data.data);
        // setInputDisabled(true);
      }
    } catch (e) {
      setErr(e.response.data?.message);
      setSubmitFormLoader(false);
    }
  };

  const submitData = async (d) => {
    let data = {
      customerEmailId: d.customerEmailId || "",
      customerCellPhone: showForm ? d.customerCellPhone || "" : "",
      customerZipCode: showForm ? d.customerZipCode || "" : "",
      isRegistered: showForm,
      firstName: !showForm ? d.firstName || "" : "",
      lastName: !showForm ? d.lastName || "" : "",
      reasonForContacting: d.reasonForContacting || "",
      canBeContactedByText:
        d.canBeContactedByText === "yes" ? true : false || true,
      details: d.details || "",
    };

    if (d.customerEmailId2) {
      data.customerEmailId = d.customerEmailId2;
    }

    try {
      setSubmitFormLoader(true);
      const res = await services.contactUs(data);
  
        setSubmitFormLoader(false);
        setEmail(res.data.email);
        setShowModal(true);
      // reset()
    } catch (e) {
      toast.error(
        e.response.data.message,

        {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        }
      );

      setSubmitFormLoader(false);
    }
  };

  return (
    <>
      <ModalForm
        msg={err}
        verifyotp={verifyOtp}
        onchange={(e) => setVal(e.target.value)}
        show1={showModal}
        handleclose={HandleClose}
      />
      <UserHeader />
      <section className="bredcum">
        {submitFormLoader && <div style={{zIndex:1300}} className="loader"></div>}
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <div className="logo-watermark-2">
                <img src={logo} alt="" />{" "}
              </div>
              <h1>Contact Us</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="/user/contact-us" className="active">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-sec text-center">
                <h2>Contact Support</h2>
                <h4>
                  {/* For questions specific to your account, you will need to fill
                  this form. */}
                  Account Question? Provide Account Number for quickest
                  response. If that does not work, please call customer support
                  at the number below.{" "}
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-8 offset-lg-2 mt-5 mt-lg-0">
              <form
                method="post"
                role="form"
                className="email-form"
                onSubmit={handleSubmit(submitData)}
              >
                <div className="row">
                  <div className="form-group col-md-12 text-center">
                    <label htmlFor="name">Are you already registered?</label>
                    <div className="form-group mb-0">
                      <div className="form-check form-check-inline">
                        <input
                          defaultChecked
                          onChange={(e) => setChooseOp(e.target.value)}
                          className="form-check-input"
                          type="radio"
                          name="chooseoption"
                          id="choose"
                          defaultValue="yes"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios5"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          onChange={(e) => setChooseOp(e.target.value)}
                          name="chooseoption"
                          id="choose1"
                          defaultValue="no"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios6"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {showForm ? (
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Please select</label>
                      <select
                        {...register("selectoption", { required: true })}
                        className="form-control"
                      >
                        <option value="customerid">Customer ID</option>
                        <option value="cellphonenumber">
                          Cell Phone Number
                        </option>
                      </select>
                    </div>
                    {subscription.selectoption === "cellphonenumber" ? (
                      <div className="form-group col-md-6">
                        <label htmlFor="name">Cell Phone Number</label>
                        {/* <input
                          {...register("customerCellPhone", { required: true })}
                          type="text"
                          className="form-control"
                          name="customerCellPhone"
                        /> */}
                        <Controller
                          control={control}
                          name="customerCellPhone"
                          {...register("customerCellPhone", { required: true })}
                          render={({ field: { value, onChange } }) => (
                            <PhoneInput
                              style={{ border: "1px solid #ced4da" }}
                              defaultCountry="US"
                              international
                              placeholder="Enter phone number"
                              value={value}
                              className="form-control mb-0"
                              onChange={onChange}
                            />
                          )}
                        />

                        {errors.customerCellPhone && (
                          <p className="text-danger">
                            Please enter the cell phone number.
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="form-group col-md-6">
                        <label htmlFor="name">
                          Customer ID (Registered Email ID){" "}
                        </label>
                        <input
                          {...register("customerEmailId", { required: true })}
                          type="text"
                          className="form-control"
                          name="customerEmailId"
                        />
                        {errors.customerEmailId && (
                          <p className="text-danger">
                            Please enter the register email id.
                          </p>
                        )}
                      </div>
                    )}

                    <div className="form-group col-md-6">
                      <label htmlFor="name">
                        Enter Zip Code used for registration address?
                      </label>
                      <input
                        {...register("customerZipCode", { required: true })}
                        type="text"
                        className="form-control"
                        id="subject"
                      />
                      {errors.customerZipCode && (
                        <p className="text-danger">
                          Please enter the zip code.
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">First Name</label>
                      <input
                        {...register("firstName", { required: true })}
                        type="text"
                        className="form-control"
                        id="name"
                      />
                      {errors.firstName && (
                        <p className="text-danger">
                          Please enter the first name.
                        </p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Last Name</label>
                      <input
                        key={chooseop}
                        {...register("lastName", { required: true })}
                        type="text"
                        className="form-control"
                        id="lastname"
                      />
                      {errors.lastName && (
                        <p className="text-danger">
                          Please enter the last name.
                        </p>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Email ID</label>
                      <input
                        key={chooseop}
                        {...register("customerEmailId2", { required: true })}
                        className="form-control"
                        id="email"
                      />
                      {errors.customerEmailId2 && (
                        <p className="text-danger">
                          Please enter the email id.
                        </p>
                      )}
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">May we contact you by text?</label>
                    <div className="form-group mb-0">
                      <div className="form-check form-check-inline">
                        <input
                          {...register("canBeContactedByText", {
                            required: true,
                          })}
                          className="form-check-input"
                          type="radio"
                          id="exampleRadios3"
                          defaultValue="yes"
                          defaultChecked
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios3"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          {...register("canBeContactedByText", {
                            required: true,
                          })}
                          className="form-check-input"
                          type="radio"
                          id="exampleRadios4"
                          defaultValue="no"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios4"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Reason for contacting us</label>
                    <select
                      {...register("reasonForContacting", { required: true })}
                      className="form-control"
                    >
                      <option value="">Please select a reason</option>
                      <option value="Account Question">Account Question</option>
                      <option value="Groups Question">Groups Question</option>
                      <option value="General Rules Question">
                        General Rules Question{" "}
                      </option>
                      <option value="Prize Question">Prize Question</option>
                      <option value="Event Type Question">
                        Event Type Question
                      </option>
                      <option value="Privacy Policy">Privacy Policy</option>
                      <option value="Terms and Conditions">
                        Terms &amp; Conditions
                      </option>
                      <option value="Website">Website</option>
                      <option value="Other">Other</option>
                    </select>
                    {errors.reasonForContacting && (
                      <p className="text-danger">Please select the reason.</p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="name">
                    Details of situation/question: (150 words max)
                  </label>
                  <textarea
                    {...register("details", { required: true })}
                    className="form-control"
                    rows={3}
                  />
                  {errors.details && (
                    <p className="text-danger">Please enter some details.</p>
                  )}
                </div>

                <div className="text-center">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
                <h6 className="pt-5">
                  If you would prefer to phone us, please call (480) 712-6940.
                </h6>
              </form>
            </div>
          </div>
        </div>
      </section>
      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}

export default ContactUs;
