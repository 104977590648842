/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
// import './event.css';
import styled from "styled-components";
import Button from "react-bootstrap/Button";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const Event = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
  const [eventId, setEventId] = useState();
  const [showEvents, setEvents] = useState([]);
  const [getArchive, setArchive] = useState([]);
  const [check, setCheck] = useState({});
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [showArchive, setShowArchive] = useState(false);
  const [showDeleted, setDeleted] = useState(false);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [reason, setReason] = useState('');
  const [getDelete, setDelete] = useState([]);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    fetchData2();
  }, [page2]);

  useEffect(()=>{
    fetchDeleted()
  },[])

  async function fetchData(search,search2) {
    try {
      setSubmitFormLoader(true);
      let data = await services.events(page, 10, search);
      setEvents(data?.data.data);

      data?.data.data.data.map(({ status, id }) => {
        if (status === 1) {
          setCheck((p) => ({ ...p, [`event${id}`]: true }));
        } else {
          setCheck((p) => ({ ...p, [`event${id}`]: false }));
        }
        return false;
      });
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }


  async function fetchData2(search) {
    try {
      setSubmitFormLoader(true);
      let res = await services.getArchive(page2, 10, search);
      setArchive(res?.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }

  async function fetchDeleted() {
    try {
      setSubmitFormLoader(true);
      let res = await services.getDeletedEvents();
      setDelete(res?.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }
   
  const deleteAdvertisementByClick = () => {
    if (showSweetAlert) {
      services
        .deleteEvent(eventId,reason)
        .then((response) => {
          setSweetAlert(false);

          // Render to load advertisement page

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });

          fetchData();
          fetchDeleted();
        })
        .catch((e) => {
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const sweetAlertForDelete = (event) => {
    setSweetAlert(true);
    setEventId(event);
    setReason("")
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const renderOnEditPage = (event) => {
    history.push(`/admin/edit-event/${event}`);
  };

  const renderOnAdvertisementDetailPage = (event) => {
    history.push({
      pathname: `/admin/edit-event/${event}`,
      state: "event",
    });
  };

  const updateStatusOfAdvertisement = async (eventId, event) => {
    if (event.target.checked) {
      await services
        .updateEventStatus(eventId, {
          status: 1,
          isDelete: false,
          modelType: "Event",
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setCheck((p) => ({ ...p, [`event${eventId}`]: true }));
        })
        .then((errors) => {
          toast.success(errors, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      await services
        .updateEventStatus(eventId, {
          status: 0,
          isDelete: false,
          modelType: "Event",
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setCheck((p) => ({ ...p, [`event${eventId}`]: false }));
        })
        .then((errors) => {
          toast.success(errors, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => showEvents?.currentPage * 10 + i + 1,
      // sortable: true,
      filterable: true,
    },

    {
      name: "Event Id",
      selector: (row) => row.eventId,
      sortable: true,
      fontFamily:"sans-serif",
      filterable: true,
    },
    {
      name: "Away Team",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{row?.awayNameData?.partnerName}</span>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Home Team",
      selector: (row) => row?.homeNameData?.partnerName,
      sortable: true,
      filterable: true,
    },
   

    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => sweetAlertForDelete(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => renderOnEditPage(row.id)}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Status",
      sortable: true,
      filterable: true,
      cell: (row) => (
        <span>
          <label className="switch">
            <input
              onClick={(e) => updateStatusOfAdvertisement(row.id, e)}
              checked={check[`event${row.id}`]}
              name={`event${row.id}`}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </span>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];



  const columns2 = [
    {
      name: "S. No.",
      selector: (row, i) =>  i + 1,
      sortable: true,
      filterable: true,
    },
    {
      name: "Event Id",
      selector: (row) => row.eventId,
      sortable: true,
      filterable: true,
    },
    {
      name: "Away Team",
      selector: (row) => row?.awayNameData?.partnerName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Home Team",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{row?.homeNameData?.partnerName}</span>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
        
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    
  ];


  const columnsDelete = [
    {
      name: "S. No.",
      selector: (row, i) =>  i + 1,
      sortable: true,
      filterable: true,
    },
    {
      name: "Event Id",
      selector: (row) => row.eventId,
      sortable: true,
      filterable: true,
    },
    {
      name: "Away Team",
      selector: (row) => row?.awayNameData?.partnerName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Home Team",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{row?.homeNameData?.partnerName}</span>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Reason",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{row?.deleteReason}</span>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
        
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    
  ];


  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    padding: 0px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    showArchive ? fetchData2(filterText) :fetchData(filterText) ;
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>{showArchive && "Archive" || showDeleted && "Deleted"} Events</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right">
                {" "}
              {(!showDeleted && !showArchive)  &&  <Link to="/admin/create-event">
                  {" "}
                  <button type="button" className="btn btn-primary">
                    Add
                  </button>
                </Link>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4"></div>

                <div className="table-responsive">
                <div className={`mb-2 d-flex align-items-baseline justify-content-between`}>
               {!showDeleted && <button onClick={()=>{setShowArchive(!showArchive);setFilterText('')}} type="button" class="btn btn-primary">{showArchive ? "Back" : "Archive"}</button>}
                 
                 {!showArchive && <button onClick={()=>setDeleted(!showDeleted)} type="button" class="btn btn-primary">{showDeleted? "Back" : "Deleted Events"}</button>}
                 {!showDeleted && <div className="d-flex">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder={`${!showArchive ? "Filter Events" : "Filter Archive"}`}
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>}
                  </div>
                  {   showDeleted ? 
                   <DataTable
                    columns={columnsDelete}
                    data={getDelete?.data}
                    pagination
                    // paginationServer
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    persistTableHead
                  /> : !showArchive ? <DataTable
                    columns={columns}
                    data={showEvents.data}
                    pagination
                    paginationServer
                    paginationTotalRows={showEvents.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage(page)}
                    persistTableHead
                  /> 
                  :
                    <DataTable
                    columns={columns2}
                    data={getArchive.data}
                    pagination
                    paginationServer
                    paginationTotalRows={getArchive.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage2(page)}
                    persistTableHead
                  />}
                  {/* <BasicTable

                  data={showEvents}

                /> */}
                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      // dangerBtnBsStyle="primary"
                      title="Are you sure?"
                      onConfirm={deleteAdvertisementByClick}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                      disabled={!reason}
                    >
                        <textarea
                        type="text"
                        className="form-control mt-3"
                        placeholder="Enter valid reason why you deleting?"
                        onChange={(e)=>setReason(e.target.value)}
                      />
 
                    </SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="space-foot"></div>

          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default Event;
