import { useEffect } from 'react'
import {Link} from 'react-router-dom'
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import PreviewAdvertisement from '../preview-advertisement/preview-advertisement';
import './privacypolicy.css';



export default function PrivacyPolicy(){
    useEffect(()=>window.scrollTo(0, 0),[])
    return (
        <>
        <UserHeader/>
         <section className="bredcum">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                <h1>Terms &amp; Conditions</h1>
                <Link to="/">Home</Link> <span> <i className="fa fa-angle-right" /> </span> <Link to="#" className="active"> Terms &amp; Conditions </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="heading-sec mb-5">
                <h2>LEGAL NOTICE - KIBU® TERMS OF USE</h2>
                <h4 className="pb-3">BEFORE USING, READ CAREFULLY - THESE TERMS OF USE AFFECT YOUR LEGAL RIGHTS AND
                  OBLIGATIONS.
                </h4>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <p>KIBU® (KiBu Gaming Corporation) is a for-profit company registered in the State of Nevada. An additional
                business location is registered in the State of Arizona. </p>
              <h5> INTRODUCTION</h5>
              <p>At KIBU (“KIBU”, “we”, “us”, “our”, or the “Company”), the TERMS OF SERVICE applies to websites owned and
                operated by KIBU, including, but not limited to, <a href="http://kibugaming.com/" target="blank">www.kibugaming.com</a>,
                <a href="http://carrejoue.com/" target="blank"> www.carrejoue.com</a> and <a href="http://www.squareplay.games/" target="blank">www.squareplay.games</a>. Throughout this document,
                this website and affiliated websites with links to this
                policy will be collectively referred to as the “Websites”. Services provided by or enabled via the
                Websites will be referred to as the “Services”.
              </p>
              <p>KIBU is not responsible for the content or Terms of Service of non-KIBU websites to which this website,
                or any other affiliated website, may link. Also, KIBU is not responsible for the Terms of Service of our
                Partners’, Advertisers’ and Customers’ or Clients’ websites. You should review any additional privacy
                policy and terms of use agreements before using a Partner’s, Advertiser’s, Customer’s or Client’s website.
              </p>
              <p>These Terms of Use are intended to be in addition to the End User License Agreement (found here: <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">https://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula</a>) and to the extent any of these
                Terms of Use conflict with the End User License Agreement, these Terms of Use shall be deemed to apply and
                the conflicting provision in the End User License Agreement shall not be applicable. Any reference to the
                Websites in these Terms of Use shall also be deemed to include any Mobile Application.</p>
              <h5>USER AGREEMENT</h5>
              <p>KIBU owns and operates the Websites that link to these Terms of Use. We are pleased to offer you access
                to our Websites and the ability to participate in our games and utilize other products and services
                provided from our Websites, subject to these Terms of Use (the "Terms of Use", or "Terms"), our Privacy
                Policy (the "Privacy Policy") and the Game Rules (the "Game Rules" or "Rules") for the applicable games
                and promotions. Collectively, using the Websites, you are subject to the Game Rules together with the
                Terms of Use and the Privacy Policy (the "Agreements").</p>
              <p>By logging in and using our Websites, you consent to the data practices described in the Privacy Policy
                (found here: <a href="https://www.kibugaming.com/privacypolicy">https://www.kibugaming.com/privacypolicy</a>). If you do
                not agree with the data practices described in the Privacy Policy, you should not use our Websites.</p>
              <p>By logging in and using our Websites, you consent to be bound by the General Rules, Group Rules, Event
                Type Rules, Key Points and outlines defined in the Definitions and Locations (the “Game Rules” or
                “Rules”). The Game Rules for “Free Games” are found here <a href="https://www.squareplay.games/gamerules">https://www.squareplay.games/gamerules</a>, and for
                “Payout Games” here <a href="https://www.carrejoue.com/gamerules">https://www.carrejoue.com/gamerules</a>.
                If you do not agree with the conditions described in the Game Rules, you should not use our Websites.</p>
              <p>You agree to these Terms of Use by accessing or using the Websites, registering for Services offered on
                the Websites, or by accepting, uploading, submitting or downloading any information or content from or to
                the Websites. IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THESE TERMS OF USE, DO NOT USE THE WEBSITES. These
                Terms of Use constitute a legal agreement between you and KIBU, and shall apply to your use of the
                Websites and the Services even after termination.</p>
              <h5>FOR CONTENT PROVIDERS / OPTIMIZATION TOOLS</h5>
              <p>KIBU reserves the right to enforce its Terms of Use if KIBU, in its sole discretion, determines that the
                services provided by a content provider's platform (including but not limited to websites, mobile
                applications, or social media accounts) is detrimental to the Company brand or brands, including, without
                limitation, in any manner that is disparaging or that otherwise portrays KIBU in a negative light, or has
                a negative impact on the integrity of KIBU's products, services or business operations.</p>
              <h5>DISCLAIMER OF WARRANTIES</h5>
              <p>THE WEBSITES, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, SOFTWARE, AND FUNCTIONS MADE AVAILABLE ON OR
                ACCESSED THROUGH OR SENT FROM THE WEBSITES, ARE PROVIDED "AS IS," "AS AVAILABLE," AND "WITH ALL FAULTS."
                TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THE COMPANY AND ITS PARENTS, SUBSIDIARIES AND AFFILIATES MAKE NO
                REPRESENTATION OR WARRANTIES OR ENDORSEMENTS OF ANY KIND WHATSOEVER (EXPRESS OR IMPLIED) ABOUT: (A) THE
                WEBSITES; (B) THE CONTENT AND SOFTWARE ON AND PROVIDED THROUGH THE WEBSITES; (C) THE FUNCTIONS MADE
                ACCESSIBLE ON OR ACCESSED THROUGH THE WEBSITES; (D) THE MESSAGES AND INFORMATION SENT FROM THE WEBSITES BY
                USERS; (E) ANY PRODUCTS OR SERVICES OFFERED VIA THE WEBSITES OR HYPERTEXT LINKS TO THIRD PARTIES; AND/OR
                (F) SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE WEBSITES OR ANY LINKED
                SITE. THE COMPANY DOES NOT WARRANT THAT THE WEBSITES, ANY OF THE WEBSITES' FUNCTIONS OR ANY CONTENT
                CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE
                WEBSITES OR THE SERVERS THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
              <p>THE COMPANY DOES NOT WARRANT THAT YOUR ACTIVITIES OR USE OF THE WEBSITES IS LAWFUL IN ANY PARTICULAR
                JURISDICTION AND, IN ANY EVENT, THE COMPANY SPECIFICALLY DISCLAIMS SUCH WARRANTIES. YOU UNDERSTAND THAT BY
                USING ANY OF THE FEATURES OF THE WEBSITES, YOU ACT AT YOUR OWN RISK, AND YOU REPRESENT AND WARRANT THAT
                YOUR ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR USE THE WEBSITES OR THE CONTENT
                THEREON. FURTHER, THE COMPANY AND ITS PARENTS, SUBSIDIARIES AND AFFILIATES DISCLAIM ANY EXPRESS OR IMPLIED
                WARRANTIES INCLUDING, WITHOUT LIMITATION, NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND TITLE.</p>
              <p>THE COMPANY, ITS PARENTS, SUBSIDIARIES AND AFFILIATES, AND THE DIRECTORS, OFFICERS, EMPLOYEES, AND OTHER
                REPRESENTATIVES OF EACH OF THEM, SHALL NOT BE LIABLE FOR THE USE OF THE WEBSITES INCLUDING, WITHOUT
                LIMITATION, THE CONTENT AND ANY ERRORS CONTAINED THEREIN. SOME JURISDICTIONS LIMIT OR DO NOT ALLOW THE
                DISCLAIMER OF IMPLIED OR OTHER WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT SUCH
                JURISDICTION'S LAW IS APPLICABLE TO THIS AGREEMENT.</p>
              <h5>LIMITATION OF LIABILITY</h5>
              <p>YOU UNDERSTAND AND AGREE THAT THE COMPANY LIMITS ITS LIABILITY IN CONNECTION WITH YOUR USE OF THE
                WEBSITES AS SET FORTH BELOW: UNDER NO CIRCUMSTANCES SHALL THE COMPANY, ITS PARENTS, SUBSIDIARIES, OR
                AFFILIATES, OR THE DIRECTORS, OFFICERS, EMPLOYEES, OR OTHER REPRESENTATIVES OF EACH OF THEM (COLLECTIVELY,
                THE "COMPANY ENTITIES AND INDIVIDUALS"), BE LIABLE TO YOU FOR ANY LOSS OR DAMAGES OF ANY KIND (INCLUDING,
                WITHOUT LIMITATION, FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, ECONOMIC, PUNITIVE, OR
                CONSEQUENTIAL DAMAGES) THAT ARE DIRECTLY OR INDIRECTLY RELATED TO (1) THE WEBSITES, THE CONTENT, OR YOUR
                UPLOAD INFORMATION; (2) THE USE OF, INABILITY TO USE, OR PERFORMANCE OF THE WEBSITES; (3) ANY ACTION TAKEN
                IN CONNECTION WITH AN INVESTIGATION BY THE COMPANY OR LAW ENFORCEMENT AUTHORITIES REGARDING YOUR USE OF
                THE WEBSITES OR CONTENT THEREON;(4) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OWNERS; OR (5) ANY
                ERRORS OR OMISSIONS IN THE WEBSITES’ TECHNICAL OPERATION, EVEN IF FORESEEABLE OR EVEN IF THE COMPANY
                ENTITIES AND INDIVIDUALS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES WHETHER IN AN ACTION OF
                CONTRACT, NEGLIGENCE, STRICT LIABILITY TORT (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED IN WHOLE OR IN
                PART BY NEGLIGENCE, ACTS OF GOD, TELECOMMUNICATIONS FAILURE, OR THEFT OR DESTRUCTION OF THE WEBSITES). IN
                NO EVENT WILL THE COMPANY ENTITIES AND INDIVIDUALS BE LIABLE TO YOU OR ANYONE ELSE FOR LOSS OR INJURY,
                INCLUDING, WITHOUT LIMITATION, DEATH OR PERSONAL INJURY. SOME STATES DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO
                YOU. IN NO EVENT SHALL THE COMPANY ENTITIES AND INDIVIDUALS TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
                LOSSES, OR CAUSES OF ACTION EXCEED ONE HUNDRED DOLLARS ($100). THE COMPANY ENTITIES AND INDIVIDUALS ARE
                NOT RESPONSIBLE FOR ANY DAMAGE TO ANY USER'S COMPUTER, HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR
                TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS,
                TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE
                OR NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION. YOUR ACCESS TO AND USE OF THIS WEBSITES IS
                AT YOUR RISK. IF YOU ARE DISSATISFIED WITH THE WEBSITES OR ANY OF THE CONTENT, YOUR SOLE AND EXCLUSIVE
                REMEDY IS TO DISCONTINUE ACCESSING AND USING THE WEBSITES OR THE CONTENT. YOU RECOGNIZE AND CONFIRM THAT
                IN THE EVENT YOU INCUR ANY DAMAGES, LOSSES OR INJURIES THAT ARISE OUT OF THE COMPANY'S ACTS OR OMISSIONS,
                THE DAMAGES, IF ANY, CAUSED TO YOU ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION
                PREVENTING ANY EXPLOITATION OF ANY WEBSITES OR OTHER PROPERTY OWNED OR CONTROLLED BY THE COMPANY AND/OR
                ITS PARENTS, SUBSIDIARIES, AND/OR AFFILIATES OR YOUR UPLOAD INFORMATION, AND YOU WILL HAVE NO RIGHTS TO
                ENJOIN OR RESTRAIN THE DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING, EXHIBITION OR EXPLOITATION OF
                ANY COMPANY WEBSITE OR OTHER PROPERTY OR YOUR UPLOAD INFORMATION OR ANY AND ALL ACTIVITIES OR ACTIONS
                RELATED THERETO. BY ACCESSING THE WEBSITES, YOU UNDERSTAND THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO
                CLAIMS THAT ARE AT THIS TIME UNKNOWN OR UNSUSPECTED. ACCORDINGLY, YOU AGREE TO WAIVE THE BENEFIT OF ANY
                LAW, INCLUDING, TO THE EXTENT APPLICABLE, CALIFORNIA CIVIL CODE SECTION 1542, THAT OTHERWISE MIGHT LIMIT
                YOUR WAIVER OF SUCH CLAIMS.</p>
              <h5>ELIGIBILITY – PRIZE (“FREE”) GAMES</h5>
              <p>You must be at least eighteen (18) years of age to open an account, participate in free
                advertising/promotional games or win prizes offered by the Websites. </p>
              <h5>ELIGIBILITY – CASH (“PAYOUT”) GAMES</h5>
              <p>In jurisdictions, territories, and locations where the minimum age for permissible use of the Websites is
                greater than eighteen (18) years old, you must meet the age requirement for gaming within the
                jurisdictions the GROUP is organized.
              </p>
              <p>You agree to abide by all applicable laws in the jurisdiction where you are located while using the
                Websites.</p>
              <h5>ACCOUNT OWNER</h5>
              <p>You may establish only one account per person to participate in the Services offered on the Websites. In
                the event KIBU discovers that you have opened more than one account per person, in addition to any other
                rights that KIBU may have, KIBU reserves the right to suspend or terminate any or all of your accounts and
                terminate, withhold or revoke the awarding of any prizes. You are responsible for maintaining the
                confidentiality of your login names and passwords and you accept responsibility for all activities,
                charges, and damages that occur under your account. </p>
              <p>It shall be a violation of these Terms of Use to allow any other person to use your account to
                participate in any game. You are responsible for ensuring you use strong and secure passwords, and you may
                be required to change your passwords from time to time. If you have reason to believe that someone is
                using your account without your permission, you should contact us immediately. We will not be responsible
                for any loss or damage resulting from your failure to notify us of unauthorized use. If we request
                registration information from you, you must provide us with accurate and complete information and must
                update the information when it changes.</p>
              <p>"Authorized Account Holder" is defined as the natural person 18 years of age or older who is assigned to
                an e-mail address by an Internet access provider, on-line service provider, or other organization (e.g.,
                business, education institution, etc.) that is responsible for assigning e-mail addresses for the domain
                associated with the submitted e-mail address for registration on the Websites. By inputting a payment
                method to participate in real money “PAYOUT” games, the Authorized Account Holder hereby affirms that the
                Authorized Account Holder is the lawful owner of the payment method account used to make any deposit(s) on
                the Websites. It shall be a violation of these Terms of Use for any Authorized Account Holder to submit
                payment using any payment method that is not owned by the Authorized Account Holder.</p>
              <p>If any deposit is charged back, any winnings generated from KIBU games shall be invalidated, forfeited
                and deducted from your Account balance. In addition, the amount of the initial deposit will be
                invalidated, forfeited and deducted from the Account balance. KIBU reserves the right to close, limit or
                otherwise restrict your account - without notice - shall a deposit be charged back.</p>
              <p>To be eligible to enter any game or receive any prize, the Authorized Account Holder may be required to
                provide KIBU with additional documentation and/or information to verify the identity of the Authorized
                Account Holder, and to provide proof that all eligibility requirements are met. In the event of a dispute
                as to the identity or eligibility of an Authorized Account Holder, KIBU will, in its sole and absolute
                discretion, utilize certain information collected by KIBU to assist in verifying the identity and/or
                eligibility of such Authorized Account Holder.</p>
              <p>KIBU employees ("Employees"), employees in “Affiliated Companies” and Immediate Family Members, including
                but not limited to domestic partner and relative of the employee who resides at an employee's residence,
                such as parents, grandparents, in-laws, children, siblings, and spouses, are not permitted to play in any
                public games hosted on the Websites. However, Employees, employees of “Affiliated Companies” and Immediate
                Family Members are permitted to play in the following games on the Websites: a KIBU or “Affiliated
                Company”-sponsored Private Game, Private Games for cash, or Private Games for prizes other than cash. A
                "Private Game" is one that is not listed in the website public domain, and may be hosted by KIBU or an
                “Affiliated Company,” by an Employee thereof, or a relative, friend of the Employee or Immediate Family
                Member of an Employee.</p>
              <p>Professional or amateur athletes, sports agents, coaches, team owners, team employees, referees or league
                officials, and their immediate family members, are not eligible to participate in, and are strictly
                prohibited from entering, any KIBU games in the sport in which they are associated.</p>
              <h5>GAME ENTRY AND PAYMENTS</h5>
              <p>Users will be able to visit the Websites and view the games that may be available for entry (the
                "Games"). The purchase cost of a game card can be determined by examining the Game Rules and the cost of
                cards as discussed in the rules. Card costs are calculated and posted in US dollars. The minimum card cost
                for Games that are not free to play is two dollars ($2.00), and the maximum card cost may be set by law,
                the group rules, the rules of the game, limitations of the software or any other limitation KIBU may set.
                When you select to participate in a Game and complete the entry process, the listed amount of US dollars
                will be debited from your KIBU account or require payment in an authorized manner. The maximum number of
                game cards an Authorized Account Holder may obtain for a particular Game is listed in the Group Rules.</p>
              <p>Information about responsible gaming, including self-exclusion and self-limitation options, is available
                at: <a href="https://www.kibugaming.com/responsible-gaming">https://www.kibugaming.com/responsible-gaming</a>.
                If you self-exclude, you will be removed from any
                upcoming games as set forth in the self-exclusion procedures available at:
                <a href="https://www.kibugaming.com/account/selfexclusion">https://www.kibugaming.com/account/selfexclusion</a>,
                and any entry fees for such upcoming games you are
                removed from will be refunded to you. Self-exclusion will also preclude, at present, you from joining free
                games operated by KIBU for advertising and promotional purposes.
              </p>
              <h5>REFUND POLICY</h5>
              <p>KIBU accepts all major debit cards and Pay Pal, and, depending on the jurisdiction you are in, KIBU may
                also accept major credit cards (including Visa, MasterCard, Discover, and American Express) and gift cards
                (including KIBU specific cards, Visa, MasterCard, and American Express). Deposits made on the Websites
                will appear on your statement as KIBU. All payments are final. No refunds will be issued. In the event of
                a dispute regarding the identity of the person obtaining a game card, the game card will be deemed
                obtained by the person in whose name the account was registered.</p>
              <p>Customers depositing funds using a credit card in some states may see a charge on their credit card
                statement labeled "international transaction fee" or similar description. The company processing credit
                card payments for KIBU in certain states may operate outside the United States and may impose fees for
                international transactions.</p>
              <p>If you are charged this international transaction fee to deposit funds into your KIBU account, you may
                contact KIBU at <a href="mailto:support@kibugaming.com">support@kibugaming.com</a> and request a full
                reimbursement of the international transaction fee. To process your reimbursement request, KIBU will need
                the following information: (i) original transaction date and amount deposited; (ii) the amount(s) of the
                fee(s) assessed on such deposited amount; (iii) date(s) such fee(s) was/were assessed; and (iv) a copy of
                your credit card statement showing such fee(s). KIBU reserves the right to request additional information
                to process your request. KIBU will review your submission and if KIBU determines that you have been
                charged the international transaction fee as a result of depositing funds into your KIBU account, KIBU
                will credit such amount back to your Account.</p>
              <p>Accounts may be deemed dormant or inactive, and any remaining funds held in a dormant or inactive account
                will be treated, in each case, in accordance with applicable state laws and regulations.</p>
              <p>Players can obtain a copy of their Account statements by emailing customer service at
                <a href="mailto:support@kibugaming.com">support@kibugaming.com</a>.
              </p>
              <h5>PLAYER RESERVE AND WITHDRAWALS</h5>
              <p>Customer deposits, player winnings after games are finished and the payout pool of the unfinished games
                are held in separate, segregated bank accounts (the "Segregated Account"). Vendors, players, and creditors
                shall be aware that the funds in the Segregated Account are not available to creditors of KiBu Gaming
                Corporation, except to a player in the amount said player has funds held in such account. KIBU, Inc. is
                prohibited from comingling other funds held by KIBU, Inc. and funds belonging to the Segregated Account.
              </p>
              <p>KiBu Gaming Corporation will take appropriate steps to prevent unauthorized withdrawals from consumer
                accounts. Users may be subject to an automated Knowledge Base Authentication ("KBA") verification process
                provided whenever a user attempts to withdraw funds. This process asks a series of at least four multiple
                choice questions about the person's life, such as known relatives, previous addresses, and to enter their
                last 4 digits of their Tax ID or Social Security Number. In the event of a dispute as to the identity of a
                customer, KIBU can and does require its users to produce a copy of their driver's license, credit cards,
                utility bills or passport for identity verification. If a customer cannot validate their identity and
                account, KIBU will not release the funds from the account. Once per month, KIBU shall reconcile all
                liabilities owed to players and the sum of funds or credits in the Segregated Account, the PayPal Account,
                and credits due to KIBU from credit card processors as of the end date of the immediately preceding month.
              </p>
              <p>Players who believe that funds held by or in their accounts with KIBU have been misallocated, compromised
                or otherwise mishandled, may register a complaint with KIBU online by e-mailing <a href="mailto:support@kibugaming.com">support@kibugaming.com</a> or
                in writing to 1517 W. 7th Street, Mesa, Arizona 85201, USA. KIBU shall use its best efforts to respond to
                such complaints within ten (10) days. If KIBU determines that the relief requested in the complaint will
                not be granted, its response will set forth the specific reasons therefore. If more information is
                required for KIBU to process the complaint, the response will note the form and nature of the necessary
                additional information needed.</p>
              <p>A player can request to withdraw funds from their account at any time. Requests for withdrawal will be
                honored by the later of five (5) business days or ten (10) business days of submission of any tax
                reporting paperwork required by law, unless KIBU believes in good faith that the player engaged in either
                fraudulent conduct or other conduct that would put KIBU in violation of Nevada state law. A request for
                withdrawal will be considered honored if it is processed by KIBU but delayed by a payment processor,
                credit card issuer, or by the custodian of a financial account. Players can also permanently close their
                account at any time for any reason through the KIBU customer service department.</p>
              <h5>CONDITIONS OF PARTICIPATION</h5>
              <p>By entering a Game, entrants agree to be bound by these Terms of Service, the Privacy Policy and the Game
                Rules set forth on the Websites and the decisions of KIBU, which shall be final and binding in all
                respects. The Company, at its sole discretion, may disqualify any entrant from a Game, refuse to award
                benefits or prizes and require the return of any prizes, if the entrant engages in conduct or otherwise
                utilizes any information the Company deems to be improper, unfair or otherwise adverse to the operation of
                the Game or is in any way detrimental to other entrants. These Terms prohibit entering a Game if the
                entrant is:</p>
              <ul className="list-a">
                <li> Except as otherwise stated in the Account Owner section above, an Employee of the Company or an
                  immediate family member of such employee;</li>
                <li> An employee, operator or consultant to a sports governing body where such employee, operator or
                  consultant is prohibited from participating in applicable Games by such governing body;</li>
                <li> Breaches any rules or policies of the entrant's employer, of any sports governing body, or any other
                  professional body of which the entrant is a member, including any rule or policy regarding participation
                  in Games or accepting prize money;</li>
                <li> Any person prohibited from participating pursuant to court order;</li>
              </ul>
              <p className="mt-1">In addition, conduct that would be deemed improper also includes, but is not limited to:</p>
              <ul className="list-a">
                <li> Falsifying personal information required to enter a Game or claim a prize;</li>
                <li> Engaging in any type of financial fraud including unauthorized use of credit instruments to enter a
                  Game or claim a prize;</li>
                <li> Colluding with any other individual(s) or engaging in any type of syndicate play;</li>
                <li> Any violation of the Game Rules or the Terms of Use;</li>
                <li> Collaborating with others to participate in any Game;</li>
                <li> Using automated means (including but not limited to scripts and third-party tools) to interact with
                  the Websites in any way (this includes, but is not limited to: creating a game, entering a game,
                  withdrawing from a game, creating an account, and editing an account);</li>
                <li> Using automated means (including but not limited to harvesting bots, robots, parser, spiders or
                  screen scrapers) to obtain, collect or access any information on the Websites or of any User for any
                  purpose.</li>
                <li> Any type of bonus abuse, abuse of the refer-a-friend program, or abuse of any other offers or
                  promotions;</li>
                <li> Tampering with the administration of a Game or trying to in any way tamper with the computer programs
                  or any security measure associated with a Game;</li>
                <li> Obtaining other entrants information and spamming other entrants; or</li>
                <li> Abusing the Websites or Users thereof in any way.</li>
              </ul>
              <p>Users further acknowledge that the forfeiture and/or return of any prize shall in no way prevent KIBU
                from pursuing criminal or civil proceedings in connection with such conduct.</p>
              <p>By entering into a Game or accepting any prize, entrants, including but not limited to the winner(s),
                agree to indemnify, release and to hold harmless KIBU, its parents, subsidiaries, affiliates and agents,
                as well as the officers, directors, employees, shareholders and representatives of any of the foregoing
                entities (collectively, the "Released Parties"), from any and all liability, claims or actions of any kind
                whatsoever, including but not limited to injuries, damages, or losses to persons and property which may be
                sustained in connection with participation in the Game, the receipt, ownership, use or misuse of any prize
                or while preparing for, participating in and/or travelling to or from any prize related activity, as well
                as any claims based on publicity rights, defamation, or invasion of privacy. KIBU may, in its sole and
                absolute discretion, require an Authorized Account Holder to execute a separate written release of claims
                similar to the one listed above in this Paragraph as a condition of being awarded any prize or receiving
                any payout.</p>
              <p>KIBU is not responsible for: any incorrect, invalid or inaccurate entry information; human errors; postal
                delays/postage due mail; technical malfunctions; failures, including public utility or telephone outages;
                omissions, interruptions, deletions or defects of any telephone system or network, computer online
                systems, data, computer equipment, servers, providers, or software (including, but not limited to software
                and operating systems that do not permit an entrant to participate in a Game), including without
                limitation any injury or damage to any entrant's or any other person's computer or video equipment
                relating to or resulting from participation in a Game; inability to access the Websites, or any web pages
                that are part of or related to the Websites; theft, tampering, destruction, or unauthorized access to, or
                alteration of, entries and/or images of any kind; data that is processed late or incorrectly or is
                incomplete or lost due to telephone, postal issues, computer or electronic malfunction or traffic
                congestion on telephone lines or transmission systems, or the Internet, or any service provider's
                facilities, or any phone site or website or for any other reason whatsoever; typographical, printing or
                other errors, or any combination thereof.</p>
              <p>KIBU is not responsible for incomplete, illegible, misdirected or stolen entries. If for any reason a
                Game is not capable of running as originally planned, or if a Game, computer application, or website
                associated therewith (or any portion thereof) becomes corrupted or does not allow the proper entry to a
                Game in accordance with the Terms of Use or applicable Official Rules, or if infection by a computer (or
                similar) virus, bug, tampering, unauthorized intervention, actions by entrants, fraud, technical failures,
                or any other causes of any kind, in the sole opinion of KIBU corrupts or affects the administration,
                security, fairness, integrity, or proper conduct of a Game, the Company reserves the right, at its sole
                discretion, to disqualify any individual implicated in such action and/or to cancel, terminate, extend,
                modify or suspend the Game, and select the winner(s) from all eligible entries received, cancel all prizes
                for the Game and/or refund all monies entered into the Game. If such cancellation, termination,
                modification or suspension occurs, notification will be posted on the Websites.</p>
              <p>Any attempt by an entrant or any other individual to deliberately damage the websites or undermine the
                legitimate operation of any game is a violation of criminal and/or civil laws and should such an attempt
                be made, KIBU reserves the right to seek damages and other remedies from any such person to the fullest
                extent permitted by law.</p>
              <p>All game cards become the property of KIBU and will not be acknowledged or returned.</p>
              <p>Where legal, both entrants and winners consent to the use of their name, voice, and likeness/photograph
                in and in connection with the development, production, distribution and/or exploitation of any Game or the
                Websites. Winners agree that from the date of notification by KIBU of their status as a potential winner
                and continuing until such time when KIBU informs them that they no longer need to do so, they will make
                themselves available to KIBU for publicity, advertising, and promotion activities.</p>
              <p>KIBU reserves the right to move entrants from the Games they have entered to substantially similar Games
                in certain situations determined by KIBU in its sole discretion.</p>
              <h5>GAME PRIZES AND PROMOTIONS</h5>
              <p>Prizes are non-cash in nature, often promotions from our sponsoring advertiser. Payouts are “cash
                prizes”. Prizes and Payouts will only be awarded if a Game is considered final. We reserve the right to
                cancel Games at any time. In the event of a cancellation, all entry fees will be refunded to the customer
                except as specifically provided in these Terms of Use.</p>
              <p>To be eligible to enter any game or receive any prize or payout, the Authorized Account Holder may be
                required to provide KIBU with additional documentation and/or information to verify the identity of the
                Authorized Account Holder, and to provide proof that all eligibility requirements are met. In the event of
                a dispute as to the identity or eligibility of an Authorized Account Holder, KIBU will, in its sole and
                absolute discretion, utilize certain information collected by KIBU to assist in verifying the identity
                and/or eligibility of such Authorized Account Holder.</p>
              <p>Guaranteed prizes and payouts are offered in connection with some of the Games. Each Game or promotion is
                governed by its own set of Game Rules. We encourage you to read such General, Group, Event Type Rules and
                Key Points before participating.</p>
              <p>KIBU is not responsible for any travel, personal expenses, damages, assessments, costs of delivery or
                taxes incurred by the claimant when, or as a result of, collecting the prizes or payouts. Certain
                government agencies may require KIBU file forms disclosing the claimant, the date and time of the Game,
                the amount wagered and the “Fair Market Value” of prizes and payouts won.</p>
              <h5>PRIZES (NON-CASH)</h5>
              <p>At the conclusion of each Game, prizes will be awarded by 12:00 NOON EST on the following day except in
                circumstances where technical failure, the Company is unable to verify your compliance with these Terms or
                other reasons prevent such timely award. Prizes are listed in a Prize Table in the Groups page. Prizes won
                for a Game are accessible from your account as a coupon or claimant information under a Results page which
                lists the Total Game Cards played, Total Winners, Winners’ List, in some instances, and a Call Summary for
                the Game. The claimant may have to comply with additional procedures to obtain their prizes.</p>
              <p>KIBU’s liability for any Game will not exceed the prizes listed in the prize table list in accordance
                with the prize distribution rules of the Group for that game. In the event, several Groups vie for the
                same prizes, KIBU’s liability will not exceed the liability of the prizes set forth in the most extensive
                prize table list amongst the Groups.</p>
              <h5>PAYOUTS (CASH)</h5>
              <p>At the conclusion of each Game, payouts will be awarded by 12:00 NOON EST on the following day except in
                circumstances where technical failure, the Company is unable to verify your compliance with these Terms or
                other reasons prevent such timely payout. Average Payouts for the Event Type are listed in a Payout Table
                accessible from the Groups page. Final payouts for a Game are accessible from your account in a Payout
                Table under the Results page which lists the Total Game Cards Played, Total Winners, Total Payout Pool,
                Winners’ List, in some instances, and a Call Summary for the Game. Payouts are often calculated live
                during the live event and such live calculations are anticipated to change until the conclusion of the
                event. Payouts won are added to the winning participants account balance or notification made and
                participant may have to comply with additional procedures to obtain their payouts. Payouts may sometimes
                be redeemed at kiosks, with casino sports wagering personnel or other customer service representatives.
              </p>
              <p>Payouts and the calculation of payouts of each Group are governed by different sets of Game Rules. We
                encourage you to read all applicable Game Rules.</p>
              <p>KIBU’s payout liability for any Game will not exceed the carryovers brought forward by the current Event
                teams to the Group plus the current entry fees, less the management fees, collected for the current Event
                connected to the instance of a Carrejoue Game.</p>
              <h5>AWARD AND WITHDRAWAL OF PRIZES</h5>
              <p>Winners may be posted on the Websites by first name, last initial and their state of residence.</p>
              <p>Before making any award, KIBU may require that an entrant complete and execute an affidavit of
                eligibility in which, among other things, the entrant is required to represent and warrant that the
                entrant is eligible to participate in a Game, is otherwise in compliance with this Agreement and,
                potentially, is required to provide additional documentation, proof of eligibility and compliance. If KIBU
                requests that an entrant completes and executes such an affidavit and the entrant fails to do so within
                seven (7) days, or KIBU otherwise determines that the entrant does not meet the eligibility requirements
                or is not in compliance with these Terms, KIBU reserves the right to terminate the entrant's account and
                withhold or revoke the awarding of any prizes associated with such account. In such a situation, KIBU may
                pay out any withheld or revoked prizes to the other entrants in the relevant game in a manner consistent
                with the Rules of the Game.</p>
              <p>Entrants may be requested to complete a liability/publicity release (unless prohibited by law) and/or
                appropriate tax forms or provide forms of identification including but not limited to a Driver's License,
                Proof of Residence, and/or any information relating to payment/deposit accounts as reasonably requested by
                KIBU in order to complete the withdrawal of prizes. Failure to comply with this requirement may result in
                disqualification and forfeiture of any prizes. Disqualification or forfeiture of any prizes may also occur
                if it is determined any such entrant did not comply with these Terms of Use in any manner.</p>
              <p>Promotional deposits, credits, and other bonuses may not be withdrawn unless appropriate terms of the
                promotion are achieved first by the user.</p>
              <p>All taxes associated with the receipt of any prize are the sole responsibility of the winner. In the
                event that the awarding of any prizes to winners of Games is challenged by any legal authority, KIBU
                reserves the right in its sole discretion to determine whether or not to award such prizes.</p>
              <p>No substitution or transfer of prize is permitted, except that KIBU reserves the right to substitute a
                prize of equal value or greater if the advertised prize is unavailable. All prizes are awarded "as is" and
                without warranty of any kind, express or implied (including, without limitation, any implied warranty of
                merchantability for a particular purpose).</p>
              <p>Any withdrawal requests to a credit or debit card, after approved by KIBU, will be credited back to the
                same credit card or method of payment used to deposit funds on the Websites. KIBU will only release
                withdrawals to a different credit card or other payment method other than that which was used to make
                deposit(s) after the aggregate amount of such deposit(s) has already been released back to the credit
                card(s) or payment method(s) used for the deposit(s).</p>
              <p>Other withdrawal methods available after satisfying the requirements of the previous paragraph include
                check, cashier’s check, direct deposit or funds transfer. You may request more information and the
                additional cost of such methods by emailing <a href="mailto:support@kibugaming.com">support@kibugaming.com</a>. Any communications between you and
                KIBU may be monitored and recorded.</p>
              <div className="heading-sec mb-4 mt-4 text-center">
                <h2>OTHER LEGAL RESTRICTIONSE</h2>
              </div>
              <h5>GAME RESULTS, LIVE SCORING and EVENT CANCELLATIONS.</h5>
              <p>To the extent that we offer 'live' statistics during game play, all 'live' statistics and other
                information provided through the KIBU Websites and related information sources are unofficial. Live sports
                statistics and their respective components are used to determine the results of our Games. While KIBU and
                the third parties used to provide the KIBU Services use reasonable efforts to include accurate and
                up-to-date information, errors are possible due to the nature of the live sporting event and its
                connection to the Games. The decisions of KIBU and its employees are final with respect to an instance of
                a Carrejoue Game, its operation, conclusions and results.</p>
              <p>Game results and prize calculations are based on the decisions made regarding the on-field occurrences of
                the live sporting event, the Game Rules and the game cards within each individual Group. Once Game results
                are reviewed and final, the prizes are awarded. Prizes are deemed final one hour after the conclusion of
                the Event. The scoring results of a Game will not be changed regardless of any official statistic or
                scoring adjustments made by the leagues at later times or dates, except in KIBU's sole discretion. </p>
              <p>Information about the effects of a postponed, official shortened or cancelled Event on a Game are
                available in the Game Rules under the Key Points tab.</p>
              <h5>GAME CANCELLATION</h5>
              <p>KIBU reserves the right, in its sole discretion, to cancel or suspend the games (or any portion thereof)
                should virus, bugs, unauthorized human intervention, or other causes corrupt administration, security,
                fairness, integrity or proper operation of the game (or any portion thereof) warrant doing so.
                Notification of such changes may be provided by KIBU to its customers but will not be required.</p>
              <h5>TERMINATION AND EFFECT OF TERMINATION</h5>
              <p>In addition to any other legal or equitable remedy, KIBU may, without prior notice, immediately revoke
                any or all of your rights granted hereunder or suspend your account. In such event, you will immediately
                cease all access to and use of the Websites. KIBU may revoke any password(s) and/or account identification
                issued to you and deny you access to and use of the Websites. Any such action shall not affect any rights
                and obligations arising prior thereto. All provisions of the Terms of Use which by their nature should
                survive termination shall survive termination, including, without limitation, ownership provisions,
                warranty disclaimers, indemnity and limitations of liability.</p>
              <h5>INTELLECTUAL PROPERTY RIGHTS</h5>
              <p>The content on the Websites, including without limitation, the text, software, scripts, graphics, photos,
                sounds, music, videos, interactive features and the like and the trademarks, service marks and logos
                contained therein (the "Intellectual Property"), are owned by or licensed to KIBU, subject to copyright
                and other intellectual property rights under United States and foreign laws and international conventions.
                Content on the Websites is provided to you AS IS for your information and personal use only and may not be
                used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise
                exploited for any other purposes whatsoever without the prior written consent of the respective owners.
                KIBU reserves all rights not expressly granted in and to the Websites and the Intellectual Property. You
                agree to not engage in the use, copying, or distribution of any of the Intellectual Property other than
                expressly permitted herein. If you download or print a copy of the Intellectual Property for personal use,
                you must retain all copyright and other proprietary notices contained therein. You agree not to
                circumvent, disable or otherwise interfere with security related features of the Websites or features that
                prevent or restrict use or copying of any Intellectual Property or enforce limitations on use of the
                Websites or the Intellectual Property therein.</p>
              <p>Some of the Services may allow you to submit or transmit audio, video, text, or other materials
                (collectively, "User Submissions") to or through the Services. When you provide User Submissions, you
                grant to KIBU, its parents, subsidiaries, affiliates, and partners a non-exclusive, worldwide,
                royalty-free, fully sub-licensable license to use, distribute, edit, display, archive, publish,
                sublicense, perform, reproduce, make available, transmit, broadcast, sell, translate, and create
                derivative works of those User Submissions, and your name, voice, likeness and other identifying
                information where part of a User Submission, in any form, media, software, or technology of any kind now
                known or developed in the future, including, without limitation, for developing, manufacturing, and
                marketing products. You hereby waive any moral rights you may have in your User Submissions.</p>
              <p>In addition, you agree that any User Submissions you submit shall not contain any material that is, in
                the sole and absolute discretion of KIBU, inappropriate, obscene, vulgar, unlawful, or otherwise
                objectionable (hereinafter, "Prohibited Content"). Posting of any Prohibited Content, in addition to any
                and all other rights and remedies available to KIBU, may result in account suspension or termination.</p>
              <p>We respect your ownership of User Submissions. If you owned a User Submission before providing it to us,
                you will continue owning it after providing it to us, subject to any rights granted in the Terms of Use
                and any access granted to others. If you delete a User Submission from the Services, our general license
                to that User Submission will end after a reasonable period of time required for the deletion to take full
                effect. However, the User Submission may still exist in our backup copies, which are not publicly
                available. If your User Submission is shared with third parties, those third parties may have retained
                copies of your User Submissions. In addition, if we made use of your User Submission before you deleted
                it, we will continue to have the right to make, duplicate, redistribute, and sublicense those pre-existing
                uses, even after you delete the User Submission. Terminating your account on a Service will not
                automatically delete your User Submissions.</p>
              <p>We may refuse or remove a User Submission without notice to you. However, we have no obligation to
                monitor User Submissions, and you agree that neither we nor our parents, subsidiaries, affiliates,
                employees, or agents will be liable for User Submissions or any loss or damage resulting from User
                Submissions.</p>
              <p>Except as provided in the Privacy Policy, we do not guarantee that User Submissions will be private, even
                if the User Submission is in a password-protected area. Accordingly, you should not provide User
                Submissions that you want protected from others.</p>
              <p>You represent and warrant that you have all rights necessary to grant to KIBU the license above and that
                none of your User Submissions are defamatory, violate any rights of third parties (including intellectual
                property rights or rights of publicity or privacy), or violate applicable law.</p>
              <h5>CONSENT TO JURISDICTION IN NEVADA, ATTORNEY'S FEES</h5>
              <p>These Terms of Use shall be governed by the internal substantive laws of the State of Nevada, without
                regard for its conflict of laws principles. Any claim or dispute between you and KIBU that arises in whole
                or in part from the Terms of Use, the Websites or any Game shall be decided exclusively by a court of
                competent jurisdiction located in Clark County, Nevada.</p>
              <p>The exclusive jurisdiction and venue for proceedings involving any and all disputes, claims or
                controversies arising out of or relating to this Agreement, the breach thereof, or any use of the Websites
                (including all commercial transactions conducted through the Websites) ("Claims") shall be the courts of
                competent jurisdiction sitting within Clark County, Nevada (the "Forum"), and the parties hereby waive any
                argument that any such court does not have personal jurisdiction or that the Forum is not appropriate or
                convenient; (b) you and KIBU waive any and all rights to trial by jury with respect to any Claims.</p>
              <p>In the event that either party initiates a proceeding involving a Claim under this Section other than in
                the Forum, the other party shall recover all attorneys' fees and expenses reasonably incurred in enforcing
                this Agreement and the Forum to which the parties have herein agreed.</p>
              <h5>MISCELLANEOUS</h5>
              <p>Nothing in the Terms of Use shall create or confer any rights or other benefits in favor of any third
                parties except as specifically provided herein. By participating in any Game on the Websites, you agree to
                indemnify, protect, defend and hold harmless KIBU, its parents, subsidiaries, affiliates and divisions,
                and their respective directors, officers, employees, agents and representatives (the "KIBU Entities"),
                from and against any and all third party claims, liabilities, losses, damages, injuries, demands, actions,
                causes of action, suits, proceedings, judgments and expenses, including reasonable attorneys' fees, court
                costs and other legal expenses including, without limitation, those costs incurred at the trial and
                appellate levels and in any bankruptcy, reorganization, insolvency or other similar proceedings, and any
                other legal expenses (collectively, "Claims") arising from or connected with your use of the Websites, any
                payment methods used, any funding of your account, and/or your participation in any Game. The Websites may
                contain links to third party websites that are not owned or controlled by KIBU. KIBU has no control over,
                and assumes no responsibility for, the content, privacy policies, or practices of any third party
                websites. In addition, KIBU will not and cannot censor or edit the content of any third-party site. By
                using the Websites, you expressly relieve KIBU from any and all liability arising from your use of any
                third-party website. Accordingly, we encourage you to be aware when you leave the Websites to read the
                terms and conditions and privacy policy of each third-party website that you visit.</p>
              <p>Nothing in the Terms of Use shall create or be deemed to create a partnership, agency, trust arrangement,
                fiduciary relationship or joint venture between you and KIBU.</p>
              <p>No professional or amateur sports league or any team associated with any professional or amateur sports
                league is associated with KIBU or in any way affiliated or associated with the Games.</p>
              <p>Third-party online publishers that refer users to the KIBU websites shall not be responsible or liable
                for the KIBU websites or any of the content, software, or functions made available on, or accessed
                through, or sent from, the KIBU websites.</p>
              <p>If any provision of these Terms of Use is deemed invalid by a court of competent jurisdiction, the
                invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of
                Use, which shall remain in full force and effect.</p>
              <p>No waiver of any term of these Terms of Use shall be deemed a further or continuing waiver of such term
                or any other term, and KIBU's failure to assert any right or provision under these Terms of Use shall not
                constitute a waiver of such right or provision.</p>
              <p>KIBU reserves the right to amend these Terms of Use at any time and without notice, and it is your
                responsibility to review these Terms of Use for any changes. If you continue to use the Services after we
                change the Terms of Use, you accept all changes. The failure of KIBU to comply with any provision of these
                Terms of Use due to an act of God, hurricane, war, fire, riot, earthquake, terrorism, act of public
                enemies, actions of governmental authorities outside of the control of the Company (excepting compliance
                with applicable codes and regulations) or other force majeure event will not be considered a breach of
                these Terms of Use</p>
              <p>KIBU AND OTHER TRADEMARKS CONTAINED ON THE WEBSITES ARE TRADEMARKS OR REGISTERED TRADEMARKS OF KIBU IN
                THE UNITED STATES AND/OR OTHER COUNTRIES. THIRD PARTY TRADEMARKS, TRADE NAMES, PRODUCT NAMES AND LOGOS MAY
                BE THE TRADEMARKS OR REGISTERED TRADEMARKS OF THEIR RESPECTIVE OWNERS. YOU MAY NOT REMOVE OR ALTER ANY
                TRADEMARK, TRADE NAMES, PRODUCT NAMES, LOGO, COPYRIGHT OR OTHER PROPRIETARY NOTICES, LEGENDS, SYMBOLS OR
                LABELS ON THE WEBSITES.</p>
              <p>You may submit a question, dispute, or complaint via the Contact Us Page
                ( <a href="https://www.kibugaming.com/help/contact-us">https://www.kibugaming.com/help/contact-us</a> ) or by emailing <a href="mailto:support@kibugaming.com">support@kibugaming.com</a> . Any communications
                between you and KIBU may be monitored and recorded.</p>

            </div>
          </div>
        </div>
      </section>

        <PreviewAdvertisement/>
        <UserFooter/>
        </>
    )
}