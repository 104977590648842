import logo from '../../../assets/images/kibu-logo.png';
import moment from "moment-timezone";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { useState } from 'react';

const UserCarousel = (data) => {
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  let history = useHistory();
  function errorCallback(error) {
    if (error.code == 1) {
      toast.error(
        "Please Enable your location from the browser else you won't be Enter the game",
        {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        }
      );
    } else if (error.code == 2) {
      toast.error(
        "The network is down or the positioning service can't be reached.",
        {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        }
      );
    } else if (error.code == 3) {
      toast.error(
        "The attempt timed out before it could get the location data.",
        {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        }
      );
    } else {
      toast.error("Geolocation failed due to unknown error.", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  }

  const navigateData = (id) => {
    // If geolocation is available, try to get the visitor's position
    if (!(localStorage.getItem("role") === "2")) {
      Swal.fire({
        icon: "warning",
        // title: "Oops...",
        text: "Please login first in order to enter event",
        confirmButtonText: "Login",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/login");
        }
      });
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }

    // Define callback function for successful attempt
    async function successCallback(position) {
      // alert("Latitude: " + position.coords.latitude + ", " +  position.coords.longitude);
      setSubmitFormLoader(true);
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
      history.push({
        pathname: `/user/associated-groups-with-events/${id}`,
      });
      setSubmitFormLoader(false);
    }
  };

  return(
    <section id="home" className="home overflow-hidden">
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to={0} className="active" />
        <li data-target="#carouselExampleIndicators" data-slide-to={1} />
        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
      </ol>
      {submitFormLoader && <div className="loader"></div>}
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="home-banner home-banner-1">
          <div className="logo-watermark">
          <img src={logo} alt="" />
           </div>
            <div className="home-banner-text">
              <h2>{data?.data?.data?.[0]?.awayNameData?.partnerName ? <> {data?.data?.data?.[0]?.awayNameData?.partnerName} <span>Vs</span> {data?.data?.data?.[0]?.homeNameData?.partnerName} </> : "Events are UpComing" }</h2>
              {/* {data?.data?.data?.[0] && <> <h3>{moment(data?.data?.data?.[0]?.eventDate).format('MMMM Do YYYY')} / {data?.data?.data?.[0]?.eventTime} </h3> */}
              {data?.data?.data?.[0] && <> <h3>{moment(data?.data?.data?.[0]?.eventDate).tz("America/New_York").format("MM-DD-YYYY")} / {data?.data?.data?.[0]?.eventTime} ET </h3>

              <button onClick={() => navigateData(data?.data?.data?.[0]?.id)} className="shop-btn ">Enter Now</button> </>}
              {/* <a href="#" className="shop-btn2 mt-4 ml-3">Read More</a> */}
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="home-banner home-banner-2">
          <div className="logo-watermark">
          <img src={logo} alt="" />
           </div>
            <div className="home-banner-text">
              <h2>{data?.data?.data?.[1]?.awayNameData?.partnerName ? <> {data?.data?.data?.[1]?.awayNameData?.partnerName} <span>Vs</span> {data?.data?.data?.[1]?.homeNameData?.partnerName} </> : "Coming Soon" } </h2>
              {data?.data?.data?.[1] && <> <h3>{moment(data?.data?.data?.[1]?.eventDate).tz("America/New_York").format("MM-DD-YYYY")} / {data?.data?.data?.[1]?.eventTime} ET</h3>
              <button onClick={() => navigateData(data?.data?.data?.[1]?.id)} className="shop-btn">Enter Now</button></>}
              {/* <a href="#" className="shop-btn2 ml-3">Read More</a> */}
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="home-banner home-banner-3">
          <div className="logo-watermark">
          <img src={logo} alt="" />
           </div>
            <div className="home-banner-text">
              <h2>{ "Coming Soon" } </h2>
              {/* {data?.data?.data?.[1] && <> <h3>{moment(data?.data?.data?.[1]?.eventDate).format('MMMM Do YYYY')} / {data?.data?.data?.[1]?.eventTime}</h3> */}
              {/* <button onClick={() => navigateData(data?.data?.data?.[1]?.id)} className="shop-btn">Enter Now</button></>} */}
              {/* <a href="#" className="shop-btn2 ml-3">Read More</a> */}
            </div>
          </div>
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true">
          <i className="fa fa-chevron-left" />
        </span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true">
          <i className="fa fa-chevron-right" />
        </span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  </section>
 )
}

export default UserCarousel;