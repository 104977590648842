/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const AdvertisementList = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
  const [advertisementId, setAdvertisementId] = useState();
  const [advertisement, setAdvertisement] = useState({
    advertisement: [],
    aData: [],
  });
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [check, setCheck] = useState({});
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = (search) => {
    setSubmitFormLoader(true);
    services
      .getAdvertisementList(page, 10, search)
      .then((result) => {
        setAdvertisement({
          isLoadedAdvertisement: true,
          advertisement: result.data.data.data,
          aData: result.data.data,
        });

        result.data.data.data.map(({ status, id }) => {
          if (status === 1) {
            setCheck((p) => ({ ...p, [`advertisement${id}`]: true }));
          } else {
            setCheck((p) => ({ ...p, [`advertisement${id}`]: false }));
          }
          return false;
        });
        setSubmitFormLoader(false);
      })
      .catch((e) => {
        setSubmitFormLoader(false);
      });
  };

  const deleteAdvertisementByClick = () => {
    if (showSweetAlert) {
      services
        .deleteAdvertisement(advertisementId)
        .then((response) => {
          setSweetAlert(false);
          fetchData();
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        })
        .catch((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        });
    }
  };

  const sweetAlertForDelete = (event) => {
    setSweetAlert(true);
    setAdvertisementId(event);
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const renderOnEditPage = (event) => {
    history.push(`/admin/edit-advertisement/${event}`);
  };

  const renderOnAdvertisementDetailPage = (event) => {
    // history.push(`/admin/advertisement-details/${event}`);
    history.push({
      pathname: `/admin/advertisement-details/${event}`,
      state: "advertise",
    });
  };

  const updateStatusOfAdvertisement = async (advertisementId, event) => {
    if (event.target.checked) {
      await services
        .updateAdvertisementStatus(advertisementId, {
          status: 1,
          isDelete: false,
          modelType: "Advertisement",
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });

          setCheck((p) => ({
            ...p,
            [`advertisement${advertisementId}`]: true,
          }));
        })
        .then((errors) => {
          toast.success(errors, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        });
    } else {
      await services
        .updateAdvertisementStatus(advertisementId, {
          status: 0,
          isDelete: false,
          modelType: "Advertisement",
        })
        .then((response) => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          setCheck((p) => ({
            ...p,
            [`advertisement${advertisementId}`]: false,
          }));
        })
        .then((errors) => {
          toast.success(errors, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        });
    }
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => advertisement?.aData?.currentPage * 10 + i + 1,
      sortable: true,
      filterable: true,
      center:true,
    },
    {
      name: "Advertiser Id",
      width:'200px',
      selector: (row) => row.advertiserId,
      sortable: true,
      filterable: true,
      center:true,
    },
    {
      name: "Name",
      selector: (row) => row.advertiserName,
      sortable: true,
      filterable: true,
      center:true,
    },
    {
      name: "Email",
      width:'300px',
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
      center:true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      filterable: true,
      center:true,
    },

    {
      name: "Location",
      width:'200px',
      selector: (row) => row.location,
      sortable: true,
      filterable: true,
      center:true,
    },
    {
      name: "Description",
      width:'200px',
      selector: (row) => row.description,
      sortable: true,
      filterable: true,
      center:true,
    },

    {
      name: "Actions",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <span
            onClick={() => renderOnAdvertisementDetailPage(row.id)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => sweetAlertForDelete(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
          <span
            onClick={() => renderOnEditPage(row.id)}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "Status",
      sortable: true,
      filterable: true,
      cell: (row) => (
        <span>
          <label className="switch">
            <input
              defaultChecked={check[`advertisement${row.id}`]}
              name={`advertisement${row.id}`}
              onClick={(e) => updateStatusOfAdvertisement(row.id, e)}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </span>
      ),
    },
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    padding: 0px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    fetchData(filterText);
    // setFilterText('')
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>Advertisers</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-right">
                {" "}
                <Link to="/admin/create-advertisement">
                  {" "}
                  <button type="button" className="btn btn-primary">
                    Add
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4"></div>

                <div className="table-responsive">
                  <div className="d-flex justify-content-end">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter Advertisements"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>

                  <DataTable
                    columns={columns}
                    data={advertisement.advertisement}
                    pagination
                    paginationServer
                    paginationTotalRows={advertisement?.aData?.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage(page)}
                    persistTableHead
                  />
                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      title="Are you sure?"
                      onConfirm={deleteAdvertisementByClick}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                    ></SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-foot"></div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default AdvertisementList;
