import image3 from '../../../assets/images/Image 3.png'
export function CarrejoueVideos() {
    return (
        <section className="video-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="heading-sec text-center">
                            <h2>Carrejoue Videos</h2>
                            <h4>Let us help you get a <b>Game Card</b>, understand <b>Factors</b>, see <b>Group Details</b> and get your <b>Coupons</b>.</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                <div className="col-xl-3 text-center col-lg-3 col-md-6">
                    <video controls  className="img-fluid">
                        <source src= {"https://api.kibugaming.com/uploads/videoHowtoGetaGameCard.mp4"} type="video/mp4" />
                        </video>      
                        <span style={{fontSize:'16px'}}><b>How to Get a Game Card</b></span>            
                 </div>
                    <div className="col-xl-3 text-center col-lg-3 col-md-6">
                    <video controls  className="img-fluid">
                        <source src= {"https://api.kibugaming.com/uploads/videoNavigatingTheWebsite.mp4"} type="video/mp4" />
                        </video>      
                        <span style={{fontSize:'16px'}}><b>Navigating the Website</b></span>     
                    </div> 
                    <div className="col-xl-3 text-center col-lg-3 col-md-6">
                        {/* <img alt="" src={image3} className="img-fluid" /> */}
                        <video controls  className="img-fluid">
                        <source src= {"https://api.kibugaming.com/uploads/videoWhatAreFactors.mp4"} type="video/mp4" />
                        </video>
                        <span style={{fontSize:'16px'}}><b>What Are Factors</b></span>    
                    </div>
                    <div className="col-xl-3 text-center col-lg-3 col-md-6">
                        <video controls  className="img-fluid">
                        <source src= {"https://api.kibugaming.com/uploads/videoWhyAreFactorsImportant.mp4"} type="video/mp4" />
                        </video>
                        <span style={{fontSize:'16px'}}><b>Why Are Factors Important</b></span>    
                    </div>
                  

                  

                </div>
            </div>
        </section>
    )
}