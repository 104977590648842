import { Button, Modal } from "react-bootstrap";

export default function ModalForm({ handleclose, show1, verifyotp, msg, onchange }) {
  return (
    <>
      {/* <Button variant="primary" onClick={handleshow}>
          Launch static backdrop modal
        </Button>
   */}
      <Modal
        centered
        show={show1}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Otp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="dropdown ">
              <label>OTP</label>
              <input
                type="text"
                className="form-control"
                onChange={onchange}
                name="lat"
                data-language="en"
                placeholder="Enter OTP"
              />
              <div className="errorMsg">{msg}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleclose}>
            Close
          </Button>
          <Button style={{background:'#0070C0',padding:'10px 30px'}} onClick={verifyotp} variant="primary">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


