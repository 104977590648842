import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import parse from 'html-react-parser';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export default function BarCodeModal({data, show,handleclose}) {


  const findType = (d) => {

    if(d==="D" || d==="D2"){
    return "Drawing"
    }

    if(d==="C" || d==="C2"){
    return "Contest"
    }
     
    return "Retailer"
  }


  const exportPdf = () => {

    
    document.querySelector("#pdfdownZ").style.display = "none"
    html2canvas(document.querySelector("#myModalCoupon")).then(canvas => {
       document.body.appendChild(canvas);  // if you want see your screenshot in body.
       const imgData = canvas.toDataURL('image/png');
       const pdf = new jsPDF();
       let width = pdf.internal.pageSize.getWidth();
       let height = pdf.internal.pageSize.getHeight();
       pdf.addImage(imgData, 'PNG', 0, 0,width, height);
       pdf.save("Coupon.pdf"); 
      });
      document.querySelector("#pdfdownZ").style.display = "block"

}

  return (
    <>
      {/* <Button variant="primary" onClick={handleshow}>
          Launch static backdrop modal
        </Button>
   */}
      <Modal id="myModalCoupon"
        size="lg"
        centered
        show={show}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><h4 className="modal-title text-center">COUPON/ENTRY FORM </h4></Modal.Title>
          <button id="pdfdownZ" onClick={exportPdf}><i class="fa fa-2x fa-download" aria-hidden="true"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 xc">
          <form role="form">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="form-group m-0">
              <label className="control-label">Event Date</label>
              <h6>{data?.eventId?.eventDate
                              ? moment(data?.eventId?.eventDate).format("MM/DD/YYYY")
                              : moment()}</h6>
              {/* <input disabled value= {data?.eventId?.eventDate
                              ? moment(data?.eventId?.eventDate).format("MM/DD/YYYY")
                              : moment()} className="form-control" type="text"  /> */}
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6">
            <div className="form-group">
              <label className="control-label">Event Location</label>
              <input value={data?.eventId?.eventLocation} className="form-control" type="text"  />
            </div>
          </div> */}
          <div className="col-lg-4 col-md-6">
            <div className="form-group m-0">
              <label className="control-label">Event ID</label>
              <h6>{data?.eventId?.eventId}</h6>
              {/* <input disabled value={data?.eventId?.eventId} className="form-control" type="text"  /> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
                <div className="form-group m-0">
                  <label className="control-label"> Your Pin <span className="pin-txt" /> </label>
                  <h6>{data?.pin}</h6>
                  {/* <input disabled value={data?.pin} className="form-control" type="text" placeholder="XYZ1254" /> */}
                </div>
              </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-1">
                  <label className="control-label">Group Name</label>
                  <h6>{data?.groupId?.groupName}</h6>
                  {/* <input disabled value={data?.groupId?.groupName} className="form-control" type="text"  /> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group mb-1">
                  <label className="control-label">Group ID</label>
                  <h6>{data?.groupId?.groupId} </h6>
                  {/* <input disabled value={data?.groupId?.groupId} className="form-control" type="text"  /> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group  mb-1">
                  <label className="control-label">Expiraton/Enter by Date</label>
                  <h6>{data?.expireEnterDate && moment(data?.expireEnterDate ).format("MM/DD/YYYY")|| ""}</h6>
                  {/* <input disabled value={data?.expireEnterDate && moment(data?.expireEnterDate ).format("MM/DD/YYYY")|| ""} className="form-control" type="text"  /> */}
                </div>
              </div>
                <div className="col-lg-6 col-md-6">
                <div className="form-group  mb-1">
                  <label className="control-label">Select Date</label>
                  <h6>{data?.selectDate && moment(data?.selectDate ).format("MM/DD/YYYY")|| ""}</h6>
                  {/* <input disabled value={data?.selectDate && moment(data?.selectDate ).format("MM/DD/YYYY")|| ""} className="form-control" type="text"  /> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group  mb-1">
                  <label className="control-label">Notify Date</label>
                  <h6>{data?.noticeDate && moment(data?.noticeDate ).format("MM/DD/YYYY")|| ""}</h6>
                  {/* <input disabled value={data?.noticeDate && moment(data?.noticeDate ).format("MM/DD/YYYY")|| ""} className="form-control" type="text"  /> */}
                </div>
              </div>
               <div className="col-lg-6 col-md-6">
                <div className="form-group  mb-1">
                  <label className="control-label">Pattern</label>
                  <h6>{data?.pattern}</h6>
                  {/* <input disabled value={data?.pattern} className="form-control" type="text"  /> */}
                </div>
              </div>


            </div>
          </div>
          <div className="col-lg-4 col-md-12 text-center">
          
            <img src={data.qrCode} className="img-fluid" />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 coupon-content">
            <h5>RETAILER/ENTRY INSTRUCTIONS :</h5>
            <p>Check Expiration Date.
       Use QR Code to verify validity by entering PIN.  “Valid” is the acceptable response.
      “Used” or “Expired” are do not sell responses.  Try again? may appear and allow PIN to 
       be re-entered.  Thank you for your cooperation and advertising.
</p>
            <h5>Prize Description : <span style={{color:' #7e7e7e', fontWeight: 500}}>{data?.prizeData && data?.prizeData[0]?.shortPrize} </span></h5>
            <div>{parse(data?.longPrize || "")}</div>
            <h5>How to Claim: {data?.prizeData && findType(data?.prizeData[0]?.retailDrawContest)}</h5>
            <p>{data?.prizeData && parse(data?.prizeData[0]?.claimDescribe || "")}</p>
            <h5>Participating Merchants</h5>
            <p>Prizes are provided courtesy of the following merchants: {
              data?.advertiserIdData?.map((d, index) => 
        <span key={d.advertiserId}>{d.advertiserName} {index < data?.advertiserIdData.length - 1 ? ", " : ""}</span>
 ) || ""
}</p>
          { data?.prizeData && findType(data?.prizeData[0]?.retailDrawContest) === "Retailer" && <div>{parse(data?.adDescription || "")}</div>}
            <h5>LEGAL/ADDITIONAL TERMS :</h5>
            <p>{data?.prizeData && parse(data?.prizeData[0]?.legalDescribe || "") }
            </p>
          </div>
        </div>
        <div>
        </div>
      </form>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
}


