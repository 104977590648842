import { Component } from "react";
import services from "../../services/services";
import { Link } from "react-router-dom";
import "./forgot-password.css";
import "../../css/master-style.css";
import "../../css/bootstrap.min.css";
import "../../css/responsive.css";

import { toast } from "react-toastify";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: false, // Indicates in progress state of login form
      showEmail: true,
      showOtp: false,
      otp: "",
      isButtonDisabled: false,
      newPass: false,
      submitFormLoader: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.verifyOtp = this.verifyOtp.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  handleInputChange = (event) => {
    const { id, value } = event.target;

    const email = id;
    let { formData } = this.state;
    formData[email] = value;

    this.setState({
      formData: formData,
    });
  };
  validateForm() {
    let formData = this.state.formData;
    let errors = {};
    let formIsValid = true;

    if (!formData["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email id.";
    }

    if (typeof formData["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(formData["email"])) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email id.";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  async forgotPassword(e) {
    try {
      e.preventDefault();
      let data = {
        email: this.state.formData.email,
      };

      if (this.validateForm()) {
        this.setState({
          submitFormLoader: true,
        });
        const response = await services.forgotPassword(data, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          this.setState({
            submitFormLoader: false,
          });
          this.setState({ showEmail: false, showOtp: true });
        }

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      this.setState({
        submitFormLoader: false,
      });
    }
  }

  async verifyOtp(e) {
    try {
      e.preventDefault();
      let data = {
        email: this.state.formData.email,
        otp: Number(this.state.formData.otp),
      };

      let formData = this.state.formData;
      let errors = {};
      if (!formData["otp"]) {
        errors["otp"] = "*Please enter your otp.";
        this.setState({
          errors: errors,
        });
        return false;
      }

      const response = await services.verifyOtp(data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        this.setState({ showEmail: false, showOtp: false });
      }

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  }

  async resendOtp() {
    try {
      let formData = this.state.formData;
      formData["otp"] = "";

      let data = {
        email: this.state.formData.email,
      };

      this.setState({
        submitFormLoader: true,
      });
      const response = await services.forgotPassword(data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      this.setState({
        submitFormLoader: false,
      });

      this.setState({
        isButtonDisabled: true,
      });

      // **** here's the timeout ****
      setTimeout(() => this.setState({ isButtonDisabled: false }), 10000);

      // return this.props.onLaunchClicked();
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  }

  async resetPassword() {
    try {
      let formData = this.state.formData;
      let errors = {};

      if (!formData["pass"]) {
        errors["pass"] = "*Please enter your new password.";
        this.setState({
          errors: errors,
        });
        return false;
      }
      if (
        !formData["pass"].match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,30}$/
        )
      ) {
        errors["pass"] = `*Please enter secure and strong password.
                                    Include both lower and upper case character.
                                   Include at least one number and one symbol (@#$%^...)`;
        this.setState({
          errors: errors,
        });
        return false;
      }

      if (!formData["cPass"]) {
        errors["pass"] = "";
        errors["cPass"] = "*Please enter your confirm password.";
        this.setState({
          errors: errors,
        });
        return false;
      }

      if (formData["pass"] !== formData["cPass"]) {
        toast.error(
          "Password must be matched",

          {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          }
        );
        return false;
      }

      let data = {
        email: this.state.formData.email,
        newPassword: this.state.formData.pass,
        confirmNewPassword: this.state.formData.cPass,
      };
      const response = await services.resetPassword(data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });

      if (response.status === 200) {
        this.props.history.push("/login");
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  }

  render() {
    let { formData } = this.state;

    return (
      <section className="login-bg">
        <div className="container">
          <div className="row">
            {this.state.submitFormLoader && (
              <div style={{ left: "46%" }} className="loader"></div>
            )}
            <div className="col-xl-4 col-lg-5 col-md-6 col-12 m-auto text-center">
              {this.state.showEmail ? (
                <div className="login-area">
                  <div className="logo text-center">
                    <img src="logo.png" alt="Avatar" />
                  </div>
                  <h2>Forgot Password</h2>
                  <form name="loginform" onSubmit={this.forgotPassword}>
                    <div className="form-group mb-2"> </div>
                    <div className="form-group mb-3">
                      <img src="icon-mail.svg" alt="Avatar" className="icon2" />
                      <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Email Id"
                        autoFocus="off"
                        autoComplete="off"
                        className="form-control"
                        onChange={this.handleInputChange}
                      />
                      <div className="errorMsg">{this.state.errors.email}</div>
                    </div>
                    <button
                      className="btn btn-primary btn-block mt-4 mb-2"
                      type="submit"
                      bsstyle="primary"
                    >
                      Submit
                    </button>
                    <Link to="/login">Back to login</Link>
                  </form>
                </div>
              ) : this.state.showOtp ? (
                <div className="login-area">
                  <div className="logo text-center">
                    <img src="logo.png" alt="logo" />
                  </div>
                  <h2>Enter Otp</h2>
                  <form name="loginform">
                    <div className="form-group mb-3">
                      <div id="divOuter">
                        <div id="divInner">
                          <input
                            id="partitioned"
                            onChange={(e) => (formData["otp"] = e.target.value)}
                            type="text"
                            maxLength={6}
                          />
                          <div className="errorMsg">
                            {this.state.errors.otp}
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      disabled={this.state.isButtonDisabled}
                      type="button"
                      onClick={this.resendOtp}
                      className="resend-otp"
                    >
                      Resend OTP
                    </button>
                    <button
                      onClick={this.verifyOtp}
                      type="button"
                      className="btn btn-primary btn-block mt-5 mb-2"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              ) : (
                <div className="login-area">
                  <div className="logo text-center">
                    <img src="logo.png" alt="logo" />
                  </div>
                  <h2>Change Password</h2>
                  <form name="loginform">
                    <div className="form-group mb-2">
                      <img
                        src="icon-password.svg"
                        alt="Avatar"
                        className="icon"
                      />
                      <input
                        id="username"
                        type="password"
                        name="password"
                        placeholder="New Password"
                        autoFocus="off"
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => (formData["pass"] = e.target.value)}
                      />
                      <div className="errorMsg">{this.state.errors.pass}</div>
                    </div>
                    <div className="form-group mb-2">
                      <img
                        src="icon-password.svg"
                        alt="Avatar"
                        className="icon"
                      />
                      <input
                        id="username"
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        autoFocus="off"
                        autoComplete="off"
                        className="form-control"
                        onChange={(e) => (formData["cPass"] = e.target.value)}
                      />
                      <div className="errorMsg">{this.state.errors.cPass}</div>
                    </div>

                    <button
                      onClick={this.resetPassword}
                      type="button"
                      className="btn btn-primary btn-block mt-5 mb-2"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;
