import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../actions/userLoginAction";
import { useHistory,useLocation, Redirect, } from "react-router-dom";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';

// toast-configuration method, 
// it is compulsory method.
toast.configure();
// import './login.css'

const Login = () => {
    const location = useLocation();
    const history = useHistory();
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const cookies = new Cookies();
    const [showPass, setshowPass] = useState(true);
    const [submitFormLoader, setSubmitFormLoader] = useState("");
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((pre) => ({ ...pre, [name]: value }))
    }

    const dispatch = useDispatch();

    const validateForm = () => {
        let formIsValid = true;
        if (!formData["email"]) {
            formIsValid = false;
            setErrors((pre) => ({ ...pre, 'email': "*Please enter your email id." }));
        }
        if (typeof formData["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData["email"])) {
                formIsValid = false;
                setErrors((pre) => ({ ...pre, 'email': "*Please enter valid email id." }));
            }
        }
        if (!formData["password"]) {
            formIsValid = false;
            setErrors((pre) => ({ ...pre, 'password': "*Please enter your password." }));
        }
        return formIsValid;
    }




    const login = async (e) => {


       
        try {
            e.preventDefault();

            // if(!cookies.get('longitude') && !cookies.get('latitude')){
            //     return   toast.error("Please Enable your location from the browser else you won't be login", {
            //         position: toast.POSITION.TOP_CENTER
            //     });
            // }
         
            let data = {
                email: formData.email,
                password: formData.password,
                latitude:cookies.get('latitude'),
                longitude:cookies.get('longitude')
            }
            if (validateForm()) {
                setErrors({});
                setSubmitFormLoader(true)
             await dispatch(loginAction(data));
             setSubmitFormLoader(false)
           
            }
        } catch (e) {
            toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
        }
    }
    // Define callback function for successful attempt
    function successCallback(position) {
    cookies.set('latitude', position.coords.latitude, { path: '/' });
    cookies.set('longitude', position.coords.longitude, { path: '/' });
        // console.log("Latitude: " + position.coords.latitude + ", " + "Longitude: " + position.coords.longitude);
    
    }
    
    // Define callback function for failed attempt
    function errorCallback(error) {
        if(error.code == 1) {
            toast.error("Please Enable your location from the browser else you won't be login", {
                position: toast.POSITION.TOP_CENTER
            });
        } else if(error.code == 2) {
            toast.error("The network is down or the positioning service can't be reached.", {
                position: toast.POSITION.TOP_CENTER
            })
        } else if(error.code == 3) {
            toast.error("The attempt timed out before it could get the location data.", {
                position: toast.POSITION.TOP_CENTER
            })
          
        } else {
            toast.error("Geolocation failed due to unknown error.", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    useEffect(() => {
localStorage.removeItem("guest")
    }, [])

    return (
        <section className="login-bg">
            <div className="container">
            {submitFormLoader && <div className="loader"></div>}
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-8 col-12 m-auto text-center">
                        <div className="login-area">
                            <div className="logo text-center">
                                <img style={{width:'inherit'}} src="logo.png" alt="Avatar" />
                            </div>
                            <h2>User Login</h2>
                            <form name="loginform" onSubmit={login}>
                                <div className="form-group mb-3">
                                    <img src="icon-user.svg" alt="Avatar" className="icon" />
                                    <input id="email" type="text" name="email" placeholder="Type your email" autoFocus="off" autoComplete="off" className="form-control mb-0" onChange={handleInputChange} />
                                    <div className="errorMsg">{errors.email}</div>
                                </div>
                                <div className="form-group mb-4">
                                    <img src="icon-password.svg" alt="Avatar" className="icon" />
                                    <input id="password" type={showPass ? "password" : "text"} name="password" placeholder="Type your password" autoFocus="off" autoComplete="off" className="form-control mb-0" onChange={handleInputChange} />
                                    <i  onClick={()=>setshowPass(!showPass)} class={showPass ? 'fa fa-eye-slash hpass' : 'fa fa-eye hpass'}></i>
                                    <div className="errorMsg">{errors.password}</div>
                                </div>
                                <div className="log-link rem-pass">
                                    {/* <!-- Checkbox --> */}
                                    <div className="form-check mb-0">
                                        <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                                        <label className="form-check-label ml-2" htmlFor="form2Example3">
                                        Remember Password
                                        </label>
                                    </div>
                                    <Link to="/forgot-password" className="res-log">Forgot Password?</Link>
                                </div>
                                <p className="text-sign mt-2">Don't have an account?  <Link to="/signup">Sign Up</Link></p>
                                <p className="text-sign mt-2"><b>KiBu Gaming Corporation ©2019-{new Date().getFullYear()}</b></p>
                                <button className="btn btn-primary btn-block mt-4 mb-3" type="submit" bsstyle="primary">Submit</button>
                                <button onClick={()=>{localStorage.setItem("guest",true)
                                 history.push('/')}} type="button" className="btn btn-secondary btn-block mb-2">Continue as Guest</button>
                            </form>
                            <p>By clicking Submit, you indicate that you have read and understand our <Link to="/user/privacy-policy"> <span style={{color:'#0070C0'}}>Privacy Policy</span> </Link>and <Link to="/user/terms-condition">Terms of Use</Link> and represent that you are at least 18 years of age.</p>
                          
                            <p className="text-sign">Or Login Using  </p>
                            <a href="#test" className="facebook"><i className="fa fa-facebook-f"></i></a>
                            <a href="#test" className="google"><i className="fa fa-google"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;