import { Link } from "react-router-dom";

export const Group = () => (
  <div className="tab-pane fade" id="nav-grp" role="tabpanel" aria-labelledby="nav-grouprules-tab">
        <h3 className="pt-4 pb-2">Group Rules</h3>
        <p>Specific Rules can vary by Group. Group Details pages show specific rules for the
          Group. These are a brief explanation of items that may vary by Group.</p>
        <p className="cont-pdb"><strong> 1. Factors</strong></p>
        <p>Groups use different Factors and population percentages to populate the 25 squares of
          the Game Cards. This can vary within a Group by Event Type as well. In general, the
          more Factors the harder it is to win. More factors are used when larger amounts of
          participants are expected in the Group.</p>
        <p>Under the Group Details the Game Card Version is listed. This is the game system’s
          way of tracking how to populate the Game Cards. The Group Details will display the
          Factor Code for a Defined Result with the population percentage displayed next to
          it. Multiplying the percentage by 25 squares (number of squares on a game card)
          gives the average number of squares usually Pre-Populated with that Defined Result
          on a Game Card.</p>
        <p className="text-center"> <strong>Example: Pre-Populating Game Cards – Baseball MLB
            Factor: O(35%) <br />
            35% x 25 squares = 8.75 squares, on average, will be Out Recorded (O).
          </strong> </p> <br />
        <p className="text-center"><strong> Odds of Winning</strong></p>
        <p>The odds of winning are deeply influenced by the number of factors and the population
          percentages. The Historical Wins Percentage for each Pattern type will be shown here
          also. The odds of winning can be determined as shown in the following example.</p>
        <p className="text-center"><strong> Example: Wins Percentage - Baseball MLB: C(15.3%) <br />
            Odds of Winning Corners (C) pattern = 100/15.3 = 1 in 6.54 Game Cards.
          </strong></p>
        <p className="cont-pdb"><strong>2. Location </strong></p>
        <p>Groups often limit participation by Location. For More details on Location, <a href="#">click here. </a></p>
        <p>Areas outside of larger cities and towns may not respond to our Geolocation services
          and our software may not be able to provide Game Cards. Per law, we must ascertain
          our Game Cards are obtained within the jurisdictions governing them. As technology
          becomes more accurate and prevalent, your situation may change. Please check back
          often or contact our customer support for possible solutions. Please accept our
          apologies for any inconvenience.</p>
        <p>Game Cards can generally be obtained in Groups approximately one week in advance of
          the Event. If you are unable to obtain a Game Card and it is more than five (5) days
          in Advance of the Event, please try again later. If it is five (5) days or less,
          please contact customer service with the error.</p>
        <p className="cont-pdb"><strong> 3. Patterns Played </strong></p>
        <p>Groups use variations of the pattern classes to determine winning Game Cards.
          These variations will be listed under the Group Details.
        </p>
        <p> <strong>Line patterns </strong> are the most common variations.</p>
        <ul className="ml-4">
          <li>Some Groups do not even play the Line pattern.</li>
          <li>Many Groups only use the horizontal lines in the Line pattern.</li>
          <li>Some use both the horizontal and vertical lines in the Line pattern.</li>
          <li>It is possible for the Group to only use a single line, horizontal or vertical.
          </li>
          <li>Some may use three lines, either horizontal or vertical.</li>
        </ul>
        <p><strong>Blackout patterns</strong> have a few common variations in the Groups, some
          include the Window Pattern and some do not. See additional RollOver/PushDown rules
          for when patterns are not won. The following codes are associated with the Blackout
          patterns.</p>
        <p>NW &nbsp; &nbsp; Windows Pattern does not play <br />
          WW &nbsp; &nbsp;Windows Win, if no Black Out, else it wins a Consolation Prize <br />
          WC &nbsp; &nbsp;Windows wins a Consolation Prize even if there is not Black Out.
        </p>
        <p> <strong>Other Patterns</strong> may be varied as well. This section of the Group
          Details is where you can find these variations. However, the Line and Black Out are
          the most
          common.</p>
        <p className="cont-pdb"><strong>4. Prize/Payout </strong></p>
        <p className="mt-3"> <a href="#">Prize Group </a>  <a href="#" className="ml-4">Payout Group </a></p>
        <p>Prize Groups have no costs for players to enter and no management percentage.</p>
        <p>Payout Groups have a management percentage taken from entry fees.</p>
        <p><strong>Prize Groups</strong></p>
        <p><strong>*Contests </strong>are judged by company representatives to award the Prizes
          listed without a drawing. Remaining entries may be placed in a second hand drawing
          for the remaining Group entries and additional winners drawn at random for the Prizes
          listed. The number of Prizes to be awarded are in parentheses.</p>
        <p><strong>**Drawings</strong> are made at random from the Group entries received in
          accordance with the rules and by the timeline as established on the Drawing Entry
          Voucher for the Prizes listed. The number of Prizes to be awarded are in
          parentheses.
        </p>
        <p><strong>**Winners</strong> means each winning Game Card will win the Prize listed.
        </p>
        <p>Contest and Drawing Entries will be updated regularly and no more entries accepted
          after the cutoff time allotted on entry forms. The cutoff time will be no less than two
          days in advance of the Contest or Drawing.</p>
        <p>The total number of winning Game Cards for each winning pattern for each Group will
          be posted shortly after the Event concludes. </p>
        <p>The total number of Game Cards entered in an Event for each Group will be posted
          shortly after the start of the Event.</p>
        <p> <strong>Payout Group </strong> </p>
        <p>Management percentages vary by Group. It is an essential element for determining
          Payout
          Pools and ultimately the Payout for a winning Game Card.</p>
        <p>A Group may have a different Management Percentage for different Event Types, for
          example
          Football NFL may have a different Management Percentage than College Football.</p>
        <p>In brief, the Management Percentage is deducted from all entries made into a Payout
          Pool.
          Then the Payout is calculated by dividing the Payout Pool by the number of winning
          Game
          Cards. </p>
        <p className="cont-pdb"><strong>5. Rounding</strong></p>
        <p>Rounding is used only in Groups with Payouts. </p>
        <p>States require a $1 bet to pay at least $1.10. In the case of Carrejoue, a $2 bet
          will
          pay at least $2.20. Payouts will always be rounded to the nearest dime.</p>
        <p>Rounding is often dictated by law and the resulting monies are called the overage or
          underage. The following codes are used to express how a Group rounds its Payout –
        </p>
        <p>Rd6 &nbsp; &nbsp;Rounds all amounts 6 and greater up and 5 and under down. <br />
          Rd8 &nbsp; &nbsp;Rounds all amounts 8 and greater up and 7 and under down. <br />
          Rd9 &nbsp; &nbsp;Rounds all amount down.
        </p>
        <p className="cont-pdb"><strong>6. RollOver/PushDown</strong></p>
        <p>Roll Over and Push Down are only used in Groups with Payouts. They are both methods
          for
          how to handle Payout Pools that were not won. They are most frequently seen in the
          Black
          Out pattern Payout Pool and are also influenced by how a Group handles the Window
          pattern in that Payout Pool.</p>
        <p>States can require a Group that normally uses a Roll Over method to occasionally Push
          Down on a published and set schedule the Group’s Payout Pools. Look for these
          mandatory
          Push Down Events to increase the odds of Payouts.</p>
        <p><strong>Roll Over</strong> is when a Payout Pool is carried over as the starting
          Payout
          Pool for the same pattern in the next associated Event because there was no winning
          Game
          Card. The new entry fees less than Management Fee will be added to this carried over
          amount increasing the Payout that would normally be won.</p>
        <p>An associated Event will be explained in this section of the Group Details. The
          easiest
          associated Event to understand is inside a stadium. If the location of the Group is
          inside a stadium, then a Roll Over will be the home team’s next Scheduled Event.
          Often
          in a National Pool, a Roll Over Payout Pool will be split between the two opposing
          teams
          with half carried over to each of the team’s next Scheduled Event.</p>
        <p><strong>Push Down</strong> is when a Payout Pool is required to be paid out but there
          was
          no winning Game Card. It is paid out by pushing it down to the next lowest pattern
          with
          winning Game Cards for the same Event.</p>
        <p className="cont-pdb"><strong>7. Cards Costs</strong></p>
        <p>Cards are free in Prize Groups. If you were not charged for your Game Card, then you
          are
          playing in a Prize Group. </p>
        <p className="text-center mb-0"> <strong>Prize Groups</strong> </p>
        <p className="text-center">Generally, each account is allowed one entry per Event per Prize Group. </p>
        <p>Additional entries are sometimes obtained as an awarded Prize of a winning pattern in
          Carrejoue games. Sometimes additional entries can be obtained by taking surveys from
          our
          Advertisers/Sponsors or visiting their websites during various promotional times.
        </p>
        <p className="text-center mb-0"> <strong>Payout Groups</strong> </p>
        <p className="text-center">Details on Cost Calculations, <a href="#">click here</a>. </p>
        <p>There is no limit to the number of entries, other than the maximum allowed by the
          Group, a participant may purchase.</p>
        <p>Two basic methods for calculating Game Card costs are possible. There are slight
          variations possible within the two methods. The Group Details will provide further
          explanation of the associated Card Costs. The two basic methods –</p>
        <p><strong>Fixed Cost –</strong> Often provides one or more patterns as free patterns or
          patterns not played at all. The Game Card must be bought for the price listed and
          plays
          in all available patterns as explained in the Group Details.</p>
        <p> <strong>Per Pattern Cost –</strong> Sometimes a pattern is not available or
          sometimes a
          pattern is free with any purchase as explained in the Group Details. However, each
          pattern that must be purchased is purchased separately. A participant can purchase
          all
          the patterns, some of the patterns or one of the patterns available for purchase.
        </p>
        <p className="cont-pdb"><strong>8. Organized Group Rules</strong></p>
        <p>Organized Groups can set additional rules outside the variations traditionally
          offered.
          These additional rules will be reflected in the other Group Details or here.
        </p>
        <p>The primary change is an Organized Group can limit the participants however they see
          fit.
          They often utilize a password to enter instead of Geolocation. </p>
        <p>A company may offer a Game of Carrejoue around an Event during a company outing. A
          group
          of friends may organize to play Carrejoue as they see fit allowing entries by
          friends
          and friends of friends.</p>
        <p>Organized Groups can select the variations above (if allowed by law) and also change
          the
          following:</p>
        <ol className="ml-4" type="I">
          <li> What the cost of the cards will be </li>
          <li> What the prizes or payouts will be within the ability of our websites </li>
          <li> How a season will be divided up into different sessions of Carrejoue </li>
        </ol>
        <p>For more information about Organized Groups call our customer service department for
          availability, flexibility and costs. We will try to accommodate your needs.</p>
      </div>
);
