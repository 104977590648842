/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { UserFooter } from "../../core/footer/userFooter";
import UserHeader from "../../core/header/userHeader";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import pro from "../../../assets/images/pic-prof.png";
import { Example } from "../modal";
import Geocode from "react-geocode";
import moment from "moment";
import * as universalFunction from "../../../utils/universalFunctions";

function EventAssociateGroup(prop) {
  const [state, setState] = useState([]);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const [saveLocation, setLocation] = useState({});
  const [pass, setPass] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [saveData, setSaveData] = useState("");
  const [err, setErr] = useState("");
  let { history, location } = prop;


  useEffect(() => {
    window.scrollTo(0, 0);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }
    fetchData();
  }, []);

  async function successCallback(position) {
    setSubmitFormLoader(true);
    localStorage.setItem("lat", position.coords.latitude);
    localStorage.setItem("lon", position.coords.longitude);
    setSubmitFormLoader(false);
  }

  const fetchData = async () => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY); //Insert your Google Maps API here
    try {
      const result = await services.associatedEvents(id);
      setState(result.data.data);
      if (localStorage.getItem("lat")) {
        let response = await Geocode.fromLatLng(
          localStorage.getItem("lat"),
          localStorage.getItem("lon")
        );
        // console.log(response.results[0], "kkkk");
        const { address_components, place_id } = response.results[0];
        
        let area =
          address_components.length > 0 &&
          address_components.filter(
            (data) => data.types[0] === "administrative_area_level_1"
          );
        let country =
          address_components.length > 0 &&
          address_components.filter((data) => data.types[0] === "country");
          setLocation({
          groupLocation: area[0]?.long_name,
          placeId: place_id,
          country: country[0]?.long_name,
        })
      }

    } catch (e) {
      // console.log(e);
    }
  };

  const handleNavigate = (data) => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback1, universalFunction.errorCallback);
    } else {
      alert("Sorry, your browser does not support HTML5 geolocation.");
    }

    // Define callback function for successful attempt
    async function successCallback1(position) {
      // alert("Latitude: " + position.coords.latitude + ", " +  position.coords.longitude);
      setSubmitFormLoader(true);
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
      const newData = {
        userId: Number(localStorage.getItem("id")),
        groupId: Number(state.id),
        eventId: Number(data),
        isOrganized: state.groupType === "Organized" ? true : false,
        password: state.password,
        latitide:  position.coords.latitude,
        longitude:  position.coords.longitude,
        groupLocation: saveLocation?.groupLocation,
        placeId: saveLocation?.placeId,
        // latitide: state.latitude,
        // longitude: state.longitude,
        // groupLocation: state.groupLocation,
        // placeId: state.placeId,
        country: state.country,
      };
  
      // console.log(newData);
      if (newData.isOrganized) {
        setShow(true);
        setSaveData(newData);
        return false;
      }
  
      history.push({ pathname: `/user/game-card`, state: { newData } });
      setSubmitFormLoader(false);
    }

  };

  const handleClose = () => {
    setShow(false)
    setSubmitFormLoader(false);
  };

  const enterGame = () => {
    if (!pass) {
      return setErr("Please Enter Game Password");
    }
    if (pass !== saveData.password) {
      return setErr("Password is not matched");
    }
    history.push({ pathname: `/user/game-card`, state: { newData: saveData } });
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) =>  i + 1,
      // sortable: true,
      filterable: true,
    },

    {
      name: "Enter Game",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <button onClick={() => handleNavigate(row.id)} className="btn btn-game">
            Enter Now
          </button>{" "}
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      center: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "Date",
      selector: (row) => moment(row.eventDate).format("MM/DD/YYYY"),
      sortable: true,
      center: true,
      filterable: true,
    },
    {
      name: "Time (ET)",
      selector: (row) => row.eventTime,
      sortable: true,
      center: true,
      filterable: true,
    },
    {
      name: "Away Team",
      selector: (row) => row.awayNameData,
      sortable: true,
      center: true,
      filterable: true,
    },
    {
      name: "Home Team",
      selector: (row) => row.homeNameData,
      sortable: true,
      filterable: true,
      center: true,
    },
    {
      name: "League	Name",
      selector: (row) => universalFunction.findLeague(row.eventType, row.league),
      sortable: true,
      center: true,
      filterable: true,
    },
    


  ];


  return (
    <>
      <UserHeader />
      <Example
        show1={show}
        handleclose={handleClose}
        entergame={enterGame}
        onchange={(e) => setPass(e.target.value)}
        msg={err}
      />
     {submitFormLoader && <div className="loader"></div>}
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>Associated Events</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                <i className="fa fa-angle-right" />
              </span>
              <Link onClick={()=>history.push('/user/user-group')} to="#">
                Groups
              </Link>
              <span>
                <i className="fa fa-angle-right" />
              </span>
              <Link to="#" className="active">
                Associated Events
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="groupdetails-sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="group-details">
                <div className="profile-area-2">
                  <div className="profile-pic-2">
                    <img src={pro} alt="" />
                  </div>
                  <div className="profile-content">
                    <h5 className="m-0"> {state?.groupName}</h5>
                    <span className="group-id">
                      Group Id : {state?.groupId}
                    </span>
                  </div>
                </div>
                <div className="group-des">
                  <div className="row">
                    <div className="col-lg-6 col-6 mt-4">
                      <p>
                        <strong>Card Cost :</strong>
                      </p>
                      <span>N/A</span>
                    </div>
                    <div className="col-lg-6 col-6 mt-4">
                      <p>
                        <strong>Group Type :</strong>
                      </p>
                      <span>{state?.groupType}</span>
                    </div>
                    {/* <div className="col-lg-6 col-6 mt-4">
                            <p><strong>Location :</strong></p>
                            <span>{state?.location}</span>
                        </div> */}
                    <div className="col-lg-6 col-6 mt-4">
                      <p>
                        <strong>Minimum Age :</strong>
                      </p>
                      <span>18 Years </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9">
              <div className="group-right">
                <h3>Associated events with this group</h3>
              
                <DataTable
                    columns={columns}
                    data={state.eventData}
                    pagination
                    paginationPerPage={10}
                    persistTableHead
                  />
              </div>
            </div>
          </div>
        </div>
      </section>
   
      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}

export default EventAssociateGroup;
