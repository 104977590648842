/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import services from "../../../services/services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import AdminHeader from "../../core/header/adminHeader";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminFooter from "../../core/footer/adminFooter";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

const UserModule = () => {
  const history = useHistory();
  const [showSweetAlert, setSweetAlert] = useState(false);
  const [cardId, setId] = useState("");
  const [showUsers, setShowUsers] = useState([]);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(0);
  const [check, setCheck] = useState({});

  useEffect(() => {
    fetchData();
  }, [page]);

  async function fetchData(search) {
    try {
      setSubmitFormLoader(true);
      let data = await services.userList(page, 10, search);
      setShowUsers(data?.data.data);
      data?.data.data.data.map(({ isBlocked, id }) => {
        if (isBlocked) {
          setCheck((p) => ({ ...p, [`user${id}`]: true }));
        } else {
          setCheck((p) => ({ ...p, [`user${id}`]: false }));
        }
        return false;
      });
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  }

  const deletePartnerByClick = () => {
    if (showSweetAlert) {
      setSubmitFormLoader(true);
      services
        .deleteCardParameter(cardId)
        .then((response) => {
          setSweetAlert(false);

          // Render to load advertisement page

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitFormLoader(false);
          fetchData();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setSubmitFormLoader(false);
        });
    }
  };

  const sweetAlertForDelete = (id) => {
    setSweetAlert(true);
    setId(id);
  };

  const hideSweetAlert = () => {
    setSweetAlert(false);
  };

  const updateStatusOfUser = async (id, event) => {
    setCheck((p) => ({ ...p, [`user${id}`]: !check[`user${id}`] }));
    await services
      .userBlock(id, event.target.checked ? { isBlocked:true }: { isBlocked:false })
      .then((response) => {
   
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        
      })
      .catch((e) => {
        toast.success(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => showUsers.currentPage * 10 + i + 1,
      sortable: true,
      filterable: true,
    },

    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email Id	",
      width:'300px',
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row.mobileNumber,
      sortable: true,
      filterable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      filterable: true,
    },

    {
      name: "Unblock/Block",
      sortable: true,
      filterable: true,
      cell: (row) => (
        <span>
          <label className="switch">
            <input
              onClick={(e) => updateStatusOfUser(row.id, e)}
              checked={check[`user${row.id}`]}
              name={`user${row.id}`}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </span>
      ),
    },

    // {
    //   name: "Special Factor",
    //   selector: (row) => row.specialFactorCode,
    //   sortable: true,
    //   filterable: true,
    // },
    // {
    //   name: "Actions",
    //   selector: (row) => row.id,
    //   cell: (row) => (
    //     <>
    //       <button disabled
    //         onClick={() => sweetAlertForDelete(row.id)}
    //         className="fa fa-trash fa-2x icon-act"
    //       ></button>{" "}
    //     </>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  const ClearButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    padding: 0px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const getSearch = () => {
    fetchData(filterText);
    // setFilterText('')
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        <AdminHeader />
        <AdminSidebar />
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row mt-2">
              <div className="col-lg-6 col-md-6">
                <div className="left">
                  <h4>User Lists</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue p-0">
                <div className="row mb-4"></div>

                <div className="table-responsive">
                  <div className="d-flex justify-content-end">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter Users"
                      aria-label="Search Input"
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button" onClick={getSearch}>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>
                  <DataTable
                    columns={columns}
                    data={showUsers.data}
                    pagination
                    paginationServer
                    paginationTotalRows={showUsers.totalItems}
                    // paginationPerPage={10}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPage(page)}
                    persistTableHead
                  />

                  {showSweetAlert ? (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Yes, delete it!"
                      confirmBtnBsStyle="danger"
                      title="Are you sure?"
                      onConfirm={deletePartnerByClick}
                      onCancel={hideSweetAlert}
                      focusCancelBtn
                    ></SweetAlert>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="space-foot"></div>
          <AdminFooter />
        </div>
      </div>
    </div>
  );
};

export default UserModule;
