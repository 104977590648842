/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React,{ useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { UserFooter } from "../../core/footer/userFooter";
import DataTable from "react-data-table-component";
import UserHeader from "../../core/header/userHeader";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import services from "../../../services/services";
import { toast } from "react-toastify";
toast.configure();
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import * as universalFunction from "../../../utils/universalFunctions";
import moment from "moment";


function MyEvent(prop) {

const [state, setState] = useState([]);
const [showArchive, setShowArchive] = useState(false);
const [archiveData, setArchiveData] = useState([]);
const [submitFormLoader, setSubmitFormLoader] = useState("");
const [filterText, setFilterText] = useState('');
const [selectText, setSelectText] = useState('');
const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch()
 
  }, []);

  async function fetch() {
    try {
      setSubmitFormLoader(true);
      let response = await services.userEvents();
      setState(response?.data?.data);
      setArchiveData(response?.data?.data?.archived)
      setSubmitFormLoader(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER
    })
      setSubmitFormLoader(false);
    }
  }

  const enterGameCard = (data) => {
  let {id, groupId} = data;
  let saveData = {
     d : {
      groupId,
      userId: Number(localStorage.getItem("id")),
      eventId:id,
    },
  
    id:"myevents"
  }
    history.push({ pathname: `/user/game-card`, state: { newData: saveData } });  
  }

  const newArrayOfObj = (arrayOfObj) => {
if(arrayOfObj?.length > 0){
 return arrayOfObj.map(({
    id: ids,
    ...rest
  }) => ({
    ids,
    ...rest
  }));
}
return []
  }
  

  const columns = [

    {
      name: "View Cards",
      selector: (row) => row.groupId?.id,
      cell: (row) => (
        <>
          <button onClick={() => enterGameCard({groupId:row.groupId?.id,id:row.ids})} className="btn btn-game">
            View
          </button>
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: "Group Id",
      selector: (row) => row?.groupId?.groupId,
      sortable: true,
      center: true,
      filterable: true,
    },
    {
      name: "Away Team",
      selector: (row) => row.awayNameData,
      sortable: true,
      filterable: true,
      center: true,
      width: "14rem",
    },
    {
      name: "Home Team",
      selector: (row) => row.homeNameData,
      sortable: true,
      center: true,
      filterable: true,
      width: "14rem",
    },
    {
      name: "Date",
      center: true,
      selector: (row) => moment(row.eventDate).format("MM/DD/YYYY"),
      // sortable: true,
      // filterable: true,
    },
    {
      name: "Time (ET)",
      selector: (row) => row.eventTime,
      center: true,
    },
    {
      name: "League Name",
      selector: (row) => universalFunction.findLeague(row.eventType, row.league),
      center: true,
    },
    {
      name: "Event Type",
      selector: (row) => (
        <span style={{ fontFamily: "sans-serif" }}>{`${universalFunction.findLeague(row.eventType)} (${(row.eventType)})`}</span>
      ),
      width:'170px',
      center: true,
    },

   
 


  ];


  // const filterData = (data, inputText) => {
  //   console.log(data);
  //   if (
  //     (data?.groupId.groupId &&
  //       data?.groupId.groupId.toLowerCase().includes(inputText.toLowerCase())) ||
  //        (data.homeNameData?.partnerName &&
  //         data.homeNameData?.partnerName.toLowerCase().includes(inputText.toLowerCase())) ||
  //       (data.eventDate) ||
  //       (data.uploadedBy &&
  //         data.uploadedBy.toLowerCase().includes(inputText.toLowerCase())) ||
  //       (data.advertiserName &&
  //         data.advertiserName
  //           .toLowerCase()
  //           .includes(inputText.toLowerCase())) ||
  //       (data.email &&
  //         data.email.toLowerCase().includes(inputText.toLowerCase()))
  //     ){
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const filteredItems = state?.archived?.filter(
  //   (item) => filterData(item, filterText) 
  // );



	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (<>
              <button onClick={()=>setShowArchive(false)} type="button" className="btn btn-primary">Back</button>
              <h4 style={{color:'#0070c0 '}}>Archive Lists</h4>
              <div className="d-flex align-items-center">
              <select onChange={(e)=>setSelectText(e.target.value)}  style={{    width: 'unset', margin:"0px 8px 0 0"}}
                       
                        className="drop-button form-control"
                        // {...register("eventType", { required: true })}
                      >
                        <option value=""> Filter By Column </option>
                        <option value="grpId"> Group Id </option>
                        <option value="awayNameData"> Away Team </option>
                        <option value="homeNameData"> Home Team </option>
                             
               </select>
            { selectText && <FilterComponent onFilter={(e)=>setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />}
              </div>
          </>
		);
	}, [filterText, selectText, resetPaginationToggle]);

  return (
    <>
      <UserHeader />
      {submitFormLoader && <div className="loader"></div>}
      <section className="bredcum sxa">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                <h1>My Events</h1>
                <Link to="/">Home</Link> <span> <i className="fa fa-angle-right" /> </span> <Link to="#" className="active">My Events</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="myaccount-sec">
          <div className="container">
            <div className="row">

              <div className="col-xl-12 col-lg-12">
                <div className="upcoming-right" style={{maxHeight:"initial",minHeight:"initial"}}>
                  <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                      <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Upcoming</a>
                      <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Live</a>
                      <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Completed</a>
                    </div>
                  </nav>
                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                      <div className="table-responsive vv">

                      <DataTable
                    columns={columns}
                    data={newArrayOfObj(state?.upcoming)}
                    pagination
                    persistTableHead
                  />
                      </div>
                    </div>
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                      <div className="table-responsive">
                      <div className="table-fixed vv">
                        <DataTable
                    columns={columns}
                    data={newArrayOfObj(state?.live)}
                    pagination
                    persistTableHead
                  />
                      </div>
                      </div>
                    </div>
                    <div className="tab-pane fade vv" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
               {   !showArchive &&  <div className={`d-flex align-items-baseline justify-content-end`}>
                  {/* { showArchive && <div className="d-flex">
                    <input
                      style={{ width: "195px", height: "35px" }}
                      className="form-control"
                      type="text"
                      placeholder="Filter Archive"
                      aria-label="Search Input"
                      // value={filterText}
                      // onChange={(e) => setFilterText(e.target.value)}
                    />
                    <ClearButton type="button">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </ClearButton>
                  </div>} */}
                    <button onClick={()=>setShowArchive(true) } type="button" className="btn btn-primary mb-2">Archive</button>
                    </div>}
                  
                    { showArchive ? 
                    <>
                   
                    <DataTable
                    columns={columns}
                    data={newArrayOfObj(archiveData?.filter(d => selectText ? d[selectText]?.includes(filterText.toUpperCase()) : d || '')) }
                    pagination
                    // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  /> </>:
                      <div className="table-responsive">

                      <DataTable
                    columns={columns}
                    data={newArrayOfObj(state?.completed)}
                    pagination
                    persistTableHead
                  />
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}

export default MyEvent;



const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="d-flex">
    <TextField
      id="search"
      type="text"
      placeholder="Search..."
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>
  </div>
);


const TextField = styled.input`
height: 32px;
width: 200px;
border-radius: 3px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border: 1px solid #e5e5e5;
padding: 0 32px 0 16px;

&:hover {
  cursor: pointer;
}
`;
const ClearButton = styled(Button)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
height: 34px;
width: 32px;
padding: 0px 25px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`;
