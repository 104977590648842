/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import moment from "moment";
import Swal from "sweetalert2";
import * as Constants from "../../../helper/constants";
import * as universalFunction from "../../../utils/universalFunctions";
import { useForm, Controller } from "react-hook-form";
import GameModal from "./gameModal";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import './userR.css'
// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const UserRegisterd = () => {

  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [user, setUser] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [metaData, setMetaData] = useState({});

  const {
    register,
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();


  // let subscribe = watch();

  // console.log(subscribe);

const findUser = async (d) => {
  try {
    setSubmitFormLoader(true);
    let res = await services.userRegistered(d);
    setUser(res.data.data);
    setSubmitFormLoader(false);
  } catch (error) {
    setSubmitFormLoader(false);
    toast.error(error.response.data.message, {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  }
}



  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => i + 1,

    },
    {
      name: "User Email",
      selector: (row) =>row.email,
      width:'210px'
    },
    {
      name: "Name",
      selector: (row) => row.firstName,
    },
    {
      name: "Last Name",
      width:'120px',
      selector: (row) => row.lastName,
    },
    {
      name: "Birth Date",
      selector: (row) => moment(row.dob).format("MM/DD/YYYY"),
      // sortable: true,
      width:'120px',
      // filterable: true,
    },
    {
      name: "Phone No",
      width:'150px',
      selector: (row) => row.mobileNumber,
    },

    {
      name: "Zip Code",
      selector: (row) => row.zipCode,
      sortable: true,
      width:'120px',
      filterable: true,
    },
    {
      name: "Secret Word",
      selector: (row) => row.secretWord,
      sortable: true,
      width:'120px',
      filterable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      width:'150px',
      filterable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
      filterable: true,
    },
    {
      name: "State",
      selector: (row) => row?.stateData[0]?.stateName,
      sortable: true,
      filterable: true,
    },

    {
      name: "Action",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <button onClick={() => findUserData(row.id)} className="btn btn-game">
            View
          </button>
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];


  const findUserData = async (id) => {
    try {
      setSubmitFormLoader(true);
      let res = await services.userRegisteredMeta(id);
      setMetaData(res.data.data);
      setSubmitFormLoader(false);
    } catch (e) {
      setSubmitFormLoader(false);
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    } 
  }

  const viewGame = (id) => {

    setData(id);
    setShow(true)

  }


  const cardColumns = [
    {
      name: "S. No.",
      selector: (row, i) => i + 1,
  
    },
    {
      name: "Cards No",
      selector: (row) => row.cardNumber || "10001"
    },
    {
      name: "Group Id	",
      selector: (row) => row?.groupData?.groupId,
    },
    {
      name: "Event Id",
      selector: (row) =>row?.eventData?.eventId,
      // sortable: true,
      // filterable: true,
    },
    {
      name: "Event Type",
      selector: (row) =>` ${universalFunction.findLeague(row?.eventData?.eventType)} (${row?.eventData?.eventType})`,
    },
  
    {
      name: "Event League",
      selector: (row) => universalFunction.findLeague(row?.eventData?.eventType, row?.eventData.league),
   
    },
  
    {
      name: "Action",
      selector: (row) => row.id,
      cell: (row) => (
        <>
          <button 
          onClick={() => viewGame(row)}
          className="btn btn-game">
            View
          </button>
        </>
      ),
      width: "8rem",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleClose = () => setShow(false);

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
      {submitFormLoader && <div className="loader"></div>}
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>Registered Users </h4>
                </div>
              </div>
            </div>
            <GameModal data={data} handleclose={handleClose} show={show}/>
            <div className="row mt-2 mb-4">
              <div className="col-lg-12 col-md-12">
                <div className="users-area">
                  <div>
                    <h5>Search User</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label> User Email</label>
                        <input
                          type="text"
                          {...register("email")}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label>Birth Date</label>                
                          <Controller
                          control={control}
                          name="dob"
                          {...register("dob")}
                          render={({ field: { value, onChange } }) => (
                              <DatePicker
                          className="form-control mb-0"
                          placeholderText="DOB"
                          selected={value ? moment(value).toDate() : value}
                          onChange={onChange}
                          showYearDropdown
                          dropdownMode="select"
                          maxDate={moment().subtract(18, 'years')._d}
                          dateFormatCalendar="MMMM"
                          scrollableYearDropdown
                          />
                          )}
                          />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label> Phone No</label>
                        <input
                          type="tel"
                          {...register("mobileNumber")}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label>Zip Code</label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          {...register("zipCode")}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          type="text"
                          {...register("lastName")}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <button onClick={handleSubmit(findUser)} className="btn admin-primary-btn">Search</button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="users-area-2">
                  <div>
                    <h5>Take Action</h5>
                  </div>
                  <div className="row vv">
                  <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={user}
                    pagination
                    persistTableHead
                  />
                  </div>
                
                    <div style={{background: '#eee'}} className="col-lg-12 nex col-xl-12 gamerules-sec mt-4">
                      <nav>
                        <div
                          className="nav nav-tabs nav-fill"
                          id="nav-tab userR"
                          role="tablist"
                        >
                          <a
                            className="nav-item nav-link active"
                            id="nav-home-tab"
                            data-toggle="tab"
                            href="#nav-def"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            Cards Played
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-generalrules-tab"
                            data-toggle="tab"
                            href="#nav-gen"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            Unclaimed Prizes
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-grouprules-tab"
                            data-toggle="tab"
                            href="#nav-grp"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                          >
                            Groups Entered
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-location-tab"
                            data-toggle="tab"
                            href="#nav-loc"
                            role="tab"
                            aria-controls="nav-about"
                            aria-selected="false"
                          >
                            Log Note
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-keypoints-tab"
                            data-toggle="tab"
                            href="#nav-kep"
                            role="tab"
                            aria-controls="nav-about"
                            aria-selected="false"
                          >
                            Password Help{" "}
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-emailques-tab"
                            data-toggle="tab"
                            href="#nav-email"
                            role="tab"
                            aria-controls="nav-about"
                            aria-selected="false"
                          >
                            Email Question
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-taxinfo-tab"
                            data-toggle="tab"
                            href="#nav-tax"
                            role="tab"
                            aria-controls="nav-about"
                            aria-selected="false"
                          >
                            Tax Information
                          </a>
                          <a
                            className="nav-item nav-link"
                            id="nav-changebio-tab"
                            data-toggle="tab"
                            href="#nav-bio"
                            role="tab"
                            aria-controls="nav-about"
                            aria-selected="false"
                          >
                            Change Bio
                          </a>
                        </div>
                      </nav>
                      <div
                      style={{background: '#eee'}}
                        className="tab-content py-4 px-3 px-sm-0"
                        id="nav-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="nav-def"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <h3 className="pt-4 pb-3">Cards Played</h3>
                          <div className="table-responsive">
                          <DataTable
                          columns={cardColumns}
                          data={metaData?.cardsPlayed}
                          pagination
                          persistTableHead
                        />
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-gen"
                          role="tabpanel"
                          aria-labelledby="nav-generalrules-tab"
                        >
                          <h3 className="pt-4 pb-3">Unclaimed Prizes</h3>
                          <div className="table-responsive">
                            <DataTable
                      columns={unclaimColumns}
                      data={metaData?.unclaimedPrizes}
                      pagination
                      persistTableHead
                    />
                        
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-grp"
                          role="tabpanel"
                          aria-labelledby="nav-grouprules-tab"
                        >
                          <h3 className="pt-4 pb-2">Groups Entered</h3>
                          <div className="table-responsive">
                          <DataTable
                      columns={groupColumns}
                      data={metaData?.groupsEnetered}
                      pagination
                      persistTableHead
                    />
                        
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-loc"
                          role="tabpanel"
                          aria-labelledby="nav-location-tab"
                        >
                          <h3 className="pt-4 pb-2">Log Note</h3>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-kep"
                          role="tabpanel"
                          aria-labelledby="nav-keypoints-tab"
                        >
                          <h3 className="pt-4 pb-2">Password Help</h3>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-email"
                          role="tabpanel"
                          aria-labelledby="nav-emailques-tab"
                        >
                          <h3 className="pt-4 pb-2">Email Question </h3>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-tax"
                          role="tabpanel"
                          aria-labelledby="nav-taxinfo-tab"
                        >
                          <h3 className="pt-4 pb-2">Tax Information</h3>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-bio"
                          role="tabpanel"
                          aria-labelledby="nav-changebio-tab"
                        >
                          <h3 className="pt-4 pb-2">Change Bio</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="spacer-foot" />
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default UserRegisterd;



const unclaimColumns = [
  {
    name: "S. No.",
    selector: (row, i) => i + 1,

  },
  // {
  //   name: "Date",
  //   selector: (row) => moment(row.eventDate).format("MM/DD/YYYY"),
  // },
  {
    name: "Group Id	",
    selector: (row) => row?.groupData?.groupId,
  },

  {
    name: "Event Id",
    selector: (row) => row?.eventData?.eventId,
  },

  {
    name: "Event League",
    selector: (row) => universalFunction.findLeague(row?.eventData?.eventType, row?.eventData.league),
    sortable: true,
    filterable: true,
  },

  {
    name: "Pattern",
    selector: (row) => row?.pattern,
  },
];

const groupColumns = [
  {
    name: "S. No.",
    selector: (row, i) => i + 1,

  },
  // {
  //   name: "Event Id",
  //   selector: (row) => row.eventId,
  // },
  
  // {
  //   name: "Card Cost",
  //   selector: (row) => row.eventId,
  // },
  {
    name: "Group Id	",
    selector: (row) => row.groupId,
  },
  {
    name: "Group Name",
    selector: (row) => row.groupName,
    sortable: true,
    filterable: true,
  },

  {
    name: "Group Type",
    selector: (row) => row.groupType,
    sortable: true,
    filterable: true,
  },

  {
    name: "Minimum Age",
    selector: (row) => row.age,
    sortable: true,
    filterable: true,
  }
  // {
  //   name: "Game Cards",
  //   selector: (row) => row.awayNameData,
  //   sortable: true,
  //   filterable: true,
  // }
];