/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignUpAction } from "../actions/userSignUpAction";
import { useHistory, Link } from "react-router-dom";
import services from "../services/services";
import { toast } from "react-toastify";
import "../css/bootstrap.min.css";
import "../css/responsive.css";
import "../css/master-style.css";
import "../css/slick-theme.css";
import "../css/slick.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./signup.css";
import { subDays } from "date-fns";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Import toastify css file
// import 'react-toastify/dist/ReactToastify.css';

// toast-configuration method,
// it is compulsory method.
toast.configure();

const SignUp = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [check, setcheck] = useState("");
  const [showPass1, setshowPass1] = useState(true);
  const [showPass, setshowPass] = useState(true);
  const [countries, setCountries] = useState({ countries: [] });
  const [states, setSates] = useState({ states: [] });
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [cities, setCities] = useState({ cities: [] });
  const [value, setValue] = useState("");

  const handleInputChange = (event) => {
    const { name, value, checked } = event.target;
    if (name === "term") {
      setcheck(checked);
    }

    setFormData((pre) => ({ ...pre, [name]: value }));
  };

  const dispatch = useDispatch();
  const handleInputChangeForCountryId = (Id, type) => {
    if (Id !== "" && type === "state") {
      services.listOfStates(Id).then((result) => {
        console.log("result", result);
        setSates({
          isLoadedStates: true,
          states: result.data.data,
        });
      });
    }

    if (Id !== "" && type === "city") {
      let countryId = Id.split(",")[0];
      let stateId = Id.split(",")[1];

      services.listOfCities(stateId, countryId).then((result) => {
        setCities({
          isLoadedStates: true,
          cities: result.data.data,
        });
      });
    }
    // setSates({
    //     isLoadedStates: true,
    //     states: []
    // });
  };

  function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const validateForm = () => {
    let formIsValid = true;

    if (!formData["name"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, name: "*Please enter your name." }));
    }

    if (!formData["lname"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, lname: "*Please enter your last name." }));
    }

    if (formData["name"]) {
      if (!formData["name"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        setErrors((pre) => ({
          ...pre,
          name: "*Please enter alphabet characters only.",
        }));
      }
    }
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!formData["email"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, email: "*Please enter your email id." }));
    }
    if (!pattern.test(formData["email"])) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, email: "*Please enter valid email id." }));
    }

    if (!value) {
      formIsValid = false;
      setErrors((pre) => ({
        ...pre,
        mobileNumber: "*Please enter your mobile no with country code.",
      }));
    }

    if (!formData["dob"]) {
      formIsValid = false;
      setErrors((pre) => ({
        ...pre,
        dob: "*Please enter your date of birth.",
      }));
    }

    if (getAge(formData["dob"]) < 18) {
      formIsValid = false;
      toast.error("age must be 18+", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (!formData["howToKnow"]) {
      formIsValid = false;
      setErrors((pre) => ({
        ...pre,
        howToKnow: "*Please select how did you hear about us?",
      }));
    }
    if (!formData["password"]) {
      formIsValid = false;
      setErrors((pre) => ({
        ...pre,
        password: "*Please enter your password.",
      }));
    }

    if (!formData["confirmPassword"]) {
      formIsValid = false;
      setErrors((pre) => ({
        ...pre,
        confirmPassword: "*Please enter your confirm password.",
      }));
    }
    if (!formData["address"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, address: "*Please enter your address." }));
    }

    if (!formData["country"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, country: "*Please select country." }));
    }

    if (!formData["state"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, state: "*Please select state." }));
    }

    if (!formData["city"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, city: "*Please enter city." }));
    }

    if (!formData["zipCode"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, zipCode: "*Please enter your zip code." }));
    }
    
    if (!formData["secretWord"]) {
      formIsValid = false;
      setErrors((pre) => ({ ...pre, secretWord: "*Please enter the secret word." }));
    }
    
    if (
      !formData["password"].match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d[\]{};:=<>_+^#$@!%*?&]{8,30}$/
      )
    ) {
      formIsValid = false;
      setErrors((pre) => ({
        ...pre,
        password: `*Please enter secure and strong password.
                                     Include both lower and upper case character.
                                    Include at least one number and one symbol (@#$%^...)`,
      }));
    }

    if (!check) {
      formIsValid = false;
      toast.error("Please Accept the Privacy Policy and Terms of Condition.",{
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }

    return formIsValid;
  };
  const signUp = async (e) => {
    try {
      e.preventDefault();
      if (validateForm()) {
        let stateId = formData.state.split(",");

        let data = {
          firstName: formData.name,
          lastName: formData.lname,
          email: formData.email,
          mobileNumber: value,
          dob: formData.dob,
          howToKnow: formData.howToKnow,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          address: formData.address,
          country: formData.country,
          state: stateId[1],
          city: formData.city,
          zipCode: formData.zipCode,
          secretWord:formData.secretWord
        };
        setSubmitFormLoader(true);
        let getdata = await dispatch(SignUpAction(data));
        setSubmitFormLoader(false);
        toast.success(getdata.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        if (getdata.data.message === "User registered successfully.") {
          return history.push("/login");
        }
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  };

  useEffect(async () => {
    services.listOfCountry().then((result) => {
      setCountries({
        isLoadedCountry: true,
        countries: result.data.data,
      });

      
    });
  handleInputChangeForCountryId(1,"state");
  setFormData((pre) => ({ ...pre, country: "USA" }));
  }, []);

  return (
    <section className="login-bg pt-4 pb-4 sign addC">
      {submitFormLoader && <div className="loader"></div>}
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-md-8 col-12 m-auto text-center">
            <div className="login-area">
              <div className="logo text-center">
                <img style={{ width: "inherit" }} src="logo.png" alt="Avatar" />
              </div>
              <h2>User Registration</h2>
              <form  name="loginform" onSubmit={signUp}>
                <div className="form-group mb-2">
                  <img src="icon-user.svg" alt="Avatar" className="icon" />
                  <input
                    controlid="name"
                    name="name"
                    type="text"
                    placeholder="First Name"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="form-group mb-2">
                  <img src="icon-user.svg" alt="Avatar" className="icon" />
                  <input
                    name="lname"
                    type="text"
                    placeholder="Last Name"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <div className="errorMsg">{errors.lname}</div>
                </div>
                <div className="form-group mb-2">
                  <img src="icon-mail.svg" alt="Avatar" className="icon2" />
                  <input
                    id="emailid"
                    name="email"
                    type="text"
                    placeholder="Email Id"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <div className="errorMsg">{errors.email}</div>
                </div>
                <div className="form-group mb-0">
                  {/* <img src="icon-phone.svg" alt="Avatar" className="icon" /> */}
                  {/* <input id="phone" type="tel" name="mobileNumber" placeholder="Mobile Number" autoFocus="off" autoComplete="off" className="form-control" onChange={handleInputChange} /> */}
                  <PhoneInput
                    defaultCountry={"US"}
                    addInternationalOption={false}
                    countries={["US"]}
                    // international = {false}
                    placeholder="Enter mobile number"
                    value={value}
                    limitMaxLength={10}
                    className="form-control mb-0"
                    onChange={setValue}
                  />
                  <div className="errorMsg">{errors.mobileNumber}</div>
                </div>
                <div className="form-group mb-2">
                  <img
                    style={{ zIndex: 2 }}
                    src="icon-calendar.svg"
                    alt="Avatar"
                    className="icon"
                  />
                  {/* <input
                    type="date"
                    className="datepicker-here-1 form-control mb-0"
                    data-language="en"
                    name="dob"
                    placeholder="Date of Birth"
                    onChange={handleInputChange}
                  /> */}
                  <DatePicker
                    className="form-control mb-0"
                    placeholderText="DOB"
                    selected={formData['dob']}
                    onChange={date=>setFormData((pre) => ({ ...pre, ["dob"]: date }))}
                    name="dob"
                    showYearDropdown
                    maxDate={moment().subtract(18, 'years')._d}
                    dateFormatCalendar="MMMM"
                    dropdownMode="select"
                    scrollableYearDropdown
                  />
                  <div className="errorMsg">{errors.dob}</div>
                </div>
                <div className="form-group mb-2">
                  <img src="icon-share.svg" alt="Avatar" className="icon" />
                  <select
                    className="form-control mb-0"
                    id="sel1"
                    name="howToKnow"
                    onChange={handleInputChange}
                  >
                    <option>How did you hear about us?</option>
                    <option>Television</option>
                    <option>Newspaper</option>
                    <option>Friends</option>
                  </select>
                  <div className="errorMsg">{errors.howToKnow}</div>
                </div>
                <div className="form-group mb-2">
                <img src="icon-password.svg" alt="Avatar" className="icon" />
                  <input
                    name="secretWord"
                    type="text"
                    placeholder="Secret Word"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <div className="errorMsg">{errors.secretWord}</div>
                </div>
                <div className="form-group mb-2">
                  <img src="icon-password.svg" alt="Avatar" className="icon" />
                  <input
                    id="username"
                    type={showPass1 ? "password" : "text"}
                    name="password"
                    placeholder="Password"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <i
                    onClick={() => setshowPass1(!showPass1)}
                    class={
                      showPass1 ? "fa fa-eye-slash hpass" : "fa fa-eye hpass"
                    }
                  ></i>
                  <div className="errorMsg">{errors.password}</div>
                </div>
                <div className="form-group mb-2">
                  <img src="icon-password.svg" alt="Avatar" className="icon" />
                  <input
                    id="username"
                    type={showPass ? "password" : "text"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <i
                    onClick={() => setshowPass(!showPass)}
                    class={
                      showPass ? "fa fa-eye-slash hpass" : "fa fa-eye hpass"
                    }
                  ></i>
                  <div className="errorMsg">{errors.confirmPassword}</div>
                </div>
                <div className="form-group mb-2">
                  <img src="placeholder.svg" alt="Avatar" className="icon" />
                  <input
                    id="address"
                    name="address"
                    type="address"
                    placeholder="Address"
                    autoFocus="off"
                    autoComplete="off"
                    className="form-control mb-0"
                    onChange={handleInputChange}
                  />
                  <div className="errorMsg">{errors.address}</div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <img
                        src="placeholder.svg"
                        alt="Avatar"
                        className="icon2"
                      />
                      <select
                        className="form-control mb-0"
                        id="sel1"
                        name="country"
                        onChange={(e) => {
                          handleInputChangeForCountryId(
                            e.target.value,
                            "state"
                          );
                          handleInputChange(e);
                        }}
                      >
                        {countries.countries.map((countries) => (
                          <>
                            <option value="">Select Country</option>
                            <option selected={countries.country==="USA"} value={countries.id}>
                              {countries.country}
                            </option>
                          </>
                        ))}
                      </select>
                      <div className="errorMsg">{errors.country}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <img
                        src="placeholder.svg"
                        alt="Avatar"
                        className="icon2"
                      />
                      <select
                        className="form-control mb-0"
                        name="state"
                        onChange={(e) => {
                          // handleInputChangeForCountryId(e.target.value, "city");
                          handleInputChange(e);
                        }}
                      >
                        <option value="">Select State</option>
                        {states.states.map((states) => (
                          <>
                            <option value={[states.countryId, states.id]}>
                              {states.stateName}
                            </option>
                          </>
                        ))}
                      </select>
                      <div className="errorMsg">{errors.state}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <img
                        src="placeholder.svg"
                        alt="Avatar"
                        className="icon2"
                      />
                      <input
                        id="sel1"
                        type="text"
                        placeholder="Enter Your City"
                        autoFocus="off"
                        autoComplete="off"
                        name="city"
                        className="form-control mb-0"
                        onChange={handleInputChange}
                      />

                      <div className="errorMsg">{errors.city}</div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <img
                        src="placeholder.svg"
                        alt="Avatar"
                        className="icon2"
                      />

                      <input
                        id="pincode"
                        type="text"
                        placeholder="Zip Code"
                        autoFocus="off"
                        autoComplete="off"
                        name="zipCode"
                        className="form-control mb-0"
                        onChange={handleInputChange}
                      />
                      <div className="errorMsg">{errors.zipCode}</div>
                    </div>
                  </div>

                  <div className="d-flex col-md-12 justify-content-between align-items-center rem-pass">
                    {/* Checkbox */}
                    <div className="form-check mb-0">
                      <input
                        onChange={handleInputChange}
                        name="term"
                        className="form-check-input me-2"
                        type="checkbox"
                        defaultValue
                        id="form2Example3"
                      />
                      <label
                        className="form-check-label-2"
                        htmlFor="form2Example3"
                      >
                        I have read and agree of{" "}
                        <Link to="/user/privacy-policy">
                          {" "}
                          <span style={{ color: "#0070C0" }}>
                            Privacy Policy
                          </span>{" "}
                        </Link>
                        and{" "}
                        <Link to="/user/terms-condition">
                          <span style={{ color: "#0070C0" }}>
                            Terms of Use
                          </span>
                        </Link>
                        .
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  bsstyle="primary"
                  className="btn btn-primary btn-block mt-4 mb-2"
                >
                  Register
                </button>
              </form>
              <p className="text-sign">
                Already have an account? <Link to="/login">Sign In</Link>{" "}
              </p>
            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
