/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import gfg from "../../../assets/images/gfgg.png";
import loccard from "../../../assets/images/loc-card.png";
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import iconC from "../../../assets/images/icon-calendar.svg";
import "./gamecard.css";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import AdGameCard from "../preview-advertisement/adGameCard";
import services from "../../../services/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import firebConfig from "../../../firebase/firebaseConfig";
import moment from "moment";
import * as universalFunction from "../../../utils/universalFunctions";
// toast-configuration method,
// it is compulsory method.
toast.configure();




function GameCard(prop) {
  const [showCard, setCards] = useState([]);
  const [eventData, setEventData] = useState({});
  const [gameIndex, setGameIndex] = useState("");
  const [greenBox, setgreenBox] = useState([]);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [gameCall, setGameCall] = useState("");
  const [miniCallData, setMiniCallData] = useState([]);
  const [blur, setBlur] = useState(false);
  const [gameImg, setGameImg] = useState([]);
  const firebaseInit = firebase.initializeApp(firebConfig);
  let history = useHistory();
  const [gIndex, setGIndex] = useState(0)
  const [gIndex2, setGIndex2] = useState(1)
  const [showElement,setShowElement] = useState(false)
  const [showElement2,setShowElement2] = useState(false)


  useEffect(()=>{
    if(gameImg?.length){
      setShowElement(false);
      if(gIndex > gameImg?.length-1){
        setGIndex(0);
     }
    }
    
  },[gIndex])

  useEffect(()=>{
    if(gameImg?.length){
      setShowElement2(false);
      if(gIndex2 > gameImg?.length-1){
        setGIndex2(1);
     }
    }
    
  },[gIndex2])

  useEffect(() => {
    if(gameImg?.length > 0){
      let time = (gameImg[gIndex]?.runTime + gameImg[gIndex]?.delay) * 1000;
      const interval = setInterval(() => {/*todo reset at max value*/ setGIndex((i)=>i+2)},  time)
      return () => clearInterval(interval)
    }
  },[gameImg]);

  useEffect(() => {
    if(gameImg?.length > 0){
      let time = (gameImg[gIndex2]?.runTime + gameImg[gIndex2]?.delay) * 1000;
      const interval = setInterval(() => {/*todo reset at max value*/ setGIndex2((i)=>i+2)},  time)
      return () => clearInterval(interval)
    }
  },[gameImg]);


  useEffect(()=>{
    if(gameImg?.length > 0){
      const timer =   setTimeout(function() {
      setShowElement(true)
         }, gameImg[gIndex]?.runTime * 1000);
        return () => clearTimeout(timer);

        }
       },
   [gIndex, gameImg]);

  //  console.log("delay and run",showElement,gIndex,gameImg.length);
  //  console.log("showElement",showElement, gIndex);
  useEffect(()=>{
    if(gameImg?.length > 0){
      const timer =  setTimeout(function() {
      setShowElement2(true)
         }, gameImg[gIndex2]?.runTime * 1000);
         return () => clearTimeout(timer);
        }
       },
   [gIndex2, gameImg])



  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      try {
        // let database = firebase.database();
        setSubmitFormLoader(true);
        let data = prop.location?.state?.newData?.id ? await services.enterGameCardEvents(prop.location?.state?.newData?.d) :  await services.associatedUser({...prop.location?.state?.newData,date:moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')});
        setGameImg(universalFunction.adsControlImgShuffle(data?.data?.data?.adsToDisplay) || []);
        // setGameImg(data?.data?.data?.adsToDisplay || [])
        if (data.data.data.eventObj?.isEventCancelled) {
          toast.info("Event has been Cancelled", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }
        
        else if(data.data.data.eventObj?.isEventStarted && !data.data.data.eventObj?.isEventFinished){        
          toast.warn("Game Card active!!", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }

        
     
        else if (!data.data.data.fireBaseCallerBoardId) {
          toast.warn("Game Card active!!", {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }

        setCards(JSON.parse(data.data.data?.card));
        setEventData(data.data?.data)

        firebaseInit
          .database()
          .ref(`callerboards/${data.data.data?.fireBaseCallerBoardId}`)
          .on("value", async(snapshot) => {
            // console.log("here234234jn23hjk23hjkh3",snapshot.val());
            try{
              // if(snapshot.val()?.isEventFinished){
              //   setSubmitFormLoader(false);
              //   return
              // }
              let isEventCancelledForGroup = data?.data?.data?.isEventCancelledForGroup;
              let factName = findFactor(data.data.data?.numberOfFactor);

               setGameCall(factName);
              // console.log(snapshot.val()?.calls);
              let filterData =  data?.data?.data?.eventObj?.eventType==="A" ? snapshot.val()?.calls : snapshot.val()?.calls?.filter((data) => data[factName] != "R");
              let gameCallArr = filterData?.map((d,i)=>d[factName]) || [];
              let singleArrayCard = await universalFunction.singleArray(gameCallArr,JSON.parse(data?.data?.data?.card).flat());
               let greenCard =  await universalFunction.greenColorBox(singleArrayCard,data?.data?.data?.winParameters)
               !isEventCancelledForGroup && setgreenBox(greenCard)


               !isEventCancelledForGroup && setMiniCallData(universalFunction.chunckArr(filterData, setGameIndex));
              setSubmitFormLoader(false);
            }catch(e){
              toast.error(e.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                toastId: "1",
              });
              setSubmitFormLoader(false);
            }
          });


     
      } catch (e) {
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setBlur(true)
        setSubmitFormLoader(false);
      }
    }

    fetchData();
  }, []);


const findFactor = (data) => {
if(data==4) return "callForFourFactor" 
if(data==6) return "callForSixFactor" 
if(data==8) return "callForEightFactor" 
}

const navigateS = () => {

if(eventData?.isEventCancelledForGroup){
 return toast.error("Event has been Cancelled for this group.", {
  position: toast.POSITION.TOP_CENTER,
  toastId: "1",
});
}

 return history.push({
        pathname: `/user/result/${eventData?.eventId}`, 
        state: !prop.location?.state?.newData.id && { data :  prop.location?.state?.newData, id: eventData?.fireBaseCallerBoardId, winParameterId:eventData?.winParameterId, numberOfFactor:eventData?.numberOfFactor} || { data :prop.location?.state?.newData?.d,id: eventData?.fireBaseCallerBoardId, winParameterId:eventData?.winParameterId, numberOfFactor:eventData?.winParameterId}
            })
}

// gameCall.length = 0 
// console.log(miniCallData[3]?.find((d=>d[gameCall]==showCard[0][3])) && alert('a'));
// universalFunction.compareArray
// console.log(miniCallData.flat()?.map((d,i)=>d[gameCall]));

  return (
    <>
      <UserHeader />

      <section className="bredcum">
        {submitFormLoader && <div className="loader"></div>}
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>Game Cards</h1>
              <Link to="/">Home</Link>{" "}
              <span>
                <i className="fa fa-angle-right" />{" "}
              </span>
              <Link to="#" onClick={()=>history.goBack()}>Associated Groups Details</Link>{" "}
              <span>
                <i className="fa fa-angle-right" />{" "}
              </span>
              <Link to="#" onClick={e => e.preventDefault()} className="active">
                Game Cards
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="gamecards-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="heading-sec">
                <h2>KIBU CARD</h2>
                {/* <h4 className="pb-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </h4> */}
                 <p style={{fontSize:'16px'}} className="text-sign mt-1"><b>KiBu Gaming Corporation ©2019-{new Date().getFullYear()}</b></p>
                <h5 className="pt-4">An affiliate relationship exists with some of our fine advertisers.  We may be
                compensated for purchases you make from them.  Purchases do not increase
                your odds of winning.  No purchase necessary to play.   
                 </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={`card-bg ${blur && "blur-gameCard"}`}>
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3">
              <div className="banner-add">
          {  !showElement &&  <AdGameCard gameCard={gameImg[gIndex]} />}
                {/* <img src={gfg} className="img-fluid" alt="" /> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 m-auto">
              <div className="card-area">
                <div className="card-top">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <h2 className="text-center">Carrejoue/SQUAREPLAY</h2>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h6>{eventData?.awayNameData?.partnerName} VS {eventData?.homeNameData?.partnerName}</h6>
                  </div>
                    <div className=" col-xl-1 col-lg-2 col-md-1 col-2 pb-3">
                      <img src={loccard} alt="" />
                    </div>
                    <div className=" col-xl-10 col-lg-10 col-md-10 col-10 pl-0 pb-3">
                     {eventData?.eventObj?.eventLocation}
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-xl-1 col-lg-2 col-md-1 col-2 cont">
                      <img src={iconC} alt="" />
                    </div>
                    <div className=" col-xl-10 col-lg-10 col-md-10 col-10 cont pl-0">
                      {/* {moment(eventData?.eventDate).toDate()} */}
                      {moment(eventData?.eventObj?.eventDate).format("MM/DD/YYYY")}
                    </div>
                    <div style={{width:"100%"}}>
                      <p className="text-danger text-center m-0">{eventData?.isEventCancelledForGroup && "*Event has been Cancelled for this group*"}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-xl-6 col-lg-6 col-md-6 col-12 cont">
 {/* {(eventData?.numberOfFactor==8) && Constants.BASEBALL_EIGHT_FACTOR.map(data=><span>{data}</span>) ||
  (eventData?.numberOfFactor==6) && Constants.BASEBALL_SIX_FACTOR.map(data=><span>{data}</span>) ||
  (eventData?.numberOfFactor==4) && Constants.BASEBALL_FOUR_FACTOR.map(data=><span>{data}</span>)} */}
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12 cont pl-0 text-md-right">
                      <strong>$14 Wagered</strong>{" "}
                    </div> */}
                  </div>
                </div>
                <div className="line-bod" />
                <div className="text-center card-number"> CARD : #{eventData?.cardNumber}</div>
                <div className="card-btn-top">
                  <div className="row">
                    <div className=" col-xl-6 col-lg-6 col-md-6 col-6">
                      {" "}
                      <button 
                      onClick={navigateS}  
                      // to ={{
                      // pathname: `/user/result/${eventData?.eventId}`, 
                      // state: !prop.location?.state?.newData.id && { data :  prop.location?.state?.newData, id: eventData?.fireBaseCallerBoardId, winParameterId:eventData?.winParameterId, numberOfFactor:eventData?.numberOfFactor} || { data :prop.location?.state?.newData?.d,id: eventData?.fireBaseCallerBoardId, winParameterId:eventData?.winParameterId, numberOfFactor:eventData?.winParameterId}
                      //      }} 
                     className="btn status-btn">
                        Status
                      </button>{" "}
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-6 text-md-right">
                      {" "}
                      <button className="btn prizes-btn">Prizes</button>{" "}
                    </div>
                  </div>
                </div>
                <div className="card-design-outer">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">{eventData?.patternPlayedMultipleWinVersion}</div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">{eventData?.eventTypeLeagueFactorCardVersion}</div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center">
                      {" "}
                      <div className="btntop-txt">{eventData?.cardCreatedTime}</div>
                    </div>
                  </div>
                  <div className="card-inner-design">
                    <div className="card-wrap">
                      <div style={{background:greenBox[0] ? "green" : miniCallData[0]?.find((d=>d[gameCall]==showCard[0][0])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 1 && "current"
                            }`}>
                        <div className="card-pattern">
                        {miniCallData.length > 0 && miniCallData[0].length > 0 && miniCallData[0]?.map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}
                        </div>
                        <div className="pattern-main">
                          {showCard.length && showCard[0][0]}
                        </div>
                      </div>
                      <div style={{background:greenBox[1] ? "green" : miniCallData[1]?.find((d=>d[gameCall]==showCard[0][1])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 2 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[1]?.length > 0 && miniCallData[1].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[0][1]}
                        </div>
                      </div>
                      <div style={{background:greenBox[2] ? "green" : miniCallData[2]?.find((d=>d[gameCall]==showCard[0][2])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 3 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[2]?.length > 0 && miniCallData[2].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[0][2]}
                        </div>
                      </div>
                      <div style={{background:greenBox[3] ? "green" : miniCallData[3]?.find((d=>d[gameCall]==showCard[0][3])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 4 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[3]?.length > 0 && miniCallData[3].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[0][3]}
                        </div>
                      </div>
                      <div style={{background:greenBox[4] ? "green" : miniCallData[4]?.find((d=>d[gameCall]==showCard[0][4])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 5 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[4]?.length > 0 && miniCallData[4].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[0][4]}
                        </div>
                      </div>
                    </div>
                    <div className="card-wrap">
                      <div style={{background:greenBox[5] ? "green" : miniCallData[5]?.find((d=>d[gameCall]==showCard[1][0])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 6 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[5]?.length > 0 && miniCallData[5].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[1][0]}
                        </div>
                      </div>
                      <div style={{background:greenBox[6] ? "green" : miniCallData[6]?.find((d=>d[gameCall]==showCard[1][1])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 7 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[6]?.length > 0 && miniCallData[6].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[1][1]}
                        </div>
                      </div>
                      <div style={{background:greenBox[7] ? "green" : miniCallData[7]?.find((d=>d[gameCall]==showCard[1][2])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 8 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[7]?.length > 0 && miniCallData[7].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[1][2]}
                        </div>
                      </div>
                      <div style={{background:greenBox[8] ? "green" : miniCallData[8]?.find((d=>d[gameCall]==showCard[1][3])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 9 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[8]?.length > 0 && miniCallData[8].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[1][3]}
                        </div>
                      </div>
                      <div style={{background:greenBox[9] ? "green" : miniCallData[9]?.find((d=>d[gameCall]==showCard[1][4])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 10 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[9]?.length > 0 && miniCallData[9].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[1][4]}
                        </div>
                      </div>
                    </div>
                    <div className="card-wrap">
                      <div style={{background:greenBox[10] ? "green" : miniCallData[10]?.find((d=>d[gameCall]==showCard[2][0])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 11 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[10]?.length > 0 && miniCallData[10].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[2][0]}
                        </div>
                      </div>
                      <div style={{background:greenBox[11] ? "green" : miniCallData[11]?.find((d=>d[gameCall]==showCard[2][1])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 12 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[11]?.length > 0 && miniCallData[11].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[2][1]}
                        </div>
                      </div>
                      <div style={{background:greenBox[12] ? "green" : miniCallData[12]?.find((d=>d[gameCall]==showCard[2][2])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 13 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[12]?.length > 0 && miniCallData[12].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[2][2]}
                        </div>
                      </div>
                      <div style={{background:greenBox[13] ? "green" : miniCallData[13]?.find((d=>d[gameCall]==showCard[2][3])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 14 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[13]?.length > 0 && miniCallData[13].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[2][3]}
                        </div>
                      </div>
                      <div style={{background:greenBox[14] ? "green" : miniCallData[14]?.find((d=>d[gameCall]==showCard[2][4])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 15 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[14]?.length > 0 && miniCallData[14].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[2][4]}
                        </div>
                      </div>
                    </div>
                    <div className="card-wrap">
                      <div style={{background:greenBox[15] ? "green" : miniCallData[15]?.find((d=>d[gameCall]==showCard[3][0])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 16 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[15]?.length > 0 && miniCallData[15].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[3][0]}
                        </div>
                      </div>
                      <div style={{background:greenBox[16] ? "green" : miniCallData[16]?.find((d=>d[gameCall]==showCard[3][1])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 17 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[16]?.length > 0 && miniCallData[16].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[3][1]}
                        </div>
                      </div>
                      <div style={{background:greenBox[17] ? "green" : miniCallData[17]?.find((d=>d[gameCall]==showCard[3][2])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 18 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[17]?.length > 0 && miniCallData[17].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[3][2]}
                        </div>
                      </div>
                      <div style={{background:greenBox[18] ? "green" : miniCallData[18]?.find((d=>d[gameCall]==showCard[3][3])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 19 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[18]?.length > 0 && miniCallData[18].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[3][3]}
                        </div>
                      </div>
                      <div style={{background:greenBox[19] ? "green" : miniCallData[19]?.find((d=>d[gameCall]==showCard[3][4])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 20 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[19]?.length > 0 && miniCallData[19].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[3][4]}
                        </div>
                      </div>
                    </div>
                    <div className="card-wrap">
                      <div style={{background:greenBox[20] ? "green" : miniCallData[20]?.find((d=>d[gameCall]==showCard[4][0])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 21 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[20]?.length > 0 && miniCallData[20].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[4][0]}
                        </div>
                      </div>
                      <div style={{background:greenBox[21] ? "green" : miniCallData[21]?.find((d=>d[gameCall]==showCard[4][1])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 22 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[21]?.length > 0 && miniCallData[21].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[4][1]}
                        </div>
                      </div>
                      <div style={{background:greenBox[22] ? "green" : miniCallData[22]?.find((d=>d[gameCall]==showCard[4][2])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 23 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[22]?.length > 0 && miniCallData[22].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[4][2]}
                        </div>
                      </div>
                      <div style={{background:greenBox[23] ? "green" : miniCallData[23]?.find((d=>d[gameCall]==showCard[4][3])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 24 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[23]?.length > 0 && miniCallData[23].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[4][3]}
                        </div>
                      </div>
                      <div style={{background:greenBox[24] ? "green" : miniCallData[24]?.find((d=>d[gameCall]==showCard[4][4])) && '#ffbc00'}} className={`card-box ${
                              gameIndex === 25 && "current"
                            }`}>
                        <div className="card-pattern">{miniCallData.length > 0 && miniCallData[24]?.length > 0 && miniCallData[24].map((d,i)=><span style={{color:d?.color && 'red' }} key={i}>{d[gameCall]}</span>)}</div>
                        <div className="pattern-main">
                          {showCard.length && showCard[4][4]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">{eventData?.groupIdCardDateCreated}</div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <div className="btntop-txt">
                      {/* {eventData?.eventDateNumber} */}
                      {eventData?.eventObj?.eventId?.slice(2)}
                      </div>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center">
                      {" "}
                      <div className="btntop-txt">{eventData?.partnerIdSaleSpot || "C309k"}</div>
                    </div>
                  </div>
                </div>
                <div className="button-wrap">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <Link to="#" className="mycard-btn">
                        My Cards
                      </Link>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center btn-res">
                      {" "}
                      <Link to="#" className="symbol-btn">
                        Symbols
                      </Link>{" "}
                    </div>
                    <div className="col-lg-4 col-md-4 text-center">
                      <Link to="#" className="validate-btn">
                        Validate
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 text-lg-right">
              <div className="banner-add">
            { !showElement2 && <AdGameCard gameCard={gameImg[gIndex2]} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}

export default withRouter(GameCard);
