import { Button, Modal } from "react-bootstrap";

function Example({ handleclose, show1, entergame, msg, onchange }) {
  return (
    <>
      {/* <Button variant="primary" onClick={handleshow}>
          Launch static backdrop modal
        </Button>
   */}
      <Modal
        centered
        show={show1}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Enter Game Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="dropdown ">
              <label>Password</label>
              <input
                type="text"
                className="form-control"
                onChange={onchange}
                name="lat"
                data-language="en"
                placeholder="Password"
              />
              <div className="errorMsg">{msg}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleclose}>
            Close
          </Button>
          <Button style={{background:'#0070C0',padding:'10px 30px'}} onClick={entergame} variant="primary">
            Enter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export { Example };
