/* eslint-disable eqeqeq */
import UserHeader from "../../core/header/userHeader";
import { useState } from "react";
import UserCarousel from "./userCarousel";
import { useHistory } from "react-router-dom";
import { UserSearch } from "./user-search";
import { UserEvent } from "./user-event";
import { CarrejoueVideos } from "./carrejoue-videos";
import { LatestNewsAndBlogs } from "./news-blogs";
import { Subscribe } from "./subscribe";
import { Sponsors } from "./sponsors";
import { UserFooter } from "../../core/footer/userFooter";
import { useEffect } from "react";
import { BeenSaid } from "./beenSaid";
import services from "../../../services/services";
import { toast } from "react-toastify";
import moment from "moment";
toast.configure();


const UserDashboard = () => {
  let history = useHistory();
  const [event, setEvent] = useState([]);
  const [val, setVal] = useState({});
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("role") == "2") return history.push("/");
    if (localStorage.getItem("role") == "1") {
      history.push("/admin-dashboard");
    }
    if (!localStorage.getItem("guest")) {
      history.push("/login");
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVal((p) => ({ ...p, [name]: value }));
  };

  const { search, startDate, endDate } = val;

  const searchData = async () => {
    if (moment(startDate).format("YYYY-MM-DD HH:mm:ss") > moment(endDate).format("YYYY-MM-DD HH:mm:ss") ) {
      return   toast.error("Start date should not be greater than end date", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
  }
    let data = await services.upComingEvent(search, startDate, endDate);
    setEvent(data.data.data);
  };

  const nextEvent = async (id) => {
    setSubmitFormLoader(true);
    let data = await services.upComingEvent(search, startDate, endDate,id);
    setEvent(data.data.data);
    setSubmitFormLoader(false);
  };

  return (
    <>
      <UserHeader />
      <UserCarousel data={event} />
      <UserSearch onChange={handleChange} onClick={searchData} />
      <UserEvent loader={submitFormLoader} fetchData={nextEvent} data={event} />
      <BeenSaid />
      <CarrejoueVideos />
      <LatestNewsAndBlogs />
      {/* <Subscribe /> */}
      <Sponsors/>
      <UserFooter />
    </>
  );
};

export default UserDashboard;
