import http from "./http.common";
import axios from "axios";
import moment from "moment";

class DataService {
  // ****************************************** Authentication *************************************************************

  // Api call for user sign up
  async create(data) {
    return axios.post(`${http.baseURL}/user`, data);
  }

  // Api call for user login
  async login(data) {
    return axios.post(`${http.baseURL}/login`, data);
  }
  // Api call for reset password
  async resetPassword(data) {
    return axios.put(`${http.baseURL}/reset-password`, data);
  }

    // Api call for change password
    async changePassword(data) {
      return axios.put(`${http.baseURL}/change-password`, data,{
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    }

  // Api call for user forgot password
  async forgotPassword(data) {
    return axios.post(`${http.baseURL}/forgot-password`, data);
  }

  // Api call for verify otp
  async verifyOtp(data) {
    return axios.post(`${http.baseURL}/verify-otp`, data);
  }
  // Api call for user logout
  async logoutUser(id) {
    return axios.delete(`${http.baseURL}/logout/${id}`);
  }
  // ****************************************** For the public *************************************************************
  // Api call for list of states
  async listOfStates(countryId) {
    return axios.get(`${http.baseURL}/state/country/${countryId}`);
  }

  // Api call for list of cities
  async listOfCities(stateId) {
    return axios.get(`${http.baseURL}/cities/state/${stateId}`);
  }

  // Api call for list of countries
  async listOfCountry() {
    return axios.get(`${http.baseURL}/countries`);
  }

  // ****************************************** For the User *************************************************************

  // Api call for get user information
  async userInfo() {
    return axios.get(`${http.baseURL}/user`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update user information
  async userUpdate(data) {
    return axios.put(`${http.baseURL}/user`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update user information
  async contactUs(data) {
    return axios.post(`${http.baseURL}/contact-us/send/otp`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for contact-us otp
  async contactUsOtp(data) {
    return axios.post(`${http.baseURL}/contact-us/verify-otp`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update All coming Event
  async upComingEvent(eventLocation = "", startDate = "", endDate = "",page=0) {
    return await axios.get(
      `${http.baseURL}/events/upcoming?page=${page===0 ? 0 : page-1}&size=${10}&search=${eventLocation}&startDate=${startDate}&endDate=${endDate}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for searching groups
  async searchGroup({groupName = "", groupLocation = "", groupId = "",groupLocationToFind ="",country="",lat="",lng=""}) {
    return await axios.get(
      `${http.baseURL}/groups-for-user?groupName=${groupName}&groupLocation=${groupLocationToFind}&groupId=${groupId}&groupLocationToFind=${groupLocation}&country=${country}&latitude=${lat}&longitude=${lng}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for the exclusion form

  async exclusion(data) {
    return await axios.post(
      `${http.baseURL}/user/validate/self-exclusion`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for the exclusion form without login

  async exclusionWithoutLogin(data) {
    return await axios.post(
      `${http.baseURL}/user/self-exclusion/without-login`,
      data
    );
  }

  // Api call for the exclusion form verify otp

  async exclusionOtpVerify(data) {
    return await axios.post(
      `${http.baseURL}/user/self-exclusion/verify-otp`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for the exclusion form time period

  async exclusionTimePeriod(data) {
    return await axios.post(`${http.baseURL}/user/self-exclusion`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }


    // Api call for get ad Fame Card image list 
    async adGameCardImage() {
      return axios.get(`${http.baseURL}/advertisements/game-cards`,{
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    }

  // ****************************************** For the Admin *************************************************************

  // Api call for get adbanner image list 
  async adBannerImage() {
    return axios.get(`${http.baseURL}/advertisements/ad-banner`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get adsponsor image list 
  async adSponsorImage() {
    return axios.get(`${http.baseURL}/advertisements/sponsor-ribbon`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

    // Api call for create advertisement
    async createAdvertisement(data) {
      return axios.post(`${http.baseURL}/advertisement`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    }

  // Api call for get all advertisements
  async getAdvertisementList(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/advertisements?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get all  active advertisements for user dashboard
  async getActiveAdvertisementList() {
    return axios.get(`${http.baseURL}/advertisements/active`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get single advertisement
  async getAdvertisement(id) {
    return axios.get(`${http.baseURL}/advertisement/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update or edit advertisement
  async updateAdvertisement(id, data) {
    return axios.put(`${http.baseURL}/advertisement/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for delete advertisement

  async deleteAdvertisement(advertisementeventId) {
    return axios.delete(
      `${http.baseURL}/advertisement/${advertisementeventId}`,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        data: {
          isDelete: true,
          modelType: "Advertisement",
        },
      }
    );
  }

  // Api call for update status of advertisement
  async updateAdvertisementStatus(id, data) {
    return axios.put(`${http.baseURL}/advertisement-status/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // ==================================================================
  // Api call for create of partner
  async partner(data) {
    return axios.post(`${http.baseURL}/partner`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for list of partner
  async partners(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/partner?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get single partner
  async getPartner(id) {
    return axios.get(`${http.baseURL}/partner/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update or edit partner
  async updatePartner(id, data) {
    return axios.put(`${http.baseURL}/partner/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for delete partner

  async deletePartner(id) {
    return axios.delete(`${http.baseURL}/partner/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // ===============================================================================
  // Api call for create of event
  async event(data) {
    return axios.post(`${http.baseURL}/event`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for list of event
  async events(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/events?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }


  // Api call for list the all callBoard event
  async callerBoardEvents(page=0,countPerPage=10, eventType="") {
    return axios.get(`${http.baseURL}/events-for-callerboard?eventType=${eventType}&page=${page===0 ? 0 : page-1}&size=${countPerPage}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

 // Api call for get single events
 async getEvent(id) {
  return axios.get(`${http.baseURL}/event/${id}`, {
    headers: {
      Authorization: localStorage.getItem("token"),
    },
  });
}


  // Api call for get filter event for group
  async getFilterEventGroup(type, league) {
    return axios.get(`${http.baseURL}/events/type/league?eventType=${type}&league=${league}&size=${100}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }


  // Api call for get filter card parameter for group
  async getFilterCardPGroup(type, league, factor) {
    return axios.get(`${http.baseURL}/card-parameter/type/league/factor?eventType=${type}&league=${league}&assignFactor=${factor}&size=${100}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update or edit advertisement
  async updateEvent(id, data) {
    return axios.put(`${http.baseURL}/event/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for delete advertisement

  async deleteEvent(eventId,reason) {
    return axios.delete(`${http.baseURL}/event/${eventId}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        isDelete: true,
        modelType: "Event",
        deleteReason:reason,
        date:moment().format("MM-DD-YYYY HH:mm")
      },
    });
  }

  // Api call for update status of event
  async updateEventStatus(id, data) {
    return axios.put(`${http.baseURL}/event-status/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for create of group
  async group(data) {
    return axios.post(`${http.baseURL}/group`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for list of groups
  async groups(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/groups?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for list of new groups
  async groupsU(page=0,countPerPage=10, country="", loc="",lat="",long="") {
    return axios.get(`${http.baseURL}/groups-user?page=${page===0 ? 0 : page-1}&size=${countPerPage}&country=${country}&groupLocation=${loc}&latitude=${lat}&longitude=${long}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get single group
  async getGroup(id) {
    return axios.get(`${http.baseURL}/group/${id}?date=${moment(new Date()).utc().format("MM-DD-YYYY")}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for update or edit group
  async updateGroup(id, data) {
    return axios.put(`${http.baseURL}/group/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for delete group

  async deleteGroup(groupId, reason) {
    return axios.delete(`${http.baseURL}/group/${groupId}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      data: {
        isDelete: true,
        modelType: "Group",
        deleteReason:reason,
      },
    });
  }

  // Api call for update status of event
  async updateGroupStatus(id, data) {
    return axios.put(`${http.baseURL}/event-status/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for the EVENT ASSOCIATED GROUPS

  async associatedGroups(data) {
    return await axios.post(
      `${http.baseURL}/event-association-with-group`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for get the ASSOCIATED Event DETAILS

  async associatedEvents(data) {
    return await axios.get(
      `${http.baseURL}/event-associated-with-group/${data}`,

      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for grt the ASSOCIATED GROUPS DETAILS

  async associatedUser(data) {
    return await axios.post(
      `${http.baseURL}/user-association-with-group`,
      data,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
  }

  // Api call for the create advertisement page  Upload Image

  async uploadImage(data) {
    return await axios.post(`${http.baseURL}/upload`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for the submit call board information

  async callerBoard(data) {
    return await axios.post(`${http.baseURL}/caller-board`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for the submit call board information

  async firebase(data) {
    return await axios.get(`${http.baseURL}/caller-board/${data}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for the submit win-parameter information

  async createWinParameter(data) {
    return await axios.post(`${http.baseURL}/win-parameter`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for the get all win-parameter information

  async winParameter(page=0,countPerPage=10, search="") {
    return await axios.get(`${http.baseURL}/win-parameter?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

   // Api call for the get particular win-parameter information

   async getWinParameter(id) {
    return await axios.get(`${http.baseURL}/win-parameter/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for the delete particular win-parameter information

  async deleteWinPar(id) {
    return await axios.delete(`${http.baseURL}/win-parameter/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

    // Api call for the status after game completion

    async gameCardStatus(data) {
      return await axios.post(`${http.baseURL}/user/add/prize-detail`,data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    }

    // Api call for the prize detail retailer

    async prizeDetailRetailer(data) {
      return await axios.post(`${http.baseURL}/user/prize-detail/update-by/retailer`,data, {
        // headers: {
        //   Authorization: localStorage.getItem("token"),
        // },
      });
    }


    // Api call for the game end in caller board

    async endCall(data) {
      return await axios.post(`${http.baseURL}/caller-board/game/end`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    }

    // ===============================================================================
  // Api call for create of card parameter
  async cardParameter(data) {
    return axios.post(`${http.baseURL}/card-parameter`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for list of card parameter
  async cardParameters(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/card-parameter?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get single card parameter
  async getCardParameter(id) {
    return axios.get(`${http.baseURL}/card-parameter/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

    // Api call for update card parameter
    async updateCardParameter(id, data) {
      return axios.put(`${http.baseURL}/card-parameter/${id}`, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
    }

  // Api call for delete card parameter

  async deleteCardParameter(id) {
    return axios.delete(`${http.baseURL}/card-parameter/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

    // Api call for list the user in admin dashboard

    async userList(page=0,countPerPage=10, search="") {
      return axios.get(`${http.baseURL}/users?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

    // Api call for block and unblock the user in admin dashboard

    async userBlock(id,data) {
      return axios.post(`${http.baseURL}/user/block-unblock/${id}`, data,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

      // Api call for list permissions in admin dashboard

     async listPermissions() {
       return axios.get(`${http.baseURL}/permission`, {
        headers: {
           Authorization: localStorage.getItem("token"),
        }
       });
    }

     // Api call for create permission module in admin dashboard

     async createPermission(data) {
      return axios.post(`${http.baseURL}/permission`, data,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

    // Api call for create permission module in admin dashboard

    async deletePermission(id,data) {
      return axios.delete(`${http.baseURL}/permission/${id}`,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }, data
      });
    }

 // Api call for get list role in admin dashboard

    async getRole(page=0,countPerPage=10, search="") {
      return axios.get(`${http.baseURL}/role?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }


 // Api call for create role in admin dashboard

    async createRole(data) {
      return axios.post(`${http.baseURL}/role`, data,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

     // Api call for update role in admin dashboard

     async updateRole(data) {
      return axios.put(`${http.baseURL}/role`, data,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

     // Api call for delete role in admin dashboard

     async deleteRole(id) {
      return axios.delete(`${http.baseURL}/role/${id}`,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

    // Api call for update status role in admin dashboard

    async statusRole(id,data) {
    return axios.put(`${http.baseURL}/role/enable-disable/${id}`,data,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

     // Api call for start game in admin dashboard

     async startGame(id) {
      return axios.put(`${http.baseURL}/event/start/${id}`,null,{
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
    }

    // Api call for start game in admin dashboard

    async cancelGame(id) {
    return axios.put(`${http.baseURL}/event/cancel/${id}`,{date:moment().format("MM-DD-YYYY HH:mm")},{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

  // Api call for get single event in result page for the user dashboard

  async singleEventResult(id) {
    return axios.get(`${http.baseURL}/event/${id}/event/status`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

  // Api call for update profile image in user dashboard

  async updateProfile(data) {
    return axios.put(`${http.baseURL}/user/update/profilepicture`,data,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

// Api call for get all upcoming and completed events user dashboard

  async userEvents() {
    return axios.get(`${http.baseURL}/events/user/all`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

  // Api call for get all card palyed in result page for user dashboard

  async userCardPlayed(eventId,groupId,winId) {
    return axios.get(`${http.baseURL}/win-parameter/${winId}/event/${eventId}/group/${groupId}`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

  // Api call for enter the game card through my events page for user dashboard

  async enterGameCardEvents(data) {
    return axios.post(`${http.baseURL}/my/events/gamecard`,data,{
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    });
  }

  // Api call for get employee for admin dashboard
  async getEmployee(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/employee/list?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for create employee for admin dashboard
  async createEmployee(data) {
    return axios.post(`${http.baseURL}/employee/create`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for particular employee information for admin dashboard
  async getSingleEmployee(id) {
    return axios.get(`${http.baseURL}/employee/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for particular update employee information for admin dashboard
  async updateEmployee(id, data) {
    return axios.patch(`${http.baseURL}/employee/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for particular delete employee information for admin dashboard
  async deleteEmployee(id) {
    return axios.delete(`${http.baseURL}/employee/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for GET ARCHIVE EVENT LISTS for admin dashboard
  async getArchive(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/events/archived?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for GET American Football Callerboard Data for admin dashboard
  async footBallCallerBoard(data) {
    return axios.post(`${http.baseURL}/caller-board/football`,data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }


  // Api call for GET Register User Data for admin dashboard
  async userRegistered({email, dob="", mobileNumber, zipCode, lastName}) {
    return axios.get(`${http.baseURL}/user-registered?email=${email}&dob=${dob}&mobileNumber=${mobileNumber}&zipCode=${zipCode}&lastName=${lastName}`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for meta Register User Data for admin dashboard
  async userRegisteredMeta(id) {
    return axios.get(`${http.baseURL}/user-registered/${id}/metadata`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }


  // Api call for get event id based on event type and league for admin dashboard
  async getEventId(data) {
    return axios.post(`${http.baseURL}/events/group/creation`,data,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get all deleted events for admin dashboard
  async getDeletedEvents() {
    return axios.get(`${http.baseURL}/events/deleted`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get all deleted groups for admin dashboard
  async getDeletedGroups() {
    return axios.get(`${http.baseURL}/groups-deleted`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get all prizes of prize section for admin dashboard
  async getPrizes(page=0,countPerPage=10, search="") {
    return axios.get(`${http.baseURL}/prizes?page=${page===0 ? 0 : page-1}&size=${countPerPage}&search=${search}&delay=1`,{
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for create prize for admin dashboard
  async createPrize(data) {
    return axios.post(`${http.baseURL}/prizes`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for particular update prize information for admin dashboard
  async updatePrize(id, data) {
    return axios.put(`${http.baseURL}/prizes/${id}`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for particular prize information for admin dashboard
  async getSinglePrize(id) {
    return axios.get(`${http.baseURL}/prizes/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }
  

  // Api call for prize information based on type for admin dashboard
  async getPrizeId(id) {
    return axios.get(`${http.baseURL}/prizes/group/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }

  // Api call for get particular of group ads controll for admin dashboard
  async getAdsControll(id) {
    return axios.get(`${http.baseURL}/group/${id}/ad-controls`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }


  // Api call for update particular of group ads controll for admin dashboard
  async updateAdsControll(id,data) {
    return axios.post(`${http.baseURL}/group/${id}/ad-controls`, data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  }
  
  

}

export default new DataService();
