import AdminHeader from "../header/adminHeader";
import { Link, useHistory } from "react-router-dom";

import inventory from "../../../assets/images/inventory.png";
import dashboard from "../../../assets/images/dashboard.svg";
import advertiser from "../../../assets/images/advertiser.svg";
import group from "../../../assets/images/group.svg";
import events from "../../../assets/images/events.svg";
import callerB from "../../../assets/images/caller-board.svg";
import winP from "../../../assets/images/win-parameter.svg";
import cardP from "../../../assets/images/card-parameter.svg";
import partner from "../../../assets/images/partner.svg";
import genC from "../../../assets/images/generate-card.svg";
import monitor from "../../../assets/images/monitor-card.svg";
import viewR from "../../../assets/images/view-result.svg";
import account from "../../../assets/images/accounts.svg";
import webC from "../../../assets/images/web-control.svg";
import permission from "../../../assets/images/permission.svg";
import employee from "../../../assets/images/employee.svg";
import userRole from "../../../assets/images/user-role.svg";

const AdminSidebar = (props) => {
  let history = useHistory();
  let path = history?.location?.pathname;

  const close = (e) => {
    e.stopPropagation();
    e.preventDefault()
    document.getElementsByTagName("body")[0].classList.toggle("removeBoby");

    // $(".overLay").fadeToggle(100);
  };
  return (
    <div id="admin-dashboard">
      <AdminHeader />

      <div>
        <div className="mainWrapper">
          {/*------ LeftAsideBar panel start------ */}
          <div className="leftAsideBar ">
            <Link to={"/admin-dashboard"} className="logo">
              <img src={dashboard} alt="" /> <span>Dashboard</span>
              <i onClick={close} className="fa fa-times" id="close-leftbar"></i>
            </Link>
            <div className="sidenavBar scrollbar" id="style-2">
              <div className="das">
                <ul className="das-list">
                  <li>
                    <div id="faq1" data-bs-parent=".das-list">
                      <ul>
                        <li>
                          <Link
                            className={
                              path === "/admin/advertisements" && "active22"
                            }
                            to="/admin/advertisements"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={advertiser}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText"> Advertisers</span>
                            </div>
                          </Link>
                        </li>


                        <li>
                          <Link
                           className={
                              path === "/admin/prizes" && "active22"
                            }
                          to="/admin/prizes"> 
                             {/* to="#"> */}
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={monitor}
                                  alt=""
                                  className="icon-big"
                                />
                              </span>
                              <span className="leftText">Prizes</span>
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link
                            className={path === "/admin/partner" && "active22"}
                            to="/admin/partner"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={partner}
                                  alt=""
                                  className="img-responsive icon-big"
                                />
                              </span>
                              <span className="leftText"> Partners </span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={path === "/admin/event" && "active22"}
                            to="/admin/event"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={events}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText"> Events</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              path === "/admin/win-parameters" && "active22"
                            }
                            to="/admin/win-parameters"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={winP}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText"> Win Parameters</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              path === "/admin/card-parameter" && "active22"
                            }
                            to="/admin/card-parameter"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={cardP}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText">Card Parameters</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={path === "/admin/group" && "active22"}
                            to="/admin/group"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={group}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText"> Groups</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              path === "/admin/caller-board" && "active22"
                            }
                            to="/admin/caller-board"
                          >
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={callerB}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText"> Caller Boards</span>
                            </div>
                          </Link>
                        </li>
             
                    

                        {/* <li>
                          <Link to="#">
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={genC}
                                  alt=""
                                  srcSet
                                  className="icon-big"
                                />
                              </span>
                              <span className="leftText"> Generate Cards</span>
                            </div>
                          </Link>
                        </li> */}


                        <li>
                          <Link to="#">
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={viewR}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText">View Results</span>
                            </div>
                          </Link>
                        </li>

                        {/* <li>
                          <Link to="#">
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={account}
                                  alt=""
                                  srcSet
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText">Accounts</span>
                            </div>
                          </Link>
                        </li> */}
                        <li>
                          <Link
                          className={
                              path === ("/admin/user-list" || "/admin/set-permissions") && "active22"
                            }
                           to="/admin/user-list">
                             
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={account}
                                  alt=""
                                  className="icon-big"
                                />
                              </span>
                              <span className="leftText">Users Accounts</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                          className={
                              path === "/admin/roles" && "active22"
                            }
                           to="/admin/roles">
                           {/* to="#"> */}
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={userRole}
                                  alt=""
                                  className="icon-big"
                                />
                              </span>
                              <span className="leftText">Roles</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                           className={
                              path === "/admin/permissions" && "active22"
                            }
                           to="/admin/permissions">
                           {/* to="#"> */}
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={permission}
                                  alt=""
                                  className="icon-big"
                                />
                              </span>
                              <span className="leftText">Permissions</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                           className={
                              path === "/admin/employees" && "active22"
                            }
                           to="/admin/employees">
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={employee}
                                  alt=""
                                  className="icon-big"
                                />
                              </span>
                              <span className="leftText">Employees</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="control">
                              <span className="leftIc">
                                <img
                                  src={webC}
                                  alt=""
                                  className="img-responsive "
                                />
                              </span>
                              <span className="leftText">Web Controls</span>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*------ right Body Start -----*/}
        </div>
        <div className="overLay" />
      </div>
    </div>
  );
};

export default AdminSidebar;
