import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import moment from "moment";
import "./advertisement.css";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import { ModalImage } from "./modal";
import { useForm, Controller } from "react-hook-form";

// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreateAdvertisemnt = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const [arrImage, setArrImage] = useState({
    bannerImageData: [],
    gameCardImageData: [],
    sponsorRibbonImageData: [],
  });

  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const subscription1 = watch();
  const [imgArr, setImgArr] = useState({
    bannerImageData: [],
    gameCardImageData: [],
    sponsorRibbonImageData: [],
  });
  
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [showAmountFields, setAmountFields] = useState(false);
  const [countries, setCountries] = useState({ countries: [] });
  const [states, setSates] = useState({ states: [] });

  const { id } = useParams();

  const handleInputChangeForCountryId = (Id) => {
    services.listOfStates(Id).then((result) => {
      setSates({
        isLoadedStates: true,
        states: result.data.data,
      });
    });
  };

  useEffect(() => {
    async function fetchData() {
      services.listOfCountry().then((result) => {
        setCountries({
          isLoadedCountry: true,
          countries: result.data.data,
        });
      });
      if (id !== undefined) {
        setSubmitFormLoader(true);
        if (history?.location?.state) {
          setDisInput(true);
        }

        let response = await services.getAdvertisement(id);
        handleInputChangeForCountryId(response.data.data.country);
        setImgArr({
          bannerImageData: response.data.data.bannerImageData,
          gameCardImageData: response.data.data.gameCardImageData,
          sponsorRibbonImageData: response.data.data.sponsorRibbonImageData,
        });
        setArrImage({
          bannerImageData: response.data.data.bannerImageData,
          gameCardImageData: response.data.data.gameCardImageData,
          sponsorRibbonImageData: response.data.data.sponsorRibbonImageData,
        });
        setFormData(response.data.data);
        setSubmitFormLoader(false);
        if (response.data.data.amount) {
          setAmountFields(true);
        }
        if (response.data.data.subscriptionType === "paid") {
          setAmountFields(true);
        }
      }
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    // reset form with user data
    reset(formData);
  }, [formData]);

  // Function for create mock up of data
  const createAdvertisemnt = async (d) => {
    // e.preventDefault();
    // console.log(!arrImage);

    try {
      const data = {
        title: d.title,
        advertiserName: d.advertiserName,
        email: d.email,
        phone: d.phone,
        cell: d.cell,
        address: d.address,
        country: d.country,
        state: d.state,
        city: d.city,
        zipCode: d.zipCode || null,
        startDate: d.startDate,
        endDate: d.endDate || null,
        contact: d.contact,
        bannerImageData: arrImage.bannerImageData,
        sponsorRibbonImageData: arrImage.sponsorRibbonImageData,
        gameCardImageData: arrImage.gameCardImageData,
        location: d.location,
        uploadedBy: "admin",
        priority: d.priority ||0,
        status: "1",
        description: d.description || "",
        amount: d.amount || 0,
        // "website": d.website,
        advertiserId: d.advertiserId,
        subscriptionType: d.subscriptionType || "",
        subscriptionDuration: d.subscriptionDuration || "",
      };
      // console.log(data);

      if (id) {
        // if (arrImage.bannerImageData.length === 0) {
        //   toast.error("Please Upload Ad Banner Location Image", {
        //     position: toast.POSITION.TOP_CENTER,
        //     toastId: "1",
        //   });
        //   return false;
        // }

        setSubmitFormLoader(true);
        let response = await services.updateAdvertisement(id, data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/advertisements");
      } else {
        // if (arrImage.bannerImageData.length === 0) {
        //   toast.error("Please Upload Ad Banner Location Image", {
        //     position: toast.POSITION.TOP_CENTER,
        //     toastId: "1",
        //   });
        //   return false;
        // }
        try {
          setSubmitFormLoader(true);
          let response = await services.createAdvertisement(data);

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          setSubmitFormLoader(false);
          history.push("/admin/advertisements");
        } catch (errors) {
          // console.log(errors.response);
          toast.error(errors.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          setSubmitFormLoader(false);
        }
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  const subscription = (e) => {
    if (e.target.value === "free") {
      setAmountFields(false);
    } else {
      setAmountFields(true);
    }
  };

  const [showModal, setmodalShow] = useState(false);
  const handleClose = () => setmodalShow(false);

  const sendimage = (data) => {
    setArrImage(data);
    setmodalShow(false);
  };

  useEffect(()=>{
if(subscription1.country){
  handleInputChangeForCountryId(subscription1.country)
}
  },[subscription1.country])


  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>
                    {" "}
                    {disInput
                      ? "View Advertiser"
                      : id
                      ? "Edit Advertiser"
                      : "Create Advertiser"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ModalImage
            {...imgArr}
            showb={disInput}
            sendImage={sendimage}
            show1={showModal}
            handleclose={handleClose}
          />
          <div className="row mt-2">
            <div className="Total-Revenue">
              <form onSubmit={handleSubmit(createAdvertisemnt)}>
                <div className="row management">
                  {submitFormLoader && <div className="loader"></div>}

                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Advertiser Id <b className="text-danger">*</b></label>
                      <input
                        type="text"
                        maxLength={5}
                        placeholder="Eg:1A0A9"
                        disabled={disInput}
                        className="form-control"
                        {...register("advertiserId", {
                          required: true,
                          pattern: {
                            value: /^[0-9][A-Z][A-Z0-9]{3}/,
                          },
                        })}
                      />
                      {errors.advertiserId && (
                        <p className="text-danger">
                          Please enter advertiser id in required pattern.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Advertiser Name <b className="text-danger">*</b></label>
                      <input
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        {...register("advertiserName", { required: true })}
                      />
                      {errors.advertiserName && (
                        <p className="text-danger">
                          Please enter the advertiser name.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Email</label>
                      <input
                        type="email"
                        disabled={disInput}
                        className="form-control"
                        {...register("email")}
                      />
                      {errors.email && (
                        <p className="text-danger">
                          Please enter the valid email.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Title</label>
                      <input
                        type="text"
                        name="title"
                        disabled={disInput}
                        className="form-control"
                        {...register("title")}
                      />
                      {errors.title && (
                        <p className="text-danger">Please enter the title.</p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Contact</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled={disInput}
                        className="form-control"
                        {...register("contact")}
                      />
                      {errors.contact && (
                        <p className="text-danger">Please enter the contact.</p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Phone Number</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled={disInput}
                        className="form-control"
                        {...register("phone")}
                      />
                      {errors.phone && (
                        <p className="text-danger">Please enter the phone.</p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Cell Number</label>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        disabled={disInput}
                        className="form-control"
                        {...register("cell")}
                      />
                      {errors.cell && (
                        <p className="text-danger">Please enter the cell.</p>
                      )}
                    </div>
                  </div>

                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Location</label>
                      <input
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        {...register("location")}
                      />
                      {errors.location && (
                        <p className="text-danger">
                          Please enter the location.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown">
                      <label>Priority</label>
                      <select
                        disabled={disInput}
                        className="drop-button form-control"
                        {...register("priority")}
                      >
                        <option value="">Select Priority</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                      {errors.priority && (
                        <p className="text-danger">
                          Please select the priority.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Mailing Address</label>
                      <input
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        {...register("address")}
                      />
                      {errors.address && (
                        <p className="text-danger">Please enter the address.</p>
                      )}
                    </div>
                  </div>

                  <div className=" col-lg-3 col-md-6">
                    <div className="form-group mb-2">
                      <label>Country</label>
                      <select
                        className="form-control"
                        id="sel1"
                        disabled={disInput}
                        {...register("country")}
                      >
                        {countries.countries.map((countries) => (
                          <>
                            <option value="">Select Country</option>
                            <option
                              value={countries.id}
                              selected={FormData?.country == countries.id}
                            >
                              {countries.country}
                            </option>
                          </>
                        ))}
                      </select>
                      {errors.country && (
                        <p className="text-danger">
                          Please select the country.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="form-group mb-2">
                      <label>State</label>
                      <select
                        disabled={disInput}
                        className="form-control"
                        {...register("state")}
                      >
                        <option value="">Select State</option>
                        {states.states.map((states) => (
                          <>
                            <option
                              value={[states.id]}
                              selected={formData.state == states.id}
                            >
                              {states.stateName}
                            </option>
                          </>
                        ))}
                      </select>
                      {errors.state && (
                        <p className="text-danger">Please select the state.</p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="form-group mb-2">
                      <label>City</label>
                      <input
                        id="sel1"
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("city")}
                      />
                      {errors.city && (
                        <p className="text-danger">Please enter the city.</p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="form-group mb-2">
                      <label>Zip Code</label>
                      <input
                        id="pincode"
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        name="zipCode"
                        {...register("zipCode")}
                      />
                      {errors.zipCode && (
                        <p className="text-danger">Please enter the zipCode.</p>
                      )}
                    </div>
                  </div>

                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>Start Date <b className="text-danger">*</b></label>

                      <Controller
                        control={control}
                        {...register("startDate", { required: true })}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subDays(new Date(), 0)}
                            maxDate={subscription1.endDate}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />
                      {errors.startDate && (
                        <p className="text-danger">
                          Please enter the start date.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className=" col-lg-3 col-md-6">
                    <div className="dropdown ">
                      <label>End Date</label>
                      <Controller
                        control={control}
                        {...register("endDate")}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            className="form-control"
                            placeholderText="please select date"
                            disabled={disInput}
                            selected={value ? moment(value).toDate() : value}
                            onChange={onChange}
                            minDate={subscription1.startDate}
                            showYearDropdown
                            dateFormatCalendar="MMMM"
                            scrollableYearDropdown
                          />
                        )}
                      />
                      {errors.endDate && (
                        <p className="text-danger">Please enter the end date.</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                  <label>Advertiser Image / Video</label>
                    <button
                      onClick={() => setmodalShow(true)}
                      type="button"
                      className="btn btn-back btn-block mt-0"
                    >
                     <i className="fa fa-cloud-upload" aria-hidden="true"></i> {disInput ? "View" : "Add"}  File
                    </button>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <div className="dropdown ">
                      <label>Descriptions</label>
                      <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("description")}
                      />
                      {errors.description && (
                        <p className="text-danger">
                          Please enter the description.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="subscriptionType"
                        {...register("subscriptionType", { required: true })}
                        value="free"
                        checked
                        disabled={disInput}
                        onClick={subscription}
                      />
                      <label className="form-check-label" for="inlineRadio1">
                        Free
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        disabled
                        onClick={subscription}
                        {...register("subscriptionType", { required: true })}
                        value="paid"
                      />
                      <label className="form-check-label" for="inlineRadio2">
                        Paid
                      </label>
                    </div>
                    {errors.subscriptionType && (
                      <p className="text-danger">
                        Please select the subscription.
                      </p>
                    )}
                    {showAmountFields && (
                      <div className="mt-n1">
                        <div
                          className="mb-3"
                          style={{ borderBottom: "1px solid #cfcfcf" }}
                        >
                          <label>Subscription Duration</label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            disabled={disInput}
                            type="radio"
                            value="none"
                            {...register("subscriptionDuration", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio1"
                          >
                            None
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled={disInput}
                            value="One Month"
                            {...register("subscriptionDuration", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            One Month
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            disabled={disInput}
                            type="radio"
                            value="Quarterly"
                            {...register("subscriptionDuration", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            Quarterly
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            disabled={disInput}
                            {...register("subscriptionDuration", {
                              required: true,
                            })}
                            value="Half Year"
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            Half Year
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            disabled={disInput}
                            className="form-check-input"
                            type="radio"
                            value="One Year"
                            {...register("subscriptionDuration", {
                              required: true,
                            })}
                          />
                          <label
                            className="form-check-label"
                            for="inlineRadio2"
                          >
                            One Year
                          </label>
                        </div>
                        {errors.subscriptionDuration && (
                          <p className="text-danger">
                            Please choose the subscription duration.
                          </p>
                        )}

                        <div className="col-lg-6">
                          <div className="dropdown ">
                            <label>Amount</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={disInput}
                              {...register("amount", { required: true })}
                            />
                            {errors.amount && (
                              <p className="text-danger">
                                Please enter the amount.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <Link to="/admin/advertisements">
                        <button
                          type="button"
                          className="btn admin-back-btn mr-2 "
                        >
                          Back
                        </button>
                      </Link>
                      {!disInput && (
                        <button type="submit" className="btn admin-primary-btn">
                        {id ? "Update" : "Submit"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {/*------ Footer bar------ */}
            <AdminFooter />
          </div>
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreateAdvertisemnt;
