import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import services from "../../../services/services";
import DatePicker from "react-datepicker";
import { addDays  ,subDays } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import * as Constants from "../../../helper/constants";
import moment from "moment";
import Select from "react-select";
import * as universalFunction from "../../../utils/universalFunctions";
import { PrizeTable } from "./table";
import ShortDesc from '../../../user/component/group/shortDesc'
import { TextEditor } from "../editor/editor";
import parse from 'html-react-parser';
import {EditorState} from 'draft-js';


function AddTypeModal({
  handleclose,
  disInput,
  show,
  setprize,
  advertiser,
  eventInfo,
  cb,
  prize,
  prizeM,
  pattern,
  // oldPrize,
  
}) {
  const [showTable, setShowTable] = useState(false);
  const [retired, setRetired] = useState("");
  const [prizeId, setPrizeId] = useState([]);
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [showP, setShowP] = useState(false);
  const [sendData, setSendData] = useState({
    h:'',
    p:''
  });
  const [oldPrize, setOldPrize] = useState([]);
  const [newPrize, setNewPrize] = useState([]);
  const [chk1, setChk] = useState(false);
  const {
    register,
    reset,
    watch,
    setError,
    setValue,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  let subscribe = watch();

  const addRetired = (id) => {
    setRetired(id);
    setValue("endDate", newPrize[id]?.endDate);
  };

  const updatePrize = (id) => {
    let data = [...newPrize];
    data[id]["endDate"] = subscribe?.endDate;
    setprize(data);
    setValue("endDate", "");
    setRetired("");
  };

  useEffect(async() => {
    if(subscribe?.type){
    try{
      setSubmitFormLoader(true);
   let getPrizeId = await services.getPrizeId(subscribe?.type);
   setPrizeId(getPrizeId.data.data.data);
   setValue("selectDate", "");
   setValue("noticeDate", "");
  
    setSubmitFormLoader(false);
    }
    catch(e){
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  }
  

}, [subscribe.type]);

useEffect(()=>{
let data = [...prize];
let oldP = [];
let newP = []
// console.log(data,"ee");
 data?.length ?  data?.map(d=>{
  
  if(d?.isOldPrize){
    oldP.push(d)
  }else{
    newP.push(d)
  }

}) : [];
setOldPrize(oldP);
setNewPrize(newP)
},[prize])


// console.log(prizeM,"===");

const findPrizeId = (id) => {
let data = [...prizeM];
let findName = data.find(d=>d.id==id) || "";
return findName.prizeId
}

useEffect(()=>{
  if(subscribe?.expireEnterDate==null || subscribe?.expireEnterDate==""){
    setValue("selectDate","")
  }

  if(subscribe?.selectDate==null || subscribe?.selectDate==""){
    setValue("noticeDate","")
  }

},[subscribe?.expireEnterDate,subscribe?.selectDate ])


// console.log(oldPrize,"prizeprize");
  const EndDate = ({ newId }) => (
    <div className={`${!(retired || retired === 0) && "col-lg-2 col-12"}`}>
      <div className="dropdown ">
        {newId == 0 ? "" : !newId && <label>End Date</label>}
        <Controller
          control={control}
          {...register("endDate")}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              className="form-control"
              placeholderText="please select date"
              selected={value ? moment(value).toDate() : value}
              onChange={onChange}
              minDate={
                retired || retired === 0
                  ? new Date(prize[retired]?.startDate)
                  : subscribe.startDate
              }
              disabled={disInput}
              showYearDropdown
              dateFormatCalendar="MMMM"
              scrollableYearDropdown
            />
          )}
        />
      </div>
      {/* {errors.endDate && (
    <p className="text-danger">Please select the end date.</p>
  )} */}
    </div>
  );
const adDesc = (d,chk) =>{ 
    setValue("adDescription", d, { shouldValidate: chk }); 
};

// const longP = (d) => {
//   setValue("longPrize", d, { shouldValidate: true }); 
// }

  return (
    <>
      <Modal
        className="Total-Revenue csx"
        size="xl"
        // style={{maxWidth: '1400px'}}
        centered
        show={show}
        onHide={() => handleclose(reset)}
        backdrop="static"
        keyboard={false}
      >     {submitFormLoader && <div className="loader"></div>}

        <Modal.Header className="align-items-center" closeButton>
        <ShortDesc handleclose={()=>setShowP(false)} data={sendData} show={showP}/>
          {/* <Modal.Title> Prizes  </Modal.Title> */}
          <h5 className="mr-3" style={{ fontSize: "17px" }}>
            <b>Event Type :</b>{" "}
            {`${universalFunction.findLeague(eventInfo?.eventType)} (${
              eventInfo?.eventType
            })`}
          </h5>
          <h5 style={{ fontSize: "17px" }}>
            <b>League :</b>{" "}
            {universalFunction.findLeague(
              eventInfo?.eventType,
              eventInfo?.league
            )}
          </h5>
        </Modal.Header>
        <Modal.Body className="gscroll2q">
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  type="button"
                  onClick={() => setShowTable(!showTable)}
                  style={{ fontSize: "14px" }}
                  className="btn btn-primary mt-4"
                >
                  {disInput && !showTable
                    ? `View Old Prizes`
                    : disInput && showTable
                    ? "view prizes"
                    : !disInput && showTable
                    ? "Back"
                    : "View Old Prizes"}
                </button>

                {!showTable && !disInput && (
                  <button
                    type="submit"
                    onClick={handleSubmit((d) => {cb(d, reset); setChk(!chk1)})}
                    style={{ fontSize: "14px" }}
                    className="btn btn-primary mt-4 mr-4"
                  >
                    Add Prizes
                  </button>
                )}
              </div>
            </div>
            {showTable ? (
              <PrizeTable cb={findPrizeId} prize={oldPrize} />
            ) : (
              <>
              <div className="  col-md-12">
                {/* <div className="gstyle"> */}

                <div className="formBxx">
                  <div className="row p-0">
                    <div className=" col-lg-2 col-12">
                      <div className="dropdown ">
                        <label>Pattern</label>
                        <select
                          id=""
                          disabled={disInput}
                          className="form-control"
                          name="pattern"
                          {...register("pattern", { required: true })}
                        >
                          <option value="">Choose Pattern</option>
                          {pattern.length &&
                            pattern.map((data) => (
                              <option value={data}>
                                {data?.toUpperCase()}
                              </option>
                            ))}
                        </select>
                        {errors.pattern && (
                          <p className="text-danger">
                            Please select the pattern.
                          </p>
                        )}
                      </div>
                    </div>


                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label>Type </label>
                        <select
                          disabled={disInput}
                          className="drop-button form-control"
                          {...register("type", { required: true })}
                        >
                          <option value="">Please Select</option>
                          <option value="R">R</option>
                          <option value="D">D</option>
                          <option value="D2">D2</option>
                          <option value="C">C</option>
                          <option value="C2">C2</option>
                        </select>
                        {errors.type && (
                          <p className="text-danger">
                            Please select the given value.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label>Prize Id </label>
                        <select
                          disabled={disInput}
                          className="drop-button form-control"
                          {...register("prizeId", { required: true })}
                        >
                          <option value="">Please Select</option>
                        { prizeId?.length && prizeId?.map((d)=>
                          <option key={d.prizeId} value={d.id}>{d.prizeId}</option>
                        ) }
                         
                        </select>
                        {errors.prizeId && (
                          <p className="text-danger">
                            Please select the prize id.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label>Expire/Enter Date Type</label>
                        <select
                          disabled={disInput}
                          className="drop-button form-control"
                          {...register("hasNumberOfDays", { required:  true })}
                        >
                          <option value="">Please Select</option>
                      {subscribe.type=="R"  &&  <option value={true}>Days</option>}
                          <option value={false}>Date</option>
                        </select>
                      </div> 
                      {errors.hasNumberOfDays && (
                        <p className="text-danger">
                          Please select the option.
                        </p>
                      )}
                  </div>

                    { subscribe.hasNumberOfDays ==="true"  && subscribe.type=="R"  &&
                     <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label> Number of Days</label>
                        <input
                          type="number"
                          disabled={disInput}
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          {...register("expireNumberOfDays", {
                            required: true,
                          })}
                        />
                      </div> 
                      {errors.expireNumberOfDays && (
                        <p className="text-danger">
                          Please enter the number of days.
                        </p>
                      )}
                  </div> }

                    {subscribe.hasNumberOfDays ==="false" && <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label> Expire/Enter Date</label>
                        <Controller
                          control={control}
                          {...register("expireEnterDate", { required: true })}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              className="form-control"
                              placeholderText="please select date"
                              selected={value ? moment(value).toDate() : value}
                              onChange={onChange}
                              minDate={addDays(new Date(), 1)}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              disabled={disInput}
                              scrollableYearDropdown
                            />
                          )}
                        />
                      </div>
                      {errors.expireEnterDate && (
                        <p className="text-danger">
                          Please enter the expire enter date.
                        </p>
                      )}
                    </div>}

                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label> Select Date</label>
                        <Controller
                          control={control}
                          {...register("selectDate")}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              className="form-control"
                              placeholderText="please select date"
                              selected={value ? moment(value).toDate() : value}
                              onChange={onChange}
                              minDate={addDays(subscribe?.expireEnterDate, 1)}
                              disabled={subscribe?.type==="R" || !subscribe.expireEnterDate || disInput}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              scrollableYearDropdown
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label> Notice Date</label>
                        <Controller
                          control={control}
                          {...register("noticeDate")}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              className="form-control"
                              placeholderText="please select date"
                              selected={value ? moment(value).toDate() : value}
                              onChange={onChange}
                              disabled={subscribe?.type==="R" || !subscribe.selectDate || disInput}
                              minDate={addDays(subscribe?.selectDate, 1)}
                              showYearDropdown
                              dateFormatCalendar="MMMM"
                              scrollableYearDropdown
                            />
                          )}
                        />
                      </div>
                    </div>


                    <div className="col-lg-2 col-12">
                      <div className="dropdown  mb-3">
                        <label>Advertiser Id</label>
                        <Controller
                          control={control}
                          name="advertizerId"
                          {...register("advertizerId", { required: true })}
                          render={({ field: { value, onChange } }) => (
                            <Select
                              isMulti
                              isDisabled={disInput}
                              value={value}
                              onChange={onChange}
                              options={advertiser}
                            />
                          )}
                        />
                      </div>
                      {errors.advertizerId && (
                        <p className="text-danger mt-4">
                          Please select the advertiser id.
                        </p>
                      )}
                    </div>

                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label>Estimated Value</label>
                        <input
                          type="number"
                          disabled={disInput}
                          className="form-control"
                          placeholder="Estimated Value"
                          {...register("estimatedRetailValue", {
                            required: true,
                          })}
                        />
                      </div>
                      {errors.estimatedRetailValue && (
                        <p className="text-danger">
                          Please enter the estimated value.
                        </p>
                      )}
                    </div>

                    <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                        <label> Start Date</label>
                        <Controller
                          control={control}
                          {...register("startDate", { required: true })}
                          render={({ field: { value, onChange } }) => (
                            <DatePicker
                              className="form-control"
                              placeholderText="please select date"
                              selected={value ? moment(value).toDate() : value}
                              onChange={onChange}
                              disabled={disInput}
                              minDate={subDays(new Date(), 0)}
                              showYearDropdown
                              maxDate={subscribe.endDate}
                              dateFormatCalendar="MMMM"
                              scrollableYearDropdown
                            />
                          )}
                        />
                      </div>
                      {errors.startDate && (
                        <p className="text-danger">
                          Please select the start date.
                        </p>
                      )}
                    </div>
                    {retired === "" ? (
                      <EndDate />
                    ) : (
                      <div className="col-lg-2 col-12">
                        <div className="dropdown ">
                          <label>End Date</label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="please select date"
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-lg-6 col-12">
                      <div className="dropdown borderBox">
                        <label className="mb-3">Long Prize</label>
                        <TextEditor read={disInput} check={chk1} {...register("longPrize", { required: true })} cb={(d,chk) =>setValue("longPrize", d, { shouldValidate: chk })} />
                      </div>
                      {errors.longPrize && (
                        <p className="text-danger">
                          Please enter the long prize.
                        </p>
                      )}
                    </div>

                    <div className="col-lg-6 col-12">
                      <div className="dropdown borderBox">
                        <label>Ad Description</label>
                        <TextEditor read={disInput} check={chk1} {...register("adDescription", { required: true })} cb={adDesc} />
                      </div>
                      {errors.adDescription && (
                        <p className="text-danger">
                          Please enter the ad description.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              </>
            )}
            </div>
         
{!showTable &&
                      <div className="hide_from">    
                    <div className="price_tittle">
                      <h4>Prize Lists</h4>
                    </div>
                        <div className="table-responsive">
                          <table className="table RichEditor-editor tgForPrize table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Pattern</th>
                                <th scope="col">Type</th>
                                <th className="text-nowrap" scope="col">Prize Id</th>
                                <th className="text-nowrap" scope="col">Expire/Enter Date</th>
                                <th className="text-nowrap" scope="col">Select Date</th>
                                <th className="text-nowrap" scope="col">Notice Date</th>
                                <th className="text-nowrap" scope="col">Long Prize</th>
                                <th className="text-nowrap" scope="col">Advertiser Id</th>
                                <th className="text-nowrap" scope="col">Estimated Value ($)</th>
                                <th className="text-nowrap" scope="col">Start Date</th>
                                <th className="text-nowrap text-center" scope="col">End Date</th>
                                <th className="text-nowrap" scope="col">Ad Description</th>
                                <th className="text-nowrap" scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            {newPrize?.length > 0 ?
                              newPrize?.map(
                  (
                    {
                      pattern,
                      offerCode,
                      advertizerId,
                      estimatedRetailValue,
                      startDate,
                      endDate,
                      adDescription,
                      type,
                      prizeId,
                      hasNumberOfDays,
                      expireNumberOfDays,
                      expireEnterDate,
                      selectDate,
                      noticeDate,
                      longPrize,

                    },
                    id
                  ) => {
                    return (   <tr>
                                <td scope="row"><b>{pattern.toUpperCase()=="HALFBLACKOUT" && "HALF BLACKOUT" ||pattern.toUpperCase()}</b></td>
                                <td>{type}</td>
                                <td>{findPrizeId(prizeId)}</td>
                                <td>{hasNumberOfDays ? expireNumberOfDays :  expireEnterDate
                                ? moment(expireEnterDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}</td>
                                <td>
                                  { selectDate
                                ? moment(selectDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"}
                                </td>
                                <td> 
                                { noticeDate
                                ? moment(noticeDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : "Not Found"}
                                </td>
                                <td style={{cursor:'pointer'}} className="text-center" onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'Long Prize',p: parse(longPrize)})}}> 
         
                                <span className="fa fa-eye fa-4x icon-act" ></span>
           
                                </td>
                                <td>{ advertizerId?.length &&
                                    advertizerId.map((data,index) => (index ? ', ' : '') + `${data}` )}</td>
                                <td>{estimatedRetailValue}</td>
                                <td>
                                { startDate
                                ? moment(startDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""}
                                </td>
                                <td> {retired===id ? <EndDate newId={id}/> :  
                        <div className="dropdown ">
                          <input
                            type="text"
                            value={
                              endDate
                                ? moment(endDate).format(
                                    "MM/DD/YYYY"
                                  )
                                : ""
                            }
                            className="form-control"
                            disabled
                          />
                      </div>}</td>
                                <td style={{cursor:'pointer'}} className="text-center" onClick={() =>{ setShowP(!showP); setSendData({...sendData,h:'Ad Description',p: parse(adDescription)})}}>
                                <span className="fa fa-eye fa-4x icon-act" ></span>
                               </td>
                                <td> { retired===id ? <button
                                  onClick={() => updatePrize(id)}
                                  type="button"
                                  className="btn m-1 btn-danger btn-block"
                                >
                                <span style={{fontSize: 'small',fontWeight: 700}}>Update</span>
                                  
                                </button>
                                  :
                                <button
                                  onClick={() => addRetired(id)}
                                  type="button"
                                  disabled={disInput}
                                  className="btn m-1 btn-danger btn-block"
                                >
                                <span style={{fontSize: 'small',fontWeight: 700}}>Retired</span>
                                  
                                </button>}</td>
                              </tr>
                              );
                  }
                ) :
              
                              <tr>
                                <td className="text-center" colSpan={12}>
                                  No Data Found
                                </td>
                              </tr>
                
                }
                            </tbody>
                          </table>
                        </div>
                      </div>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleclose(reset)}>
            close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTypeModal;
