/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import {SearchLocationInput} from "../SearchLocationInput";
import "./group.css";
import Geocode from "react-geocode";
import Select,{components} from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";
import { subDays } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import * as Constants from "../../../helper/constants";
import * as universalFunction from "../../../utils/universalFunctions";
import {TypeTable} from './table'
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import AddTypeModal from "./addTypeModal";
import AdsControl from "./adsControlled";
import EventID from "./eventId";
import { TextEditor } from "../editor/editor";
// import { convertFromRaw } from "draft-js-export-html";
import parse from 'html-react-parser';
import LocalMap from "./localMap";
// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreateGroup = () => {
  const history = useHistory();
  const [disInput, setDisInput] = useState(false);

  const [versionN, setVersionN] = useState([]);
  const [showPattern, setShowPattern] = useState([]);
  const [showEventLeague, setShowEventLeague] = useState([]);
  const [showModal, setmodalShow] = useState(false);
  const [showModalAds, setmodalShowAds] = useState(false);
  const [eventP, setEventP] = useState(false);
  const [showTypes, setShowTypes] = useState({});
  const [prizes, setPrizes] = useState([]);
  const [oldPrizes, setOldPrizes] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [uId, setId] = useState('');
  const [retired, setRetired] = useState("");
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [advertisement, setAdvertisement] = useState([]);
  const [allAdvertisement, setAllAdvertisement] = useState([]);
  const [carP, setCarP] = useState([]);
  const [allCarP, setAllCarP] = useState([]);
  const [oldEvent, setOldEvent] = useState([]);
  const [eventIdOptions, setEventIdOptions] = useState([]);
  const [groupPrizeAssociation, setGroupPrizeAssociation] = useState([]);
  const [oldEventIds, setOldEventIds] = useState([]);
  const [newEv, setNewEv] = useState([]);
  const [listAd, setListAd] = useState([]);
  const [formData, setFormData] = useState([]);
  const [prizeA, setPrize] = useState([]);
  const [forLocal, setForLocal] = useState({});
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);

  const {
    register,
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let subscribe = watch();

  const {
    register: register2,
    setValue: setValue2,
    reset: reset1,
    control: control2,
    watch: watch2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const subscribe2 = watch2();

  const { id } = useParams();

  // effect runs when user state is updated



  useEffect(() => {
    fetchData();
  }, [id]);

  async function fetchData() {
    try {
    setSubmitFormLoader(true);
    let adver = await services.getAdvertisementList(0, 100);
    let res = await services.winParameter(0, 100);
    let data = await services.cardParameters(0, 100);
    let prizeDataArray = await services.getPrizes(0, 100);
    // let cardP = await services.cardParameters(0, 100);
    setVersionN(res.data.data.data);
    setAllCarP(data.data.data.data)
    setPrize(prizeDataArray.data.data.data);
    setAllAdvertisement(adver?.data?.data?.data)
    setAdvertisement(
      adver?.data?.data?.data?.map(({ advertiserId, advertiserName, id }) => ({
        value: advertiserId,
        label: `${advertiserId}, ${advertiserName}`,
        id:id
      }))
    );
   
    setSubmitFormLoader(false);
    if (id !== undefined) {
      setSubmitFormLoader(true);

      if (history?.location?.state) {
        setDisInput(true);
      }
      let response = await services.getGroup(id);
      let adRes = await services.getAdsControll(id);
      let obj = {
        groupName: response.data.data.groupName,
        groupType: response.data.data.groupType,
        createdBy: response.data.data.createdBy,
        descriptions: response.data.data.groupType==="Local" ? "" : response.data.data.descriptions,
        latitude: response.data.data.groupType==="Local" ? "" : response.data.data.latitude,
        longitude: response.data.data.groupType==="Local" ? "" : response.data.data.longitude,
        age: response.data.data.age,
        amount: response.data.data.amount,
        password: response.data.data.password,
        charitable: response.data.data.charitable,
        groupId: response.data.data.groupId,
        placeId: response.data.data.placeId,
        groupLocation: response.data.data.groupLocation,
        country: response.data.data.country,
        rounding: response.data.data.rounding,
        rolloverPushdown: response.data.data.rolloverPushdown,
        cardCosts: response.data.data.cardCosts,
        organizedGroupRules: response.data.data.organizedGroupRules,
        patterns: response.data.data.patterns,
        prizePayout: response.data.data.prizePayout,
        localGroupLocations:response.data.data.localGroupLocations
      };
      setForLocal({ groupLocation: response.data.data.groupLocation,
        country: response.data.data.country})
      setFormData(obj)
      reset(obj);

   setListAd(adRes?.data?.data.map(d=>({
    advertiserId: d?.advertisementData.id,
    adId: d?.adId,
    runTime: d?.runTime,
    delayTime: d?.delayTime,
    hasOccurence: d?.hasOccurence,
    timeDuration: d?.timeDuration || null,
    occurence:d?.occurence || null
   })) || []);

      setPolygonCoordinates(response.data.data.localGroupLocations || [])
      setValue("patterns", response.data.data.patterns, { shouldValidate: true })
      let assoc = response?.data?.data?.events?.map(({uuid,cardParameterData, groupPrizeAssociationOld,...d})=>d) || [];
      setOldEventIds(response?.data?.data?.eventIdsData)
      let selectedOp =  response?.data?.data?.eventIdsData?.filter(({id,eventId,isExpired})=> !isExpired) || [];
      let selectedOp1 =  selectedOp?.map(d=>({ value: d.id, label: d.eventId})) || [];
      setGroupPrizeAssociation(assoc);
      getEventFunc(assoc);
      setValue("eventIds",selectedOp1 );
      // setEventIdOptions(selectedOp1)
      setOldEvent(response?.data?.data?.eventsOld)
      setSubmitFormLoader(false);

   
    }
  }catch(e){

  }
  }


  useEffect(() => {
    let data = Constants.EVENT_TYPES_AND_LEAGUES.find(
      ({ code }) => code === subscribe2.eventType
    );
    setShowEventLeague(data?.eventLeague);
    // setValue("league", '');
  }, [subscribe2.eventType]);


  useEffect(async() => {

    if(subscribe2.league && subscribe2.numberOfFactor && subscribe2.eventType){
      try{
        setSubmitFormLoader(true);
      let data = await services.getFilterCardPGroup(subscribe2?.eventType, subscribe2?.league, subscribe2.numberOfFactor);
      setCarP(data.data.data.data);
      setSubmitFormLoader(false);
      }
      catch(e){
        toast.error(e.response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
      }
    }

  }, [subscribe2.league, subscribe2.numberOfFactor, subscribe2.eventType]);




  const createGroup = async (d) => {

    let eventsData = [...groupPrizeAssociation];
if(d.groupType==="Local" && polygonCoordinates?.length===0){
  toast.error("Please draw a local group map", {
    position: toast.POSITION.TOP_CENTER,
    toastId: "1",
  });
}
    
    eventsData?.forEach((node) => {
      node?.groupPrizeAssociation?.forEach((item) => {
              delete item.isOldPrize
      })
    });

   let filt = oldEventIds.filter(d=>d?.isExpired).map(({id, eventId})=> ({value:id,label:eventId})) || [];


    try {
      const data = {
        groupName: d.groupName,
        groupType: d.groupType,
        //as of now is default is admin
        createdBy: "Admin",
        groupId: d.groupId ,
        latitude: d.groupType==="Local" ? polygonCoordinates[0]?.polygonCoordinates[0]?.lat : d.latitude,
        longitude:d.groupType==="Local" ? polygonCoordinates[0]?.polygonCoordinates[0]?.lng :  d.longitude,
        descriptions: d.groupType==="Local" ?  polygonCoordinates[0]?.locationDescription : d.descriptions,
        age: d.age,
        password: d.password || "",
        amount: 0,
        charitable: d.charitable || "false",
        placeId: d.groupType==="Local" ?  "test" :d.placeId,
        groupLocation: d.groupType==="Local" ? forLocal?.groupLocation : d.groupLocation,
        country: d.groupType==="Local" ? forLocal?.country : d.country,
        events: eventsData,
        rounding: d.rounding,
        rolloverPushdown: d.rolloverPushdown,
        cardCosts: d.cardCosts,
        organizedGroupRules: d.organizedGroupRules,
        eventIds:d?.eventIds?.map(d=>d.value) || null,
        patterns: d.patterns,
        prizePayout: d.prizePayout,
        localGroupLocations:d?.groupType==="Local" ? polygonCoordinates : [],
  
        //as of now is empty string
        uploadedBy: "",
      };

      if (id) {
        setSubmitFormLoader(true);
        let response = await services.updateGroup(id, {...data, eventGroupAssociationCancelled: [...new Set([...newEv, ...filt]?.map(d=>d.value))] || [] });
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/group");
      } else {
        setSubmitFormLoader(true);
        let response = await services.group({...data,adControls: listAd});
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/group");
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  

  const AddgroupPrizeAssociation = (data) => {


  let typeData = { 
  eventType: data.eventType,
  league: data.league,
  numberOfFactor: data.numberOfFactor,
  cardParameterVersionId: data.cardParameterVersionId,
  cardParameterStartDate: data.cardParameterStartDate,
  cardParameterEndDate: data.cardParameterEndDate || "",
  winParameterVersionId: data.winParameterVersionId,
  winParameterStartDate: data.winParameterStartDate,
  winParameterEndDate: data.winParameterEndDate || "",
}  

// console.log(typeData);
      
let copyData = [...groupPrizeAssociation,typeData]

    let result = universalFunction.groupBy(copyData, function(item){
      return [item.eventType, item.league];
      });
      
      let checkValidateOverlapping = universalFunction.checkValidateOverlapping(result);
        if(checkValidateOverlapping){
         return false
        }

let checkDate = universalFunction.validateDates({
cardParameterStartDate:data.cardParameterStartDate,
cardParameterEndDate:data.cardParameterEndDate,
winParameterStartDate:data.winParameterStartDate,
winParameterEndDate:data.winParameterEndDate
})

if(checkDate){
   return toast.error("Please enter the similar date for card parameter and win parameter for the same row.", {
    position: toast.POSITION.TOP_CENTER,
    toastId: "1",
  });
}else{
  setGroupPrizeAssociation((p)=>[...p,{...typeData,groupPrizeAssociation:prizes}]);
  getEventFunc(copyData);
    setPrizes([]);
    reset1({
      eventType: "",
      league: "",
      numberOfFactor: "",
      cardParameterVersionId: "",
      cardParameterStartDate: "",
      cardParameterEndDate: "",
      winParameterVersionId: "",
      winParameterStartDate: "",
      winParameterEndDate: ""
    });
}
  };


  // console.log(subscribe,"subscribesubscribe");

  const getDataLocation = async (data) => {
    // console.log(data, "00989");
    setValue("descriptions", data, { shouldValidate: true });
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY); //Insert your Google Maps API here
    Geocode.enableDebug();

    try {
      let response = await Geocode.fromAddress(data);
      const {
        address_components,
        geometry: { location },
        place_id,
      } = response.results[0];

      let area =
        address_components.length > 0 &&
        address_components.filter(
          (data) => data.types[0] === "administrative_area_level_1"
        );
      let country =
        address_components.length > 0 &&
        address_components.filter((data) => data.types[0] === "country");

      setValue("latitude", location.lat);
      setValue("longitude", location.lng);
      setValue("placeId", place_id);
      setValue("groupLocation", area[0]?.long_name || country[0]?.long_name);
      setValue("country", country[0]?.long_name);
    } catch (e) {
      setSubmitFormLoader(false);
    }
  };

  // const onChangeHandler2 = (change) => {
  //   setSelectedOption2(change);
  // };

  const prizesClick = (data) => {

    // console.log(data,"groupPrizeAssociationOld");
    setId('')
    setShowPattern([])
    setOldPrizes([])
    setShowTypes(data);
    setmodalShow(true)
    if(data?.winParameterVersionId){
      let copyWin = [...versionN];
     let findWin = copyWin.length && copyWin.find(d=>d.id==data?.winParameterVersionId)
      setShowPattern(findWin?.patterns)
    }
    if(data?.groupPrizeAssociationOld){
      setOldPrizes(data?.groupPrizeAssociationOld)
    }
    if(data?.groupPrizeAssociation){
      setPrizes(data?.groupPrizeAssociation)
    }
    if(data.id || data.id ===0){
      setId(data.id)
    }
  };


  
  const getEventFunc = async (data) => {
  try {
    setSubmitFormLoader(true);
    let newData = data?.length && data.map(({eventType,league})=>({eventType, league}))
    if(newData){
      let res = await services.getEventId({filters:newData});
      setEventIdOptions(res?.data?.data?.map(({id,eventId})=> ({ value: id, label: eventId })) || [])
    }
    // console.log(res.data.data,"++++++======");
    setSubmitFormLoader(false);
  } catch (e) {
    setSubmitFormLoader(false);
    toast.error(e.response.data.message, {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  }
  }

  // console.log(prizes,"prizes");
  
  const AddPrizes = (data, res) => {
    let dataP = prizes.filter(d=>!(d.isOldPrize));
    // console.log(dataP,'**');
    let cdata = [...dataP];
    if (cdata?.length) {
      let filterData = cdata.find(({ pattern }) => pattern === data.pattern);
      if (filterData) {
        toast.warn("Pattern should be unique", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        return;
      }
    }

    // console.log(data.advertizerId,"data.advertizerId");

    const gData = {
      eventType: showTypes?.eventType,
      league: showTypes?.league,
      pattern: data.pattern,
      offerCode: "d",
      advertizerId: data.advertizerId?.map(({ value }) => value),
      estimatedRetailValue: Number(data.estimatedRetailValue),
      adDescription: data.adDescription,
      startDate: data.startDate,
      endDate: data.endDate,
      isOldPrize:false,
      type: data.type,
      prizeId: Number(data.prizeId),
      hasNumberOfDays: data.hasNumberOfDays  ==="true" ? true : false,
      expireNumberOfDays: Number(data.expireNumberOfDays) || 0,
      expireEnterDate: data.expireEnterDate || "",
      selectDate: data.selectDate,
      noticeDate:data.noticeDate,
      longPrize: data.longPrize,


      
    };

    if(uId || uId === 0){
      let sdata = [...groupPrizeAssociation]
       sdata[uId] = {...sdata[uId],groupPrizeAssociation:[...sdata[uId].groupPrizeAssociation, gData]}  
      setGroupPrizeAssociation(sdata);
    }
      setPrizes((p) => [...p, gData]);


    res({
      pattern: "",
      offerCode: "",
      advertizerId: "",
      estimatedRetailValue: "",
      adDescription: "",
      startDate: "",
      endDate: "",
      type: "",
      prizeId: "",
      hasNumberOfDays: "",
      expireNumberOfDays: "",
      expireEnterDate: "",
      selectDate: "",
      noticeDate:"",
      longPrize: "",
    });
  };



const resetForm =  (res) => {
  setmodalShow(false);  
  res({
    pattern: "",
    offerCode: "",
    advertizerId: "",
    estimatedRetailValue: "",
    adDescription: "",
    startDate: "",
    endDate: "",
    type: "",
    prizeId: "",
    hasNumberOfDays: "",
    expireNumberOfDays: "",
    expireEnterDate: "",
    selectDate: "",
    noticeDate:"",
    longPrize: "",
  });
  if(uId || uId===0){

    setPrizes([]);
  }
}


const copyEventType = (data,id) => {

if(data?.winParameterEndDate && data?.cardParameterEndDate){
  if(!(new Date(data?.winParameterStartDate) >= new Date(data?.cardParameterStartDate))){
    return toast.error("Please enter the similar date for card parameter and win parameter for the same row.", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
   }
   if(!(new Date(data?.winParameterStartDate) <= new Date(data?.cardParameterEndDate))){
    return toast.error("Please enter the similar date for card parameter and win parameter for the same row.", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  }
 }
// console.log(data);
setPrizes(data?.groupPrizeAssociation)
delete data?.groupPrizeAssociation;

reset1({
    eventType: data.eventType,
    league: data.league,
    numberOfFactor: data.numberOfFactor,
    cardParameterVersionId: data.cardParameterVersionId,
    // cardParameterStartDate: data.cardParameterStartDate,
    // cardParameterEndDate: new Date(data.cardParameterEndDate),
    winParameterVersionId:data.winParameterVersionId,
    // winParameterStartDate: data.winParameterStartDate,
    // winParameterEndDate:data.winParameterEndDate 
})
// debugger
// setGroupPrizeAssociation(result);
toast.success("Copied Successfully", {
  position: toast.POSITION.TOP_CENTER,
  toastId: "1",
  
});
// console.log(result);
}


const updateEvent = (id) => {
  let data = [...groupPrizeAssociation];
    data[id]["winParameterEndDate"] = subscribe2?.winParameterEndDate;
    data[id]["cardParameterEndDate"] = subscribe2?.cardParameterEndDate;
    data[id]["reason"] = subscribe2?.reason;

  let checkDate = universalFunction.validateDates({
    cardParameterStartDate:data[id].cardParameterStartDate,
    cardParameterEndDate:data[id].cardParameterEndDate,
    winParameterStartDate:data[id].winParameterStartDate,
    winParameterEndDate:data[id].winParameterEndDate
    })
    
    if(checkDate){
       return toast.error("Please enter the similar date for card parameter and win parameter for the same row.", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }else{
  setGroupPrizeAssociation(data)
  toast.success("Update Successfully", {
    position: toast.POSITION.TOP_CENTER,
    toastId: "1",
    
  });


  
  setValue2("winParameterEndDate", "")
  setValue2("cardParameterEndDate", "")
  setRetired("");
}
}

useEffect(()=>{
  setValue2("winParameterStartDate",subscribe2.cardParameterStartDate)
},[subscribe2.cardParameterStartDate])

// useEffect(()=>{
//   setValue2("cardParameterStartDate",subscribe2.winParameterStartDate)
// },[subscribe2.winParameterStartDate])


const addRetired = (id) => {
  setRetired(id);
  setValue2("reason", groupPrizeAssociation[id]?.reason)
  setValue2("winParameterEndDate", groupPrizeAssociation[id]?.winParameterEndDate)
  setValue2("cardParameterEndDate", groupPrizeAssociation[id]?.cardParameterEndDate)
}



const CardParameterEndDate = ({newId}) => (
  <div className="col-lg-2 col-12">
                          <div className="dropdown ">
                          { newId==0 ? "" : !newId &&    <label>End Date</label>}
                            <Controller
                              control={control2}
                              {...register2("cardParameterEndDate")}
                              render={({ field: { value, onChange } }) => (
                                <DatePicker
                                  className="form-control"
                                  placeholderText="please select date"
                                  selected={
                                    value ? moment(value).toDate() : value
                                  }
                                  onChange={onChange}
                                  minDate={retired || retired===0  ? new Date(groupPrizeAssociation[retired]?.cardParameterStartDate) : subscribe2.cardParameterStartDate}
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  scrollableYearDropdown
                                />
                              )}
                            />
                          </div>
                          {errors2.cardParameterEndDate && (
                            <p className="text-danger">
                              Please select the close date.
                            </p>
                          )}
                        </div>
)


const WinParameterEndDate = ({newId}) => (
  <div className="col-lg-2 col-12">
  <div className="dropdown ">
  { newId==0 ? "" : !newId &&  <label>End Date</label>}
         <Controller
         control={control2}
         {...register2("winParameterEndDate")}
         render={({ field: { value, onChange } }) => (
         <DatePicker
          className="form-control"
          placeholderText="please select date"
          selected={
            value ? moment(value).toDate() : value
          }
          onChange={onChange}
          minDate={retired || retired===0 ? new Date(groupPrizeAssociation[retired]?.winParameterStartDate) : subscribe2.winParameterStartDate}
          showYearDropdown
          dateFormatCalendar="MMMM"
          scrollableYearDropdown
        />
      )}
    />
  </div>
  {errors2.winParameterEndDate && (
    <p className="text-danger">
      Please select the close date.
    </p>
  )}
</div>
)


const findCard = (c)=>{
  if(c){
let data = allCarP?.length && allCarP?.find(d=>d.id==c)
return data.versionNumber
  }
}

const findWin = (x)=>{
  if(x){
    let data = versionN?.length && versionN?.find(w=>w.id==x)
   return data.versionNumber

  }
}


const customStyles = {
  multiValue: (styles, { data }) => {
  return ({
    ...styles,
      display:  data?.chck ?  'none' : "flex",
 
  })}
}


const eHandle = (e,act) => {
if(act?.action==="remove-value"){
let data = [...subscribe.eventIds];
let filterD = data.filter(({value})=>act?.removedValue.value!==value);
setNewEv([...newEv, act?.removedValue])
setValue("eventIds", filterD,{ shouldValidate: true });
}

if(act?.action==="select-option"){
setValue("eventIds", e,{ shouldValidate: true });
}
}


const findWinId = (id)=>{
  let data = [...versionN];
  let findData = data.find(d=>d.id==id) || "";
  return findData?.versionNumber
}

const cardId = (id)=>{
  let data = [...allCarP];
  let findData = data.find(d=>d.id==id) || "";
  return findData?.versionNumber
}

const forUi = subscribe.groupType === "Organized" || subscribe.groupType === "Local";

const addAdsControl = (d,res) => {
  let data =  
     {
    advertiserId: d.advertiserId?.id,
    adId: d?.adId?.value,
    runTime: d?.runTime,
    delayTime: d?.delayTime,
    hasOccurence: d?.hasOccurence ==="true" ? true : false,
    timeDuration: Number(d?.timeDuration) || null,
    occurence: Number(d?.occurence) || null
   }

   let Addata = [...listAd];

   let findData = Addata.find((d)=> d.advertiserId == data.advertiserId && d?.adId == data?.adId );

   if(findData){
    return toast.error("This Advertiser Id and Ad Id already exist on the below lists.", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
   }
  setListAd([...listAd,data]);
  res({
      advertiserId: '',
      adId: '',
      runTime: '',
      delayTime: '',
      hasOccurence:'false',
      timeDuration:'',
      occurence:''
  });
}



const adDelete = (id) => {
let data = [...listAd];
let filterData = data.filter((d,ind)=>ind!==id);
setListAd(filterData)
}

// console.log(subscribe.eventIds,"subscribe.eventIds");
// console.log(eventIdOptions,"eventIdOptions");
useEffect(()=>{
if(subscribe?.groupType){
setPolygonCoordinates([])
setValue("descriptions","")
setValue("latitude","")
setValue("longitude","")
}
},[subscribe?.groupType])
  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        
        <div className="rightBody">
          <div className="customTableWrapper">
          <AddTypeModal
            // passClearStateFunc={clearState}
            key={prizeA}
            prizeM={prizeA}
            pattern={showPattern}
            disInput={disInput}
            cb={AddPrizes}
            prize={prizes}
            setprize={setPrizes}
            oldPrize={oldPrizes}
            eventInfo={showTypes}
            advertiser={advertisement}
            show={showModal}
            handleclose={resetForm}
          />
           <EventID data={oldEventIds}  handleclose={()=>setEventP(false)}  show={eventP} />

          <AdsControl setListAd={setListAd} id={id} disInput={disInput} adsDelete={adDelete} listAds={listAd} adver={allAdvertisement} cb={addAdsControl} advertiser={advertisement}   handleclose={(res)=>{  res({
      advertiserId: '',
      adId: '',
      runTime: '',
      delayTime: ''
  });
  setmodalShowAds(false)}}  show={showModalAds} />

            <div className="row">
              <div className="col-sm-12">
                <div className="left addng">
                  <h4>
                    {" "}
                    {disInput
                      ? "View Group"
                      : id
                      ? "Edit Group"
                      : "Create Group"}
                  </h4>

                        <button
                          type="button"
                          onClick={()=>setmodalShowAds(true)}
                          className="btn  btn-primary"
                        >
                          Ads Control
                        </button>
              
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="Total-Revenue pb-5">
              <form>
                <div className="row management">
                  {submitFormLoader && <div className="loader"></div>}

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Group ID</label>
                      <input
                        type="text"
                        name="groupId"
                        placeholder="Eg:A123"
                        className="form-control"
                        maxLength={4}
                        disabled={disInput}
                        {...register("groupId", {
                          required: true,
                          pattern: {
                            value: /^[A-Z][A-F0-9]{3}/,
                          },
                        })}
                      />
                      {errors.groupId && (
                        <p className="text-danger">
                          Please enter the valid group Id.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Group Name</label>
                      <input
                        type="text"
                        name="groupName"
                        className="form-control"
                        disabled={disInput}
                        {...register("groupName", { required: true })}
                      />
                      {errors.groupName && (
                        <p className="text-danger">
                          Please enter the valid group name.
                        </p>
                      )}
                    </div>
                  </div>

                

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Group Type</label>
                      <select
                        id=""
                        className="form-control"
                        name="groupType"
                        disabled={disInput}
                        {...register("groupType", { required: true })}
                      >
                        <option value="">Choose Group Type</option>
                        <option value="Local">Local</option>
                        <option value="National">National</option>
                        <option value="Regional">Regional</option>
                        <option value="Organized">Organized</option>
                      </select>
                      {errors.groupType && (
                        <p className="text-danger">
                          Please select the group type.
                        </p>
                      )}
                    </div>
                  </div>

                 {subscribe.groupType === "Local"  && <div className="col-lg-3">
                  <label>Map</label>
                  <LocalMap forL={setForLocal} dis={disInput} getD={formData?.localGroupLocations} cb={setPolygonCoordinates}/>
                  </div>}

                  {subscribe.groupType === "Organized" && (
                    <div className="col-lg-3">
                      <div className="dropdown ">
                        <label>Password</label>
                        <input
                          disabled={disInput}
                          type="text"
                          name="password"
                          className="form-control"
                          {...register("password", { required: true })}
                        />
                        {errors.password && (
                          <p className="text-danger">
                            Please enter the password.
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Location Description</label>
                      <SearchLocationInput
                        inputname="descriptions"
                        dis={disInput || subscribe.groupType === "Local"}
                        val={subscribe.descriptions}
                        onclick={getDataLocation}
                        {...register("descriptions", { required:subscribe.groupType === "Local" ? false : true })}
                      />
                      {errors.descriptions && (
                        <p className="text-danger">
                          Please enter the location.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Minimum Age</label>
                      <input
                        type="number"
                        name="age"
                        className="form-control"
                        disabled={disInput}
                        {...register("age", { required: true })}
                      />
                      {errors.age && (
                        <p className="text-danger">Please enter the age.</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Latitude</label>
                      <input
                        type="text"
                        name="latitude"
                        disabled
                        className="form-control"
                        // value={locationData?.latitude}
                        {...register("latitude")}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Longitude</label>
                      <input
                        disabled
                        type="text"
                        name="longitude"
                        className="form-control"
                        {...register("longitude")}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Rounding</label>
                      <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("rounding", { required: true })}
                      />
                      {errors.rounding && (
                        <p className="text-danger">
                          Please enter the rounding.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={forUi ? "col-lg-3" : "col-lg-4"}>
                    <div className="dropdown ">
                      <label>Roll Over Pushdown</label>
                      <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("rolloverPushdown", { required: true })}
                      />
                      {errors.rolloverPushdown && (
                        <p className="text-danger">
                          Please enter the roll over pushdown.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={forUi ? "col-lg-3" : "col-lg-4"}>
                    <div className="dropdown ">
                      <label>Card Costs</label>
                      <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("cardCosts", { required: true })}
                      />
                      {errors.cardCosts && (
                        <p className="text-danger">
                          Please enter the card costs.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={forUi ? "col-lg-3" : "col-lg-4"}>
                    <div className="dropdown cdd">
                    <label> Event Id</label>
                    <Controller
                        control={control}
                        className="form-control"
                        style={{height: '45px'}}
                        {...register("eventIds")}
                        // {...register("eventIds", { required: groupPrizeAssociation?.length ? true : false })}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            components={{ClearIndicator:() => null ,
                            // MultiValueRemove
                            }}
                            isMulti
                            isDisabled={disInput}
                            value={value}
                            onChange={eHandle}
                            // onChange={eHandle}
                            options={eventIdOptions}
                            styles={customStyles}

                            // isOptionDisabled={}
                          />
                        )}
                      />
                      {errors.eventIds && (
                        <p className="text-danger">
                          Please select the event id.
                        </p>
                      )}
                    </div>
                             {id && 
                    <div className="dropdown mt-2 text-center cdd">
                  <button
                      type="button"
                      onClick={()=>setEventP(true)}
                      className="btn mt-0 mb-2 admin-primary-btn"
                    >
                    OLD EVENT id's
                    </button>
                  </div>}
                  </div>

                  <div className="col-lg-4">
                    <div className="dropdown borderBox">
                      <label>Organized Group Rules</label>
                      {/* <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("organizedGroupRules", { required: true })}
                      /> */}
                      <TextEditor read={disInput} readOnly key={formData.organizedGroupRules}  initial={formData.organizedGroupRules} {...register("organizedGroupRules", { required: true })} cb={(d) =>setValue("organizedGroupRules", d, { shouldValidate: true })} />

                    </div>
                      {errors.organizedGroupRules && (
                        <p className="text-danger mt-1">
                          Please enter the organized group rules.
                        </p>
                      )}
                  </div>

              
                  <div className="col-lg-4">
                    <div className="dropdown borderBox mt-2">
                      <label>Pattern Details</label>
                      {/* <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("patterns", { required: true })}
                      /> */}
                      <TextEditor read={disInput} key={formData.patterns}  initial={formData.patterns} {...register("patterns", { required: true })} cb={(d) =>setValue("patterns", d, { shouldValidate: true })} />
                    </div>
                      {errors.patterns && (
                        <p className="text-danger mt-1">
                          Please enter the patterns.
                        </p>
                      )}
                  </div>

                  
                  <div className="col-lg-4">
                    <div className="dropdown borderBox mt-2">
                      <label>Prize/Payout Details</label>
                      {/* <textarea
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("prizePayout", { required: true })}
                      /> */}
                      <TextEditor  read={disInput} key={formData.prizePayout}  initial={formData.prizePayout} {...register("prizePayout", { required: true })} cb={(d) =>setValue("prizePayout", d, { shouldValidate: true })} />

                    </div>
                      {errors.prizePayout && (
                        <p className="text-danger mt-1">
                          Please enter the prize/payout information.
                        </p>
                      )}
                  </div>


         


                  <div className="col-lg-12 mt-2">
                    <div className="dropdown ">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadio11"
                          checked={true}
                          id="inlineRadio11"
                          value="prize"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio11"
                        >
                          Prize
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadio11"
                          disabled
                          id="inlineRadio11"
                          value="payout"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio11"
                        >
                          Payout
                        </label>
                      </div>


                    </div>
                  </div>
                </div>
              </form>




              <form onSubmit={handleSubmit2(AddgroupPrizeAssociation)}>
                <div className="row">
                  <div
                    className="col-lg-12 d-flex mb-4"
                    style={{ justifyContent: "space-between" }}
                  >
                 <div className="col-lg-3 text-lg-left">
                 {id && <button
                          type="button"
                          onClick={()=>setShowTable(!showTable)}
                          className="btn btn-primary mt-4"
                        >
                         {disInput &&  !showTable ?  `View Old Type` : disInput && showTable ? "Back" : id && showTable ? "Back" : "View Old Type"}
                        </button>}
                      </div>

                    {!disInput && !showTable && (
                      <div className="col-lg-2 text-lg-right">
                        <button
                        style={{whiteSpace: 'nowrap'}}
                        disabled={retired || retired===0}
                          type="submit"
                          // onClick={}
                          className="btn btn-primary mt-4"
                        >
                          Add Type
                        </button>
                      </div>
                    )}
                  </div>

                  {showTable ? <TypeTable win={findWinId}  card={cardId}  type={oldEvent}/>

                  :

                  <div className="gscroll staticInfo col-md-12">
                    <div className="gstyle">
                      <div className="col-lg-12 d-lg-flex p-0">
                      <div className="col-lg-2 col-12">
                    <div className="dropdown">
                      <label>Event Type</label>
                      <select
                        disabled={disInput}
                        className="drop-button form-control"
                        {...register2("eventType", { required: true })}
                      >
                        <option value="">Select Event Type</option>
                        {Constants.EVENT_TYPES_AND_LEAGUES &&
                          Constants.EVENT_TYPES_AND_LEAGUES.map(
                            ({ code, name }, id) => {
                              return (
                                <option key={id} value={code}>
                                  {name}
                                </option>
                              );
                            }
                          )}
                      </select>
                      {errors2.eventType && (
                        <p className="text-danger">
                          Please select the event type.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label> League</label>
                      <select
                        disabled={disInput}
                        className="drop-button form-control"
                        {...register2("league", { required: true })}
                      >
                        <option value="">Select  League</option>
                        {showEventLeague &&
                          showEventLeague.map(({ code, name }, id) => {
                            return (
                              <option selected={subscribe2.league==code} key={id} value={code}>
                                {name}
                              </option>
                            );
                          })}
                      </select>
                      {errors2.league && (
                        <p className="text-danger">
                          Please select the league.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>Number Of Factor</label>
                      <select
                        className="form-control"
                        name="groupType"
                        disabled={disInput}
                        {...register2("numberOfFactor", { required: true })}
                      >
                        <option value="">Choose Number Of Factor</option>
                        <option value="4">4</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                      </select>
                      {errors2.numberOfFactor && (
                        <p className="text-danger">
                          Please select the number of factor.
                        </p>
                      )}
                    </div>
                  </div>


                  <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>Card Version</label>
                      <select
                        className="form-control"
                        disabled={disInput}
                        {...register2("cardParameterVersionId", {
                          required: true,
                        })}
                      >
                        <option value="">Select Card version</option>

                        {carP &&
                          carP.map(({ id, versionNumber }) => (
                            <option selected={subscribe2.cardParameterVersionId==id} key={id} value={id}>
                              {versionNumber}
                            </option>
                          ))}
                      </select>
                      {errors2.cardParameterVersionId && (
                        <p className="text-danger">
                          Please select the card version number.
                        </p>
                      )}
                    </div>
                  </div>

                        <div className="col-lg-2 col-12">
                          <div className="dropdown ">
                            <label>Start Date</label>
                            <Controller
                              control={control2}
                              {...register2("cardParameterStartDate", { required: true })}
                              render={({ field: { value, onChange } }) => (
                                <DatePicker
                                  className="form-control"
                                  placeholderText="please select date"
                                  selected={
                                    value ? moment(value).toDate() : value
                                  }
                                  onChange={onChange}
                                  minDate={subDays(new Date(), 0)}
                                  showYearDropdown
                                  maxDate={subscribe2.cardParameterEndDate}
                                  dateFormatCalendar="MMMM"
                                  scrollableYearDropdown
                                />
                              )}
                            />
                          </div>
                          {errors2.cardParameterStartDate && (
                            <p className="text-danger">
                              Please select the start date.
                            </p>
                          )}
                        </div>
                        { retired==="" ? <CardParameterEndDate />:     <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                      <label>End Date</label>
                      <input
                      disabled
                        type="text"
                        className="form-control"
                        placeholder="please select date"
                      
                      />
                    </div>
                  
                  </div>}
                        

                        <div className="col-lg-2 col-12">
                    <div className="dropdown ">
                      <label>Win Version</label>
                      <select
                        className="form-control"
                        disabled={disInput}
                        {...register2("winParameterVersionId", { required: true })}
                      >
                        <option value="">Select version number</option>

                        {versionN &&
                          versionN.map(({ id, versionNumber }) => (
                            <option key={id} value={id}>
                              {versionNumber}{" "}
                            </option>
                          ))}
                      </select>
                      {errors2.winParameterVersionId && (
                        <p className="text-danger">
                          Please select the win version number.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2 col-12">
                          <div className="dropdown ">
                            <label>Start Date</label>
                            <Controller
                              control={control2}
                              {...register2("winParameterStartDate", { required: true })}
                              render={({ field: { value, onChange } }) => (
                                <DatePicker
                                  className="form-control"
                                  placeholderText="please select date"
                                  disabled
                                  selected={
                                    value ? moment(value).toDate() : value
                                  }
                                  onChange={onChange}
                                  minDate={subDays(new Date(), 0)}
                                  // minDate={subscribe2.cardParameterStartDate}
                                  
                                  showYearDropdown
                                  maxDate={subscribe2.winParameterEndDate}
                                  dateFormatCalendar="MMMM"
                                  scrollableYearDropdown
                                />
                              )}
                            />
                          </div>
                          {/* {errors2.winParameterStartDate && (
                            <p className="text-danger">
                              Please select the start date.
                            </p>
                          )} */}
                        </div>

                      { retired==="" ? <WinParameterEndDate/> :     <div className="col-lg-2 col-12">
                      <div className="dropdown ">
                      <label>End Date</label>
                      <input
                      disabled
                        type="text"
                        className="form-control"
                        placeholder="please select date"
                      
                      />
                    </div>
                  
                  </div>}
                  <div className="col-lg-2 col-12">
                        <div className="dropdown ">
                            <label className="d-flex">Reason</label>
                            </div>
                            </div>

                        <div className="col-lg-3 col-12">
                        <div className="dropdown ">
                            <label className="d-flex justify-content-center">Actions</label>
                            <div className="d-flex ">
                                    <button
                                    style={{
                                      width: '55%',
                                    }}
                                      disabled={!subscribe2.eventType || !subscribe2.league || !subscribe2.winParameterVersionId }
                                      onClick={() => prizesClick({eventType:subscribe2.eventType, league:subscribe2.league,winParameterVersionId:subscribe2.winParameterVersionId })}
                                      type="button"
                                      className="btn btn-danger m-auto btn-block"
                                    >
                                      <span style={{fontSize: 'small',fontWeight: 700}}>Add Prizes</span>
                                    </button>
                                    </div>
                                  </div>
                                  </div>

              
                      </div>

                      {groupPrizeAssociation.length > 0 &&
                        groupPrizeAssociation.map(
                          (
                            {
                              eventType,
                              league,
                              numberOfFactor,
                              cardParameterVersionId,
                              cardParameterStartDate,
                              cardParameterEndDate,
                              winParameterVersionId,
                              winParameterStartDate,
                              groupPrizeAssociationOld,
                              winParameterEndDate,
                              groupPrizeAssociation,
                              reason
                            },
                            id
                          ) => {
                            return (
                              <div className="col-lg-12 d-lg-flex p-0" key={id}>
                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>Event Type</label> */}
                                    <input
                                      type="text"
                                      value={`${universalFunction.findLeague(eventType)} (${eventType})`}
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>League</label> */}
                                    <input
                                      type="text"
                                      value={universalFunction.findLeague(eventType,league)}
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>Number Of Factor</label> */}
                                    <input
                                      type="text"
                                      value={numberOfFactor}
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>Card Version </label> */}
                                    <input
                                      type="text"
                                      value={findCard(cardParameterVersionId)}
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>Start Date</label> */}
                                    <input
                                      type="text"
                                      value={
                                        cardParameterStartDate
                                          ? moment(cardParameterStartDate).format(
                                              "MM/DD/YYYY"
                                            )
                                          : moment()
                                      }
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>

                             { retired===id ?  <CardParameterEndDate newId={id} /> : <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>End Date</label> */}
                                    <input
                                      type="text"
                                      value={
                                        cardParameterEndDate
                                          ? moment(cardParameterEndDate).format(
                                              "MM/DD/YYYY"
                                            )
                                          : ""
                                      }
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>}

                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>Win Version</label> */}
                                    <input
                                      type="text"
                                      value={findWin(winParameterVersionId)}
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>Start Date</label> */}
                                    <input
                                      type="text"
                                      value={
                                        winParameterStartDate
                                          ? moment(winParameterStartDate).format(
                                              "MM/DD/YYYY"
                                            )
                                          : moment()
                                      }
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>

                               {retired===id ? <WinParameterEndDate newId={id}/> : <div className="col-lg-2 col-12">
                                  <div className="dropdown ">
                                    {/* <label>End Date</label> */}
                                    <input
                                      type="text"
                                      value={
                                        winParameterEndDate
                                          ? moment(winParameterEndDate).format(
                                              "MM/DD/YYYY"
                                            )
                                          : ""
                                      }
                                      className="form-control"
                                      disabled
                                    />
                                  </div>
                                </div>}

                                {retired===id ?  
                                  <div className="col-lg-2 col-12">
                                <textarea
                                {...register2("reason")}
                        type="text"
                        // defaultValue={reason}
                        placeholder="Enter some reason..."
                        className="form-control"
                        disabled={disInput}
                        // {...register("rounding", { required: true })}
                      /> </div> :   <div className="col-lg-2 col-12">
                                <textarea
                        type="text"
                        defaultValue={reason}
                        placeholder="Enter some reason..."
                        className="form-control"
                        disabled
                        // {...register("rounding", { required: true })}
                      /> </div>}

                                <div className="col-lg-3 col-12">
                                <div className="dropdown ">
                                {/* <label className="d-flex justify-content-center">Action</label> */}
                                <div className="d-flex ">
                                    <button
                                      onClick={() => prizesClick({
                                                      eventType,
                                                      league,
                                                      winParameterVersionId,
                                                      groupPrizeAssociation,
                                                      groupPrizeAssociationOld,
                                                      id
                                                    })}
                                      type="button"
                                      className="btn btn-danger m-1 btn-block"
                                    >
                                      <span style={{fontSize: 'small',fontWeight: 700}}>Prizes</span>
                                    </button>
                                   { retired===id ? <button
                                      onClick={() => updateEvent(id)}
                                      type="button"
                                      className="btn m-1 btn-danger btn-block"
                                    >
                                    <span style={{fontSize: 'small',fontWeight: 700}}>Update</span>
                                      
                                    </button>
                                      :
                                    <button
                                      onClick={() => addRetired(id)}
                                      type="button"
                                      className="btn m-1 btn-danger btn-block"
                                      disabled={disInput}
                                    >
                                    <span style={{fontSize: 'small',fontWeight: 700}}>Retired</span>
                                      
                                    </button>}
                                    <button
                                      onClick={() => copyEventType({
                              eventType,
                              league,
                              numberOfFactor,
                              cardParameterVersionId,
                              cardParameterStartDate,
                              cardParameterEndDate,
                              winParameterVersionId,
                              winParameterStartDate,
                              winParameterEndDate,
                              groupPrizeAssociation,
                            },id)}
                            disabled={retired===id || disInput}
                                      type="button"
                                      className="btn m-1 btn-danger btn-block"
                                    >
                                    <span style={{fontSize: 'small',fontWeight: 700}}>Copy</span>
                                      
                                    </button>
                                    
                                    </div>
                                  </div>
                                  </div>

                              </div>
                            );
                          }
                        )}
                      </div>
                      </div>}

            
                     
                </div>
              </form>

              <div className="row mb-5">
                <div className="col-lg-12">
                  <Link to="/admin/group">
                    <button type="button" className="btn admin-back-btn mr-2">
                      Back
                    </button>
                  </Link>

                  {!disInput && (
                    <button
                      type="submit"
                      onClick={handleSubmit(createGroup)}
                      // onClick={alert}
                      className="btn admin-primary-btn"
                    >
                      {id ? "Update" : "Submit"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreateGroup;
