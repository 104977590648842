import { useEffect } from 'react'
import {Link} from 'react-router-dom'
import player from '../../../assets/images/player.png'
import team from '../../../assets/images/team-icon.png'
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import { Subscribe } from "../user-dashboard/subscribe";
import { Sponsors } from "../user-dashboard/sponsors";
import './about.css'
import PreviewAdvertisement from '../preview-advertisement/preview-advertisement';

export default function About (){

  useEffect(()=>window.scrollTo(0, 0),[])
    return(
        <>
            <UserHeader />
                <section className="bredcum">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                <h1>About Us</h1>
                <Link to="/">Home</Link> <span> <i className="fa fa-angle-right" /> </span> <Link to="#" className="active">About Us</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="about-us">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading-sec text-center">
                  <h2>about us</h2>
                  <h4>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <img src={player} className="img-fluid" alt="" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <p>KiBu Gaming Corporation, founded in 2019, is operating on an exclusive license to provide the flagship game
                  CARRÉJOUE. In addition, the license allows us to create and operate additional games based on the
                  proprietary
                  game system.</p>
                <p>Robert Bugh, the game creator and member of the patent company, is our President and Chief Executive
                  Officer
                  (CEO). He has an Electrical Bachelor of Science and Engineering (BSE) from Arizona State University in 1992.
                  Also
                  an Accounting Bachelor of Business (BB) from Arizona State University in 1997. He sat for one of the last
                  paper-proctored Certified Public Accountant (CPA) exams and past it the first time. He has operated his own
                  small
                  l public accounting practice, Southwest ACT, PLLC, in the state of Arizona for nearly 20 years.</p>
                <p>The game patent is pending internationally, through the World Intellectual Property Organization (WIPO) and
                  in
                  the United States through the United States Patent and Trademark Organization (USPTO). Additional patents
                  are
                  planned for Japan, China, Europe and Australia.</p>
                <p>He grew up playing games of all types. His father taught him pari-mutuel betting at the age of about 12. He
                  has
                  fond memories of going to the dog racetrack and watching the dogs run while dad bet, giving dad his two
                  cents
                  worth of course.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="vision-about">
          <div className="equalhw">
            <div className="vision-content">
              <h2>Our vision</h2>
              <h5>Our vision is to operate the game in national, regional, local and private markets.</h5>
              <h4>National</h4>
              <p>Country by country where legal, for example - Las Vegas, NV.</p>
              <h4>Regional</h4>
              <p>State by state whereby, like the state lottery, only residents and visitors within the state are allowed to
                participate or consortiums of Native American nations.</p>
              <h4>Local</h4>
              <p>Stadiums, arenas, fields, etc. where the event the game is based on is being played. Only attendees to
                the game are allowed to participate.</p>
              <h4>Organized </h4>
              <p>Friends, companies, sports bars, etc. can operate their own game limiting the participation
                according to their needs.</p>
            </div>
          </div>
          <div className="equalhw">
            <div className="stadium" />
          </div>
        </section>
        {/* <div className="banner-ad pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <img src={banner} alt="banner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div> */}
        <PreviewAdvertisement />
        <section className="our-team">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="heading-sec text-center">
                  <h2>Experienced &amp; Professional Team</h2>
                  <h4>lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="team-area">
                  <div className="row">
                    <div className="col-lg-4">
                      <img src={team} alt="team" className="img-fluid" />
                    </div>
                    <div className="col-lg-8">
                      <div className="content-area">
                        <h5>Team Member Name</h5>
                        <h6>Designation</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer took Link galley
                          of type and scrambled it to make Link type specimen book.
                          It has survived not only five centuries, but also the leap into
                          electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="team-area">
                  <div className="row">
                    <div className="col-lg-4">
                      <img src={team} alt="team" className="img-fluid" />
                    </div>
                    <div className="col-lg-8">
                      <div className="content-area">
                        <h5>Team Member Name</h5>
                        <h6>Designation</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer took Link galley
                          of type and scrambled it to make Link type specimen book.
                          It has survived not only five centuries, but also the leap into
                          electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="team-area">
                  <div className="row">
                    <div className="col-lg-4">
                      <img src={team} alt="team" className="img-fluid" />
                    </div>
                    <div className="col-lg-8">
                      <div className="content-area">
                        <h5>Team Member Name</h5>
                        <h6>Designation</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer took Link galley
                          of type and scrambled it to make Link type specimen book.
                          It has survived not only five centuries, but also the leap into
                          electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="team-area">
                  <div className="row">
                    <div className="col-lg-4">
                      <img src={team} alt="team" className="img-fluid" />
                    </div>
                    <div className="col-lg-8">
                      <div className="content-area">
                        <h5>Team Member Name</h5>
                        <h6>Designation</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer took Link galley
                          of type and scrambled it to make Link type specimen book.
                          It has survived not only five centuries, but also the leap into
                          electronic typesetting, remaining essentially unchanged.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Subscribe />
      <Sponsors />
        <UserFooter/>
        </>
    )
}