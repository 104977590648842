/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreatePartner = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [submitFormLoader, setSubmitFormLoader] = useState("");

  const { id } = useParams();

  useEffect(() => {
    // reset form with user data
    reset(formData);
  }, [formData]);

  useEffect(() => {
    async function fetchData() {
      if (id !== undefined) {
        setSubmitFormLoader(true);
        let response = await services.getPartner(id);
        setFormData(response.data.data);
        if (history?.location?.state) {
          setDisInput(true);
        }
        setSubmitFormLoader(false);
      }
    }
    fetchData();
  }, [id]);

  const createPartner = async (d) => {
    let data = {
      partnerId: d.partnerId,
      partnerName: d.partnerName,
      partnerLocationDescription: d.partnerLocationDescription,
      partnerLocationAddress: d.partnerLocationAddress,
      partnerMailingAddress: d.partnerMailingAddress,
      contactInfoFields: d.contactInfoFields,
    };

    try {
      if (id) {
        setSubmitFormLoader(true);
        let response = await services.updatePartner(id, data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/partner");
      } else {
        // if (selectedFile?.name) {
        setSubmitFormLoader(true);
        let response = await services.partner(data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        setSubmitFormLoader(false);
        history.push("/admin/partner");
      }
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
  };

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>
                    {" "}
                    {disInput
                      ? "View Partner"
                      : id
                      ? "Edit Partner"
                      : "Create Partner"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="Total-Revenue">
              <form onSubmit={handleSubmit(createPartner)}>
                <div className="row">
                  {submitFormLoader && <div className="loader"></div>}
                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Assign Partner Id</label>
                      <input
                        maxLength={4}
                        placeholder="Eg:A0AF"
                        disabled={disInput}
                        type="text"
                        className="form-control"
                        {...register("partnerId", {
                          required: true,
                          pattern: {
                            value: /^[A-Z][A-F0-9]{3}/,
                          },
                        })}
                      />
                      {errors.partnerId && (
                        <p className="text-danger">
                          Please enter the Assign Partner Id in correct format.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Partner Name</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={disInput}
                        {...register("partnerName", { required: true })}
                      />
                      {errors.partnerName && (
                        <p className="text-danger">
                          Please enter the partner name.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Partner Location Description</label>
                      <input
                        type="text"
                        name="awayName"
                        disabled={disInput}
                        className="form-control"
                        {...register("partnerLocationDescription", {
                          required: true,
                        })}
                      />
                      {errors.partnerLocationDescription && (
                        <p className="text-danger">
                          Please enter the partner location description.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Partner Location Address</label>
                      <input
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        {...register("partnerLocationAddress", {
                          required: true,
                        })}
                      />
                      {errors.partnerLocationAddress && (
                        <p className="text-danger">
                          Please enter the partner location address.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Partner Mailing Address</label>
                      <input
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        {...register("partnerMailingAddress", {
                          required: true,
                        })}
                      />
                      {errors.partnerMailingAddress && (
                        <p className="text-danger">
                          Please enter the partner mailing address.
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="dropdown ">
                      <label>Contact Info Fields</label>
                      <input
                        type="text"
                        disabled={disInput}
                        className="form-control"
                        {...register("contactInfoFields", { required: true })}
                      />
                      {errors.contactInfoFields && (
                        <p className="text-danger">
                          Please enter the contact info fields.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-lg-12">
                    <Link to="/admin/partner">
                      <button
                        type="button"
                        className="btn admin-back-btn mr-2 "
                      >
                        Back
                      </button>
                    </Link>
                    {!disInput && (
                      <button type="submit" className="btn admin-primary-btn">
                      {id ? "Update" : "Submit"}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreatePartner;
