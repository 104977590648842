import React, { useEffect } from "react";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/forgot-password/forgot-password";
import UserDashboard from "./user/component/user-dashboard/user-dashboard";
import MainAdminDashboard from "./admin/components/index";
import ResetPassword from "./components/reset-password/reset-password";

import "./css/master-style.css";
import "./css/datepicker.min.css";
import "./css/footer.css";
import "./css/my-account.css";
import "./css/group-details.css";
import "./css/responsive.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { PrivateRoute, AdminRoute, PublicRoute } from "./route/PrivateRoute";
import { useSelector } from "react-redux";
// import firebase from 'firebase';
import userGroup from "./user/component/group/group";
import EventTypeRule from "./user/component/event-type-rule/eventTypeRule";
import GroupAssociateEvent from "./user/component/group-associate/groupAssociateEvent";
import About from "./user/component/about/about";
import TermAndCondition from "./user/component/term-and-condition/termAndCondition";
import PrivacyPolicy from "./user/component/term-and-condition/privacyPolicy";
import GameRule from "./user/component/game-rule/gameRule";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import GameCard from "./user/component/game-card/gameCard";
import BaseballRule from "./user/component/baseball-event/baseBallRule";
import FootBallRule from "./user/component/football-event/footBallRule";
import EventAssociateGroup from "./user/component/event-associate/eventAssociateGroup";
import MyEvent from "./user/component/user-profile/myEvents";
import EditProfile from "./user/component/user-profile/editProfile";
import GameResult from "./user/component/result/result";
import ResponsibleGaming from "./user/component/responsible-gaming/responsibleGaming";
import ContactUs from "./user/component/contact-us/contactUs";
import RetailerCoupon from "./user/component/retailer-coupon/retailer";

// import firebaseInit from "./firebase/firebase";

function App() {
  let data = useSelector((state) => state.loginReducer);
  let history = useHistory();

  useEffect(() => {
    if (data.user?.role === 2) return history.push("/");
    if (data.user?.role === 1) {
      history.push("/admin-dashboard");
    }

  }, [data]);

  return (
    <Switch>
      <PublicRoute
        restricted={true}
        exact
        path="/login"
        component={Login}
      ></PublicRoute>
      <PublicRoute restricted={true} exact path="/signup" component={SignUp} />
      <Route exact path="/forgot-password" component={ForgotPassword}></Route>
      <PublicRoute exact path="/" component={UserDashboard} />
      <PublicRoute exact path="/user/user-group" component={userGroup} />
      <PublicRoute
        exact
        path="/user/event-type-rule"
        component={EventTypeRule}
      />
      <PrivateRoute
        exact
        path="/user/associated-groups-with-events/:id"
        component={GroupAssociateEvent}
      />
      <PrivateRoute
        exact
        path="/user/associated-events-with-group/:id"
        component={EventAssociateGroup}
      />
      <PublicRoute
        restricted={false}
        exact
        path="/user/contact-us"
        component={ContactUs}
      />
      <PublicRoute exact path="/user/game-rules" component={GameRule} />
      <PublicRoute
        restricted={false}
        exact
        path="/user/about-us"
        component={About}
      />
      <PublicRoute
        restricted={false}
        exact
        path="/user/terms-condition"
        component={TermAndCondition}
      />
      <PublicRoute
        restricted={false}
        exact
        path="/user/privacy-policy"
        component={PrivacyPolicy}
      />
      <PrivateRoute exact path="/user/change-password" component={ResetPassword} />
      <PrivateRoute exact path="/user/game-card" component={GameCard} />
      <PrivateRoute exact path="/user/result/:id" component={GameResult} />
      <PublicRoute
        exact
        path="/user/baseball-event-rules"
        component={BaseballRule}
      />
      <PublicRoute
        exact
        path="/user/football-event-rules"
        component={FootBallRule}
      />
      <PrivateRoute exact path="/user/my-account" component={EditProfile} />
      <PrivateRoute exact path="/user/my-events" component={MyEvent} />
      <PublicRoute
        restricted={false}
        exact
        path="/user/responsible-gaming"
        component={ResponsibleGaming}
      />
      <PublicRoute
        exact
        path="/user/:uId/prize-detail/event/:eId/group/:gId/pattern/:name"
        component={RetailerCoupon}
      />
      <AdminRoute component={MainAdminDashboard} />
      <Route path="*">
        <h1 className="text-center">Page not found</h1>
      </Route>
      {/* <Redirect to="/login"></Redirect> */}
    </Switch>
  );
}

export default withRouter(App);
