import {Link} from 'react-router-dom';
import baseball from '../../../assets/images/baseball-img.png';
import football from '../../../assets/images/american-football.png';
import basketball from '../../../assets/images/basketball-img.png';
// import banner from '../../../assets/images/banner-flyer.jpg';
import UserHeader from '../../core/header/userHeader';
import {UserFooter} from '../../core/footer/userFooter';
import './eventtype.css'
import PreviewAdvertisement from '../preview-advertisement/preview-advertisement';


export default function EventTypeRule (){

    return (
        <>
      <UserHeader />
            <section className="bredcum">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                <h1>Event Type Rules</h1>
                <Link to="/">Home</Link> <span> <i className="fa fa-angle-right" /> </span> <Link to="#" className="active">Event Type Rules</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="eventrules-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="heading-sec mb-5">
                  <h2>Specific Rules of Play by Sport </h2>
                  <h4 className="pb-3"><b>Sporting Events</b> are classified by <b> Event Types </b> and their ties to the <b> Carrejoue </b> game are detailed here.  Please contact us with questions, concerns or for clarifications.
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Link to="/user/baseball-event-rules">
                <div className="event-box-green text-center">
                    <img src={baseball} alt="" />
                  <div className="overlay">
                  <div className="button-list">
                        <span className="line1" />
               <Link to="/user/baseball-event-rules" className="game-name-2">Baseball</Link> 
                      <span className="line2" />
                    </div>
                  </div>
                  <Link to="/user/baseball-event-rules" className="game-name">Baseball</Link> 
                </div>
                </Link> 
              </div> 
              <div className="col-lg-4 col-md-6">
              <Link to="/user/football-event-rules">
                <div className="event-box-pink text-center">
                  <img src={football} alt="football" />
                  <div className="overlay">
                    <div className="button-list">
                      <span className="line1" />
                      <Link to="/user/football-event-rules" className="game-name-2">Football</Link> 
                      <span className="line2" />
                    </div>
                  </div>
                  <Link to="/user/football-event-rules" className="game-name">Football</Link> 
                </div>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="event-box-grey text-center">
                  <img src={basketball} alt="bascetball" />
                  <div className="overlay">
                    <div className="button-list">
                      <span className="line1" />
                      <Link to="#" className="game-name-2">Basketball</Link> 
                      <span className="line2" />
                    </div>
                  </div>
                  <Link to="#" className="game-name">Basketball</Link> 
                </div>
              </div>
            </div>
          </div> 
        </section>
        <PreviewAdvertisement />
        {/* <div className="banner-ad pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <img src={banner} alt="banner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div> */}
             <UserFooter />
        </>
    )
}