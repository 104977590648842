import { Route, Switch } from 'react-router-dom';
import AdvertisementList from './advertisement/advertisements';
import CreateAdvertisemnt from './advertisement/create-advertisement';
import AdminDashboard from './admin-dashboard/admin-dashboard';
import Event from './event/event';
import CreateEvent from './event/CreateEvent';
import Group from './group/group';
import CreateGroup from './group/CreateGroup';
import '../css/footer.css';
import '../css/header.css';
import '../css/leftsidebar.css';
import '../css/login.css';
import '../css/rightbar.css';
import '../css/responsive.css';
import BaseBallCallerBoard from './caller-board/baseBallcallerBoard';
import WinParameter from './win-parameter/winParameter';
import Partner from './partner/partner';
import CreatePartner from './partner/CreatePartner';
import CardParameter from './card-parameter/cardParameter';
import CreateWinParameter from './win-parameter/createWinPara';
import CreateCardPara from './card-parameter/CreateCardPara';
import Roles from './permission-and-roles/roles';
import PermissionModule from './permission-and-roles/permission';
import SetPermission from './permission-and-roles/setPermission';
import UserModule from './user_module/user';
import CallerBoardHome from './caller-board/callerBoardHome';
import FootBallCallerBoard from './caller-board/footballCallerBoard';
import BasketBallCallerBoard from './caller-board/basketBallCallerBoard';
import Employee from './employee/employee';
import CreateEmployee from './employee/createEmployee';
import UserRegisterd from './admin-dashboard/userRegisterd';
import Prizes from './prizes/prizes';
import CreatePrizes from './prizes/createPrizes';
import ResetPassword from '../../components/reset-password/reset-password';

const MainAdminDashboard = () => {

    return (
        <Switch>
       
            <Route exact path='/admin-dashboard' component={AdminDashboard}></Route>
            <Route exact path="/admin/change-password" component={ResetPassword} />

            <Route exact path='/admin/advertisements' component={AdvertisementList}></Route>
            <Route exact path='/admin/create-advertisement' component={CreateAdvertisemnt}></Route>
            <Route exact path='/admin/edit-advertisement/:id' component={CreateAdvertisemnt}></Route>
            <Route exact path='/admin/advertisement-details/:id' component={CreateAdvertisemnt}></Route>
      
            <Route exact path='/admin/event' component={Event}></Route>
            <Route exact path='/admin/create-event' component={CreateEvent}></Route>
            <Route exact path='/admin/edit-event/:id' component={CreateEvent}></Route>
            <Route exact path='/admin/event-details/:id' component={CreateEvent}></Route>

            <Route exact path='/admin/group' component={Group}></Route>
            <Route exact path='/admin/create-group' component={CreateGroup}></Route>
            <Route exact path='/admin/edit-group/:id' component={CreateGroup}></Route>
            <Route exact path='/admin/group-details/:id' component={CreateGroup}></Route>

            <Route exact path='/admin/partner' component={Partner}></Route>
            <Route exact path='/admin/create-partner' component={CreatePartner}></Route>
            <Route exact path='/admin/edit-partner/:id' component={CreatePartner}></Route>
            <Route exact path='/admin/partner-details/:id' component={CreatePartner}></Route>

            <Route exact path='/admin/win-parameters' component={WinParameter}></Route>
            <Route exact path='/admin/create-win-parameter' component={CreateWinParameter}></Route>
            <Route exact path='/admin/view-win-parameter/:id' component={CreateWinParameter}></Route>
            <Route exact path='/admin/edit-win-parameter/:id' component={CreateWinParameter}></Route>

            <Route exact path='/admin/caller-board' component={CallerBoardHome}></Route>
            <Route exact path='/admin/baseball-caller-board' component={BaseBallCallerBoard}></Route>
            <Route exact path='/admin/football-caller-board' component={FootBallCallerBoard}></Route>
            <Route exact path='/admin/basketball-caller-board' component={BasketBallCallerBoard}></Route>
            

            <Route exact path='/admin/card-parameter' component={CardParameter}></Route>
            <Route exact path='/admin/create-card-parameter' component={CreateCardPara}></Route>
            <Route exact path='/admin/view-card-parameter/:id' component={CreateCardPara}></Route>

            <Route exact path='/admin/prizes' component={Prizes}></Route>
            <Route exact path='/admin/create-prizes' component={CreatePrizes}></Route>
            <Route exact path='/admin/edit-prize/:id' component={CreatePrizes}></Route>

            <Route exact path='/admin/roles' component={Roles}></Route>
            <Route exact path='/admin/view-permissions' component={SetPermission}></Route>
            <Route exact path='/admin/edit-permissions' component={SetPermission}></Route>
            <Route exact path='/admin/set-permissions' component={SetPermission}></Route>
            
            <Route exact path='/admin/permissions' component={PermissionModule}></Route>

            <Route exact path='/admin/employees' component={Employee}></Route>
            <Route exact path='/admin/create-employee' component={CreateEmployee}></Route>
            <Route exact path='/admin/employee-details/:id' component={CreateEmployee}></Route>




            <Route exact path='/admin/user-list' component={UserModule}></Route>
            <Route exact path='/admin/user-registered' component={UserRegisterd}></Route>


            <Route path="*">
             <h1 className="text-center">Page not found</h1>
             </Route>
            
        </Switch>
        
    )

}

export default MainAdminDashboard;