import * as Constants from "../helper/constants";
import moment from "moment";
import { toast } from "react-toastify";
import _ from "lodash";

export function findLeague(eventtype, league) {
  if (eventtype) {
    if (eventtype && league) {
      let data = Constants.EVENT_TYPES_AND_LEAGUES.find(
        ({ code }) => code === eventtype
      );
      let findLeague = data.eventLeague.find(({ code }) => code === league);
      return findLeague?.name;
    }

    let data = Constants.EVENT_TYPES_AND_LEAGUES.find(
      ({ code }) => code === eventtype
    );
    return data?.name;
  }

  return "";
}

export function chunckArr(arr,cb) {
  let x = 0;
  let y = 0;

  let arr2 = [];
  arr?.map((data, i) => {
    if (x <= 24) {
      if (y <= 24) {
        arr2.push([data]);
        cb(x + 1);
      } else {
        cb(x + 1);

        arr2[x].push(data);
      }

      x++;
      y++;
    } else {
      arr2[0].push(data);
      x = 1;
      cb(x);
    }
  });

  return arr2;
}

export function errorCallback(error) {
  if (error.code == 1) {
    toast.error(
      "Please Enable your location from the browser else you won't be Enter the game",
      {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      }
    );
    
  } else if (error.code == 2) {
    toast.error(
      "The network is down or the positioning service can't be reached.",
      {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      }
    );
  } else if (error.code == 3) {
    toast.error(
      "The attempt timed out before it could get the location data.",
      {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      }
    );
  } else {
    toast.error("Geolocation failed due to unknown error.", {
      position: toast.POSITION.TOP_CENTER,
      toastId: "1",
    });
  }
}

export function compareArray(array1, array2) {
  let result = array1.map((item, i) => item === array2[i]);
  return result;
}

async function getWinParameter(type,arr) {
  let winParameter = [];
  let checkVerticalLine1 =arr[0]&& arr[5]&& arr[10] && arr[15] && arr[20];
  let checkVerticalLine2 =arr[1]&& arr[6]&& arr[11] && arr[16] && arr[21];
  let checkVerticalLine3 =arr[2]&& arr[7]&& arr[12] && arr[17] && arr[22];
  let checkVerticalLine4 =arr[3]&& arr[8]&& arr[13] && arr[18] && arr[23];
  let checkVerticalLine5 =arr[4]&& arr[9]&& arr[14] && arr[19] && arr[24];

  
  let checkHorizontalLineOne =  arr[0]  && arr[1] && arr[2] && arr[3] && arr[4];
  let checkHorizontalLineTwo =  arr[5]  && arr[6] && arr[7] && arr[8] && arr[9];
  let checkHorizontalLineThree =arr[10] && arr[11] && arr[12] && arr[13] && arr[14];
  let checkHorizontalLineFour = arr[15] && arr[16] && arr[17] && arr[18] && arr[19];
  let checkHorizontalLineFive = arr[20] && arr[21] && arr[22] && arr[23] && arr[24];

  
  let checkUpperLeftLowerRight =arr[0]&& arr[6]&& arr[12] && arr[18] && arr[24];
  let checkLowerLeftUpperRight= arr[4]&& arr[8]&& arr[12] && arr[16] && arr[20];
  
  let checkX= arr[0]&& arr[6]&& arr[12] && arr[18] && arr[24] && arr[4]&& arr[8]&& arr[16] && arr[20];
  let checkSmallBox= arr[6]&& arr[7]&& arr[8] && arr[11] && arr[12] && arr[13]&& arr[16]&& arr[17] && arr[18];
  
  let checkUpperLeftHalfBlackout= checkHorizontalLineOne &&  checkVerticalLine1 && arr[6] && arr[7] && arr[8] &&  arr[11] && arr[12]&& arr[16];
  let checkLowerLeftHalfBlackout= checkVerticalLine1 && checkHorizontalLineFive &&  arr[6] && arr[11] && arr[12]&&  arr[16] && arr[17] &&  arr[18];
  let checkUpperRightHalfBlackout= checkHorizontalLineOne && checkVerticalLine5 &&  arr[6] && arr[7] && arr[8] && arr[12] && arr[13] && arr[18];
  let checkLowerRightHalfBlackout= checkHorizontalLineFive && checkVerticalLine5 && arr[8] && arr[12] && arr[13] && arr[16] && arr[17] &&  arr[18];
  
  let checkCorners= arr[0]&& arr[4]&& arr[20] && arr[24];

  
  let checkUpperLeftPostage= arr[0]&& arr[1]&& arr[5] && arr[6];
  let checkUpperRightPostage= arr[3]&& arr[4]&& arr[8] && arr[9];
  let checkLowerLeftPostage= arr[15]&& arr[16]&& arr[20] && arr[21];
  let checkLowerRightPostage= arr[18]&& arr[19]&& arr[23] && arr[24];

  let checkUpperLeftPostageCorners= arr[0]&& arr[1]&& arr[5] && arr[6]&& arr[4]&& arr[20]&&arr[24];
  let checkLowerRightCornersPostage= arr[0]&& arr[4]&& arr[20] && arr[18] && arr[19]&& arr[23]&& arr[24];
  let checkUpperLeftLowerRightPostage= arr[0]&& arr[1]&& arr[5] && arr[6]&& arr[18]&& arr[19]&& arr[23] && arr[24];

  let checkWindow= checkHorizontalLineOne && checkHorizontalLineFive && checkVerticalLine1 && checkVerticalLine5;

  function horizontalLineOne() {
    if (checkHorizontalLineOne) {
      winParameter.push("horizontalLineOne")
    }
    return winParameter;
  }
  function horizontalLineTwo() {
    if (checkHorizontalLineTwo) {
      winParameter.push("horizontalLineTwo")
    }
    return winParameter;
  }
  function horizontalLineThree() {
    if (checkHorizontalLineThree) {
      winParameter.push("horizontalLineThree")
    }
    return winParameter;
  }
  function horizontalLineFour() {
    if (checkHorizontalLineFour) {
      winParameter.push("horizontalLineFour")
    }
    return winParameter;
  }
  function horizontalLineFive() {
    if (checkHorizontalLineFive) {
      winParameter.push("horizontalLineFive")
    }
    return winParameter;
  }
  function anyTwoHorizontalLine() {
    if([checkHorizontalLineOne, checkHorizontalLineTwo, checkHorizontalLineThree, checkHorizontalLineFour, checkHorizontalLineFive].filter(Boolean).length === 2){
      winParameter.push("anyTwoHorizontalLine")
    }
    return winParameter;
  }
  // function allhorizontalLine() {
  //   if(checkHorizontalLineOne && checkHorizontalLineTwo && checkHorizontalLineThree && checkHorizontalLineFour && checkHorizontalLineFive){
  //     winParameter.push("allhorizontalLine")
  //   }
  //   return winParameter;
  // }
 
  
  function verticalLineOne() {
    if(checkVerticalLine1){
      winParameter.push("verticalLineOne")
    }
    return winParameter;
  }
  function verticalLineTwo() {
     if(checkVerticalLine2){
       winParameter.push("verticalLineTwo")
    }
    return winParameter;
  }
  function verticalLineThree() {
     if(checkVerticalLine3){
       winParameter.push("verticalLineThree")
    }
    return winParameter;
  }
  function verticalLineFour() {
     if(checkVerticalLine4){
       winParameter.push("verticalLineFour")
    }
    return winParameter;
  }
  function verticalLineFive() {
     if(checkVerticalLine5){
       winParameter.push("verticalLineFive")
    }
    return winParameter;
  }
  function anyTwoVerticalLine() {
    if( [checkVerticalLine1, checkVerticalLine2, checkVerticalLine3, checkVerticalLine4, checkVerticalLine5].filter(Boolean).length >= 2){
      winParameter.push("anyTwoVerticalLine")
    }
      return winParameter;
  }
  // function allVerticalLine() {
  // if(checkVerticalLine1 && checkVerticalLine2 && checkVerticalLine3 && checkVerticalLine4 && checkVerticalLine5){
  //   winParameter.push("allVerticalLine")
  //   }
  //   return winParameter;
  // }
  
  function upperLeftLowerRight() {
     if(checkUpperLeftLowerRight){
       winParameter.push("upperLeftLowerRight")
    }
    return winParameter;
  }
  function lowerLeftUpperRight() {
     if(checkLowerLeftUpperRight){
       winParameter.push("lowerLeftUpperRight")
    }
    return winParameter;
  }
  
  function top() {
     if(checkHorizontalLineOne && checkHorizontalLineTwo && checkVerticalLine3){
       winParameter.push("top")
    }
    return winParameter;
  }
  function bottom() {
     if(checkHorizontalLineThree && checkHorizontalLineFour && checkHorizontalLineFive){
       winParameter.push("bottom")
    }
    return winParameter;
  }
  function left() {
     if(checkVerticalLine1 && checkVerticalLine2 && checkVerticalLine3){
       winParameter.push("left")
    }
    return winParameter;
  }
  function right() {
     if(checkVerticalLine3 && checkVerticalLine4 && checkVerticalLine5){
       winParameter.push("right")
    }
    return winParameter;
  }
  
  function upperLeft() {
     if(checkUpperLeftHalfBlackout){
       winParameter.push("upperLeft")
    }
    return winParameter;
  }
  function lowerLeft() {
     if(checkLowerLeftHalfBlackout){
       winParameter.push("lowerLeft")
    }
    return winParameter;
  }
  function upperRight() {
     if(checkUpperRightHalfBlackout){
       winParameter.push("upperRight")
    }
    return winParameter;
  }
  function lowerRight() {
     if(checkLowerRightHalfBlackout){
       winParameter.push("lowerRight")
    }
    return winParameter;
  }

  function blackout() {
    if (checkHorizontalLineOne && checkHorizontalLineTwo && checkHorizontalLineThree && checkHorizontalLineFour && checkHorizontalLineFive) {
      winParameter.push("blackout")
    }
    return winParameter;
  }

  // function corners() {
  //   if(corners){
  //     winParameter.push("corners")
  //   }
  //   return winParameter;
  // }

  function upperLeftPostage() {
    if(checkUpperLeftPostage){
      winParameter.push("upperLeftPostage")
    }
    return winParameter;
  }

  function upperRightPostage() {
     if(checkUpperRightPostage){
       winParameter.push("upperRightPostage")
    }
    return winParameter;
  }
  function lowerLeftPostage() {
     if(checkLowerLeftPostage){
       winParameter.push("lowerLeftPostage")
    }
    return winParameter;
  }
  function lowerRightPostage() {
     if(checkLowerRightPostage){
       winParameter.push("lowerRightPostage")
    }
    return winParameter;
  }

  function upperLeftPostageCorners() {
    if(checkUpperLeftPostageCorners){
      winParameter.push("upperLeftPostageCorners")
   }
   return winParameter;
 }

 function lowerRightCornersPostage() {
  if(checkLowerRightCornersPostage){
    winParameter.push("lowerRightCornersPostage")
 }
 return winParameter;
}

function upperLeftLowerRightPostage() {
if(checkUpperLeftLowerRightPostage){
  winParameter.push("upperLeftLowerRightPostage")
}
return winParameter;
}
function windows() {
if(checkWindow){
  winParameter.push("windows")
}
return winParameter;
}

function substituteForBlackout() {
  if(checkWindow){
    winParameter.push("substituteForBlackout")
  }
  return winParameter;
  }

function x() {
  if (checkX) {
    winParameter.push("x")
  }
  return winParameter;
}
function smallBox() {
  if (checkSmallBox) {
    winParameter.push("smallBox")
  }
  return winParameter;
}
  function defaultF() {
    
    return winParameter;
  }
  
  
  let winParameters = {
    // Horizontal Lines
    horizontalLineOne: horizontalLineOne,
    horizontalLineTwo: horizontalLineTwo,
    horizontalLineThree: horizontalLineThree,
    horizontalLineFour: horizontalLineFour,
    horizontalLineFive: horizontalLineFive,
    anyTwoHorizontalLine: anyTwoHorizontalLine,
    // allhorizontalLine: allhorizontalLine,
    
    // Vertical Lines
    verticalLineOne: verticalLineOne,
    verticalLineTwo: verticalLineTwo,
    verticalLineThree: verticalLineThree,
    verticalLineFour: verticalLineFour,
    verticalLineFive: verticalLineFive,
    anyTwoVerticalLine: anyTwoVerticalLine,
    // allVerticalLine: allVerticalLine,
    
    
     // Half Blackouts
    top: top,
    bottom: bottom,
    left: left,
    right: right,
    upperLeft: upperLeft,
    lowerLeft: lowerLeft,
    upperRight: upperRight,
    lowerRight:lowerRight,
    
     // Diagonals
    upperLeftLowerRight: upperLeftLowerRight,
    lowerLeftUpperRight: lowerLeftUpperRight,

     // Blackouts
    blackout: blackout,
    x:x,
    smallBox:smallBox,
    substituteForBlackout:substituteForBlackout,
    windows:windows,
    // corners
    // corners:corners,
    // upperLeftPostage:upperLeftPostage,
    // upperRightPostage:upperRightPostage,
    // lowerLeftPostage:lowerLeftPostage,
    // lowerRightPostage:lowerRightPostage,


    upperLeftPostageCorners:upperLeftPostageCorners,
    lowerRightCornersPostage:lowerRightCornersPostage,
    upperLeftLowerRightPostage:upperLeftLowerRightPostage

    
    
  };
  return (winParameters[type] || defaultF)();
}



export function chunks(arr, len) {
  var chunks = [],
    i = 0,
    n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

export function singleArray(gamecall, GameCard) {
  let distributedArray = chunks(gamecall, 25);
  let distributedArrayWithValues = [];
  distributedArray.map((d) => {
    let arrayWithBooleanValues = compareArray(d, GameCard);
    distributedArrayWithValues.push(arrayWithBooleanValues);
  });
  let finalArrayForGettinWinParameter =
    distributedArrayWithValues && distributedArrayWithValues.length > 0
      ? distributedArrayWithValues[0]
      : [];

  for (let index = 1; index < distributedArrayWithValues.length; index++) {
    let innerArrayToCompare = distributedArrayWithValues[index];
    for (let j = 0; j < innerArrayToCompare.length; j++) {
      if (innerArrayToCompare[j] && !finalArrayForGettinWinParameter[j]) {
        finalArrayForGettinWinParameter[j] = true;
      } else if (
        !innerArrayToCompare[j] &&
        finalArrayForGettinWinParameter[j]
      ) {
        finalArrayForGettinWinParameter[j] = true;
      } else if (innerArrayToCompare[j] && finalArrayForGettinWinParameter[j]) {
        finalArrayForGettinWinParameter[j] = true;
      } else if (
        !innerArrayToCompare[j] &&
        !finalArrayForGettinWinParameter[j]
      ) {
        finalArrayForGettinWinParameter[j] = false;
      }
    }
  }

  return finalArrayForGettinWinParameter;
}


export async function greenColorBox(finalArr, pattern) {
  let newArr = new Array(25).fill(false);
  let allPattern = [];
  let verticalLineOne = [0, 5, 10, 15, 20];
  let verticalLineTwo = [1, 6, 11, 16, 21];
  let verticalLineThree = [2, 7, 12, 17, 22];
  let verticalLineFour = [3, 8, 13, 18, 23];
  let verticalLineFive = [4, 9, 14, 19, 24];

  let horizontalLineOne = [0, 1, 2, 3, 4];
  let horizontalLineTwo = [5, 6, 7, 8, 9];
  let horizontalLineThree = [10, 11, 12, 13, 14];
  let horizontalLineFour = [15, 16, 17, 18, 19];
  let horizontalLineFive = [20, 21, 22, 23, 24];

  let upperLeftLowerRight = [0, 6, 12, 18, 24];
  let lowerLeftUpperRight = [4, 8, 12, 16, 20];

  let left= [ ...verticalLineOne, ...verticalLineTwo, ...verticalLineThree];
  let right= [ ...verticalLineThree, ...verticalLineFour, ...verticalLineFive];
  let bottom= [ ...horizontalLineThree, ...horizontalLineFour, ...horizontalLineFive];
  let upperLeft = [
    ...horizontalLineOne,
    ...verticalLineOne,
    6,
    7,
    8,
    11,
    12,
    16,
  ];

  let lowerLeft = [
    ...verticalLineOne,
    ...horizontalLineFive,
    6,
    11,
    12,
    16,
    17,
    18,
  ];

  let upperRight = [
    ...horizontalLineOne,
    ...verticalLineFive,
    6,
    7,
    8,
    12,
    13,
    18,
  ];
  let lowerRight = [
    ...horizontalLineFive,
    ...verticalLineFive,
    8,
    12,
    13,
    16,
    17,
    18,
  ];


//corners

  let upperLeftPostageCorners=[0,1,5,6,4,20,24];
  let lowerRightCornersPostage=[0,4,20,18,19,23,24];
  let upperLeftLowerRightPostage=[0,1,5,6,18,19,23,24];

  let blackout = [...horizontalLineOne, ...horizontalLineTwo, ...horizontalLineThree, ...horizontalLineFour,...horizontalLineFive];
  let windows = [...horizontalLineOne, ...horizontalLineFive, ...verticalLineOne, ...verticalLineFive];
  let substituteForBlackout = [...windows];
  let x = [...upperLeftLowerRight, ...lowerLeftUpperRight];
  let smallBox = [6,7,8,11,12,13,16,17,18]

  if (pattern && finalArr) {
   await pattern.map(async (data) => {
    //  console.log(data);
    let patt = await getWinParameter(data, finalArr);
      if(patt[0]?.length){

        if(patt[0]=="anyTwoVerticalLine"){
          return false
        }
        // console.log(eval(patt[0]),"...eval(patt[0]");
        // console.log(...eval(patt[0]),"...eval(patt[0]");
        allPattern.push(eval(patt[0]));
      }
      

    });
  }

  if (allPattern) {
    [...new Set(allPattern.flat())]?.map((data) => (newArr[data] = true));
  }

  return newArr;
}



export function groupBy( array , f ){
var groups = {};
array.forEach( function( o )
{
    var group = JSON.stringify( f(o) );
    groups[group] = groups[group] || [];
    groups[group].push( o );  
});
return Object.keys(groups).map( function( group )
{
    return groups[group]; 
})
}


export function checkValidateOverlapping(result){
  let isInValidd = false
  result.length && result.map((r)=>{
      // console.log("rrr",r);
      let resul =  validateOverlapping(r)
      if(resul){
          isInValidd = resul
          // console.log("isInValiddisInValidd",isInValidd);
          return isInValidd
      }

      // console.log("resulresulresul234324",resul);
     
  });
  return isInValidd
}


function validateOverlapping(events) {
  let isInvalid =  false;
  let winParameterEndDateCounter=0;
  let cardParameterEndDateCounter=0;

  events.map((e,index)=>{
      if(!e.winParameterEndDate) {
        winParameterEndDateCounter = winParameterEndDateCounter+1 
      } else if (!e.cardParameterEndDate) {
        cardParameterEndDateCounter = cardParameterEndDateCounter+1 
      }

      if (winParameterEndDateCounter >1 ||cardParameterEndDateCounter>1 ) { 
        toast.error("please add the card parameter and win parameter end date in below lists for similar event type and league.", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
          isInvalid = true
      }
      
      if (winParameterEndDateCounter===0 ||cardParameterEndDateCounter===0 ) {
        for (let i = 0; i < events.length; i++) {
          // console.log("events[i].winParameterStartDate",events[index].winParameterStartDate);
          let c1 = moment(e.cardParameterStartDate).format("YYYY-MM-DD");
          let d1 = moment(events[i].cardParameterStartDate).format("YYYY-MM-DD");
          let d2 = moment(events[i].cardParameterEndDate).format("YYYY-MM-DD");

          let a1 = moment(e.winParameterStartDate).format("YYYY-MM-DD");
          let b1 = moment(events[i].winParameterStartDate).format("YYYY-MM-DD");
          let b2 = moment(events[i].winParameterEndDate).format("YYYY-MM-DD");


          if(i!=index){
              if(moment(c1).isBetween(d1,d2) || c1 ===d1 || c1===d2){
                toast.error("Entered card parameter end date overlap each other in below lists", {
                  position: toast.POSITION.TOP_CENTER,
                  toastId: "1",
                });
                isInvalid = true
                  break;
              }else if(moment(a1).isBetween(b1,b2) || a1 ===b1 || a1===b2){
                toast.error("Entered win parameter end date overlap each other in below lists", {
                  position: toast.POSITION.TOP_CENTER,
                  toastId: "1",
                });
                        isInvalid = true
                break;
            }
          } else {
              
          }
          
      }
      }
     
      if(isInvalid){
          return isInvalid;
      }

  })


  return  isInvalid;
}

export function validateDates(g) {
  let result = false
       let a = moment(g.cardParameterStartDate).format("YYYY-MM-DD");
       let b = g.cardParameterEndDate ?  moment(g.cardParameterEndDate).format("YYYY-MM-DD"): null;
       let c = moment(g.winParameterStartDate).format("YYYY-MM-DD");
       let d = g.winParameterEndDate ?  moment(g.winParameterEndDate).format("YYYY-MM-DD"): null;
       
       if (g.cardParameterStartDate && g.cardParameterEndDate && g.winParameterEndDate && g.winParameterEndDate) {

          if (a >b || c >d) {
              result = true
              return result
          }  else if(((a<=c && c<=b)||(a<=d && d<=b)) && ((c<=a && a<=d)|| (c<=b && b<=d) )){
              result = false
          } else {
              result = true
              return result
          }
      } else  if ( !g.cardParameterEndDate || !g.winParameterEndDate) {

      if(!g.cardParameterEndDate && !g.winParameterEndDate ){ 
          result = false
          return result
      }
      else if(!g.cardParameterEndDate ){
              if (a >b || c >d) {
                  result = true
                  return result
              } else if ((c<=a && a  <=d ) ) {
                  result = false
                  return result
              } else {
                  result = true
                  return result
              }
          } else if(!g.winParameterEndDate ){
              if (a >b || c >d) {
                  result = true
                  return result
              } else  if (a<= c && c<=b ) {
                  result = false
              } else {
                  result = true
                  return result
              }
          }
          
      }   
      else  if ((moment(new Date() ).format("YYYY-MM-DD ") > moment(g.cardParameterStartDate).format("YYYY-MM-DD")) || (moment(new Date() ).format("YYYY-MM-DD ") > moment(g.cardParameterEndDate).format("YYYY-MM-DD")) || (moment(new Date() ).format("YYYY-MM-DD ") > moment(g.winParameterStartDate).format("YYYY-MM-DD")) || (moment(new Date() ).format("YYYY-MM-DD ") > moment(g.winParameterEndDate).format("YYYY-MM-DD")) ) {
          result = true
          return result
      }

      return result;
}


export function adsControlImgShuffle(ads){
  // let ads =[
  //   {adId:1,runTime:20, delay:1,hasOccurence:false},
  //   {adId:2,runTime:10, delay:1,hasOccurence:true, timeDuration:10, occurence:3},
  //   {adId:3,runTime:15, delay:1,hasOccurence:false},
  //   {adId:4,runTime:10, delay:1,hasOccurence:false},
  //   {adId:5,runTime:15, delay:1,hasOccurence:true, timeDuration:7, occurence:4},
  //   {adId:6,runTime:20, delay:1,hasOccurence:false},
  //   {adId:7,runTime:25, delay:1,hasOccurence:true, timeDuration:7, occurence:2},
  //   {adId:8,runTime:60, delay:1,hasOccurence:false}
  // ]  
  // program to sort array by property name
  
  function getIndex() {
  let final =Math?.floor((Math?.random() * ads.length))
      return final;
  }

  let withOccurence = ads?.filter((b)=>b.hasOccurence);
  // let withoutOccurence = ads.filter((b)=>!b.hasOccurence);
  // console.log("withoutOccurence111",withOccurence)
  
  
  withOccurence = _.sortBy(withOccurence, [function(o) { return o.timeDuration; }]);
  // console.log("withOccurence",withOccurence)
  // let maxTime = withOccurence[withOccurence.length-1].timeDuration *60;
  let counter =0;
  let arrayOfData =[]
  withOccurence.map((w)=>{
  //   maxTime = maxTime + w.timeDuration
    let dataToPush =[];
    for(let i=0; i<w.occurence; i++){
      dataToPush.push(w)
    }
  // console.log("dataToPush",dataToPush)
    arrayOfData.push(dataToPush)
  })
  // console.log("maxTime",maxTime)
  // console.log("arrayOfData",arrayOfData)
  let finalArra =[]
  
  arrayOfData.map((a,i)=>{
    let innerArray = a
     let  index =getIndex();
  
     let indexes =[];
     ads.map((a,index)=>{
     if(a.timeDuration ===innerArray[0]){
         indexes.push(index)
     }
     })
     
       for(let i=0; i<indexes.length; i++){
             let valueToCompare = indexes[i]          
               for(let j=0; j<ads[valueToCompare].remainingValue; j++){
                     innerArray.push(ads[valueToCompare])
               }
           ads[valueToCompare].remainingValue =0
          counter  = counter +Number((ads[valueToCompare].runTime +ads[valueToCompare].delay)* (ads[valueToCompare].occurence));
       }
  
    let currentCounter = counter
    if(i ===0){
     currentCounter = Number(innerArray[0].runTime + innerArray[0].delay)* innerArray.length;
     counter  = counter +Number(innerArray[0].runTime + innerArray[0].delay)* innerArray.length;
    } else {
        currentCounter = counter
    }
    let newArray  = [...innerArray];
  //   console.log("index",Number(innerArray[0].timeDuration *60))
    while (counter < Number(innerArray[0].timeDuration *60)) {
       newArray.push(ads[index]);
        currentCounter = currentCounter + ads[index].runTime + ads[index].delay;
        counter = counter + ads[index].runTime + ads[index].delay;
      index = getIndex()
      }
    const shuffledArray = newArray?.sort((a, b) => 0.5 - Math.random());
    // finalArray.concat(newArray)
    let finalA = [...finalArra, ...shuffledArray]
    finalArra = finalA
  })
  // console.log("finalArray",finalArra)

  return finalArra
  
}



export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};