import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import services from "../../../services/services";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  padding:0px 25px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommonTable = (props) => {
  const [filterText, setFilterText] = React.useState("");

  let history = useHistory();
  const filterData = (data, inputText) => {
    if (
      (data.versionNumber &&
        data.versionNumber.toLowerCase().includes(inputText.toLowerCase())) ||
      (data.startDate &&
        data.startDate.toLowerCase().includes(inputText.toLowerCase())) ||
      (data.endDate &&
        data.endDate.toLowerCase().includes(inputText.toLowerCase()))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const deleteWinP = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // setloader(true);
          let res = await services.deleteWinPar(id);
          props.getlist();
          // setloader(false);
        } catch (e) {
          toast.error(e.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          // setloader(false);
        }
        Swal.fire(
          "Deleted!",
          "Your win parameter has been deleted.",
          "success"
        );
      }
    });
  };
  const columns = [
    {
      name: "S. No.",
      selector: (row, i) => props?.data?.currentPage*10 + i + 1,
      sortable: true,
      filterable: true,
    },
    {
      name: "Version Number",
      selector: (row, i) => row.versionNumber,
      sortable: true,
      filterable: true,
      
    },

    // {
    //   name: "Start Date",
    //   selector: (row, i) =>
    //     row.startDate ? moment(row.startDate).format("MM/DD/YYYY") : moment(),
    //   sortable: true,
    //   filterable: true,
    // },

    // {
    //   name: "End Date",
    //   selector: (row) =>
    //     row.endDate ? moment(row.endDate).format("MM/DD/YYYY") : moment(),
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Actions",
      selector: (row) => row.id,
      // cell: (row) => <button type="button" value={row.id} onClick={deleteAdvertisementByClick} className="fa fa-trash fa-2x"></button>,
      cell: (row) => (
        <>
          <span
            onClick={() => history.push(`/admin/view-win-parameter/${row.id}`)}
            className="fa fa-eye fa-2x icon-act"
          ></span>{" "}
           {/* <span
            onClick={() => history.push({pathname: `/admin/edit-win-parameter/${row.id}`,
           state: "win",
             })}
            className="fa fa-edit fa-2x icon-act"
          ></span>{" "} */}
          <span
            onClick={() => deleteWinP(row.id)}
            className="fa fa-trash fa-2x icon-act"
          ></span>{" "}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getSearch = () => {
    props.getlist(filterText);
    // setFilterText('')
  };
  return (
    <>
      <div className="d-flex justify-content-end">
        <input
          style={{ width: "195px", height: "35px" }}
          className="form-control"
          type="text"
          placeholder="Filter Win Parameters"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <ClearButton type="button" onClick={getSearch}>
          <i className="fa fa-search" aria-hidden="true"></i>
        </ClearButton>
      </div>
      <DataTable
        columns={columns}
        data={props?.data?.data}
        pagination
        paginationServer
        paginationTotalRows={props?.data?.totalItems}
        // paginationPerPage={10}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => props.setpage(page)}
        persistTableHead
      />
    </>
  );
};
