import { Button, Modal } from "react-bootstrap";

function ShortDesc({data,handleclose,show}) {

  return (
    <>
      <Modal
        className="Total-Revenue"
        size="md"
        centered
        show={show}
        onHide={handleclose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="align-items-center" closeButton>
          {/* <Modal.Title> Prizes  </Modal.Title> */}
          <h5 className="mr-3" style={{ fontSize: "17px" }}>
         <b>{data?.h}</b>
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
          <div className="gscroll  col-md-12">

          {data?.p}

          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
          onClick={handleclose}
          >
            close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShortDesc;
