/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import moment from "moment";
import Swal from "sweetalert2";
import * as Constants from "../../../helper/constants";
import { useForm, Controller } from "react-hook-form";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

// Function for create advertisement
const CreateCardPara = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [disInput, setDisInput] = useState(false);
  const [showEventLeague, setShowEventLeague] = useState([]);
  const [factorCodeOption, SetfactorCodeOption] = useState('');
  const {
    register,
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState([]);

  const [submitFormLoader, setSubmitFormLoader] = useState("");

  const { id } = useParams();
  let subscribe = watch();


  useEffect(() => {
    // reset form with user data
    reset(formData);
  }, [formData]);

  useEffect(() => {
    setData(Array.from(Array(Number(subscribe.assignFactor || 0)).keys()));
    if(subscribe.assignFactor == "8"){
      SetfactorCodeOption(Constants.BASEBALL_EIGHT_FACTOR)
    }
    if(subscribe.assignFactor == "6"){
      SetfactorCodeOption(Constants.BASEBALL_SIX_FACTOR)
    }
    if(subscribe.assignFactor == "4"){
      SetfactorCodeOption(Constants.BASEBALL_FOUR_FACTOR)
    }

    // Array.from(Array(Number(subscribe.assignFactor || 0)).keys())?.map(d=>setValue(`factorCode${d}`,''))

  }, [subscribe.assignFactor]);

  // console.log(formData,'____');
  useEffect(() => {
    let data = Constants.EVENT_TYPES_AND_LEAGUES.find(
      ({ code }) => code === subscribe.eventType
    );
    setShowEventLeague(data?.eventLeague);
  }, [subscribe.eventType]);

  useEffect(() => {
    async function fetchData() {
      setSubmitFormLoader(true);
      if (id !== undefined) {
        try{
          let facObj = {}
          let response = await services.getCardParameter(id);
       
           response.data.data.factors.map(({factorCode,payoutIncreasePercent,percentageOfOccurence,reasonablenessLimit,specialFactor,specialFactorCode},id)=>{
            // arr.push(`factorCode${id}`:factorCode)
            facObj[`factorCode${id}`] =factorCode;
            facObj[`payoutIncreasePercent${id}`] =payoutIncreasePercent;
            facObj[`percentageOfOccurence${id}`] =percentageOfOccurence;
            facObj[`reasonablenessLimit${id}`] = reasonablenessLimit;
            facObj[`specialFactor${id}`] =specialFactor+"";
            facObj[`specialFactorCode${id}`] =specialFactorCode;
          })

       
          // return
          let dd= response.data.data;
          setFormData((p)=>({...p,...dd,...facObj}));
          // setFormData(facObj);
          // setFormData((p)=>({...p,...facObj}));
          if (history?.location?.state) {
            setDisInput(true);
          }
        }catch(e){
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
          setSubmitFormLoader(false);
        }
       
      }
      setSubmitFormLoader(false);
    }
    fetchData();
  }, [id]);

  const createCardParameter = async (d) => {
    let factorData = [];
    let i = 0;
    for (const val in d) {
      if (d[`factorCode${i}`]) {
        factorData.push({
          factorCode: d[`factorCode${i}`]?.toUpperCase(),
          percentageOfOccurence: Number(d[`percentageOfOccurence${i}`]),
          reasonablenessLimit: Number(d[`reasonablenessLimit${i}`] || 0),
          specialFactor: d[`specialFactor${i}`]==='true' ? true : false,
          specialFactorCode: d[`specialFactorCode${i}`] || "",
          payoutIncreasePercent: Number(d[`payoutIncreasePercent${i}`]) || 0,
        });
        i++;
      }
    }

    let data = {
      versionNumber: d.versionNumber,
      eventType: d.eventType,
      league: d.league,
      assignFactor: Number(d.assignFactor),
      codeDescription: d.codeDescription,
      factors: factorData,
    };

    const hasDuplicates = new Set(factorData.map(v => v.factorCode));
    if(hasDuplicates.size < factorData.length){
      toast.error("Factors code can't be same", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      return
    }

    let sumofPercentOccurence = factorData?.reduce((total, obj) => obj.percentageOfOccurence + total,0);
    let counter = 0;
    let sumReasonablenessLimit = factorData?.reduce((total, obj) => {
      
      if(!obj.reasonablenessLimit){
        counter+=1
      }
    return  obj.reasonablenessLimit + total
    
    },0);
    if(sumofPercentOccurence !== 100){
      return   toast.error("Total of Percentage Of Occurence should be equal to 100", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      
    }

    if(!counter &&  (factorData.length==4 ||  factorData.length==6  || factorData.length==8 ) ){
      console.log(sumReasonablenessLimit);
      if(!(sumReasonablenessLimit >= 25)){
        return   toast.error("Total of Reasonableness Limit should be greater or equal to 25", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "1",
        });
        
      }
      
    }

// let sum = 0;

// factorData?.map(({percentageOfOccurence, reasonablenessLimit})=>{
// console.log(25/100*percentageOfOccurence,'====');

//   if(25/100*percentageOfOccurence === reasonablenessLimit){
//       sum= sum + Math.floor(25/100*percentageOfOccurence)
//   }
//   if(25/100*percentageOfOccurence > reasonablenessLimit){
//     sum = sum + reasonablenessLimit
// }
//   if(25/100*percentageOfOccurence < reasonablenessLimit){
//     sum= sum + Math.floor(25/100*percentageOfOccurence)
//   }
// })


// if(sum!==25){
//   toast.error("Please enter the valid Percentage of Occurence ", {
//     position: toast.POSITION.TOP_CENTER,
//     toastId: "1",
//   });
//   return

// }


// return

    if (id) {

    try {
      setSubmitFormLoader(true);
      let res = await services.updateCardParameter(id,data);
      Swal.fire(res.data.message, "", "success");
      setSubmitFormLoader(false);
      history.push("/admin/card-parameter");
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
      // console.log(e.response.data.message);
    }

    } else {

    try {
      setSubmitFormLoader(true);
      let response = await services.cardParameter(data);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
      history.push("/admin/card-parameter");
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
      // console.log(e.response.data.message);
    }
    }
    
   
  };


  return (
    <div id="admin-dashboard">
      <div className="mainWrapper">
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        {submitFormLoader && <div className="loader"></div>}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>{disInput
                      ? "View Card Parameter"
                      : id
                      ? "Edit Card Parameter"
                      : "Create Card Parameter"}  </h4>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label> Version Number</label>
                  <input
                    {...register("versionNumber", { required: true })}
                    type="text"
                    disabled={disInput}
                    placeholder="XXXXX"
                    className="form-control"
                  />
                  {errors.versionNumber && (
                    <p className="text-danger">
                      Please enter the version number.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="dropdown">
                  <label>Event Type</label>
                  <select
                    disabled={disInput}
                    className="drop-button form-control"
                    {...register("eventType", { required: true })}
                  >
                    <option value="">Select Event Type</option>
                    {Constants.EVENT_TYPES_AND_LEAGUES &&
                      Constants.EVENT_TYPES_AND_LEAGUES.map(
                        ({ code, name }, id) => {
                          return (
                            <option key={id} value={code}>
                              {name}
                            </option>
                          );
                        }
                      )}
                  </select>
                  {errors.eventType && (
                    <p className="text-danger">Please select the event type.</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label>Assign League</label>
                  <select
                    value={formData.league}
                    disabled={disInput}
                    className="drop-button form-control"
                    {...register("league", { required: true })}
                  >
                    <option value="">Select Event League</option>
                    {showEventLeague &&
                      showEventLeague.map(({ code, name }, id) => {
                        return (
                          <option key={id} value={code}>
                            {name}
                          </option>
                        );
                      })}
                  </select>
                  {errors.league && (
                    <p className="text-danger">
                      Please select the Assign league.
                    </p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label>Assign Factors</label>
                  <select
                    {...register("assignFactor", { required: true })}
                    className="form-control"
                    disabled={disInput}
                  >
                    <option value={""}>Choose Factor</option>
                    <option value={"4"}>4</option>
                    <option value={"6"}>6</option>
                    <option value={"8"}>8</option>
                  </select>
                  {errors.assignFactor && (
                    <p className="text-danger">
                      Please select the assign factor.
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="form-group">
                  <label>Code Description </label>
                  <input
                   disabled={disInput}
                    {...register("codeDescription", { required: true })}
                    type="text"
                    placeholder="On First"
                    className="form-control"
                  />
                  {errors.codeDescription && (
                    <p className="text-danger">
                      Please enter the code description.
                    </p>
                  )}
                </div>
              </div>

              <div className="table-responsive p-3">
                <div className="table-fixed">
                  <table className="table table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th>Factors Code</th>
                        <th>Percent Occurrence</th>
                        <th>Reasonableness Limit</th>
                        <th style={{whiteSpace:'nowrap'}}>Special Factor</th>
                        <th>Special Factor Code</th>
                        <th>Payout Increase (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d) => (
                        <tr>
                          <td>
                          <input
                             disabled={disInput}
                              {...register(`factorCode${d}`, {
                                required: true,
                              })}
                              maxLength={1}
                              type="text"
                              placeholder="Enter factor code"
                              className="form-control"
                            />
                            {/* <select
                             disabled={disInput}
                              {...register(`factorCode${d}`, {
                                required: true,
                              })}
                              
                              className="form-control"
                            >
                              <option value={""}>Choose Factor Code</option>
                              {factorCodeOption &&
                                factorCodeOption.map((data) => (
                                  <option >{data}</option>
                                ))}
                            </select> */}
                            {errors[`factorCode${d}`] && (
                              <p className="text-danger">
                                Please enter the factor code.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                             disabled={disInput}
                              {...register(`percentageOfOccurence${d}`, {
                                required: true,
                              })}
                              maxLength={2}
                              type="text"
                              placeholder="10"
                              className="form-control"
                            />
                            {errors[`percentageOfOccurence${d}`] && (
                              <p className="text-danger">
                                Please enter the percentage Of Occurence.
                              </p>
                            )}
                          </td>
                          <td>
                            {" "}
                            <input
                              {...register(`reasonablenessLimit${d}`, {
                               valueAsNumber: true,
                              })}
                              type="text"
                              maxLength={2}
                              disabled={disInput}
                              placeholder={0}
                              className="form-control"
                            />
                   
                          </td>
                          <td>
                            {" "}
                            <select
                              {...register(`specialFactor${d}`, {
                                required: true,
                              })}
                              disabled={disInput}
                              className="form-control"
                            >
                              <option value={false}>No</option>
                              <option value={true}>Yes</option>
                            </select>
                            {errors[`specialFactor${d}`] && (
                              <p className="text-danger">
                                Please select the special factor.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              maxLength={1}
                              {...register(`specialFactorCode${d}`, {
                                required: subscribe[`specialFactor${d}`] == undefined ? false : !(subscribe[`specialFactor${d}`] == "false"),
                              })}
                              type="text"
                              disabled={disInput}
                              placeholder="N"
                              disabled={
                                subscribe[`specialFactor${d}`] == (undefined || false)
                                  ? true
                                  : subscribe[`specialFactor${d}`] === "false"
                              }
                     
                              className="form-control"
                            />
                            {errors[`specialFactorCode${d}`] && (
                              <p className="text-danger">
                                Please enter the special factor code.
                              </p>
                            )}
                          </td>
                          <td>
                            <input
                              {...register(`payoutIncreasePercent${d}`, {
                                required:  subscribe[`specialFactor${d}`] == undefined ? false : !(subscribe[`specialFactor${d}`] == "false"),
                              })}
                              maxLength="3"
                              disabled={disInput}
                              disabled={
                                subscribe[`specialFactor${d}`] == (undefined || false)
                                  ? true
                                  : subscribe[`specialFactor${d}`] === "false"
                              }
                              
                              type="text"
                              placeholder="0%"
                              className="form-control"
                            />
                            {errors[`payoutIncreasePercent${d}`] && (
                              <p className="text-danger">
                                Please enter the payout increase.
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="spacer-foot" />
                </div>
              </div>
            </div>
            <div className="row" style={{ paddingBottom: "6rem" }}>
              <div className="col-lg-12">
                <Link to="/admin/card-parameter">
                  <button type="button" className="btn admin-back-btn mr-2">
                    Back
                  </button>
                </Link>

                {!disInput && (
                  <button
                    onClick={handleSubmit(createCardParameter)}
                    type="submit"
                    className="btn admin-primary-btn"
                  >
                    {id ? "Update" : "Submit"}
                  </button>
                )}
              </div>
            </div>
          </div>

          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default CreateCardPara;
