import { SIGNUP_SUCCESS, SIGNUP_FAIL, SET_MESSAGE } from "./type";

import AuthService from "../services/services";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// toast-configuration method,
// it is compulsory method.
toast.configure();

export const SignUpAction = (userData) => async (dispatch) => {

  if (userData.password !== userData.confirmPassword) {
    toast.error("Password must be matched", {
       position: toast.POSITION.TOP_CENTER,
        toastId: "1",
    });
    return false;
  }

  try {
    let data = await AuthService.create(userData);
    dispatch({
      type: SIGNUP_SUCCESS,
      payload: { user: data },
    });

    return data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    toast.error(message, {
       position: toast.POSITION.TOP_CENTER,
        toastId: "1",
    });

    dispatch({
      type: SIGNUP_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject();
  }

  // return Promise.resolve();
};
