/* eslint-disable jsx-a11y/alt-text */
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../../actions/userLogoutAction";
import logo from "../../../assets/images/logo.png";
import coin from "../../../assets/images/coin.png";
import bank from "../../../assets/images/bank.png";
import trophy from "../../../assets/images/trophy.png";
import humburger from "../../../assets/images/humburger.png";
import wallet from "../../../assets/images/wallet.png";
import profile from "../../../assets/images/profile.png";
import close from "../../../assets/images/close.png";
import blog from "../../../assets/images/blog.svg";
import jackpot from "../../../assets/images/jackpot.svg";
import card from "../../../assets/images/poker.svg";
import kibu from "../../../assets/images/web-icon.svg";
import carre from "../../../assets/images/web-icon.svg";
import cont from "../../../assets/images/phone-call.svg";
import eclips from "../../../assets/images/eclips.png";
import { Link } from "react-router-dom";
import { useState } from "react";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const UserHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitFormLoader, setSubmitFormLoader] = useState("");

  // Navigation cart button click Ui Design

  function open() {
    let a1 = document.getElementsByClassName("right-panel")[0];
    a1.classList.add("intro");
    let b = document.getElementsByClassName("mask-overlay")[0];
    b.classList.add("mask_opened");
  }

  function closed() {
    let a1 = document.getElementsByClassName("right-panel")[0];
    a1.classList.remove("intro");
    let b = document.getElementsByClassName("mask-overlay")[0];
    b.classList.remove("mask_opened");
  }

  const logout = async (e) => {
    try {
      e.preventDefault();
      let userData = localStorage.getItem("id");
      setSubmitFormLoader(true);
      await dispatch(logoutAction(userData));
      const { from1 } = { from1: { pathname: "/login" } };
      history.push(from1);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setSubmitFormLoader(false);
    }
  };

  return (
    <>
      <header>
        <div className="container">
          {submitFormLoader && <div className="loader"></div>}
          <nav className="navbar navbar-expand-xl navbar-light bg-white">
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Link to="#" className="navbar-brand">
              <img src={logo} />
            </Link>
            {/* Collection of nav links, forms, and other content for toggling */}
            <div
              id="navbarCollapse"
              className="collapse navbar-collapse justify-content-start"
            >
              <div className="navbar-nav">
                <Link to="/" className="nav-item nav-link active">
                  Home
                </Link>
                <Link to="/user/user-group" className="nav-item nav-link">
                  Groups
                </Link>
                <Link to="/user/game-rules" className="nav-item nav-link">
                  Game Rules
                </Link>
                <Link to="/user/event-type-rule" className="nav-item nav-link">
                  Event Type Rules
                </Link>
              </div>

              {!(localStorage.getItem("role")==="2") && <div className="navbar-nav ml-auto">
        <div className="nav-item">
          <ul className="guest-login">
            <li><Link to="#" onClick={()=>history.push('/login')}> <i className="fa fa-sign-in" aria-hidden="true" /> Login </Link></li>   
            <li><Link to="#" onClick={()=>history.push('/signup')}> <i className="fa fa-user" aria-hidden="true" /> Sign Up </Link></li>  
          </ul>
        </div>
      </div>}

              {localStorage.getItem("role") === "2" && (
                <div className="navbar-nav ml-auto">
                  {/* <div className="nav-item dropdown">
                    <Link
                      to="#"
                      data-toggle="dropdown"
                      className="nav-link dropdown-toggle user-action"
                      aria-expanded="false"
                    >
                      {" "}
                      <img src={wallet} /> My Wallet{" "}
                      <i className="fa fa-angle-down" />
                    </Link>
                    <div className="dropdown-menu">
                      <Link to="#" className="dropdown-item">
                        <i>
                          <img src={coin} />{" "}
                        </i>{" "}
                        Add Money{" "}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link to="#" className="dropdown-item">
                        <i>
                          <img src={bank} />{" "}
                        </i>
                        Withdraw Money{" "}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link to="#" className="dropdown-item">
                        <i>
                          <img src={trophy} />{" "}
                        </i>{" "}
                        Winning Amount
                      </Link>
                    </div>
                  </div> */}
                  <div className="nav-item dropdown">
                    <Link
                      to="#"
                      data-toggle="dropdown"
                      className="nav-link dropdown-toggle user-action"
                      aria-expanded="false"
                    >
                      <img src={profile} className="avatar" alt="Avatar" /> My
                      Account <i className="fa fa-angle-down" />
                    </Link>
                    <div className="dropdown-menu">
                      <Link to="/user/my-account" className="dropdown-item">
                        <i className="fa fa-user-o" />
                        My Profile{" "}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link to="/user/my-events" className="dropdown-item">
                        <i className="fa fa-calendar-o" />
                        My Events{" "}
                      </Link>
                      <div className="dropdown-divider" />
                      <Link to="/user/change-password" className="dropdown-item">
                        <i className="fa fa-lock" /> Change Password{" "}
                      </Link>
                      {/* <div className="dropdown-divider" /> */}
                      {/* <Link to="#" className="dropdown-item"><i className="fa fa-sliders" /> Settings </Link> */}
                      <div className="dropdown-divider" />
                      <Link to="#" className="dropdown-item" onClick={logout}>
                        <i className="fa fa-power-off" /> Logout{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
        <div className="header-right">
          <button onClick={open} className="side-nav">
            <img className="dsk-v" alt="" src={humburger} />
            <img className="mob-v" alt="" src={eclips} />
          </button>
        </div>
      </header>

      <div className="right-panel">
        <div className="intro">
          <div className="intro-details">
            <button onClick={closed} className="close-icon">
              {" "}
              <img alt="" src={close} />{" "}
            </button>
          </div>
        </div>
        <div className="cart-inner text-center mt-5">
          <div className="sidebar" style={{ textAlign: "left" }}>
            {/* <Link to="#">
              <img src={trophy} /> Results{" "}
            </Link>
            <Link to="#">
              <img src={blog} /> Blog &amp; News{" "}
            </Link>
            <Link to="#">
              <img src={jackpot} /> Jackpot List{" "}
            </Link>
            <Link to="#">
              <img src={card} /> My Cards{" "}
            </Link> */}
            <a target="_blank" href="https://kibugaming.com/">
              <img src={kibu} /> kibugaming.com{" "}
            </a>
            <a target="_blank" href="https://carrejoue.com/">
              <img src={carre} /> carrejoue.com{" "}
            </a>
            <Link to="/user/contact-us">
              <img src={cont} /> Contact Us{" "}
            </Link>
            <a className="mt-4" target="_blank" href="https://espn.com/college-football/">
              <img src={carre} /> ESPN College Football		
            </a>
            <a target="_blank" href="https://www.sports.yahoo.com/">
              <img src={carre} /> Yahoo Sports	
            </a>
            <a target="_blank" href="https://www.cbssports.com/">
              <img src={carre} /> CBS Sports
            </a>
            <a target="_blank" href="https://www.mlb.com/">
              <img src={carre} /> MLB
            </a>
             <a target="_blank" href="https://www.nfl.com/">
              <img src={carre} /> NFL
            </a>
             <a target="_blank" href="https://www.nba.com/">
              <img src={carre} /> NBA
            </a>
             <a target="_blank" href="https://nhl.com/">
              <img src={carre} /> NHL
            </a>
             <a target="_blank" href="https://www.ncaa.com/sports/football/">
              <img src={carre} /> NCAA College Football		
            </a>
             <a target="_blank" href="https://www.ncaa.com/sports/basketball-men/">
              <img src={carre} /> NCAA Men's Baksetball		
            </a>
            
          </div>
        </div>
      </div>
      <div className="mask-overlay"></div>
    </>
  );
};

export default UserHeader;
