import { useEffect } from "react";
import { Link } from "react-router-dom";
import UserHeader from "../../core/header/userHeader";
import { UserFooter } from "../../core/footer/userFooter";
import PreviewAdvertisement from "../preview-advertisement/preview-advertisement";
import "./privacypolicy.css";

export default function PrivacyPolicy() {
  useEffect(() => window.scrollTo(0, 0), []);
  return (
    <>
      <UserHeader />
      <section className="bredcum">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xl-12 text-center">
              <h1>Privacy Policy</h1>
              <Link to="index.html">Home</Link>{" "}
              <span>
                {" "}
                <i className="fa fa-angle-right" />{" "}
              </span>{" "}
              <Link to="#" className="active">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 text-center">
              <div className="heading-sec mb-5">
                <h2>
                  Kibu Gaming Corporation - <span>Privacy Policy</span>
                </h2>
                <h4 className="pb-3">(Last updated: January 8, 2022)</h4>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <p>
                KIBU® (KiBu Gaming Corporation) is a for-profit company
                registered in the State of Nevada. An additional business
                location is registered in the State of Arizona. KIBU offers
                advertising, promotional and gaming services via its game,
                patent pending, Carrejoue – A Sports Watching Game! When you use
                KIBU products and services, you agree to accept our policy, as
                well as our agreements.{" "}
              </p>
              <p>
                Some information and privacy settings can be managed by visiting
                your KIBU profile. This Privacy Policy applies to websites owned
                and operated by KIBU, including, but not limited to,{" "}
                <a href="http://kibugaming.com/" target="blank">
                  www.kibugaming.com
                </a>
                ,
                <a href="http://carrejoue.com/" target="blank">
                  {" "}
                  www.carrejoue.com
                </a>{" "}
                and{" "}
                <a href="http://www.squareplay.games/" target="blank">
                  www.squareplay.games
                </a>
                .
              </p>
              <p>
                We encourage you to read this Policy before using our websites
                and providing your personal data to us.
                <br />
                KIBU knows you care about how your information is used and
                shared and you believe in protecting your privacy and the
                confidentiality of your Personal Information. By using our
                Websites, you consent to the data practices described in this
                Privacy Policy.
              </p>
              <p>
                {" "}
                If you do not agree with the data practices described in this
                Privacy Policy, you should not use our Websites.{" "}
                <strong>California Residents</strong>, please see additional
                information in this Privacy Policy on{" "}
                <strong>California Privacy Rights</strong> .
              </p>
              <h5> Introduction</h5>
              <p>
                At KIBU (“KIBU”, “we”, “us”, “our”, or the “Company”), we are
                committed to protecting your privacy. This Privacy Policy
                describes the types of information we collect about users of our
                websites and is designed to help you understand how we collect,
                use, disclose, transfer and store the information you provide to
                us and to assist you in making informed decisions when using our
                Services. This Privacy Policy also describes the measures we
                take to safeguard the information we collect and your rights and
                privileges concerning our collection, use and disclosure of
                information about you.
              </p>
              <p>
                Throughout this document, this website and our affiliated
                websites with links to this policy will be collectively referred
                to as the “Websites”. Services provided by or enabled via the
                Websites will be referred to as the “Services”.
              </p>
              <p>
                This notice does not apply to websites and services from third
                party services, our advertisers, partners and other electronic
                locations that do not link to this statement. We encourage you
                to see their Privacy Policies before using their websites or
                electronic services and providing them your personal
                information.
              </p>
              <p>
                KIBU is not responsible for the content or privacy practices of
                non-KIBU websites to which this website, or any other affiliated
                website, may link. Also, KIBU is not responsible for the privacy
                practices of our Partners’, Advertisers’ and Clients’ websites.
                You should review any additional privacy policy and terms of use
                agreements before using a Partner’s, Advertiser’s or Client’s
                website.
              </p>
              <h5>Changes to the Privacy Policy</h5>
              <p>
                The data KIBU collects and uses is governed by this Privacy
                Policy as amended from time to time, not by the Privacy Policy
                in effect at the time of data collection. This includes data
                collected from KIBU Websites, affiliates with links to this
                Privacy Policy, and services provided by or enabled via these
                sites.
              </p>
              <p>
                KIBU may change the Privacy Policy at our sole discretion and
                will provide notice of changes in the Privacy Policy and on the
                privacy policy page of our Websites. If, as the result of such
                changes, you want to alter the ways in which we are allowed to
                use your personal information, you can do so by following the
                procedure described in the section entitled “Privacy Rights”.
              </p>
              <p>
                Please regularly review our Privacy Policy. If you have
                objections to the Privacy Policy, you should immediately
                discontinue use of the Websites and the Services enabled by the
                Websites. This Privacy Policy does not govern privacy practices
                associated with offline activities, websites other than the
                Websites, or any products or services not available or enabled
                via the Websites.
              </p>
              <p>
                You will be deemed to have been made aware of, and will be
                subject to, the changes to the Privacy Policy after such notice
                has been posted with the following exception: if at the time you
                provide personal information to KIBU you limit how such
                information will be used to communicate with you, either by KIBU
                or by third parties. KIBU will not change your preference in
                this regard without your express consent.
              </p>
              <p>
                However, if KIBU sells assets (or the assets of a division or
                subsidiary) to another entity, or KIBU (or a division or
                subsidiary) is acquired by or merged with another entity, you
                agree that KIBU may provide to such entity customer information
                that is related to that part of our business that was sold to or
                merged with the other entity without obtaining your further
                consent.
              </p>
              <h5>Policy Regarding Children</h5>
              <p>
                KIBU Websites and Services are not designed for use by children
                under the age of 18. As such, this Website is not directed at
                children under the age of 18 nor do we knowingly collect or
                maintain information from children under the age of 18 on the
                Websites.
              </p>
              <h5>Collection and Use of Data</h5>
              <p>
                KIBU are the sole owners of the information collected on the
                Websites. We have access to and collect information that you
                voluntarily give us via email, web form or other direct contact
                from you. We will use your information to respond to you,
                regarding the reason you contacted us. We may disclose
                information to our corporate affiliates and third parties to
                provide products and services to you, or related to products or
                services you have purchased or downloaded from KIBU. Unless you
                ask us not to, we may contact you via email in the future to
                tell you about our services, new products, or changes to this
                privacy policy or to provide you with informational or
                educational newsletters.
              </p>
              <p>
                Do not provide personal information about others unless you are
                authorized or required to do so by applicable law or contract.
                Before supplying personal information about others, you and the
                person about whom you supply the information must review and
                consent to the Terms of Use and Privacy Policy of the Website.
                By submitting any personal information about others, you
                represent and warrant that you are authorized to do so, that you
                have made the Terms of Use and Privacy Policy available to the
                person about whom you supply the information, and that you have
                obtained that person’s written consent to our Terms of Use and
                Privacy Policy. If applicable law allows you to supply the
                information without doing the foregoing, you represent and
                warrant that you have abided by that law and that it allows us
                to receive and disclose the information under this Privacy
                Policy without any further action on our part. You agree to
                indemnify, defend and hold harmless KIBU, our parents,
                subsidiaries, officers, directors, employees and agents,
                sponsors and supporting artists, licensors and suppliers for any
                failure by you to comply with this paragraph.
              </p>
              <p>
                User Feedback may be posted from time to time on our website. In
                such cases your comments, critiques and suggestions may be
                identified to you by your first name, last initial and US state
                of residence. If we choose to post your feedback, we will
                request permission to do so first.
              </p>
              <p>
                Blogs, semi-private and public forums allow our users to see the
                comments of other users. If you make any comments while on the
                Websites or using our Services, you should be aware that any
                personal information disclosed by you may be read, collected or
                used by others on the blog or forum. Unsolicited representations
                by email, cell phone, phone or social media may occur as a
                result of your disclosures. KIBU cannot be responsible for any
                personal information you so disclose in these blogs or forums.
              </p>
              <p>
                Aggregrated or De-identified Data not reasonably capable of
                identifying your personal information may be created from
                information collected by us about you. Such data is used to
                enhance our Websites and Services for our users’ experience. So
                we may improve or enhance our Websites and Services, we reserve
                the right to use such aggregated information for any purpose and
                to disclose it to third parties for any reason.
              </p>
              <h5>Personal Information</h5>
              <p>
                “Personal Information” as used in this Privacy Policy is
                information that we directly associate with a specific person or
                entity, such as name, address, payment information (including
                payment card number, expiration date, cardholder name and CVV
                code), mobile telephone number, and email address.
              </p>
              <p>
                The Personal Information you provide will vary based upon the
                context, and we will not collect your Personal Information
                unless you make it available. You are not required to provide
                Personal Information at any time while visiting the Websites;
                however, you may be required to provide Personal Information to
                use certain Services. You may provide us with Personal
                Information by participating in games, online or emailed
                surveys, subscribing to newsletters and other recurring
                offerings through the Websites, and in connection with other
                products, services, features, or requests made available through
                the Websites..
              </p>
              <p>
                Establishing an Account is required to play all our Games and
                collects most of the information obtained and used by our
                Websites or Services. Accounts facilitate transactions
                including, but not limited to, entry into a Game, verification
                of certain eligibility requirements to play Games, the deposit
                or withdrawal of funds, the awarding of Prizes, maintaining
                contact with you and allowing us to respond to your needs more
                timely, among other reasons. When establishing an Account,
                additional information may be requested by us to verify certain
                information collected. If you do not provide such information,
                your account may be suspended or closed without notice, and any
                transaction requiring such information will be halted until such
                information is collected.
              </p>
              <p>
                When you deposit or withdraw funds from your Account, or
                otherwise use our Websites or Services, we may collect all
                information necessary to complete the transaction and provide
                you with the Services, including, but not limited to, name,
                address, email address, phone number, credit or debit card
                information and other billing information, social security
                number, tax documents and records of the products and Service
                you purchased, obtained or considered.
              </p>
              <p>
                Profile information we may share with others when you are logged
                on, playing or entered in a game is your profile picture
                (avatar), username and location as may be derived from your use
                of devices and entries into games.
              </p>
              <p>
                Location information acquired, by allowing connected devices
                (for example, cell phones, tablets, computers, etc.) to send us
                your location or otherwise geolocate you, may be stored and is
                used to verify your eligibility to enter certain games by
                complying with relevant laws and Game Rules, and for fraud
                prevention services.
              </p>
              <p>
                Participating in Games, sweepstakes or other giveaways through
                our Websites and Services may require us to collect other
                information regarding preferences during the entry process, such
                as a prize selection, or as part of paying or awarding Prizes,
                such as social security number, credit or debit and bank
                information, picture identification and other eligibility and
                legal verification information.
              </p>
              <p>
                Participating in a survey grants us access to the additional
                information you may provide in your responses including, but not
                limited to, your profile, likes and dislikes, activity, usage,
                preferences and habits that may allow us or advertisers, or
                affiliates, to evaluate your interest in the quality,
                usefulness, expectation of and consumer interest in a product,
                organization, service or support. KIBU collects the Business and
                Personal Information necessary to enable us to respond to your
                requests for our products and services and to send you
                information regarding our products and services from time to
                time.
              </p>
              <p>
                When you visit our Websites, you may be asked to submit
                information such as e-mail address, company name, address, phone
                number, your product preferences and/or areas of interest. We
                may also ask you where you heard about our company or products,
                or for other information that will help us understand more about
                your needs or help us process your requests for information. In
                addition, we collect credit card and related payment information
                when you order products and services online. Our information
                collection and use practices are described in more detail below.
              </p>
              <h5>Support</h5>
              <p>
                We offer certain limited online support services. If you access
                our online support, you may be asked for information such as
                customer ID, personal identification information, account
                activity questions and other information to help us determine
                identity, what the technical issues are and how best to help you
                resolve them. In addition, submitting support-related questions
                directly online through chat, email or connected Services may
                pre-populate various personal information to our customer
                support representatives to assist them in answering questions.
                If you access online support services, while on the Websites,
                from an Account, Game, Group or other location, personal
                information may pre-populate to our customer support
                representatives to assist them in answering questions.
              </p>
              <h5>Other Information Collected</h5>
              <p>
                Web-based Services: Some of our web-based Services collect
                certain additional information, including IP address, browser,
                connection speed, domain, referring URL and other
                environment-related information. This enables us to provide a
                secure environment for the use of the services, to pre-populate
                forms and calculate aggregate statistical information about the
                customers using these services. We use this information to
                better protect you and to provide a better user experience.
              </p>
              <h5>Third-Party Products</h5>
              <p>
                KIBU may use your Personal Information to inform you of special
                discounts or offers from other companies we have carefully
                selected and whose products and services may be of interest to
                you. This information is derived from your participation in
                surveys, products you have viewed and advertisers or partners
                you have visited.
              </p>
              <h5>Public Forums</h5>
              <p>
                Information (including personal and business information) that
                you submit, post or otherwise reveal in a public forum (such as
                comments to a blog or product discussion forum) is not subject
                to this Privacy Policy, will be seen by third parties not
                related to KIBU and may be used by them or KIBU to contact you
                for any purpose.
              </p>
              <h5>Orders</h5>
              <p>
                We request information from you on our order form. To buy from
                us, you must provide contact information (like name and shipping
                address) and financial information (like credit card number,
                expiration date). This information is used for billing purposes
                and to fill your orders. If we have trouble processing an order,
                we'll use this information to contactyou.
              </p>
              <h5>Surveys and Contests</h5>
              <p>
                From time-to-time, our Websites may request information via
                surveys or contests. Participation in these surveys or contests
                is completely voluntary and you may choose whether or not to
                participate and therefore disclose this information. Information
                requested may include contact information (such as name and
                shipping address), and demographic information (such as zip
                code). Contact information will be used to notify winners and
                award prizes, if applicable. Survey information will be used for
                purposes of monitoring or improving the use and satisfaction of
                the Websites or for delivery of services.
              </p>
              <h5>Cookies</h5>
              <p>
                KIBU may place “cookies” on the browser of your computer.
                Cookies are small pieces of information that are stored by your
                browser on your computer’s hard drive. Cookies may enhance the
                convenience and use of the Websites. For example, the
                information provided through cookies may be used to recognize
                you as a previous user of the Websites (so you do not have to
                enter your personal information every time), offer personalized
                information for your use, and otherwise facilitate your
                experience using the Website. You may choose to decline cookies
                if your browser permits, but doing so may affect your ability to
                access or use certain features of the Websites.
              </p>
              <p>
                KIBU may also utilize web beacons on our Websites. A “web
                beacon,” also known as an Internet tag, pixel tag or clear GIF,
                links web pages to web servers and their cookies and may be used
                to transmit information collected through cookies back to a web
                server. The information collected through these automated means
                may include information that we gather and store about your
                visit to, and use of, the Websites, such as your IP address,
                type of browser and operating system used, date and time you
                access the websites, pages you visit, and, if you linked to the
                Websites from another website, the address of that website. This
                data does not include Personal Information and is not treated as
                Personal Information under this Privacy Policy, but it may
                include the IP address of your device. We may link an IP address
                to information that is personally identifiable.
              </p>
              <p>
                Most web browsers automatically accept cookies, but you can
                disable this function so that your browser will not accept
                cookies. Please be aware that disabling this function may impact
                your use and enjoyment of the Websites.
              </p>
              <h5>Third-Party Analytics</h5>
              <p>
                We may also allow certain third-party analytics service
                providers to include cookies and web beacons within the pages of
                the Websites on our behalf and to retain and use the information
                received from such cookies and web beacons themselves.
                Third-party service providers that collect this data on our
                behalf or on behalf of client may offer information about their
                data collection practices.
              </p>
              <h5>Do Not Track</h5>
              <p>
                Currently, various browsers — including Microsoft Edge, Google
                Chrome, Internet Explorer, Mozilla Firefox, and Apple Safari —
                offer a “do not track” or “DNT” option that relies on a
                technology known as a DNT header, which sends a signal to Web
                sites’ visited by the user about the user's browser DNT
                preference setting. We do not currently commit to responding to
                browsers' DNT signals with respect to the Websites, in part,
                because no common industry standard for DNT has been adopted by
                industry groups, technology companies or regulators, including
                no consistent standard of interpreting user intent. We take
                privacy and meaningful choice seriously and will make efforts to
                continue to monitor developments around DNT browser technology
                and the implementation of a standard.
              </p>
              <h5>Protecting Data During Collection and Storage</h5>
              <p>
                We take precautions to protect your information. When you submit
                sensitive information via the Websites, your information is
                protected both online and offline. We use appropriate security
                measures to protect the security of your data both online and
                offline. These measures vary based on the sensitivity of the
                information that we collect, process, store, and the current
                state of technology. We keep information as long as needed to
                achieve its purpose or meet our legal obligations. We make
                considerable good faith efforts to maintain the security and
                integrity of your information and our system. No website or
                internet transmission is completely secure, so while we strive
                to protect your data, we cannot guarantee that unauthorized
                access, hacking, human error, system failure, outages or other
                factors will not result in data loss or a data breach
                compromising your personal information.
              </p>
              <h5>How We Use The Data We Collect</h5>
              <p className="mb-0">
                <strong>Email Communication</strong>
              </p>
              <p>
                Each email we send will contain instructions on how to
                unsubscribe should you decide not to receive future promotional
                e-mails. If you choose to opt-out of receiving promotional
                e-mail communications from any KIBU business unit, we may still
                periodically communicate with you via mail and telephone. Please
                allow up to 10 business days to be removed from our promotional
                e-mail list.
              </p>
              <p>
                We send notification emails about the Website and Services from
                time to time as necessary. Generally, you may not opt-out of
                these communications, which are not promotional in nature. If
                you do not wish to receive them, your only option is to
                deactivate your account.
              </p>
              <p>
                We also send newsletters to our users with news of new service
                offerings and promotions. Should you change your mind about
                receiving these newsletters, you can unsubscribe by following
                the directions present in each email we send out.
              </p>
              <h6>Most of our communications are intended to:</h6>
              <ul className="list-a">
                <li>
                  {" "}
                  inform you of product upgrades and updates and subscription
                  renewals;
                </li>
                <li>
                  {" "}
                  inform you of tax, regulatory and other compliance issues with
                  your software product or service;
                </li>
                <li>
                  {" "}
                  notify you of products and services that we think may be of
                  interest to you;
                </li>
                <li>
                  {" "}
                  offer you discounts or other promotions with respect to KIBU
                  products and services that we think may interest you; and
                </li>
                <li>
                  {" "}
                  contact you for survey purposes to determine how we can better
                  service you or provide better products to meet your needs.
                </li>
              </ul>
              <p>
                For our Job Postings, KIBU collects personal and non-personal
                information To perform the service of matching job seekers to
                our company duties; To provide information about you to our
                human resources; To create an candidate profile based on the
                information you have provided us; To provide information about
                you and your recruitment; and To contact you regarding changes
                to your profile, service updates and services you may be
                interested in and various other.
              </p>
              <p className="mb-0">
                <strong>Tax Information</strong>
              </p>
              <p>
                Consent to receive your tax information electronically is hereby
                given unless you specifically request such information in paper
                format through your Account. Such information will be securely
                hosted by KIBU and accessibly by you through your Account for at
                least seven (7) years following the related tax year. Your
                electronic tax information will be in a downloadable PDF format
                accessible by a standard PDF reader. You may contact
                support@kibugaming.com, if you cannot access your tax
                information or if you wish to obtain a paper copy. Revoking your
                consent to receive electronic tax information can be done
                through your Account. After revocation all tax documents will be
                mailed to your mailing address in your Account on the date of
                mailing. KIBU will use the services of the USPS “first class”
                mail system per normal business practices and will not be
                responsible for any lost, misdirected, damaged, destroyed or
                otherwise undelivered mail and will not be responsible for any
                delayed mail once delivered to the USPS.
              </p>
              <h5>Third-Party E-Mail Promotions</h5>
              <p>
                If you access and/or use any of our Partner’s services from a
                link on the Websites, a KIBU/Partner cobranded website or by any
                other means, any information you reveal in connection with such
                service is submitted to our Partner and is not subject to this
                Privacy Policy. You should consult the privacy policy of any
                Partner or third-party website with respect to its treatment of
                any information that you may reveal through such service.
              </p>
              <h5>How We Share Data</h5>
              <p>
                Unless you give us your permission, we don’t share data we
                collect from you with third parties, except as described below:{" "}
                <br />
                Third-party service providers or consultants. We may share data
                collected from you on the KIBU Websites with third-party service
                providers or consultants who need access to the data to perform
                their work on KIBU’s behalf, such as a website analytics company
                or our third-party advertising partners. These third-party
                service providers are limited to only accessing or using this
                data to provide services to us and must provide reasonable
                assurances that they will appropriately safeguard the data.
              </p>
              <p>
                Compliance with Laws. We may disclose your data to a third party
                if (i) we believe that disclosure is reasonably necessary to
                comply with any applicable law, regulation, legal process or
                government request (including to meet national security or law
                enforcement requirements), (ii) to enforce our agreements and
                policies, (iii) to protect the security or integrity of the KIBU
                Website or our services and products, (iv) to protect ourselves,
                our other customers, or the public from harm or illegal
                activities, or (v) to respond to an emergency which we believe
                in good faith requires us to disclose data to assist in
                preventing a death or serious bodily injury. If KIBU is required
                by law to disclose any of your data that directly identifies
                you, then we will use reasonable efforts to provide you with
                notice of that disclosure requirement, unless we are prohibited
                from doing so by statute, subpoena or court or administrative
                order. Further, we object to requests that we do not believe
                were issued properly.
              </p>
              <p>
                Affiliates. We may share data collected from you from the KIBU
                Websites with our affiliates. We all only use the data as
                described in this notice.
              </p>
              <p>
                Business transfers. If we go through a corporate sale, merger,
                reorganization, dissolution or similar event, data we gather
                from you through our website may be part of the assets
                transferred or shared in connection with due diligence for any
                such transaction. Any acquirer or successor of KIBU may continue
                to use the data we collect from you through our Website as
                described in this notice.
              </p>
              <p>
                Aggregated or de-identified data. We might also share data
                collected from you from the KIBU Website with a third-party if
                that data has been de-identified or aggregated in a way that
                does not directly identify you.
              </p>
              <h5>EU-US Privacy Shield Framework</h5>
              <p>
                KIBU complies with the EU-U.S. Privacy Shield Framework as set
                forth by the U.S. Department of Commerce regarding the
                collection, use, and retention of personal information
                transferred from the European Union to the United States. If
                there is any conflict between the terms in this privacy policy
                and the Privacy Shield Principles, the Privacy Shield Principles
                shall govern. To learn more about the Privacy Shield
                program,please visit{" "}
                <a href="https://www.privacyshield.gov/welcome">
                  https://www.privacyshield.gov/
                </a>
                .
              </p>
              <p>
                KIBU chooses our third-party agents with care and works to
                ensure they uphold privacy and security standards set forth by
                the Privacy Shield Framework. Regarding the Accountability for
                Onward Transfer, KIBU remains liable if our agent processes the
                personal information we have provided them in a manner
                inconsistent with the Privacy Shield Principles, unless we can
                provide proof that KIBU is not responsible for any occurrence
                resulting in a compromise of personal data.
              </p>
              <p>
                KIBU is subject to the investigatory and enforcement powers of
                the Federal Trade Commission.
              </p>
              <p>
                KIBU’s headquarters are in the United States and as such, data
                may be transferred to the United States.
                <br />
                Personal data will be transferred to the United States only: to
                fulfill contractual obligations to you; with your consent; or to
                fulfill a compelling legitimate interest of KIBU in a manner
                that does not impeach on your rights and freedoms. As we are
                compliant with the EU-U.S. Privacy Shield Framework, we
                therefore comply with data protection requirements when
                transferring personal data from the EU. In addition, we make
                every effort to ensure the security and protection of the data
                we transfer.
              </p>
              <h5>Privacy Rights</h5>
              <p>
                We may retain your information for a period of time consistent
                with the original purpose of collection. For instance, we may
                retain your information during the time in which you have an
                account to use our Websites or Services and for a reasonable
                period afterward. We also may retain your information during the
                period of time needed for us to pursue our legitimate business
                interests, conduct audits, comply with our legal obligations,
                resolve disputes and enforce our agreements.
              </p>
              <p>
                You may opt out of any future contacts from us at any time.
                Additionally, if the collection of your personal data was based
                on your consent, you have the right to revoke that consent at
                any time. Be aware that by revoking consent, you may lose access
                to services you previously opted to use.
              </p>
              <p>
                You may request to review, correct, delete, or otherwise modify
                any of the personal information in your account that you have
                previously provided to us through the Company’s Website(s) and
                Services. Account information you may contact us about via{" "}
                <a href="mailto:support@kibugaming.com">
                  support@kibugaming.com
                </a>{" "}
                or phone number provided on our website:
              </p>
              <ul className="list-a">
                <li> See what data we have about you, if any</li>
                <li> Change/correct any data we have about you</li>
                <li> Have us delete any data we have about you</li>
                <li>
                  {" "}
                  Express any concern you have about our use of your data
                </li>
              </ul>
              <p>
                Requests to access, change, or delete your information will be
                addressed within a reasonable timeframe.
              </p>
              <p>
                To request a copy of your data, please contact{" "}
                <a href="mailto:support@kibugaming.com">
                  support@kibugaming.com
                </a>{" "}
                and a copy will be returned to you within 45 days of the
                request. Information will not be available if a request to
                remove all personal data is initiated before a copy of the data
                has been provided. Provided data will not be able to be
                re-integrated into the system in the case of an accidental
                forgetme request.
              </p>
              <h5>California Residents’ Privacy Rights</h5>
              <p>
                Pursuant to Section 1798.83 of the California Civil Code,
                residents of California have the right to request from a
                business, with whom the California resident has an established
                business relationship, certain information with respect to the
                types of personal information the business shares with third
                parties for direct marketing purposes by such third party and
                the identities of the third parties with whom the business has
                shared such information during the immediately preceding
                calendar year. To request a copy of such information, please
                contact us as described in the section below, and we will
                respond within 30 days as required by law.
              </p>
              <h5>How to Contact Us</h5>
              <p>
                In compliance with the Privacy Shield Principles, KIBU commits
                to resolve complaints about our collection or use of your
                personal information. KIBU takes its users’ privacy concerns
                seriously.
              </p>
              <p>
                If you believe that KIBU has not complied with this Privacy
                Policy with respect to your personal information, or EU
                individuals with inquiries or complaints regarding our Privacy
                Shield policy, you should first contact us at KIBU by emailing
                us at{" "}
                <a href="mailto:support@kibugaming.com">
                  support@kibugaming.com
                </a>{" "}
                or by writing to the KIBU Privacy Officer at the following
                address:
              </p>
              <p>
                <strong>KIBU Gaming Corporation</strong> <br />
                Attn: Privacy Officer <br />
                1517 W. 7th Street <br />
                Mesa, AZ 85201
                <br />
                USA.
              </p>
              <p>
                In your letter, please describe in as much detail as possible
                the ways in which you believe that the Privacy Policy or Privacy
                Shield Framework has not been complied with. We will investigate
                your complaint promptly. KIBU has further committed to cooperate
                with the panel established by the EU data protection authorities
                (DPAs) with regard to unresolved Privacy Shield complaints
                concerning human resources data transferred from the EU.
              </p>
              <p>
                Framework to BBB EU PRIVACY SHIELD, a non-profit alternative
                dispute resolution provider located in the United States and
                operated by the Council of Better Business Bureaus. If you do
                not receive timely acknowledgment of your complaint, or if it is
                not satisfactorily addressed by KIBU, please visit
                www.bbb.org/EU-privacy-shield/for-euconsumers/ for more
                information and to file a complaint. Should you not receive a
                satisfactory resolution, you may also contact your local data
                protection authority, the Federal Trade Commission, or the US
                Department of Commerce. Finally, if your complaint remains
                unresolved, you have the right to invoke binding arbitration by
                thePrivacy Shield Panel upon written notice to KIBU at
                <a href="mailto:support@kibugaming.com">
                  support@kibugaming.com
                </a>
                .
              </p>
              <p>
                Please note that KIBU is not responsible for the content or
                privacy practices of non- KIBU websites to which the Websites or
                any other KIBU website may link. Also, KIBU is not responsible
                for the privacy practices of its customers or users of its
                customer websites. You should review the additional privacy
                policy of the customer website before using the website.
              </p>
  
            </div>
          </div>
        </div>
      </section>

      <PreviewAdvertisement />
      <UserFooter />
    </>
  );
}
