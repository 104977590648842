import slam1 from '../../../assets/images/logo-slam-1.png';
import slam2 from '../../../assets/images/logo-slam-2.png';
import slam3 from '../../../assets/images/logo-slam-3.png';
import Slider from "react-slick";
import slam4 from '../../../assets/images/logo-slam-4.png';
import services from "../../../services/services";
import { useEffect, useState } from 'react';


export function Sponsors() {

  const [sponsor, setSponsor] = useState([]);
  const [gIndex, setGIndex] = useState(0)

  async function fetch() {
    try {
      let result = await services.adSponsorImage();
      setSponsor(result.data?.data);

    } catch (e) {
      console.log(e.response);
    }
  }


  useEffect(() => {
    fetch();
    
  }, []);

  useEffect(()=>{
    if(sponsor?.length){
      if(gIndex > sponsor?.length-1){
        setGIndex(0);
     }
    }
    
  },[gIndex])

  useEffect(() => {
    if(sponsor?.length > 0){
      const interval = setInterval(() => {/*todo reset at max value*/ setGIndex((i)=>i+1)},  10000)
      return () => clearInterval(interval)
    }
  },[sponsor]);



    const settings = {
   
        infinite: true,
        speed: 300,
        autoplay: true,
        slidesToShow:  4,
        dots:false,
        slidesToScroll:  4,
        responsive: [

            {
              breakpoint: 650,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            }
          ]
      };

    return (
        <section className="sponsor">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="heading-sec text-center">
                            <h2>Our Sponsors and Partners</h2>
                            <h4><b>Shout Out</b> to those who made this site possible</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="customer-logos">
                
           
                <Slider {...settings}>
                <div className="">
                    <a
                    rel="noreferrer"
                    target="_blank"
                    href={sponsor[gIndex]?.sponsorRibbonImageAdLink}
                  >  <img src={sponsor[gIndex]?.sponsorRibbonImage || slam1} alt="" /> </a>
                </div>

                <div className="">   <a
                    rel="noreferrer"
                    target="_blank"
                    href={sponsor[gIndex+1]?.sponsorRibbonImageAdLink}
                  >  <img src={sponsor[gIndex+1]?.sponsorRibbonImage || slam2} alt="" /></a></div>

                <div className="">  <a
                    rel="noreferrer"
                    target="_blank"
                    href={sponsor[gIndex+2]?.sponsorRibbonImageAdLink}
                  > <img src={sponsor[gIndex+2]?.sponsorRibbonImage  || slam1} alt="" /></a></div>

                <div className="">  <a
                    rel="noreferrer"
                    target="_blank"
                    href={sponsor[gIndex+3]}
                  > <img src={sponsor[gIndex+3]?.sponsorRibbonImage  || slam2} alt="" /></a></div>

                <div className="">  <a
                    rel="noreferrer"
                    target="_blank"
                    href={sponsor[gIndex+4]}
                  > <img src={sponsor[gIndex+4]?.sponsorRibbonImage || slam1} alt="" /></a></div>
                </Slider> 
                 
                   
                    {/* <div className=""><img src={slam2} alt="" /></div>
                    <div className=""><img src={slam3} alt="" /></div>
                    <div className=""><img src={slam4} alt="" /></div>
                    <div className=""><img src={slam2} alt="" /></div>
                    <div className=""><img src={slam3} alt="" /></div>
                    <div className=""><img src={slam1} alt="" /></div>
                    <div className=""><img src={slam2} alt="" /></div>
                    <div className=""><img src={slam3} alt="" /></div>
                    <div className=""><img src={slam4} alt="" /></div>
                    <div className=""><img src={slam2} alt="" /></div>
                    <div className=""><img src={slam3} alt="" /></div> */}
                   

                </div>
            </div></section>
    )
}