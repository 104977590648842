import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import services from "../../services/services";
// import '../../css/bootstrap.min.css';
// import '../../css/master-style.css'
// import '../../css/responsive.css'
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
toast.configure();

const ResetPassword = () => {
    const history = useHistory();
    const [submitFormLoader, setSubmitFormLoader] = useState("");
    const {
        register,
        reset,
        setError,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm();
      const password = watch();

    const resetPassword = async (d) => {
        try {
        setSubmitFormLoader(true);
        let res = await services.changePassword(d);
        Swal.fire(res.data.message, "", "success");
        setSubmitFormLoader(false);
        history.push('/')
        } catch (e) {
        toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
        });
        setSubmitFormLoader(false);
        }       
       
    }

    return (
      
            <section className="login-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-6 col-12 m-auto text-center">
                            <div className="login-area">
                                <div className="logo text-center">
                                    <img style={{width:'inherit'}} src="../logo.png" alt="Avatar" />
                                </div>
                                {submitFormLoader && <div className="loader"></div>}
                                <h2>Change Password</h2>
                                <form name="loginform" onSubmit={handleSubmit(resetPassword)}>
                                    <div className="form-group mb-3">
                                        <img src="../icon-password.svg" className="icon" alt="Avatar" />
                                        <input maxLength={20} type="password" placeholder="Old Password"  className="form-control m-0"
                                         {...register("oldPassword", { required: true })} />
                                         {errors.oldPassword && (
                                            <p className="text-danger text-left">
                                                Please enter the old password.
                                            </p>
                                            )}
                                    </div>
                                    <div className="form-group mb-3">
                                        <img src="../icon-password.svg" className="icon" alt="Avatar" />
                                        <input maxLength={20} type="password" placeholder="New Password" className="form-control m-0"
                                         {...register("newPassword", { required: true,
                                            pattern: {
                                                value: /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/,
                                                message: `Please enter secure and strong password.
                                     Include both lower and upper case character.
                                    Include at least one number and one symbol (@#$%^...)`
                                            } })} />
                                         {errors.newPassword && (
                                            <p className="text-danger text-left">
                                                {errors.newPassword.message || "Please enter the new password."}
                                            </p>
                                            )}
                                    </div>
                                    <div className="form-group mb-3">
                                        <img src="../icon-password.svg" className="icon" alt="Avatar" />
                                        <input maxLength={20} type="password" placeholder="Confirm Password"  className="form-control m-0"
                                         {...register("confirmNewPassword", { required: true,
                                            validate: value => value === password?.newPassword || 'The passwords does not match with the new password',
                                         })} />
                                         {errors.confirmNewPassword && (
                                            <p className="text-danger text-left">
                                            {errors.confirmNewPassword.message || "Please enter the confirm password."}
                                            </p>
                                            )}
                                    </div>
                                    <button className="btn btn-primary btn-block mt-4 mb-3" type="submit" bsstyle="primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
       
    )
}

export default ResetPassword;