import {Link} from 'react-router-dom';
import baseball from '../../../assets/images/baseball-img.png';
import football from '../../../assets/images/american-football.png';
import basketball from '../../../assets/images/basketball-img.png';
import next from '../../../assets/images/next-icon.png';
import UserHeader from '../../core/header/userHeader';
import {UserFooter} from '../../core/footer/userFooter';
import PreviewAdvertisement from '../preview-advertisement/preview-advertisement';
import './gamerule.css'
import { General } from './general';
import { Group } from './group';
import { Location } from './location';
import file from '../../../assets/file/pattern.pdf'


export default function GameRule (){

    return (
        <>
      <UserHeader />
      <section className="bredcum">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xl-12 text-center">
                <h1>Game Rules</h1>
                <Link to="/" >Home</Link> <span> <i className="fa fa-angle-right" /> </span> <Link to="#" className="active">Game Rules</Link>
              </div>
            </div>
          </div>
        </section>
        <section className="gamerules-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="heading-sec mb-5">
                  <h2>Game Rules</h2>
                  <h4 className="pb-3">Please contact us if you have a question, comment or need clarity. <b>Carrejoue</b> has a common set of rules across a number of Sporting Events as explained here.  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <nav>
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-def" role="tab" aria-controls="nav-home" aria-selected="true">Definitons</a>
                    <a className="nav-item nav-link" id="nav-generalrules-tab" data-toggle="tab" href="#nav-gen" role="tab" aria-controls="nav-profile" aria-selected="false">General Rules </a>
                    <a className="nav-item nav-link" id="nav-grouprules-tab" data-toggle="tab" href="#nav-grp" role="tab" aria-controls="nav-contact" aria-selected="false">Group Rules</a>
                    <a className="nav-item nav-link" id="nav-location-tab" data-toggle="tab" href="#nav-loc" role="tab" aria-controls="nav-about" aria-selected="false">Locations </a>
                    <a className="nav-item nav-link" id="nav-keypoints-tab" data-toggle="tab" href="#nav-kep" role="tab" aria-controls="nav-about" aria-selected="false">Key Points </a>
                  </div>
                </nav>
                <div className="tab-content py-4 px-3 px-sm-0" id="nav-tabContent">
                  <div className="tab-pane fade show active" id="nav-def" role="tabpanel" aria-labelledby="nav-home-tab">
                    <h3 className="pt-4 pb-3">Definitions</h3>
                    <p className="cont-pdb"><strong>Subject Square – </strong></p>
                    <p> The only square, in play, on each Game Crd currently awaiting a random Defined Result
                      from the Event.</p>
                    <p className="cont-pdb"><strong>Defined Results – </strong></p>
                    <p> The only possible results delivered by the Key Element of an Event Type. For the
                      correlation to the on-field action of an Event Type, <a href="event-type-rules.html">click here</a>.</p>
                    <p className="cont-pdb"><strong>Event – </strong></p>
                    <p> This is a single sporting contest delivering Defined Results to the Game Cards in a Game
                      of Carrejoue.</p>
                    <p className="cont-pdb"><strong>Event Type – </strong></p>
                    <p> This is the sport classification such as baseball, basketball, soccer, etc.</p>
                    <p className="cont-pdb"><strong>Factors – </strong></p>
                    <p> This is the number of possible Defined Results being delivered by the Event to the Game
                      Cards. It varies by Group and Event Type.</p>
                    <p className="cont-pdb"><strong>Game – </strong></p>
                    <p> This is an instance of Carrejoue, the Group and its Game Cards are tied to an Event
                      delivering random Defined Results.</p>
                    <p className="cont-pdb"><strong>Game Cards – </strong></p>
                    <p> These are the players' entries into a Game. Each is comprised of 25 pre-populated
                      Defined Results situated to form five rows and five columns.</p>
                    <p className="cont-pdb"><strong>Groups – </strong></p>
                    <p> These establish different Games tied to the same Event. They are created by legal
                      jurisdictions, physical locations, provider requirements and other circumstances
                      classifying various players together. </p>
                    <p className="cont-pdb"><strong>Key Element – </strong></p>
                    <p>This is the recurring on-field action of an Event Type or Event generating the random
                      Defined Results delivered to a Game of Carrejoue. </p>
                    <p className="cont-pdb"><strong>Match – </strong></p>
                    <p>This occurs when the Key Element of an Event delivers the same Defined Result that is
                      pre-populated in the Subject Square of a Game Card. </p>
                    <p className="cont-pdb"><strong>Special Result – </strong></p>
                    <p>This is a subset of the Defined Results and may provide a bonus prize or payout under
                      certain circumstances. </p>
                    <p className="cont-pdb"><strong>Winning Pattern – </strong></p>
                    <p>These are pre-determined patterns of Matches set forth by a Group prior to the start of the Event.
                    These patterns can come from <b>6</b> categories – <b> LINE, DIAGONAL, CORNERS, X, HALF BLACKOUT </b> and <b>BLACKOUT</b>.  Sometimes a pattern category is not used and sometimes only select patterns are used form within a category.   
                    View the Group Rules for details of a particular Group. <a href={file} target="_blank"><b>Click Here </b></a> to see the 6 categories and their possible patterns.
                    </p>
                  </div>
             

                  <General/>
                  <Group />
                  <Location/>
                
                  <div className="tab-pane fade" id="nav-kep" role="tabpanel" aria-labelledby="nav-keypoints-tab">
                    <p>All decisions by our management and the Defined Results of an Event are final. The
                      decisions of our company and the management of Carrejoue are final. If our scoring does
                      not match the scoring of the official score keepers, our scoring is the final authority
                      with regard to Carrejoue. While mistakes happen, they are very rare. Official scorers
                      sometimes change their decisions.</p>
                    <p>Our scoring is done by personnel trained in the game of Carrejoue and the Defined Results
                      that affect the game occurring from the live sporting event. Our company uses direct
                      feeds of the live sporting event available to the host of viewers accommodated by the
                      media. In addition, our company is tethered into the official score keeping sites of the
                      live sporting events.</p>
                    <p>Our personnel are instructed to wait until the Event referees, judges and umpires have
                      made their final decision before entering results into our game system. Despite the
                      training, and sometimes due to the nature of the live sporting event, mistakes are made.
                      Again they are rare and all decisions, mistakes or not, of our company, its management
                      and the management of Carrejoue and the Defined Results of Carrejoue is final.</p>
                    <p>The parimutuel wagering system allows for all winners to share equally from the payout
                      pools officially entered. In parimutuel wagering, the last Game Card to achieve a
                      Winning Pattern shares in that Winning Patterns payout pool equally with the first Game
                      Card and all other Game Cards in between entered into that Winning Pattern.</p>
                    <p>Therefore, if you have a Winning Pattern and examine the payout calculations while the
                      Event is still live, the payout will likely change. The payouts will be recalculated as
                      the results change.</p>
                    <p>The Game continues until the conclusion of the live sporting event.</p>
                    <p>Event postponemnet or official shortened event is at the discretion of the governing body
                      of the sport. If postponement of the Event does not allow the governing body to declare
                      the Event official by the next day following the start time, all game cards obtained
                      will be null and void, no prizes won and no winners declared, game card purchases will
                      be refunded. If such postponement allows the governing body to declare the Event
                      official by the next day following the start time, then the Games will be considered
                      official one hour later. If a shortened event is declared official by the governing body
                      of the sport, the Games will be considered official one hour later.</p>
                    <p>Event cancellation is at the discretion of the governing body of the sport. If an Event
                      is cancelled or Games are cancelled for any reason, at the sole discretion of KiBu
                      Gaming Corporation, all Game Cards obtained will be null and void, no prizes won and no
                      winners declared, Game Card purchases will be refunded.</p>
                    <p>The Event returns a result based on the Key Element. If the result matches your
                      pre-populated Defined Result in the Subject Square then you get a Mark towards all
                      Winning Patterns purchased. Regardless if you receive a mark in your square or not, the
                      Game continues to the next square, now designated the Subject Square. </p>
                    <p>During the Event, several squares will receive multiple Defined Results from the Key
                      Element of the Event. This is by design and is part of the Game. If you have a square
                      that is already Marked, then no need to worry. Once a square is Marked it remains Marked
                      throughout the rest of the Game.</p>
                    <p>As strategy, when a square is receiving another Defined Result, you want it to match the
                      result in your square, of course, but the next best Defined Result would be to duplicate
                      a prior Defined Result. If a result duplicates a prior result, then no Game Cards will
                      receive additional Marks.</p>
                    <p>Marks are of course the name of the Game since they are the building blocks to Winning
                      Patterns. The more Marks your card receives the more likely you are to win a prize or a
                      share of the pool payout or possibly the Blackout (B)jackpot.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <PreviewAdvertisement />
        {/* <div className="banner-ad pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <img src={banner} alt="banner" className="img-fluid" />
              </div>
            </div>
          </div>
        </div> */}
             <UserFooter />
        </>
    )
}