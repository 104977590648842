import Slider from "react-slick";
import { Link } from "react-router-dom";

export const BeenSaid = () => {
  const settings = {
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    dots: true,
    arrows: false,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="about-home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading-sec text-center">
              <h2>Been Said</h2>
              <h4>
              See what has been said about the game <b>Carrejoue</b>, played while fans watched their favorite Sporting Events.  Ready to play, <Link style={{fontSize: '22px'}} to="/signup"> <b> Sign Up </b> </Link> now.
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div style={{ background: "none" }}>
              <Slider {...settings}>
                <div className="slide-in-right slide">
                  <div id="content">
                    <div className="testimonial">
                      <blockquote>
                        Super simple. And when you might make a pattern - wow!!!
                      </blockquote>
                      <div />
                      <p>Reuben F.</p>
                    </div>
                  </div>
                </div>
                <div className="slide-in-right slide">
                  <div id="content">
                    <div className="testimonial">
                      <blockquote>
                        Goes against my team a bit, but hey, my strikeout got
                        marked.
                      </blockquote>
                      <div />
                      <p>Adrian S.</p>
                    </div>
                  </div>
                </div>
                <div className="slide-in-right slide">
                  <div id="content">
                    <div className="testimonial">
                      <blockquote>
                        Definitely makes you wonder if the next play will be a
                        mark.
                      </blockquote>
                      <div />
                      <p>Lance P.</p>
                    </div>
                  </div>
                </div>
                <div className="slide-in-right slide">
                  <div id="content">
                    <div className="testimonial">
                      <blockquote>
                        Like the way the machine keeps up with everything. I
                        keep coming back to see how my card is doing
                      </blockquote>
                      <div />
                      <p>Greg W.</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <h6 className="pt-5 mt-4">
              Ready to <Link style={{fontSize: '22px'}} to="/signup"><b>Create an Account?</b></Link> Already
              have an account, Enter a Game (link to above) now. Direct
              questions and comments to{" "}
              <a href="mailto:support@kibugaming.com ">
                support@kibugaming.com{" "}
              </a>
              .
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
};
