
import "./preview-advertisement.css";
import gfg from "../../../assets/images/gfgg.png";

const AdGameCard = ({gameCard}) => {

  return (
    <div className="banner-ad">
          {/* {imagesAndVideos.length > 0 ?  */}
            <a
              rel="noreferrer"
              target="_blank"
              href={gameCard?.gameCardImageAdLink || "/"}
            >
              <img
                src={gameCard?.gameCardImage || gfg}
                alt="Avatar"
                className="img-fluid"
              />
            </a>
          {/* :  <img src={gfg} className="img-fluid" alt="" /> } */}
        
      
    </div>
  );

          }

export default AdGameCard;
