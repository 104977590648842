/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import * as universalFunction from "../../utils/universalFunctions";

let autoComplete;

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    // { types: ["(cities)"], componentRestrictions: { country: "us" } }
  );
  
  
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );

}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  // console.log(query,"lll");
  // console.log(addressObject);
}

function SearchLocationInput({val, onclick,inputname, dis}) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    // universalFunction.loadScript(
    //   `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,drawing&v=weekly`,
    //   () => 
      handleScriptLoad(setQuery, autoCompleteRef)
    // );
  }, []);
  const handleKeyDown = event => {
    onclick(event.target.value);
    if (event.keyCode === 13) {

      event.preventDefault();

    }
  };

  useEffect(()=>{
 setQuery(val)
  
  },[val])
  
  useEffect(()=>{
    if(query){
      onclick(query)
    }
   
  },[query])

const handleChange = (e)=> {
  onclick(e.target.value);
  setQuery(e.target.value)
}



  return (
    <div className="search-location-input">
      <input
        className="form-control"
        ref={autoCompleteRef}
        onChange={handleChange}
        placeholder="Enter a Location"
        value={query}
        disabled={dis}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}





export  {SearchLocationInput};
