import {
    LOGOUT_SUCCESS,
    LOGOUT_FAIL
  } from "../actions/type";
  
import Cookies from 'universal-cookie';
  const initialState = { isLogout: true, user: undefined  };
// const initialState = undefined
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default function (state = initialState, action) {
  const cookies = new Cookies();
    const { type } = action;

    switch (type) {
      case LOGOUT_SUCCESS:

      
        return {
          
          ...state,
          isLogout: true,
          user: undefined
        };
      case LOGOUT_FAIL:
        return {
          ...state,
        //   isLoggedIn: "fail",
          user: undefined,
        };
      default:
        return state;
    }
  }