/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AdminSidebar from "../../core/sidebar/admin-sidebar";
import AdminHeader from "../../core/header/adminHeader";
import AdminFooter from "../../core/footer/adminFooter";
import services from "../../../services/services";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import "./callerboard.css";
import { useForm } from "react-hook-form";
import * as universalFunction from "../../../utils/universalFunctions";


toast.configure();

// Function for create advertisement
const BaseBallCallerBoard = () => {
  const [submitFormLoader, setSubmitFormLoader] = useState("");
  const [eventdata, setData] = useState([]);
  const [event, setEvent] = useState(undefined);
  const [atbatInp, setAtbatInp] = useState([]);
  const [partner, setPartner] = useState([]);
  const [gameIndex, setGameIndex] = useState("");
  const [callerBoardData, setcallerBoardData] = useState([]);
  const [miniCallData, setMiniCallData] = useState([]);
  const [callName, setinitiateValue] = useState("");
  const [firebaseData, setfirebaseData] = useState({});
  const [firebaseId, setFirebaseId] = useState('');

  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  
  const subscription = watch();
  useEffect(() => {
    window.scrollTo(0, 0);
    fetch();
  }, []);
  
  const fetch = async () => {
    try {
      let res = await services.callerBoardEvents(0,100,"B");
      setData(res.data?.data?.data && res.data.data.data.map((data) => data));
      let response = await services.partners();
      setPartner(response?.data?.data?.data);
      // console.log(res.data.data.data);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  };


  useEffect(()=>{
    if (firebaseId) {
      setSubmitFormLoader(true);
      services
        .firebase(firebaseId)
        .then((res) => {
          setfirebaseData(res.data.data);
          let filterData =
            res.data?.data?.calls &&
            res.data.data.calls.filter(({ callName }) => callName != "R");
          setcallerBoardData(res.data.data);
          setMiniCallData(chunckArr(filterData));
          setSubmitFormLoader(false);
        })
        .catch((e) => {
          setSubmitFormLoader(false);
        });
    }
  },[firebaseId])

  useEffect(() => {
    if(subscription?.eventId){
    let data = [...eventdata];
    let findData = data.filter((dat) => dat?.eventId === subscription?.eventId);
    setEvent(findData[0]);

    let atbat = partner?.filter(({ id }) => id === findData[0]?.awayName);
    setAtbatInp(atbat);
    setFirebaseId('');
    if (findData[0]?.fireBaseCallerBoardId) {
      setFirebaseId(findData[0]?.fireBaseCallerBoardId)
    }
  }
  }, [subscription?.eventId]);

  const callEvent = (e, data) => {
    if (e.currentTarget.classList.contains("active")) {
      e.currentTarget.classList.remove("active");
      setinitiateValue("");
    } else {
      let a1 = document.getElementsByClassName("active")[0];
      if (a1) {
        a1.classList.remove("active");
      }

      e.currentTarget.classList.add("active");
      setinitiateValue(data);
    }
  };

  const acceptCall = async (data) => {
    if (!callName) {
      toast.warn("Please select the call event", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      return;
    }
    let callname = callName?.match(/\(([^)]+)\)/)[1];
    try {
      setSubmitFormLoader(true);
      let res = await services.callerBoard({
        ...data,
        callName: callname,
      });
      setFirebaseId('');
      setFirebaseId(res?.data?.data?.fireBaseCallerBoardId);
      reset({
        batter: "",
      });
      eraseCall();
      setSubmitFormLoader(false);
    } catch (error) {
      setSubmitFormLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
    }
  };

  // console.log("99",filterCallName);
  const eraseCall = () => {
    let a1 = document.getElementsByClassName("active")[0];
    setinitiateValue("");
    if (a1) {
      a1.classList.remove("active");
    }
  };

  
  const stopCall = async () => {
    Swal.fire({
      title: "Do you want to stop the call?",
      // showDenyButton: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setSubmitFormLoader(true);
          let res = await services.endCall({ eventId: subscription?.eventId,date:moment().format("MM-DD-YYYY HH:mm")});
          Swal.fire(res.data.message, "", "success");
          setEvent({...event,...res.data.data})
          setSubmitFormLoader(false);
        } catch (e) {
          setSubmitFormLoader(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }
      }
    });
  };
  
  const cancelCall = async () => {
    Swal.fire({
      title: "Do you want to cancel the call?",
      // showDenyButton: true,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        try {
          setSubmitFormLoader(true);
          let res = await services.cancelGame(event?.id);
          setEvent({...event,...res.data.data})
          Swal.fire(res.data.message, "", "success");
          setSubmitFormLoader(false);
        } catch (e) {
          setSubmitFormLoader(false);
          toast.error(e.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            toastId: "1",
          });
        }
      }
    });
  };
  
  const eventStart = async (id) => {
    try {
      setSubmitFormLoader(true);
      let response = await services.startGame(id);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      let res = await services.events(0,100);
      setData(res.data?.data?.data && res.data.data.data.map((data) => data));
      setValue('eventId', "")
      setValue('eventId', response?.data?.data?.eventId)
      setSubmitFormLoader(false);
      
    } catch (e) {
      toast.error(e.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
        toastId: "1",
      });
      setSubmitFormLoader(false);
    }
    // }
  }
  
  const findCallName = (d) => {
    if(callerBoardData?.counts){
      let find = callerBoardData?.counts.find(({callName})=> callName===d);
      return find?.name
    }
  }

  console.log(miniCallData);
  
  function chunckArr(arr) {
    let x = 0;
    let y = 0;

    let arr2 = [];
    arr.map((data, i) => {
      if (x <= 24) {
        if (y <= 24) {
          arr2.push([data]);
          setGameIndex(x + 1);
        } else {
          setGameIndex(x + 1);

          arr2[x].push(data);
        }

        x++;
        y++;
      } else {
        arr2[0].push(data);
        x = 1;
        setGameIndex(x);
      }
    });

    return arr2;
  }
  let arrayBox = new Array(25).fill(1);

  return (
    <div id="admin-dashboard">
      <div className="mainWrapper Total-Revenue-1">
        {submitFormLoader && <div className="loader"></div>}
        {/*--------- Top Bar start -----------*/}
        <AdminHeader />
        {/*------ LeftAsideBar panel start------ */}
        <AdminSidebar />
        {/*------ right Body Start -----*/}
        <div className="rightBody">
          <div className="customTableWrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="left">
                  <h4>Baseball Caller Board </h4>
                </div>
              </div>
            </div>

            <form className="row mt-3" 
            autoComplete="off"
            >
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Game / Event Id</label>
                  <input
                    list="encodings"
                    type="text"
                    className="form-control"
                    {...register("eventId", { required: true })}
                  />
                  {errors.eventId && (
                    <p className="text-danger">Please select the event id.</p>
                  )}
                  <datalist id="encodings">
                    {eventdata &&
                      eventdata.map((data) => (
                        <option key={data?.eventId}>{data?.eventId}</option>
                      ))}
                  </datalist>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Date:</label>
                  <input
                    disabled
                    type="text"
                    defaultValue={
                      event?.eventDate
                        ? moment(event?.eventDate).format("MM/DD/YYYY")
                        : ""
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>At Bat</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    defaultValue={
                      event?.fireBaseCallerBoardId
                        ? firebaseData?.atBat
                        : atbatInp[0] && atbatInp[0]?.partnerName
                    }
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label> Batter Number #</label>
                  <input
                    key={
                      firebaseData?.batterNumber
                        ? firebaseData?.batterNumber
                        : event
                        ? "1"
                        : ""
                    }
                    type="text"
                    className="form-control"
                    disabled
                    defaultValue={
                      firebaseData?.batterNumber
                        ? firebaseData?.batterNumber
                        : event
                        ? "1"
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Current Inning</label>
                  <input
                    key={
                      firebaseData?.nextInning
                        ? firebaseData?.nextInning
                        : event
                        ? "1.0"
                        : ""
                    }
                    type="text"
                    className="form-control"
                    disabled
                    defaultValue={
                      firebaseData?.nextInning
                        ? firebaseData?.nextInning
                        : event
                        ? "1.0"
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Total Outs</label>
                  <input
                    key={
                      firebaseData?.totalOuts
                        ? firebaseData?.totalOuts
                        : event
                        ? "0"
                        : ""
                    }
                    type="text"
                    className="form-control"
                    disabled
                    defaultValue={
                      firebaseData?.totalOuts
                        ? firebaseData?.totalOuts
                        : event
                        ? "0"
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label> Batter Name</label>
                  <input
                    type="text"
                    list="batterNameList"
                    className="form-control"
                    {...register("batter", { required: true })}
                  />
                  {errors.batter && (
                    <p className="text-danger">Please enter the batter name.</p>
                  )}
                   <datalist id="batterNameList">
                    {[...new Set(callerBoardData?.calls?.map(item => item.batter))]?.map((batter) => {

                      
                      return(
                        <option key={batter}>{batter}</option>
                      )})}
                  </datalist>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="form-group">
                  <label>Event League</label>
                  <input
                    disabled
                    defaultValue={event?.eventType && universalFunction.findLeague(event?.eventType, event?.league)}
                    type="text"
                    // placeholder="Diamondbacks Vs Dodgers"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="form-group">
                  <label>Stadium Name</label>
                  <input
                    type="text"
                    disabled
                    // placeholder="MLB - Chavez Ravine Stadium"
                    defaultValue={event && event?.eventLocation}
                    className="form-control"
                  />
                </div>
              </div>
            </form>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12 col-md-12">
              <div className="Total-Revenue-1">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Infield Flyout (N)")}
                          className="btn event-trigger-btn"
                        >
                          Infield Flyout (N){" "}
                        </button>
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[0]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Groundout (G)")}
                          className="btn event-trigger-btn"
                        >
                          Groundout{" "}
                          <span style={{ fontFamily: "sans-serif" }}>(G)</span>
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[1]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Extra Bases (X)")}
                          className="btn event-trigger-btn"
                        >
                          Extra Bases (X)
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[2]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, " Outfield Out (O)")}
                          className="btn event-trigger-btn"
                        >
                          Outfield Out (O)
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[3]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) =>
                            callEvent(e, "Strikeout Swinging (K)")
                          }
                          className="btn event-trigger-btn"
                        >
                          Strikeout Swinging (K)
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[4]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Strikeout Called (C)")}
                          className="btn event-trigger-btn"
                        >
                          Strikeout Called (C)
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[5]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Walk (W)")}
                          className="btn event-trigger-btn"
                        >
                          Walk (W)
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[6]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Home Run (H)")}
                          className="btn event-trigger-btn"
                        >
                          Home Run (H)
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[7]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Hit by Pitch (P)")}
                          className="btn event-trigger-btn"
                        >
                          Hit by Pitch (P){" "}
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[8]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "On First (F)")}
                          className="btn event-trigger-btn"
                        >
                          On First (F){" "}
                        </button>{" "}
                        <button className="btn btn-value">
                          {callerBoardData?.counts
                            ? callerBoardData?.counts[9]?.count
                            : 0}
                        </button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4">
                        <button
                          onClick={(e) => callEvent(e, "Non Batter Out (R)")}
                          className="btn event-trigger-btn"
                        >
                          Non Batter Out (R){" "}
                        </button>{" "}
                        <button className="btn btn-value">{callerBoardData?.counts
                            ? callerBoardData?.counts[10]?.count
                            : 0}</button>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-4"></div>
                      <div className="col-lg-6 col-md-6 mb-2">
                        <div className="form-group">
                          <label>Initiated Call </label>
                          <input
                            style={{ background: "white" }}
                            type="text"
                            defaultValue={callName}
                            disabled
                            // placeholder="Infield Flyout (N)"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 mb-3">
                        <button
                         disabled={event == undefined ? true : (event) == undefined ? true : (event.isEventStarted && !event.isEventFinished) ? false : true }
                          className="btn add-btn"
                          onClick={handleSubmit(acceptCall)}
                        >
                          Accept Call
                        </button>
                        {/* <button
                          onClick={eraseCall}
                          className="btn cancel-btn ml-2"
                        >
                          Erase Call
                        </button> */}
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Total Actions </label>
                          <input
                            type="text"
                            disabled
                            defaultValue={callerBoardData?.calls?.length}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-10">
                    <div className="card-design-outer">
                    <div className="card-inner-design">
                    
                    {arrayBox?.map((d,i)=>{
                     return  (i%5==-0) && <div className="card-wrap">
                        <div id={i%5+i+1}
                          className={`card-box ${
                            gameIndex === i%5+i+1 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i]?.length > 0 &&
                              miniCallData[i%5+i].map((d, ind) => (
                                <span style={{color:d?.color && 'red' }} key={ind}>{d?.callName}</span>
                              ))}
                          </div>
                          <div className="pattern-main">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i] &&
                              miniCallData[i%5+i][miniCallData[0].length - 1]
                                ?.callName}
                          </div>
                        </div>
                        <div
                        id={i%5+i+2}
                           className={`card-box ${
                            gameIndex === i%5+i+2 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {" "}
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+1]?.length > 0 &&
                              miniCallData[i%5+i+1].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))}
                          </div>
                          <div className="pattern-main">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+1] &&
                              miniCallData[i%5+i+1][miniCallData[0].length - 1]
                                ?.callName}
                          </div>
                        </div>
                        <div id={i%5+i+3}
                          className={`card-box ${
                            gameIndex === i%5+i+3 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+2]?.length > 0 &&
                              miniCallData[i%5+i+2].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))}{" "}
                          </div>
                          <div className="pattern-main">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+2] &&
                              miniCallData[i%5+i+2][miniCallData[0].length - 1]
                                ?.callName}
                          </div>
                        </div>
                        <div id={i%5+i+4}
                          className={`card-box ${
                            gameIndex === i%5+i+4 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {" "}
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+3]?.length > 0 &&
                              miniCallData[i%5+i+3].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))}
                          </div>
                          <div className="pattern-main">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+3] &&
                              miniCallData[i%5+i+3][miniCallData[0].length - 1]
                                ?.callName}
                          </div>
                        </div>
                        <div id={i%5+i+5}
                          className={`card-box ${
                            gameIndex === i%5+i+5 && "current"
                          }`}
                        >
                          <div className="card-pattern">
                            {" "}
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+4]?.length > 0 &&
                              miniCallData[i%5+i+4].map((d, i) => (
                                <span style={{color:d?.color && 'red' }} key={i}>{d?.callName}</span>
                              ))}
                          </div>
                          <div className="pattern-main">
                            {miniCallData.length > 0 &&
                              miniCallData[i%5+i+4] &&
                              miniCallData[i%5+i+4][miniCallData[0].length - 1]
                                ?.callName}
                          </div>
                        </div>
                      </div>
                      })}
                      
                    </div>
                    </div>


                    <div className="row align-items-baseline">
                    <div className="col-lg-4 col-md-4 text-center">  <button onClick={()=>eventStart(event?.id)} disabled={event == undefined ? true : (!event.isEventStarted && !event.isEventFinished) ? false : true } className="btn start-call-btn btn-block">Start Call</button></div>
                    <div className="col-lg-4 col-md-4 text-center">  <button onClick={stopCall} disabled={event == undefined ? true : !event.isEventStarted ? true : (event.isEventStarted && event.isEventFinished || event?.isEventCancelled ) ? true : false } className="btn stop-call-btn btn-block" > Stop Call </button></div>
                    <div className="col-lg-4 col-md-4 text-center">  <button onClick={cancelCall} disabled={event == undefined ? true : !event.isEventStarted ? true :(event.isEventStarted && event.isEventFinished || event?.isEventCancelled ) ? true : false } className="btn cancel-btn btn-block">Cancel Game</button></div>
                  </div>
                  </div>

                </div>
                <div className="row mt-4">
                  <div className="col-sm-12">
                    <div className="left mb-3">
                      <h4 className="d-flex flex-wrap" style={{justifyContent:'space-between',alignItems:'baseline'}}><span>Summary of Results </span>    <span style={{fontSize:'18px'}}>{event?.isEventFinished && `Call Finished :  ${ moment.utc(event?.eventFinishedAt).local().format('YYYY-MM-DD HH:mm')  }` || event?.isEventCancelled && `Event Cancelled :  ${ moment.utc(event?.eventCancelledAt).local().format('YYYY-MM-DD HH:mm')  }`}
                      </span></h4>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="table-responsive">
                      <div className="table-fixed">
                        <table className="my-0 table table-striped">
                          <thead className="thead-dark">
                            <tr>
                              <th>Batter #</th>
                              <th>Square #</th>
                              <th>Batter Name </th>
                              <th>(Top,Bottom) T/B</th>
                              <th>Inning #</th>
                              <th>Result </th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {callerBoardData?.calls ? (
                              callerBoardData?.calls.map(
                                (
                                  {
                                    atBat,
                                    batter,
                                    batterNumber,
                                    callName,
                                    currentPosition,
                                    inning,
                                    topBottom,
                                  },
                                  id
                                ) => {
                                  return (
                                    <tr key={id}>
                                      <td>{callName ==="R"? "" : batterNumber}</td>
                                      <td>{(currentPosition % 25) === 0 ? 25 : (currentPosition % 25) }</td>
                                      <td>{callName ==="R"? "Non Batter Out" : batter} </td>
                                      <td> {topBottom}</td>
                                      <td>{inning}</td>
                                      <td>{callName ==="R"? "" :callName}</td>
                                      <td>{findCallName(callName)}</td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={7}>
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="spacing-bar" />
            </div>
          </div>
          {/*------ Footer bar------ */}
          <AdminFooter />
        </div>
        {/*------ right Body end ---------*/}
      </div>
      <div className="overLay" />
    </div>
  );
};

export default BaseBallCallerBoard;
